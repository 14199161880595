import React, { useEffect, useState, useRef } from 'react';
import AddPageComponent from '../../components/add-page-component/AddPageComponent';
import { useAppConfigProvider } from '../../services/soft-cache-service';
import { XemelgoService } from '../../services/XemelgoService';
import {
  AddPageInputTypeMap,
  validCSVHeaderCheck,
  validCSVDataCheck,
  convertAsciiToHex
} from '../../common/Utilities';
import { getValue, createMultiOrderMap } from './utils/AddOrderUtils';
import {
  AddOrderFeatureFunctionMap,
  processOnboardingPageAttributes
} from '../../common/commonAPICalls';

const FEATURE_ID = 'addOrder';
const PART_CONSTRAINT = 'partConstraint';
const REUSE_SENSOR_PROFILE = 'reuseSensorProfile';
const APP_ID = 'order';

const AddOrderPageFeature = () => {
  const configProvider = useAppConfigProvider(APP_ID);
  const [defaultAttributeMap, setDefaultAttributeMap] = useState({});
  const [customAttributeMap, setCustomAttributeMap] = useState({});
  const [partConstraint, setPartConstraint] = useState({});
  const [reuseSensorProfile, setReuseSensorProfile] = useState({});
  const [generatePrintFile, setGeneratePrintFile] = useState(false);
  const [printFileData, setPrintFileData] = useState({});
  const [loading, setLoading] = useState(true);
  const [showBanner, setShowBanner] = useState(false);
  const bannerMessage = useRef('');
  const [bannerError, setBannerError] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [uploadCsv, setUploadCsv] = useState(false);
  const [enableOnboardingToLocation, setEnableOnboardingToLocation] = useState(false);
  const [workOrderClient, setWorkOrderClient] = useState(null);
  const [locationClient, setLocationClient] = useState(null);
  const [publishClient, setPublishClient] = useState(null);
  const [possibleOnboardingLocationCategories, setPossibleOnboardingLocationCategories] = useState(
    []
  );

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line
  }, []);

  const onLoad = async () => {
    setLoading(true);
    const addOrderConfiguration = configProvider.getValue(FEATURE_ID, 'object');
    const newPartConstraint = configProvider.getValue(PART_CONSTRAINT, 'object') || {};
    const newReuseSensorProfile = configProvider.getValue(REUSE_SENSOR_PROFILE, 'object') || {};

    const {
      defaultAttributeMap: newDefaultAttributeMap = {},
      customAttributeMap: newCustomAttributeMap = {},
      uploadCsv: newUploadCsv,
      enableOnboardingToLocation: newEnableOnboardingToLocation,
      possibleOnboardingLocationCategories: newPossibleOnboardingLocationCategories = [
        'Department'
      ],
      generatePrintFile: newGeneratePrintFile = false,
      printFileData: newPrintFileData = {}
    } = addOrderConfiguration;

    const xemelgoClient = XemelgoService.getClient();
    setWorkOrderClient(xemelgoClient.getWorkOrderClient());
    setLocationClient(xemelgoClient.getLocationClient());
    setPublishClient(xemelgoClient.getPublishClient());

    // Get options from API
    const processedAttributes = await processOnboardingPageAttributes(
      newDefaultAttributeMap,
      newCustomAttributeMap,
      AddOrderFeatureFunctionMap
    );

    setDefaultAttributeMap(processedAttributes.defaultAttributeMap);
    setCustomAttributeMap(processedAttributes.customAttributeMap);
    setPartConstraint(newPartConstraint);
    setReuseSensorProfile(newReuseSensorProfile);
    setGeneratePrintFile(newGeneratePrintFile);
    setPrintFileData(newPrintFileData);
    setUploadCsv(newUploadCsv);
    setEnableOnboardingToLocation(newEnableOnboardingToLocation);
    setPossibleOnboardingLocationCategories(newPossibleOnboardingLocationCategories);
    setLoading(false);
  };

  const generateOrderPayload = async (formData) => {
    const orderPayload = { task: {} };
    const multiOrderValues = {};
    for (const id in formData) {
      const {
        value,
        type,
        autoGenerateValueUsingKey = false,
        multiLine,
        multiOrder,
        numberOnly
      } = formData[id];
      if (multiOrder || autoGenerateValueUsingKey) {
        const multiOrderValue = formData[autoGenerateValueUsingKey || id].value;
        const nonDuplicateArray = [...new Set(multiOrderValue.trim().split('\n'))];
        const result = await workOrderClient.getWorkOrderDuplicates(
          autoGenerateValueUsingKey || id,
          nonDuplicateArray
        );
        const items = result.trackingSessions.map((session) => session.tracksItem[0]);
        multiOrderValues[id] = createMultiOrderMap(
          items,
          nonDuplicateArray,
          autoGenerateValueUsingKey || id
        );
      }
      let finalValue = getValue(
        type,
        value,
        id,
        autoGenerateValueUsingKey,
        multiLine,
        multiOrder,
        formData,
        numberOnly,
        multiOrderValues
      );

      if (finalValue !== null) {
        const { propertyFor = 'item' } = defaultAttributeMap[id] || customAttributeMap[id];
        if (propertyFor !== 'item') {
          orderPayload[propertyFor][id] = finalValue;
        } else {
          orderPayload[id] = finalValue;
        }
      }
    }
    return orderPayload;
  }

  const onSubmit = async (formData, partData) => {
    setLoading(true);
    setLoadingMessage('Creating Order');
    const createAndAssociate = [];
    const associate = [];
    const { allow, autoDisassociationOnReuse } = reuseSensorProfile;

    if (partConstraint.quantity !== 'none') {
      partData.forEach((partEntry) => {
        if (partEntry.new) {
          createAndAssociate.push({
            partNumber: partEntry.number,
            quantity: partEntry.qty * 1,
            name: partEntry.name,
            rfid: partEntry.rfid,
            reuseTags: allow,
            autoDisassociationOnReuse
          });
        } else {
          associate.push({
            id: partEntry.id,
            quantity: partEntry.qty * 1,
            rfid: partEntry.rfid,
            reuseTags: reuseSensorProfile.allow,
            autoDisassociationOnReuse
          });
        }
      });
    }

    const partsToAssociate = { createAndAssociate, associate };

    const orderPayload = await generateOrderPayload(formData);
    try {
      const { identifier, onboardingLocation, task, sensorProfileVid } = orderPayload;
      delete orderPayload.onboardingLocation;
      const onboardingLocationId = enableOnboardingToLocation ? onboardingLocation : null;

      let generatedFilePayload = null;
      if (generatePrintFile) {
        const { headerToPropertyMap, printerName, templateFilePath, delimiter } = printFileData;

        if (
          !headerToPropertyMap ||
          Object.keys(headerToPropertyMap).length === 0 ||
          !printerName ||
          !templateFilePath
        ) {
          throw 'Missing required config data for label printing. Please contact Xemelgo Support for resolution.';
        }

        const finalPayload = [];

        const headers = Object.keys(headerToPropertyMap);
        let isGeneratedFilePayloadDone = false;
        let currentIteration = 0;
        while (!isGeneratedFilePayloadDone) {
          let payload = {};
          let arrayLength = 1;
          headers.forEach((eachHeader) => {
            const payloadValue = orderPayload[headerToPropertyMap[eachHeader]];
            if (!payloadValue) {
              throw `Missing information required for label printing: ${headerToPropertyMap[eachHeader]}. Please contact Xemelgo Support for resolution.`;
            }
            if (Array.isArray(payloadValue)) {
              arrayLength = payloadValue.length;
              payload[eachHeader] = payloadValue[currentIteration];
            } else {
              payload[eachHeader] = payloadValue;
            }
          });
          finalPayload.push(payload);
          currentIteration += 1;
          if (currentIteration >= arrayLength) {
            isGeneratedFilePayloadDone = true;
          }
        }

        generatedFilePayload = {
          headers,
          payload: finalPayload,
          templateFilePath,
          printerName,
          delimiter
        };
      }

      let workOrders;
      delete orderPayload.identifier

      if (typeof identifier === 'string') {
        workOrders = [
          {
            ...orderPayload,
            identifier: identifier.toUpperCase(),
            task: { ...task, identifier: identifier.toUpperCase() }
          }
        ];
        await workOrderClient.createWorkOrderBatch(workOrders, onboardingLocationId, partsToAssociate);
        if (generatedFilePayload) {
          await workOrderClient.generateWorkOrderPrintFile(generatedFilePayload);
          bannerMessage.current =
            ' Print command file uploaded. Label will be printed momentarily.';
        }
        bannerMessage.current = `Order(s) created.${bannerMessage.current}`;
      } else {
        delete orderPayload.sensorProfileVid;
        workOrders = identifier.map((workOrderIdentifier) => {
          const sensorProfile = sensorProfileVid.find((sp) => sp.includes(workOrderIdentifier.replace('/', '')));
          return {
            ...orderPayload,
            sensorProfileVid: sensorProfile,
            identifier: workOrderIdentifier.toUpperCase(),
            task: { ...task, identifier: workOrderIdentifier.toUpperCase() }
          };
        });

        await workOrderClient.createWorkOrderBatch(workOrders,onboardingLocationId, partsToAssociate);
        if (generatedFilePayload) {
          await workOrderClient.generateWorkOrderPrintFile(generatedFilePayload);
          bannerMessage.current =
            ' Print command file uploaded. Label will be printed momentarily.';
        }
        bannerMessage.current = `${identifier.length} order(s) created.${bannerMessage.current}`;
      }

      setBannerError(false);
    } catch (err) {
      let errorMsg = '';
      if (typeof err === 'string') {
        errorMsg = err;
      } else {
        errorMsg = err.message;
      }
      bannerMessage.current = errorMsg;
      setBannerError(true);
    }

    setLoading(false);
    setLoadingMessage('');
    setShowBanner(true);
  };

  const formatPropValue = (propValue, attributeMap) => {
    const { type, transformInput, convertToHex, numberOnly } = attributeMap;

    let finalValue = propValue;
    switch (type) {
      case AddPageInputTypeMap.DATE_PICKER:
        finalValue = propValue ? Date.parse(propValue) : undefined;
        break;

      case AddPageInputTypeMap.CHECK_BOX_GROUP:
        finalValue = propValue ? [propValue] : undefined;
        break;

      case AddPageInputTypeMap.INPUT:
        if (transformInput) {
          if (transformInput === 'toUpperCase') {
            finalValue = propValue.toUpperCase();
            break;
          }
        }
      // eslint-disable-next-line
      case AddPageInputTypeMap.SEARCH_DROP_DOWN:
      case AddPageInputTypeMap.SEARCH_DROP_DOWN_FROM_API:
      default:
        if (numberOnly) {
          finalValue = propValue ? parseFloat(propValue) : 0;
        }
    }

    if (convertToHex) {
      finalValue = convertAsciiToHex(finalValue);
    }

    return finalValue;
  }

  const bannerDisplayHandler = (isError, prefixString, error) => {
    let errorMsg = prefixString;

    if (error) {
      errorMsg += ` - ${error.message ? error.message : error}`;
    }

    setBannerError(isError);
    bannerMessage.current = errorMsg;
    setShowBanner(true);
    setLoading(false);
    setLoadingMessage('');
  }

  const onUploadCSVSubmit = async (dataList) => {

    const locationIdentifierToIdMap = {};
    setLoading(true);

    if (enableOnboardingToLocation) {
      try {
        const allLocations = await locationClient.getLocationsOfCategory(
          possibleOnboardingLocationCategories
        ) || [];

        allLocations.forEach((location) => {
          if (location.identifier) {
            locationIdentifierToIdMap[location.identifier.toUpperCase()] = location.id;
          }
        });
      } catch (err) {
        bannerDisplayHandler(true, 'Loading Error', err);
        return [];
      }
    }

    const locationIdToItemListMap = {}; // for bulk user event payload
    const createPayloadByLocation = {};
    let dataListWithStatus = [];

    // Prepare createPayload
    dataList.forEach(workOrder => {
      const orderPayload = {};
      Object.keys(workOrder).forEach((propKey) => {

        const attributeMap = defaultAttributeMap[propKey] || customAttributeMap[propKey];
        const { propertyFor } = attributeMap;
        const propValue = formatPropValue(workOrder[propKey], attributeMap)

        if (propertyFor) {
          orderPayload[propertyFor] = orderPayload[propertyFor] || {};
          orderPayload[propertyFor][propKey] = propValue;
        } else {
          orderPayload[propKey] = propValue;
        }

      });

      let locationId = locationIdentifierToIdMap[orderPayload.onboardingLocation] || "No Location";
      if (locationId) {
        locationIdToItemListMap[locationId] = locationIdToItemListMap[locationId] || [];
        locationIdToItemListMap[locationId].push(orderPayload.identifier);
      }

      createPayloadByLocation[locationId] = createPayloadByLocation[locationId] || [];
      createPayloadByLocation[locationId].push(orderPayload);
      dataListWithStatus.push(orderPayload);
    })

    // Create Work Orders
    for (const locationId of Object.keys(createPayloadByLocation)) {
      try {
        if (locationId === "No Location") {
          await workOrderClient.createWorkOrderBatch(createPayloadByLocation[locationId]);
        } else {
          await workOrderClient.createWorkOrderBatch(createPayloadByLocation[locationId], locationId);
        }
      } catch (err) {
        bannerDisplayHandler(true, 'Failed to create work order in batch.', err);
        return []
      }
    }

    bannerDisplayHandler(false, 'Order(s) created successfully.');

    return dataListWithStatus;
  };

  return (
    <AddPageComponent
      loading={loading}
      loadingMessage={loadingMessage}
      title="Order"
      validCSVHeaderCheck={(data) => {
        const { valid, errorMessage } = validCSVHeaderCheck(
          data,
          defaultAttributeMap,
          customAttributeMap
        );
        if (!valid) {
          setBannerError(!valid);
          bannerMessage.current = errorMessage;
          setShowBanner(true);
        }
        return valid;
      }}
      validCSVDataCheck={(data) => {
        const { valid, errorMessage } = validCSVDataCheck(
          data,
          defaultAttributeMap,
          customAttributeMap
        );
        if (!valid) {
          setBannerError(!valid);
          bannerMessage.current = errorMessage;
          setShowBanner(true);
        }
        return valid;
      }}
      bannerError={bannerError}
      showBanner={showBanner}
      setShowBanner={setShowBanner}
      bannerMessage={bannerMessage.current}
      onCloseBanner={() => {
        setBannerError(false);
        bannerMessage.current = '';
        setShowBanner(false);
      }}
      defaultAttributeMap={defaultAttributeMap}
      customAttributeMap={customAttributeMap}
      uploadCsv={uploadCsv}
      partConstraint={partConstraint}
      onSubmit={onSubmit}
      onUploadCSVSubmit={onUploadCSVSubmit}
    />
  );
};

export default AddOrderPageFeature;
