export class AlertBuilder {
  constructor(ruleType) {
    this.ruleType = ruleType;
  }

  getRuleType() {
    return this.ruleType;
  }

  /**
   * @param rules
   * @param configuration
   * @return { object }
   */
  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  filterRule(rules, configuration) {
    throw new Error('Not Implemented');
  }

  /**
   *
   * @param ruleClient
   * @param configuration
   * @param defaultTemplate
   * @return {Promise<object>}
   */
  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  createRule(ruleClient, configuration, defaultTemplate) {
    return Promise.reject(new Error('Not Implemented'));
  }

  /**
   * @param rule
   * @param configuration
   * @return {boolean}
   */
  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  hasConditionsExist(rule, configuration) {
    throw new Error('Not Implemented');
  }

  /**
   * @param ruleClient
   * @param rule
   * @param configuration
   * @return {Promise<object[]>}
   */
  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  createRuleConditions(ruleClient, rule, configuration) {
    return Promise.reject(new Error('Not Implemented'));
  }

  /**
   *
   * @param ruleClient
   * @param ruleId
   * @return {Promise<object>}
   */
  // eslint-disable-next-line class-methods-use-this
  getRule(ruleClient, ruleId) {
    // TODO: this is a hack, replace with getRuleById(ruleId)
    return ruleClient.fetchRules().then((result) => {
      const { rules } = result;
      const foundRule = rules.find((rule) => rule.id === ruleId);
      return foundRule;
    });
  }

  /**
   *
   * @param ruleClient
   * @param ruleId
   * @param ruleConditionIds { string[] }
   * @return {Promise<object[]>}
   */
  // eslint-disable-next-line class-methods-use-this
  getRuleConditions(ruleClient, ruleId, ruleConditionIds) {
    // TODO: this is a hack, replace with getRuleConditionById(ruleId, conditionId)
    return ruleClient.fetchRules().then((result) => {
      const { rules } = result;
      const foundRule = rules.find((rule) => rule.id === ruleId);
      if (!foundRule) {
        return undefined;
      }

      const { ruleConditions = [] } = foundRule;
      const foundConditions = ruleConditions.filter((condition) => {
        const { id } = condition;
        return ruleConditionIds.includes(id);
      });
      return foundConditions;
    });
  }
}
