import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import AssetTrackPageFeature from '../../features/asset-track-page-feature/AssetTrackPageFeature';
import BulkUpdateAssetsFeature from '../../features/asset-track-page-feature/BulkUpdateAssetsFeature';
import AssetDetailPage from '../../components/TrackPageComponents/Asset/AssetDetailPage';
import AddAssetFeature from '../../features/asset-track-page-feature/AddAssetFeature';
import AssetTypeDetailPage from '../../components/TrackPageComponents/Asset/AssetTypeDetailPage';

const AssetApp = () => {
  return (
    <Fragment>
      <Route exact path="/asset" component={AssetTrackPageFeature} />
      <Route path="/asset/create" component={AddAssetFeature} />
      <Route path="/asset/detail" component={AssetDetailPage} />
      <Route path="/asset/bulk-update" component={BulkUpdateAssetsFeature} />
      <Route path="/asset/itemType/detail" component={AssetTypeDetailPage} />
    </Fragment>
  );
};

export default AssetApp;
