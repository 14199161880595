import React from 'react';
import PropTypes from 'prop-types';
import GridViewStyle from './GridViewComponent.module.css';

const GridViewComponent = ({ children }) => {
  if (!children.length) {
    return (
      <div
        style={{
          padding: '70px',
          color: 'gray',
          textAlign: 'center'
        }}
      >
        No Items
      </div>
    );
  }
  return <div className={GridViewStyle.container}>{children}</div>;
};

export default GridViewComponent;

GridViewComponent.defaultProps = {
  children: null
};

GridViewComponent.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element)
};
