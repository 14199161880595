import { getFormattedDate, getCurrentTimestamp } from '../../../common/utils';

const AddPageInputTypeMap = {
  DATE_PICKER: 'datepicker',
  INPUT: 'input',
  SEARCH_DROP_DOWN: 'dropdown',
  CHECK_BOX_GROUP: 'checkboxGroup',
  SEARCH_DROP_DOWN_FROM_API: 'dropdownWithOptionsFromAPI'
};

export function getStringWithZeroes(numOfZeroes) {
  let stringWithZeroes = '';
  for (let i = 0; i < numOfZeroes; i++) {
    stringWithZeroes += '0';
  }
  return stringWithZeroes;
}

export const generateSensorProfileVidUsingInput = (input) => {
  if (typeof input === 'string') {
    const inputValue = input ?? '';
    const inputString = inputValue.toString().trim();

    if (inputString && inputString.length > 0) {
      const formattedDate = getFormattedDate(getCurrentTimestamp(), 'YYYYMMDD');
      const concatString = formattedDate + inputString;

      if (concatString.length % 8 === 0) {
        return concatString;
      }

      const stringWithZeroes = getStringWithZeroes(8 - (concatString.length % 8));
      return `${formattedDate}${stringWithZeroes}${inputString}`;
    }
    return null;
  }
  if (Array.isArray(input)) {
    return input.map((eachInput) => {
      const inputValue = eachInput ?? '';
      const inputString = inputValue.toString().trim();

      if (inputString && inputString.length > 0) {
        const formattedDate = getFormattedDate(getCurrentTimestamp(), 'YYYYMMDD');
        const concatString = formattedDate + inputString;

        if (concatString.length % 8 === 0) {
          return concatString;
        }

        const stringWithZeroes = getStringWithZeroes(8 - (concatString.length % 8));
        return `${formattedDate}${stringWithZeroes}${inputString}`;
      }
      return null;
    });
  }
};

export const getMultiLineValues = (
  value,
  isMultiOrder,
  generateForSensorProfile,
  multiOrderValues = {}
) => {
  let newValue = value.trim().split('\n');
  if (isMultiOrder) {
    const valueMap = { ...multiOrderValues };
    const generatedList = [];
    newValue.forEach((tempValue) => {
      if (!valueMap[tempValue]) {
        valueMap[tempValue] = 1;
      }
      generatedList.push(
        `${tempValue}${!generateForSensorProfile ? '/' : ''}${valueMap[tempValue].toLocaleString(
          'en-US',
          {
            minimumIntegerDigits: 2
          }
        )}`
      );
      valueMap[tempValue]++;
    });
    return generatedList;
  }

  return newValue;
};

export const createMultiOrderMap = (list, values, property) => {
  const tempMap = {};
  list.forEach((element) => {
    for (const value of values) {
      if (element[property].includes(value)) {
        if (!tempMap[value]) {
          tempMap[value] = 1;
        } else {
          tempMap[value]++;
        }
        break;
      }
    }
  });
  for (const value in tempMap) {
    tempMap[value]++;
  }
  return tempMap;
};

export const getValue = (
  type,
  value,
  id,
  autoGenerateValueUsingKey,
  multiLine,
  multiOrder,
  formData,
  numberOnly,
  multiOrderValues = {}
) => {
  let finalValue;
  switch (type) {
    case AddPageInputTypeMap.DATE_PICKER:
      finalValue = value ? value.getTime() : undefined;
      break;
    case AddPageInputTypeMap.SEARCH_DROP_DOWN_FROM_API:
    case AddPageInputTypeMap.SEARCH_DROP_DOWN:
      finalValue = value && typeof value === 'object' ? value.value : value;
      break;
    case AddPageInputTypeMap.CHECK_BOX_GROUP:
      finalValue = [];
      Object.keys(value).forEach((eachId) => {
        if (value[eachId]) {
          finalValue.push(eachId);
        }
      });
      break;
    case AddPageInputTypeMap.INPUT:
      if (autoGenerateValueUsingKey) {
        if (formData[autoGenerateValueUsingKey]) {
          finalValue = generateSensorProfileVidUsingInput(
            formData[autoGenerateValueUsingKey].multiLine
              ? getMultiLineValues(
                  formData[autoGenerateValueUsingKey].value,
                  formData[autoGenerateValueUsingKey].multiOrder,
                  id === 'sensorProfileVid',
                  multiOrderValues[id]
                )
              : formData[autoGenerateValueUsingKey].value
          );
        }
      } else {
        if (multiLine) {
          finalValue = getMultiLineValues(value, multiOrder, undefined, multiOrderValues[id]);
        } else if (numberOnly && !value) {
          finalValue = 0;
        } else {
          finalValue = value;
        }
      }
      break;
    default:
      finalValue = value;
  }
  return finalValue;
};
