import React, { Component, Fragment } from 'react';
import './EditableTableComponent.css';
import uuid from 'uuid';
import RowWithActionComponent from './RowWithActionComponent';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import Spinner from 'react-bootstrap/Spinner';

/**
* Validate the result
* @params:
*  validatedResult: {
*     errorExists: [true|false]
*  }
}
* */
function hasValidationError(validatedResult) {
  return validatedResult && !!validatedResult.errorExists;
}

export default class EditableTableComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultDataList: props.dataList ? [...props.dataList] : null,
      dataList: props.dataList ? [...props.dataList] : null,
      inputErrorMap: {},
      editModeMap: {},
      loading: props.loading || false,
      /**
       * This map keeps track of all the revert-callback functions from children component
       * Format:
       *  {
       *      <rowId>: <callbackFunction>
       *  }
       */
      rowRevertFnMap: {},

      /**
       * This map keeps track of the approved callback when the changed is accepted
       */
      rowApprovedFnMap: {},

      /**
       * The map to keep track of any row-component which has contents pending for update action
       */
      rowChangedMap: {}
    };

    this.addRow = this.addRow.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSave = this.handleSave.bind(this);

    this.registerEventCallback = this.registerEventCallback.bind(this);
    this.unregisterEventCallback = this.unregisterEventCallback.bind(this);
    this.revertRow = this.revertRow.bind(this);
    this.handleSignalEvent = this.handleSignalEvent.bind(this);
    this.recordRowDataChanged = this.recordRowDataChanged.bind(this);
    this.recordRecordMarkForDelete = this.recordRecordMarkForDelete.bind(this);
    this.handleOptionsResetFn = this.handleOptionsResetFn.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.hasChanges === undefined) {
      this.setState({
        defaultDataList: newProps.dataList ? [...newProps.dataList] : null,
        dataList: newProps.dataList ? [...newProps.dataList] : null,
        rowChangedMap: {}
      });
    }
  }

  /**
   * This method handle reverting the content of a row-component
   *  It signal given child component to revert back its content, and remove the row record from 'rowChangedMap'
   *
   * */
  revertRow(rowId) {
    const revertRowFnMap = this.state.rowRevertFnMap;
    const editModeMap = this.state.editModeMap;
    const revertRowFn = revertRowFnMap[rowId];
    if (revertRowFn) {
      revertRowFn();
    }

    const updatedRowMap = this.state.rowChangedMap;

    delete updatedRowMap[rowId];
    delete editModeMap[rowId];

    this.setState({
      rowChangedMap: updatedRowMap,
      editModeMap
    });
  }

  /**
   * Handle updating 'rowChangedMap'. If row content has changed, it will register the rowId and its new content.
   *  If row content has been reverted or edited back to origin content, the registered record will be removed from the map.
   */
  recordRowDataChanged(rowId, payload) {
    const changed = payload.changed;
    const updatedRowMap = this.state.rowChangedMap;
    const editModeMap = this.state.editModeMap;

    if (changed) {
      updatedRowMap[rowId] = payload.data;
      editModeMap[rowId] = payload.data.id;
    } else {
      delete editModeMap[rowId];
      delete updatedRowMap[rowId];
    }

    this.setState({
      rowChangedMap: updatedRowMap,
      editModeMap
    });
  }

  recordRecordMarkForDelete(rowId, payload) {
    const data = payload.data;
    const editModeMap = this.state.editModeMap;
    if (data._pendingNew) {
      const dataList = [...this.state.dataList];
      const foundIndex = this.state.dataList.findIndex((data) => {
        return data.id === rowId;
      });

      if (foundIndex >= 0) {
        dataList.splice(foundIndex, 1);
        delete editModeMap[rowId];
        this.setState({
          dataList,
          editModeMap
        });
      }
    } else {
      this.recordRowDataChanged(rowId, payload);
    }
  }

  /**
   * This function is passed to children component so they can signal to parent [this] component for specific event
   */
  handleSignalEvent(event, rowId, payload) {
    switch (event) {
      case 'subscriptionChanged':
        this.props.handleSubscriptionChange(rowId, payload);
        break;
      case 'revert':
        this.revertRow(rowId);
        break;
      case 'editToggled':
      case 'contentChanged':
        this.recordRowDataChanged(rowId, payload);
        break;
      case 'delete':
        this.recordRecordMarkForDelete(rowId, payload);
        break;
      default:
        console.log(`Unsupported Event[Name:${event}]`);
        break;
    }
  }

  /**
   * Controller function: signal all children to revert its content
   */
  handleCancel() {
    const rowIds = Object.keys(this.state.rowChangedMap);
    this.handleOptionsResetFn('unselect-all');
    rowIds.forEach((rowId) => this.revertRow(rowId));
    this.props.cancelFunc && this.props.cancelFunc();

    this.setState({
      rowChangedMap: {},
      inputErrorMap: {},
      dataList: [...this.state.defaultDataList],
      editModeMap: {}
    });
  }

  handleOptionsResetFn(event, data) {
    switch (event) {
      case 'unselect-all':
        this.props.headers.forEach((header) => {
          if (header.cell.data) {
            header.cell.data.forEach((item) => {
              if (item._selected) delete item._selected;
            });
          }
        });
        break;

      case 'unselect-one':
        this.props.headers.forEach((header) => {
          if (header.cell.data) {
            header.cell.data.forEach((item) => {
              if (item.objectTypeId === data.locationId && item._selected) delete item._selected;
            });
          }
        });
        break;

      default:
        // do nothing
        break;
    }
  }

  /**
   * Pass into children component so they can register callback for each event.
   *
   */
  registerEventCallback(event, rowId, callbackFn) {
    switch (event) {
      case 'revert':
        const dataRollbackFnMap = this.state.rowRevertFnMap;
        dataRollbackFnMap[rowId] = callbackFn;
        this.setState({ rowRevertFnMap: dataRollbackFnMap });
        break;
      case 'approve':
        const approvalMap = this.state.rowApprovedFnMap;
        approvalMap[rowId] = callbackFn;
        this.setState({ rowApprovedFnMap: approvalMap });
        break;
      default:
        console.log(`Unsupported Event[Name:${event}]`);
        break;
    }
  }

  unregisterEventCallback(event, rowId) {
    switch (event) {
      case 'revert':
        const dataRollbackFnMap = this.state.rowRevertFnMap;
        delete dataRollbackFnMap[rowId];
        this.setState({ rowRevertFnMap: dataRollbackFnMap });
        break;
      case 'approve':
        const approvalMap = this.state.rowApprovedFnMap;
        delete approvalMap[rowId];
        this.setState({ rowApprovedFnMap: approvalMap });
        break;
      default:
        console.log(`Unsupported Event[Name:${event}]`);
        break;
    }
  }

  handleSave() {
    this.setState({
      loading: true
    })
    const payloads = Object.values(this.state.rowChangedMap);
    let validatedObject = {};
    let editModeMap = this.state.editModeMap;
    if (this.props.handleValidationFn) {
      validatedObject = this.props.handleValidationFn(payloads);
    }

    if (this.props.handleChangesFn && !hasValidationError(validatedObject)) {
      this.handleOptionsResetFn('unselect-all');
      editModeMap = {};
      this.props.handleChangesFn(payloads).then((ids) => {
        if (this.state.rowApprovedFnMap) {
          ids.forEach((id) => {
            this.state.rowApprovedFnMap[id]();
          });
        }
      });
    } else {
      this.setState({ loading: false });
    }

    this.setState({
      inputErrorMap: validatedObject.errorMap ? validatedObject.errorMap : {},
      editModeMap
    });
  }

  addRow() {
    const emptyData = {
      _pendingNew: true,
      id: uuid.v4()
    };

    this.props.headers.forEach((header) => {
      emptyData[header.id] = '';
    });
    const editModeMap = this.state.editModeMap;

    const dataList = this.state.dataList;
    dataList.push(emptyData);
    editModeMap[emptyData.id] = emptyData.id;

    this.setState({
      dataList,
      editModeMap
    });
  }

  render() {
    const props = this.props;
    const { loading, dataList, rowChangedMap, editModeMap, inputErrorMap } = this.state;
    let changedRows = [];
    let editModeRows = [];
    let className = 'editable-table-component';
    if (props.className) {
      className += ` ${props.className}`;
    }
    changedRows = Object.values(rowChangedMap).filter(
      (value) => value._event !== 'pending_edit'
    );
    const hasChanged = changedRows.length > 0;
    editModeRows = Object.keys(editModeMap);
    const editMode = editModeRows.length > 0;
    let shouldDisableAdd = false;
    if(this.props.disableAddItem) {
      shouldDisableAdd = this.props.disableAddItem(dataList);
    }
    return (
      <div className={className}>
        {!dataList || loading ? (
          <div className="loading-circle">
            <Spinner animation="border" />
          </div>
        ) : (
          <Fragment>
            <table className="editable-table">
              <thead className="editable-table-header">
                <tr className="editable-table-header-row">
                  {props.headers &&
                    props.headers.map((header, index) => (
                      <th className="editable-table-header-cell" key={`header-${index}`}>
                        {header.displayName}
                        {header.showTooltip && (
                          <Tooltip
                            title={header.tooltipComponent()}
                            classes={{ root: 'tool-tip-container' }}
                          >
                            <HelpOutlineIcon classes={{ root: 'tool-tip-icon' }} />
                          </Tooltip>
                        )}
                      </th>
                    ))}
                  {/* reserve the empty header for icons column */}
                  <th className="icons-header">&nbsp;</th>
                </tr>
              </thead>

              <tbody className="editable-table-data">
                {dataList &&
                  dataList.map((data) => (
                    <RowWithActionComponent
                      id={data.id}
                      className="editable-table-data-row"
                      key={data.id}
                      headers={this.props.headers}
                      data={data}
                      readOnly={!data._pendingNew}
                      enabledEditAction={!data._pendingNew && !data.disableEdit}
                      disableDeleteAction={data.disableDelete}
                      signalEventFn={this.handleSignalEvent}
                      registerEventCallbacksFn={this.registerEventCallback}
                      unregisterEventCallbacksFn={this.unregisterEventCallback}
                      inputError={inputErrorMap[data.id]}
                      handleAutoFillFn={this.props.handleAutoFillFn}
                      handleOptionsResetFn={this.handleOptionsResetFn}
                    />
                  ))}
              </tbody>
            </table>

            {!shouldDisableAdd && (
              <div className="add-new">
                <button onClick={this.addRow} className="add-new-button">
                  + Add Item
                </button>
              </div>
            )}

            <div
              className={`table-buttons ${
                hasChanged || editMode || props.hasChanges ? '' : 'hidden'
              }`}
            >
              <button className="cancel-button" onClick={this.handleCancel}>
                Cancel
              </button>
              <button
                className="default-button"
                disabled={(!hasChanged && !props.hasChanges) || loading}
                onClick={this.handleSave}
              >
                {loading ? <Spinner as="span" animation="border" /> : 'Save'}
              </button>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}
