import React, { useState, useEffect, useRef } from 'react';
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import { AddRounded, RemoveRounded, GpsFixedRounded } from '@material-ui/icons';
import './FloorMap.css';
import * as turf from '@turf/turf';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
// import Dataset from './features.geojson';

const FLOOR_PLAN_SOURCE = 'floor_plan_image';
const FLOOR_PLAN_LAYER = 'floor_plan_layer';
const LOCATIONS_SOURCE = 'each_location';
const LOCATION_COLOR_LAYER = 'color_status';
const LOCATION_OUTLINE_LAYER = 'color_status_outline';
const LOCATION_SELECTED_OUTLINE_SHADOW_LAYER = 'selected_outline_shadow';
const LOCATION_SELECTED_OUTLINE_LAYER = 'selected_outline';
const LOCATION_HOVERED_OUTLINE_LAYER = 'priority_outline';

mapboxgl.accessToken =
  'pk.eyJ1IjoibGVvbGVvbGVvNTIwNTIwIiwiYSI6ImNsMDFibnd4dTBzZXkzaW1sNm55eHQ3eWwifQ.uZTT_6tAuVue-I7wd8tbgQ';

const getTargetFeatureHelper = (features, currentZoom) => {
  let currentFeature = null;
  features.forEach((eachFeature) => {
    const { zoom, minzoom } = eachFeature.properties;
    if ((!currentFeature || currentFeature.properties.zoom <= zoom) && currentZoom > minzoom) {
      currentFeature = eachFeature;
    }
  });

  const area = turf.points(currentFeature.geometry.coordinates[0]);
  const center = turf.center(area).geometry.coordinates;

  return [currentFeature, center];
};

const getMapLayerFilter = (zoom) => {
  return ['<=', 'minzoom', zoom];
};

const getMapLayerOpacity = (zoom, selectedStateId = null) => {
  return [
    'let',
    'opacityByZoom',
    [
      '-',
      1,
      [
        'case',
        ['<=', ['-', zoom, ['get', 'zoom']], 0],
        ['/', ['abs', ['-', zoom, ['get', 'zoom']]], ['-', ['get', 'zoom'], ['get', 'minzoom']]],
        ['>', ['-', zoom, ['get', 'zoom']], 0],
        ['/', ['abs', ['-', zoom, ['get', 'zoom']]], ['-', ['get', 'maxzoom'], ['get', 'zoom']]],
        0
      ]
    ],
    [
      'let',
      'finalOpacity',
      [
        'case',
        ['<=', ['var', 'opacityByZoom'], 0.1],
        0.1,
        ['>=', ['var', 'opacityByZoom'], 0.4],
        0.4,
        ['var', 'opacityByZoom']
      ],
      [
        'case',
        ['>', zoom, ['get', 'maxzoom']],
        0,
        [
          'any',
          ['==', selectedStateId === null, true],
          ['boolean', ['feature-state', 'selected'], false]
        ],
        ['var', 'finalOpacity'],
        0.1
      ]
    ]
  ];
};

const getStatusForLocation = (colorMap) => {
  if (Object.keys(colorMap).length) {
    return [
      'match',
      ['get', 'id'],
      ...Object.keys(colorMap).reduce((accumulator, eachColor) => {
        colorMap[eachColor].locations.forEach((eachLocationId) => {
          accumulator.push(eachLocationId);
          accumulator.push(eachColor);
        });
        return accumulator;
      }, []),
      'transparent'
    ];
  } else {
    return 'transparent';
  }
};

const FloorMap = React.forwardRef(
  (
    {
      initialViewStates,
      colorMap,
      mapStyleURL,
      floorPlanTilesetURL,
      locationsSourceDatasetURL,
      locationsSourceDatasetName,
      onLocationClicked,
      showColorIndicator,
      showNavigationControl,
      mapContainerClassName,
      mapNavigationControlClassName,
      mapColorIndicatorClassName,
      children,
      mapCameraPadding,
      popupRenderer,
      popupEnabled
    },
    ref
  ) => {
    const mapContainerComponentRef = useRef(null);
    const mapRef = useRef(null);
    const hoverStateIdRef = useRef(null);
    const primarySelectedStateIdRef = useRef(null);
    const secondarySelectedStateIdRef = useRef(null);
    const popupRef = useRef(null);
    const [zoom, setZoom] = useState(initialViewStates.zoom);

    const [loading, setLoading] = useState(true);
    const [map, setMap] = useState(null);
    const [popup, setPopup] = useState(null);

    // component did mount: starting the map initialing process
    useEffect(() => {
      // initialize map only once
      mapRef.current = new mapboxgl.Map({
        ...initialViewStates,
        container: mapContainerComponentRef.current,
        style: mapStyleURL,
        maxPitch: 0,
        padding: mapCameraPadding
      });

      popupRef.current = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false
      });

      return () => {
        mapRef.current.remove(); //unmount the map properly
      };
    }, []);

    useEffect(() => {
      setMap(mapRef.current);
    }, [mapRef.current]);

    useEffect(() => {
      setPopup(popupRef.current);
    }, [popupRef.current]);

    // initializing map
    useEffect(() => {
      // wait for map to initialize
      if (!map) {
        return;
      }
      map.on('idle', () => {
        map.resize();
      });

      map.on('load', () => {
        // adding floor plan to the map
        map
          .addSource(FLOOR_PLAN_SOURCE, {
            url: floorPlanTilesetURL,
            type: 'raster'
          })
          .addLayer({
            id: FLOOR_PLAN_LAYER,
            type: 'raster',
            source: FLOOR_PLAN_SOURCE
          });
        //

        map
          .addSource(LOCATIONS_SOURCE, {
            url: locationsSourceDatasetURL,
            type: 'vector',
            promoteId: 'id'
          })
          // .addSource(LOCATIONS_SOURCE, {
          //   type: 'geojson',
          //   data: Dataset,
          //   generateId: true,
          //   promoteId: 'id'
          // })
          .addLayer({
            id: LOCATION_COLOR_LAYER,
            type: 'fill',
            source: LOCATIONS_SOURCE,
            'source-layer': locationsSourceDatasetName,
            filter: getMapLayerFilter(zoom),
            layout: {
              'fill-sort-key': ['get', 'zoom']
            },
            paint: {
              'fill-color': getStatusForLocation(colorMap),
              'fill-opacity': getMapLayerOpacity(zoom, primarySelectedStateIdRef.current)
            }
          })
          .addLayer({
            id: LOCATION_OUTLINE_LAYER,
            type: 'line',
            source: LOCATIONS_SOURCE,
            'source-layer': locationsSourceDatasetName,
            filter: getMapLayerFilter(zoom),
            layout: {
              'line-join': 'round',
              'line-sort-key': ['get', 'zoom']
            },
            paint: {
              'line-color': '#E2E2EA',
              'line-width': 3
            }
          })
          .addLayer({
            id: LOCATION_SELECTED_OUTLINE_SHADOW_LAYER,
            type: 'line',
            source: LOCATIONS_SOURCE,
            'source-layer': locationsSourceDatasetName,
            layout: {
              'line-join': 'round'
            },
            paint: {
              'line-width': 3,
              'line-gap-width': 5,
              'line-blur': 10,
              'line-color': [
                'case',
                ['boolean', ['feature-state', 'selected'], false],
                'white',
                'transparent'
              ],
              'line-opacity': 0.5
            }
          })
          .addLayer({
            id: LOCATION_SELECTED_OUTLINE_LAYER,
            type: 'line',
            source: LOCATIONS_SOURCE,
            'source-layer': locationsSourceDatasetName,
            layout: {
              'line-join': 'round'
            },
            paint: {
              'line-width': 3,
              'line-color': [
                'case',
                ['boolean', ['feature-state', 'selected'], false],
                getStatusForLocation(colorMap),
                'transparent'
              ]
            }
          })
          .addLayer({
            id: LOCATION_HOVERED_OUTLINE_LAYER,
            type: 'line',
            source: LOCATIONS_SOURCE,
            'source-layer': locationsSourceDatasetName,
            layout: {
              'line-join': 'round'
            },
            paint: {
              'line-width': 3,
              'line-color': [
                'case',
                ['boolean', ['feature-state', 'selected'], false],
                'transparent',
                ['boolean', ['feature-state', 'hover'], false],
                '#4a90ff',
                'transparent'
              ]
            }
          });

        map.on('zoom', () => {
          setZoom(map.getZoom());
          const currentZoom = map.getZoom();
          setZoom(currentZoom);
        });

        //"mouseleave" event for map when hovered location changed
        map.on('mousemove', LOCATION_COLOR_LAYER, (event) => {
          const currentZoom = map.getZoom();
          const [currentFeature, center] = getTargetFeatureHelper(event.features, currentZoom);
          if (currentFeature) {
            hoverLocation(currentFeature, center);
          }
        });

        //"mouseleave" event for map when hovered location changed
        map.on('mouseleave', LOCATION_COLOR_LAYER, () => {
          resetHover();
        });

        // the "click" even for map when selected location changed
        map.on('click', LOCATION_COLOR_LAYER, (event) => {
          const primarySelectedStateId = primarySelectedStateIdRef.current;
          const secondarySelectedStateId = secondarySelectedStateIdRef.current;

          const currentZoom = map.getZoom();
          const [currentFeature, center] = getTargetFeatureHelper(event.features, currentZoom);

          if (currentFeature) {
            selectLocation(currentFeature, center);
          }
        });
        setLoading(false);

        resetMap();
      });
    }, [map]);

    // update color_status & color_status_outline map layer filter when zoom level changed
    // update the color_status map layer fill paint when zoom & selected location changed
    useEffect(() => {
      if (!map || loading) {
        return;
      }
      map.setFilter(LOCATION_COLOR_LAYER, getMapLayerFilter(zoom));
      map.setFilter(LOCATION_OUTLINE_LAYER, getMapLayerFilter(zoom));
      map.setPaintProperty(
        LOCATION_COLOR_LAYER,
        'fill-opacity',
        getMapLayerOpacity(zoom, primarySelectedStateIdRef.current)
      );
      map.setPaintProperty(LOCATION_COLOR_LAYER, 'fill-color', getStatusForLocation(colorMap));
    }, [zoom, map, primarySelectedStateIdRef.current, colorMap, loading]);

    // update padding to the map camera view when mapCameraPadding props is changed
    useEffect(() => {
      // when map isn't ready or mapCameraPadding Props isn't there or not positive numbers, skip the function
      if (
        !map ||
        !Object.keys(mapCameraPadding).every((eachKey) => {
          return mapCameraPadding[eachKey] > 0;
        })
      ) {
        return;
      }

      map.flyTo({
        padding: {
          top: mapCameraPadding.top || 0,
          bottom: mapCameraPadding.bottom || 0,
          left: mapCameraPadding.left || 0,
          right: mapCameraPadding.right || 0
        },
        duration: 650
      });
    }, [
      map,
      mapCameraPadding?.top,
      mapCameraPadding?.bottom,
      mapCameraPadding?.left,
      mapCameraPadding?.right
    ]);

    // triggered "onLocationClicked" callback from props when a location is selected
    useEffect(() => {
      if (!map) {
        return;
      }
      const primarySelectedStateId = primarySelectedStateIdRef.current;
      const secondarySelectedStateId = secondarySelectedStateIdRef.current;

      const selectedLocationId =
        map
          .querySourceFeatures(LOCATIONS_SOURCE, { sourceLayer: locationsSourceDatasetName })
          .find((eachFeature) => {
            return eachFeature.id === (primarySelectedStateId || secondarySelectedStateId);
          })?.properties?.id || null;

      if (selectedLocationId) {
        onLocationClicked(selectedLocationId);
      }
    }, [map, primarySelectedStateIdRef.current, secondarySelectedStateIdRef.current]);

    // set the map camera to be the initialViewStates
    const resetMap = () => {
      if (!map) {
        return;
      }
      if (initialViewStates.bounds) {
        map.fitBounds(initialViewStates.bounds, {
          bearing: initialViewStates.bearing,
          padding: mapCameraPadding
        });
      } else {
        map.flyTo({ ...initialViewStates, padding: mapCameraPadding });
      }
    };

    const selectLocation = (currentFeature, center) => {
      const primarySelectedStateId = primarySelectedStateIdRef.current;
      const secondarySelectedStateId = secondarySelectedStateIdRef.current;
      const newZoom =
        primarySelectedStateId === currentFeature.id
          ? currentFeature.properties.maxzoom === 24 && currentFeature.properties.zoom + 0.5 <= 24
            ? currentFeature.properties.zoom + 0.5
            : currentFeature.properties.maxzoom
          : currentFeature.properties.zoom;

      map.flyTo({
        center,
        bearing: initialViewStates.bearing,
        zoom: newZoom
      });

      if (
        (primarySelectedStateId !== null && primarySelectedStateId !== currentFeature.id) ||
        secondarySelectedStateId !== null
      ) {
        map.setFeatureState(
          {
            source: LOCATIONS_SOURCE,
            sourceLayer: locationsSourceDatasetName,
            id:
              primarySelectedStateId !== null && primarySelectedStateId !== currentFeature.id
                ? primarySelectedStateId
                : secondarySelectedStateId
          },
          {
            selected: false
          }
        );
      }

      map.setFeatureState(
        {
          source: LOCATIONS_SOURCE,
          sourceLayer: locationsSourceDatasetName,
          id: currentFeature.id
        },
        {
          selected: true
        }
      );

      if (currentFeature.id === primarySelectedStateId) {
        secondarySelectedStateIdRef.current = primarySelectedStateId;
      }

      popup.remove();

      primarySelectedStateIdRef.current =
        currentFeature.id === primarySelectedStateId
          ? currentFeature.properties.maxzoom === 24
            ? currentFeature.id
            : null
          : currentFeature.id;
    };

    const hoverLocation = (currentFeature, center) => {
      const hoverStateId = hoverStateIdRef.current;
      if (hoverStateId !== null) {
        map.setFeatureState(
          {
            source: LOCATIONS_SOURCE,
            sourceLayer: locationsSourceDatasetName,

            id: hoverStateId
          },
          { hover: false }
        );
      }
      popup.remove();
      map.setFeatureState(
        {
          source: LOCATIONS_SOURCE,
          sourceLayer: locationsSourceDatasetName,
          id: currentFeature.id
        },
        { hover: true }
      );
      hoverStateIdRef.current = currentFeature.id;

      if (!currentFeature.state?.selected && popupEnabled) {
        popup
          .setLngLat(center)
          .setHTML(ReactDOMServer.renderToString(popupRenderer(currentFeature.properties.id)))
          .addTo(map);
      }
    };

    const resetHover = () => {
      const hoverStateId = hoverStateIdRef.current;
      if (hoverStateId !== null) {
        map.setFeatureState(
          {
            source: LOCATIONS_SOURCE,
            sourceLayer: locationsSourceDatasetName,
            id: hoverStateId
          },
          { hover: false }
        );
      }
      hoverStateIdRef.current = null;
      popup.remove();
    };

    const buildMapRefFunctions = () => {
      const getMapFeatureWithCenterByLocationId = (locationId) => {
        if (!map) {
          return [,];
        }
        const feature = map
          .querySourceFeatures(LOCATIONS_SOURCE, { sourceLayer: locationsSourceDatasetName })
          .find((eachFeature) => {
            return eachFeature.properties.id === locationId;
          });
        let center;
        if (feature) {
          const area = turf.points(feature.geometry.coordinates[0]);
          center = turf.center(area).geometry.coordinates;
        }
        return [feature, center];
      };
      return {
        selectLocation: (locationId) => {
          const [currentFeature, center] = getMapFeatureWithCenterByLocationId(locationId);
          if (currentFeature) {
            selectLocation(currentFeature, center);
          }
        },
        hoverLocation: (locationId) => {
          const [currentFeature, center] = getMapFeatureWithCenterByLocationId(locationId);
          if (currentFeature) {
            hoverLocation(currentFeature, center);
          }
        },
        resetHover: resetHover
      };
    };
    if (ref) {
      ref.current = buildMapRefFunctions();
    }

    return (
      <div ref={mapContainerComponentRef} className={`map_container ${mapContainerClassName}`}>
        {showNavigationControl && (
          <div className={`map_tool_container map_control ${mapNavigationControlClassName} `}>
            <button
              className="map_control_button"
              onClick={() => {
                map.zoomIn();
              }}
            >
              <AddRounded />
            </button>
            <button
              className="map_control_button"
              onClick={() => {
                map.zoomOut();
              }}
            >
              <RemoveRounded />
            </button>
            <button className="map_control_button" onClick={resetMap}>
              <GpsFixedRounded />
            </button>
          </div>
        )}
        {showColorIndicator && (
          <ul
            className={`map_tool_container color_indicator_container ${mapColorIndicatorClassName} `}
          >
            {Object.keys(colorMap).map((eachColor) => {
              const { label } = colorMap[eachColor];
              return (
                <li className="color_indicator" key={`${label}_${eachColor}`}>
                  <div
                    className="color_icon"
                    style={{
                      backgroundColor: eachColor
                    }}
                  ></div>
                  <p className="color_label">{label}</p>
                </li>
              );
            })}
          </ul>
        )}
        {children}
      </div>
    );
  }
);

FloorMap.defaultProps = {
  initialViewStates: {},
  colorMap: {},
  onLocationClicked: () => {},
  showColorIndicator: true,
  showNavigationControl: true,
  mapContainerClassName: '',
  mapNavigationControlClassName: '',
  mapColorIndicatorClassName: '',
  popupRenderer: () => {},
  popupEnabled: false
  // locationsSourceDatasetName: '' // must removed
};

FloorMap.propTypes = {
  initialViewStates: PropTypes.shape({
    center: (props, propName, componentName) => {
      if (
        !Array.isArray(props[propName]) ||
        props[propName].length !== 2 ||
        !props[propName].every((each) => {
          return each % 1 !== 0;
        })
      ) {
        return new Error(
          `Invalid prop \`${propName}\` supplied to \`initialViewState\` of \`${componentName}\`.`
        );
      }
    },
    zoom: PropTypes.number,
    bearing: PropTypes.number,
    bounds: (props, propName, componentName) => {
      if (
        props[propName] &&
        (!Array.isArray(props[propName]) ||
          props[propName].length !== 2 ||
          !props[propName].every((each) => {
            return (
              Array.isArray(each) &&
              each.length === 2 &&
              each.every((inner) => {
                return inner % 1 !== 0;
              })
            );
          }))
      ) {
        return new Error(
          `Invalid prop \`${propName}\` supplied to \`initialViewState\` of \`${componentName}\`.`
        );
      }
    },
    maxBounds: (props, propName, componentName) => {
      if (
        props[propName] &&
        (!Array.isArray(props[propName]) ||
          props[propName].length !== 2 ||
          !props[propName].every((each) => {
            return (
              Array.isArray(each) &&
              each.length === 2 &&
              each.every((inner) => {
                return inner % 1 !== 0;
              })
            );
          }))
      ) {
        return new Error(
          `Invalid prop \`${propName}\` supplied to \`initialViewState\` of \`${componentName}\`.`
        );
      }
    }
  }),
  colorMap: PropTypes.objectOf(
    PropTypes.shape({ label: PropTypes.string, locations: PropTypes.arrayOf(PropTypes.string) })
  ),
  mapStyleURL: PropTypes.string.isRequired,
  floorPlanTilesetURL: PropTypes.string.isRequired,
  locationsSourceDatasetURL: PropTypes.string.isRequired,
  locationsSourceDatasetName: PropTypes.string.isRequired,
  onLocationClicked: PropTypes.func,
  showColorIndicator: PropTypes.bool,
  showNavigationControl: PropTypes.bool,
  mapContainerClassName: PropTypes.string,
  mapNavigationControlClassName: PropTypes.string,
  mapColorIndicatorClassName: PropTypes.string,
  mapCameraPadding: (props, propName, componentName) => {
    if (
      props[propName] &&
      (typeof props[propName] !== 'object' ||
        props[propName].top < 0 ||
        props[propName].bottom < 0 ||
        props[propName].left < 0 ||
        props[propName].right < 0)
    ) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`mapComeraPadding\` of \`${componentName}\`. top/bottom/left/right must be positive number`
      );
    }
  },
  popupRenderer: PropTypes.func,
  popupEnabled: PropTypes.bool
};

export default FloorMap;
