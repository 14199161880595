import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';
import { Div } from '../../../../../components/div';
import { AddLocationFormSection } from '../add-location-form-section';
import './style.css';

export const AddLocationFormBody = ({
  submitRequestTicket,
  numberOfSection,
  onSubmit,
  properties,
  providedArgument,
  categoryName,
  creationError
}) => {
  const [sectionIds, setSectionIds] = useState([]);
  const [sectionPayloadMap, setSectionPayloadMap] = useState({});
  const [hasError, setHasError] = useState(false);
  const [showError, setShowError] = useState(creationError);
  const [submitTicket, setSubmitTicket] = useState(null);
  const error = creationError
    ? creationError
    : 'Action was not completed.Please review highlighted items and try again.';

  /**
   * Name: resetForNewRequestTicket
   * Reset all necessary stages to prepare for new submit request.
   */
  useEffect(() => {
    let cancelled = false;
    const unsubscribeCallback = () => {
      cancelled = true;
    };

    if (!cancelled) {
      setSectionPayloadMap({});
      setHasError(false);
    }
    setSubmitTicket(submitRequestTicket);
    return unsubscribeCallback;
  }, [submitRequestTicket]);

  /**
   * Name: processNumberOfSectionChanged
   * Update number of request section.
   */
  useEffect(() => {
    let cancelled = false;
    const unsubscriptionCallback = () => {
      cancelled = true;
    };

    const diff = numberOfSection - sectionIds.length;
    // means if diff is 0;
    if (!diff) {
      return unsubscriptionCallback;
    }

    const addNew = diff > 0;
    const secIds = [...sectionIds];
    for (let i = 0; i < Math.abs(diff); i++) {
      if (addNew) {
        const sectionId = v4();
        secIds.push(sectionId);
      } else {
        secIds.pop();
      }
    }

    if (!cancelled) {
      setSectionIds(secIds);
    }

    return () => {
      cancelled = true;
    };
  }, [numberOfSection, sectionIds]);

  /**
   * Name: waitForSectionFeedbackAndSubmit
   * This effect collect all the sections' payload and pass back to the onSubmit callback.
   */
  useEffect(() => {
    let cancelled = false;
    const unsubscribeEffect = () => {
      cancelled = true;
    };

    // wait until all the payload section has been collected
    if (Object.keys(sectionPayloadMap).length !== numberOfSection) {
      return unsubscribeEffect;
    }

    // wait for all the validation feedback to come back before marking the ticket done.
    if (hasError) {
      if (!cancelled) {
        // setHasError(false);
        setSectionPayloadMap(false);
        setSubmitTicket(null);
      }
      return unsubscribeEffect;
    }

    // regroup the payload
    const payloads = [];
    Object.keys(sectionPayloadMap).forEach((sectionId) => {
      const payload = sectionPayloadMap[sectionId];
      payloads.push(payload);
    });

    if (!cancelled) {
      onSubmit(payloads);
      setHasError(false);
      setSectionPayloadMap(false);
      setSubmitTicket(null);
    }

    return unsubscribeEffect;
  }, [sectionPayloadMap, hasError, numberOfSection, onSubmit]);

  useEffect(() => {
    setShowError(hasError || creationError);
  }, [hasError, creationError]);

  /**
   * This callback handles each section submit feedback and register the payload to the stage.
   * @type {(...args: any[]) => any}
   */
  const validateAndSubmitPayloadUponSubmitClicked = useCallback(
    (sectionResult) => {
      const { hasError: sectionHasError, payload, sectionId } = sectionResult;
      setHasError((err) => err || sectionHasError);
      setSectionPayloadMap((map) => {
        const cloned = { ...map };
        cloned[sectionId] = payload;
        return cloned;
      });
    },
    [setHasError]
  );

  return (
    <Div className="add-location-form-body">
      {showError && (
        <Div className="error-message-group">
          <Div className="error-title">Error</Div>
          <Div className="error-message">{error}</Div>
        </Div>
      )}
      {sectionIds.map((sectionId) => {
        return (
          <AddLocationFormSection
            submitRequestTicket={submitTicket}
            sectionId={sectionId}
            providedArgument={providedArgument}
            properties={properties}
            key={sectionId}
            onSubmit={validateAndSubmitPayloadUponSubmitClicked}
            categoryName={categoryName}
          />
        );
      })}
    </Div>
  );
};

AddLocationFormBody.defaultProps = {
  numberOfSection: 1,
  submitRequestTicket: null,
  onSubmit: () => {},
  properties: [],
  providedArgument: {}
};

AddLocationFormBody.propTypes = {
  categoryName: PropTypes.string.isRequired,
  numberOfSection: PropTypes.number,
  submitRequestTicket: PropTypes.string,
  onSubmit: PropTypes.func,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired
        })
      )
    })
  ),
  providedArgument: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired
        })
      )
    ])
  )
};
