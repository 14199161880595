import React, { useState, memo } from 'react';
import { useStore } from 'react-hookstore';
import { MDBInput } from 'mdbreact';
import PropTypes from 'prop-types';
import { loggingUserStore } from '../../../state-managements/stores/login-store';

const LoginForm = memo(({ logo, fnSubmit, fnForgotPassword }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [, setLoggingUser] = useStore(loggingUserStore);

  const onUsernameInput = (event) => {
    const user = event.target.value;
    setUsername(user);
  };

  const onPasswordInput = (event) => {
    const pwd = event.target.value;
    setPassword(pwd);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const trimmedUserName = username.trim();
    if (trimmedUserName.includes(' ')) {
      setErrorMessage('Username cannot contain any space');
    } else {
      setLoggingUser(trimmedUserName);
      try {
        await fnSubmit(trimmedUserName, password);
      } catch (error) {
        if (error.code === 'InvalidParameterException') {
          setErrorMessage('Please enter your password');
        } else {
          setErrorMessage(error.message);
        }
      }
    }
  };

  const onForgotPassword = () => {
    setLoggingUser(username);
    fnForgotPassword(username);
  };

  return (
    <div className="content-body login-page-body">
      <div className="container login-form" style={{ textAlign: 'initial' }}>
        <form className="login-form-background">
          <img src={logo} className="brand-logo" alt="brand-logo" />
          <div className="error_message">{errorMessage}</div>
          <div className="form-group">
            <MDBInput
              className="input-default"
              label="Email"
              id="username"
              onInput={onUsernameInput}
            />
          </div>
          <div className="form-group">
            <MDBInput
              className="input-default"
              label="Password"
              id="password"
              onInput={onPasswordInput}
              type="password"
            />
          </div>

          <button type="submit" onClick={onSubmit} className="add-user-button">
            Log In
          </button>

          <div className="center-align">
            <button onClick={onForgotPassword} className="asText" type="button">
              Forgot Password?
            </button>
          </div>
        </form>
      </div>
    </div>
  );
});

LoginForm.propTypes = {
  logo: PropTypes.string.isRequired,
  fnSubmit: PropTypes.func.isRequired,
  fnForgotPassword: PropTypes.func.isRequired
};

export { LoginForm };
