import React, { useEffect, useState } from 'react';
import BulkUpdateComponent from '../../components/bulk-update-component/BulkUpdateComponent';
import { useAppConfigProvider } from '../../services/soft-cache-service';
import { XemelgoService } from '../../services/XemelgoService';
import { BulkUpdateInputTypeMap, validCSVHeaderCheck } from '../../common/Utilities';
import {
  BulkUpdateFeatureFunctionMap,
  processBulkUpdateAttributes
} from '../../common/commonAPICalls';

const APP_ID = 'itemTypesManagement';
const FEATURE_ID = 'bulkUpdate';

const BulkUpdateItemTypesFeature = () => {
  const configProvider = useAppConfigProvider(APP_ID);
  const [defaultAttributeMap, setDefaultAttributeMap] = useState({});
  const [loading, setLoading] = useState(true);
  const [showBanner, setShowBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState('');
  const [bannerError, setBannerError] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [itemTypeClient, setItemTypeClient] = useState(null);
  const [errorExists, setErrorExists] = useState(false);

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line
  }, []);

  const onLoad = async () => {
    setLoading(true);

    const xemelgoClient = XemelgoService.getClient();
    const bulkUpdateConfig = configProvider.getValue(FEATURE_ID, 'object') || {};

    const { attributeMap: newDefaultAttributeMap = {} } = bulkUpdateConfig;

    const processedAttributes = await processBulkUpdateAttributes(
      newDefaultAttributeMap,
      {},
      BulkUpdateFeatureFunctionMap,
      {},
      true // filterOnly
    );

    setItemTypeClient(xemelgoClient.getItemTypeClient());
    setDefaultAttributeMap(processedAttributes.defaultAttributeMap);
    setLoading(false);
  };

  const closeBannerFn = () => {
    setBannerError(false);
    setBannerMessage('');
    setShowBanner(false);
  };

  const validateBulkUpdateCSVInput = async (data, defaultAttributeMap, customAttributeMap) => {
    let valid = true;
    let errorMessage;
    let processedAttributes;

    try {
      // Get options from API
      setLoading(true);
      processedAttributes = await processBulkUpdateAttributes(
        defaultAttributeMap,
        customAttributeMap,
        BulkUpdateFeatureFunctionMap,
        {},
        false // filterOnly
      );
      setDefaultAttributeMap(processedAttributes.defaultAttributeMap);
    } catch (e) {
      setLoading(false);
      valid = false;
      errorMessage = `${e}`;
      return { valid, errorMessage };
    }

    data.forEach((each) => {
      Object.keys(each).forEach((id) => {
        const attributeMap = processedAttributes.defaultAttributeMap[id];
        if (attributeMap) {
          const { type, label, options, validateCSVInputWithOptions, numberOnly } = attributeMap;
          if (each[id]) {
            switch (type) {
              case BulkUpdateInputTypeMap.DATE_PICKER:
                const date = Date.parse(each[id]);
                if (isNaN(date)) {
                  valid = false;
                  errorMessage = `Cannot import csv file: ${each[id]} is not a valid date`;
                }
                break;
              // for future use
              // case BulkUpdateInputTypeMap.SEARCH_DROP_DOWN_FROM_API:
              //   if (typeof each[id] === 'string' && validateCSVInputWithOptions) {
              //     const inputValue = each[id].trim().toUpperCase();
              //     if (options && options.length) {
              //       if (
              //         !options.find((option) => option.label.trim().toUpperCase() === inputValue)
              //       ) {
              //         valid = false;
              //         errorMessage = `Invalid ${label} - ${each[id]}`;
              //       }
              //     }
              //   }
              //   break;
              case BulkUpdateInputTypeMap.INPUT:
                const inputValue = each[id].trim().toUpperCase();
                if (validateCSVInputWithOptions && options) {
                  if (!options[inputValue]) {
                    valid = false;
                    errorMessage = `Invalid ${label} - ${each[id]}`;
                  }
                } else if (numberOnly && isNaN(inputValue)) {
                  valid = false;
                  errorMessage = `Invalid ${label} - ${each[id]}. ${label} is not a valid number.`;
                }
                break;
              // For future use
              // case BulkUpdateInputTypeMap.CHECK_BOX_GROUP:
              // case BulkUpdateInputTypeMap.SEARCH_DROP_DOWN:
              default:
                break;
            }
          }
        } else {
          valid = false;
          errorMessage = 'Cannot import csv file: Please check the provided data';
        }
      });
    });
    setLoading(false);
    setErrorExists(!valid);
    return { valid, errorMessage };
  };

  const onUploadCSVSubmit = async (dataList) => {
    const dataListWithStatus = [];
    let hasError = false;
    setLoading(true);
    setLoadingMessage(`Updating Item Type(s)`);

    const itemTypePayloadList = dataList.map((eachData) => {
      const itemTypePayload = {};
      let attributeMap = {};
      Object.keys(eachData).forEach((id) => {
        attributeMap = defaultAttributeMap[id];
        const value = eachData[id];
        const { type, transformInput, options, numberOnly } = attributeMap;
        let finalValue;

        switch (type) {
          case BulkUpdateInputTypeMap.DATE_PICKER:
            finalValue = value ? Date.parse(value) : undefined;
            break;
          case BulkUpdateInputTypeMap.INPUT:
            if (value && transformInput) {
              if (transformInput === 'toUpperCase') {
                finalValue = value.toUpperCase();
                break;
              }
            }
            if (numberOnly) {
              finalValue = value ? parseFloat(value) : undefined;
            } else {
              finalValue = value;
            }
            break;
          /* For future use
           case BulkUpdateInputTypeMap.SEARCH_DROP_DOWN_FROM_API:
           case BulkUpdateInputTypeMap.SEARCH_DROP_DOWN:
           case BulkUpdateInputTypeMap.CHECK_BOX_GROUP:
          */
          default:
            finalValue = value;
            break;
        }

        itemTypePayload[id] = finalValue;

        switch (id) {
          case 'identifier':
            if (options && finalValue && options[finalValue.trim().toUpperCase()]) {
              itemTypePayload.id = options[finalValue.trim().toUpperCase()];
            }
            break;
          default:
            break;
        }
      });

      return itemTypePayload;
    });

    for (let i = 0; i < itemTypePayloadList.length; i++) {
      const itemTypePayload = itemTypePayloadList[i];
      setLoadingMessage(`Updating item type ${i + 1} of ${itemTypePayloadList.length}`);
      const processedData = { ...itemTypePayload };
      try {
        const fieldsToUpdate = { ...itemTypePayload, id: undefined, identifier: undefined };
        if (itemTypePayload.id) {
          await itemTypeClient.updateItemType(itemTypePayload.id, fieldsToUpdate);
        }
      } catch (err) {
        const errMessage = err.errorMessage ? err.errorMessage.toString() : err.toString();
        setBannerError(true);
        hasError = true;
        processedData.errorMessage = errMessage;
      }
      dataListWithStatus.push(processedData);
    }

    setBannerError(hasError);
    setBannerMessage(
      hasError
        ? bannerMessage ||
            'One or more item types could not be updated: Please check the following item type(s) and retry.'
        : 'Item Type(s) updated successfully.'
    );

    setShowBanner(true);
    setLoading(false);
    setLoadingMessage('');
    return dataListWithStatus;
  };

  return (
    <BulkUpdateComponent
      loading={loading}
      loadingMessage={loadingMessage}
      title="Item Types"
      validCSVHeaderCheck={(data) => {
        const { valid, errorMessage } = validCSVHeaderCheck(data, defaultAttributeMap, {});
        if (!valid) {
          setBannerError(!valid);
          setBannerMessage(errorMessage);
          setShowBanner(true);
        }
        return valid;
      }}
      validCSVDataCheck={async (data) => {
        const { valid, errorMessage } = await validateBulkUpdateCSVInput(
          data,
          defaultAttributeMap,
          {}
        );
        if (!valid) {
          setBannerError(!valid);
          setBannerMessage(errorMessage);
          setShowBanner(true);
        }
        return valid;
      }}
      bannerError={bannerError}
      showBanner={showBanner}
      bannerMessage={bannerMessage}
      onCloseBanner={() => {
        closeBannerFn();
      }}
      defaultAttributeMap={defaultAttributeMap}
      onUploadCSVSubmit={onUploadCSVSubmit}
      errorExists={errorExists}
      setErrorExistsFn={setErrorExists}
      closeBannerFn={closeBannerFn}
    />
  );
};

export default BulkUpdateItemTypesFeature;
