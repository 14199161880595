/**
 * @param xemelgoClient
 * @param locationCategories
 * @return {Promise<*>}
 */
export const getAssetsGroupByLocations = (xemelgoClient, locationCategories) => {
  const trackPageClient = xemelgoClient.getTrackPageClient();
  return trackPageClient.getAssetPageData(['location'], locationCategories).then((response) => {
    return response.locations ?? [];
  });
};
