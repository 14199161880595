import React from 'react';
import ProgressBarStyle from './ProgressBar.module.css';
import PropTypes from 'prop-types';

export const ProgressBar = ({ percentage = 0, subText, color = '#166ED5' }) => {
  return (
    <div className={ProgressBarStyle.progress_bar_container}>
      <div
        className={ProgressBarStyle.progress_bar}
        style={{ width: `${percentage}%`, background: color }}
      />
      <div className={ProgressBarStyle.subtext}>{subText}</div>
    </div>
  );
};

ProgressBar.propTypes = {
  percentage: PropTypes.number,
  subText: PropTypes.string,
  color: PropTypes.string
};
