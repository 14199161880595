import React from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { useInputStyle, userInputLabelStyle } from './AddPageInputsStyle';

const CREATION_ID = 'create-id';

const SearchDropDown = ({
  id,
  options,
  onChange,
  label,
  value,
  required,
  error,
  onBlur,
  canInput,
  hasCreationOption,
  creationOptionLabel,
  disabled,
  placeholder,
  dropdownContainerClasses,
  dropdownContainerStyle,
  InputContainerProps
}) => {
  const getOptions = () => {
    if (hasCreationOption) {
      return options.concat([{ id: CREATION_ID, value: null, label: creationOptionLabel }]);
    }
    return options;
  };

  const inputStyle = useInputStyle();
  const inputLabelStyle = userInputLabelStyle();
  return (
    <Autocomplete
      classes={dropdownContainerClasses}
      style={disabled ? { borderRadius: 5, backgroundColor: '#F5F5F5' } : dropdownContainerStyle}
      disabled={disabled}
      id={id}
      renderOption={(option) => {
        const { id: optionId, label: optionLabel } = option;
        return (
          <div style={optionId === CREATION_ID ? { color: '#166ED5' } : {}}>{optionLabel}</div>
        );
      }}
      filterOptions={(allOptions, { inputValue }) => {
        return allOptions.filter((eachOption) => {
          const { id: eachOptionId, label: eachOptionLabel = '' } = eachOption;
          return (
            eachOptionId === CREATION_ID ||
            eachOptionLabel.toLowerCase().includes(inputValue.toLowerCase())
          );
        });
      }}
      freeSolo={canInput}
      onBlur={onBlur || null}
      options={getOptions()}
      getOptionLabel={(option) => option.label || ''}
      value={!canInput ? value : null}
      inputValue={typeof value === 'string' ? value : null}
      onChange={(event, object) => onChange(id, object)}
      onInputChange={
        canInput
          ? (event, text) => {
              onChange(id, text);
            }
          : null
      }
      renderInput={(params) => {
        const { InputLabelProps, InputProps } = params;
        return (
          <TextField
            {...params}
            variant="outlined"
            value={value}
            label={label}
            error={error}
            required={required}
            placeholder={placeholder}
            InputLabelProps={{ ...InputLabelProps, classes: inputLabelStyle }}
            InputProps={
              InputContainerProps
                ? { ...InputProps, ...InputContainerProps }
                : { ...InputProps, classes: inputStyle }
            }
          />
        );
      }}
    />
  );
};

export default SearchDropDown;

SearchDropDown.defaultProps = {
  id: '',
  options: [],
  label: '',
  value: null,
  onChange: () => {},
  error: false,
  required: false,
  onBlur: () => {},
  canInput: false,
  hasCreationOption: false,
  creationOptionLabel: '',
  disabled: false,
  placeholder: '',
  dropdownContainerClasses: {},
  dropdownContainerStyle: {},
  InputContainerProps: null
};

SearchDropDown.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  required: PropTypes.bool,
  onBlur: PropTypes.func,
  options: PropTypes.array,
  canInput: PropTypes.bool,
  hasCreationOption: PropTypes.bool,
  creationOptionLabel: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  dropdownContainerClasses: PropTypes.object,
  dropdownContainerStyle: PropTypes.object,
  InputContainerProps: PropTypes.object
};
