import { makeStyles, createMuiTheme } from '@material-ui/core/styles';

export const AddPageTheme = createMuiTheme({
  palette: {
    primary: { 500: '#166ED5' }
  }
});

export const useFormControlStyle = makeStyles(() => {
  return {
    root: {
      color: '#92929D',
      width: 250
    }
  };
});
export const useFormGroupStyle = makeStyles(() => {
  return {
    root: {
      marginLeft: 25
    }
  };
});

export const useFormLabelStyle = makeStyles(() => {
  return {
    root: {
      color: '#343434',
      fontSize: '1rem',
      textTransform: 'capitalize',
      '&$focused:not($error)': {
        color: '#343434'
      }
    },
    focused: {},
    error: {}
  };
});

export const useInputIconStyle = makeStyles(() => {
  return {
    root: {
      fontSize: 12,
      padding: 3,
      color: '#92929D'
    }
  };
});

export const useInputStyle = makeStyles((theme) => {
  return {
    root: {
      width: ({ width }) => width || 250,
      minWidth: 250,
      maxWidth: ({ width }) => width || 250,
      color: '#343434',
      '&:hover:not($disabled):not($error):not($focused) $notchedOutline': {
        borderColor: '#343434'
      },
      '&$focused $notchedOutline': {
        borderColor: theme.palette.primary,
        borderWidth: 2
      },
      '&$disabled $notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
        color: '#343434'
      },
      '&$disabled': {
        color: '#343434'
      }
    },
    error: {},
    disabled: {},
    notchedOutline: {
      borderColor: '#92929D'
    },
    focused: {
      color: '#343434'
    },
    adornedEnd: {
      paddingRight: 5
    }
  };
});

export const usePhoneInputStyle = makeStyles((theme) => {
  return {
    root: {
      width: ({ width }) => width || 190,
      minWidth: 190,
      maxWidth: 340,
      color: '#343434',
      '&:hover:not($disabled):not($error):not($focused) $notchedOutline': {
        borderColor: '#343434'
      },
      '&$focused $notchedOutline': {
        borderColor: theme.palette.primary,
        borderWidth: 2
      },
      '&$disabled $notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
        color: '#343434'
      },
      '&$disabled': {
        color: '#343434'
      }
    },
    error: {},
    disabled: {},
    notchedOutline: {
      borderColor: '#92929D'
    },
    focused: {
      color: '#343434'
    },
    adornedEnd: {
      paddingRight: 5
    }
  };
});

export const userInputLabelStyle = makeStyles((theme) => {
  return {
    root: {
      color: '#92929D',
      zIndex: 0,
      '&$disabled': {
        color: '#92929D'
      },
      '&$disabled $asterisk': {
        color: '#92929D'
      },
      '&$shrink:not($disabled)': {
        color: '#343434'
      },
      '&$shrink $asterisk': {
        color: '#343434'
      }
    },
    asterisk: {},
    disabled: {},
    focused: {
      color: theme.palette.primary
    },
    shrink: {}
  };
});
