import React, { useContext, useState } from 'react';

const DisplayBannerContext = React.createContext();

const initialState = {
  showBanner: false,
  bannerHasError: false,
  bannerTitle: '',
  bannerSubTitle: ''
};

export const useDisplayBannerContext = () => {
  return useContext(DisplayBannerContext);
};

export const DisplayBannerContextProvider = ({ children }) => {
  const [showBanner, setShowBanner] = useState(initialState.showBanner);
  const [bannerHasError, setBannerHasError] = useState(initialState.bannerHasError);
  const [bannerTitle, setBannerTitle] = useState(initialState.bannerTitle);
  const [bannerSubTitle, setBannerSubTitle] = useState(initialState.bannerSubTitle);

  return (
    <DisplayBannerContext.Provider
      value={{
        showBanner,
        setShowBanner,
        bannerHasError,
        setBannerHasError,
        bannerTitle,
        setBannerTitle,
        bannerSubTitle,
        setBannerSubTitle
      }}
    >
      {children}
    </DisplayBannerContext.Provider>
  );
};
