import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import AlertsPage from '../pages/alert/AlertsPage';
import UserProfilePage from '../pages/users/UserProfilePage';
import AssetApp from '../apps/asset-app/AssetApp';
import { UnauthorizedPage } from '../pages/error/UnauthorizedPage';
import AuthorizedProcessAppRoute from './AuthorizedProcessAppRoute';
import InventoryRequestsPage from '../pages/inventory-requests/InventoryRequestsPage';
import OrderApp from '../apps/order-app/OrderApp';
import ShipmentsApp from '../apps/shipments-app/ShipmentsApp';
import { MyFacility } from '../apps/my-facility';
import AddInventoryFeature from '../features/inventory-track-page-feature/AddInventoryFeature';
import UsersApp from '../apps/users-app/UsersApp';
import InventoryApp from '../apps/inventory-app/InventoryApp';
import ReportsApp from '../apps/reports-app/ReportsApp';
import PurchaseOrderApp from '../apps/purchase-order-app/PurchaseOrderApp';
import ItemTypesManagementApp from '../apps/item-types-management-app/ItemTypesManagementApp';
import ManagementDashboardApp from 'apps/management-dashboard-app/ManagementDashboardApp';

// RedirectPath is on temporary used before dashboard page is implemented
const Routes = ({ redirectPath }) => {
  return (
    <Switch>
      <Route path="/error/authorized" component={UnauthorizedPage} />
      <Route exact path="/">
        {/* // RedirectPath is on temporary used before dashboard page is implemented */}
        <Redirect to={redirectPath} />
      </Route>
      <AuthorizedProcessAppRoute path="/work-order" component={OrderApp} />
      <AuthorizedProcessAppRoute path="/shipments" component={ShipmentsApp} />
      <AuthorizedProcessAppRoute path="/inventory/create" component={AddInventoryFeature} />
      <AuthorizedProcessAppRoute path="/inventory" component={InventoryApp} />
      <AuthorizedProcessAppRoute path="/users" component={UsersApp} />
      <AuthorizedProcessAppRoute path="/my-facility" component={MyFacility} />
      <AuthorizedProcessAppRoute path="/alerts" component={AlertsPage} />
      <AuthorizedProcessAppRoute path="/profile" component={UserProfilePage} />
      <AuthorizedProcessAppRoute path="/asset" component={AssetApp} />
      <AuthorizedProcessAppRoute path="/inventory-requests" component={InventoryRequestsPage} />
      <AuthorizedProcessAppRoute path="/reports" component={ReportsApp} />
      <AuthorizedProcessAppRoute
        path="/purchase-order"
        component={PurchaseOrderApp}
        authorizingRoles={['custom:admin']} // TODO verify this preferred over isSuperAdmin
      />
      <AuthorizedProcessAppRoute path="/item-types-management" component={ItemTypesManagementApp} />
      <AuthorizedProcessAppRoute path="/management-dashboard" component={ManagementDashboardApp} />
    </Switch>
  );
};

export default Routes;

Routes.propTypes = {
  redirectPath: PropTypes.string.isRequired
};
