import React, { useState, useEffect, Fragment } from 'react';
import { getFormattedDate } from '../../common/Utilities';
import { withRouter } from 'react-router-dom';
import ListView from '../TrackPageComponents/ListView';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardBody } from 'mdbreact';
import ReportPageStyle from './ReportsPageComponent.module.css';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import TextField from '@material-ui/core/TextField';
import { FilterListComponent } from '../filter-list-component/FilterListComponent';
import CheckBoxListComponent from '../checkbox-list-component/CheckBoxListComponent';

const useDatePickerStyle = makeStyles(() => {
  return {
    root: {
      width: '165px',
      height: '40px'
    }
  };
});

const useInputIconStyle = makeStyles(() => {
  return {
    root: {
      paddingRight: '0px',
      paddingLeft: '0px',
      paddingTop: '0px',
      paddingBottom: '0px'
    }
  };
});

const ReportsPageComponent = ({
  listViewHeaders,
  listViewData,
  listViewClick,
  toggleModal,
  enableCreateReport
}) => {
  const [showFilters, setShowFilters] = useState(true);
  const [filterInput, setFilterInput] = useState('');
  const [reportTypes, setReportTypes] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sideFilterStructureMap, setSideFilterStructureMap] = useState({});

  const datePickerStyle = useDatePickerStyle();
  const inputIconStyle = useInputIconStyle();

  useEffect(() => {
    const typeList = [];
    listViewData.forEach((element) => {
      if (!typeList.includes(element.type)) {
        typeList.push(element.type);
      }
    });

    const newFilterMap = {
      type: {
        id: 'type',
        idList: reportTypes,
        checkboxList: typeList.map((each) => {
          return {
            id: each.toLowerCase(),
            label: each,
            value: reportTypes.includes(each.toLowerCase())
          };
        })
      }
    };

    setSideFilterStructureMap(newFilterMap);
  }, [listViewData, reportTypes]);

  const filteredDataList = listViewData.filter((element) => {
    const { Key, lastModified, type } = element;
    const date = new Date(lastModified);

    //set hrs, mins, sec, & ms to 0 to compare dates only
    date.setHours(0, 0, 0, 0);
    if (startDate && date.getTime() < startDate) {
      return false;
    }
    if (endDate && date.getTime() > endDate) {
      return false;
    }
    if (reportTypes.length && !reportTypes.includes(type.toLowerCase())) {
      return false;
    }
    return Key.toLowerCase().includes(filterInput.toLowerCase());
  });

  const onStartChange = (value) => {
    if (!value) {
      setStartDate(null);
    } else {
      const date = new Date(value);
      date.setHours(0, 0, 0, 0);
      setStartDate(date.getTime());
    }
  };

  const onEndChange = (value) => {
    if (!value) {
      setEndDate(null);
    } else {
      const date = new Date(value);
      date.setHours(0, 0, 0, 0);
      setEndDate(date.getTime());
    }
  };

  const getFilterIds = () => {
    const ids = reportTypes.map((reportType) => {
      return {
        id: reportType.toLowerCase(),
        label: reportType
      };
    });

    if (startDate) {
      ids.push({
        id: 'startDate',
        label: `Created After ${getFormattedDate(startDate, 'MM/DD/YYYY')}`
      });
    }

    if (endDate) {
      ids.push({
        id: 'endDate',
        label: `Created Before ${getFormattedDate(endDate, 'MM/DD/YYYY')}`
      });
    }

    return ids;
  };

  const handleFilterClick = (ids) => {
    let newReportType = [...reportTypes];
    newReportType = newReportType.filter((selectedFilter) => !ids.includes(selectedFilter));

    if (ids.includes('startDate')) {
      setStartDate(null);
    }

    if (ids.includes('endDate')) {
      setEndDate(null);
    }

    setReportTypes(newReportType);
  };

  return (
    <Card className={ReportPageStyle.card}>
      <CardBody className={ReportPageStyle.card_body}>
        <div className={ReportPageStyle.title_container}>
          <div className={ReportPageStyle.title_group}>
            <div className={ReportPageStyle.title_icon}>
              <LibraryBooksOutlinedIcon />
            </div>
            <p className={ReportPageStyle.title_value}>Reports</p>
          </div>
        </div>
        <div className={ReportPageStyle.main_container}>
          <div className={ReportPageStyle.left_main_container}>
            {enableCreateReport && (
              <div onClick={toggleModal} className={ReportPageStyle.create_button}>
                Create Report
              </div>
            )}
            <div className={ReportPageStyle.filter_title_container}>
              <p className={ReportPageStyle.filter_title}>Filter By</p>
              <p
                onClick={() => {
                  setShowFilters(!showFilters);
                }}
                className={ReportPageStyle.hide_filter}
              >{`${showFilters ? 'Hide Filters' : 'Show Filters'}`}</p>
            </div>
            {showFilters && (
              <Fragment>
                <div className={ReportPageStyle.side_filter_input}>
                  <p className={ReportPageStyle.side_filter_sub_title}>Type</p>
                  <CheckBoxListComponent
                    filterStructureMap={sideFilterStructureMap}
                    onChange={(newMap) => {
                      setReportTypes(newMap.type.idList);
                      setSideFilterStructureMap(newMap);
                    }}
                    InputComponent={({ onChange }) => {
                      return (
                        <TextField
                          onChange={onChange}
                          variant="outlined"
                          size="small"
                          label="Search"
                        />
                      );
                    }}
                  />
                </div>
                <div className={ReportPageStyle.side_filter_input}>
                  <p className={ReportPageStyle.side_filter_sub_title}>Date</p>
                  <div className={ReportPageStyle.date_container}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableFuture
                        size="small"
                        id="startTime"
                        label="Start Date"
                        variant="inline"
                        format="MM/dd/yyyy"
                        inputVariant="outlined"
                        placeholder="MM/DD/YYYY"
                        disableToolbar
                        autoOk
                        onChange={onStartChange}
                        invalidDateMessage={null}
                        maxDateMessage={null}
                        minDateMessage={null}
                        value={startDate}
                        KeyboardButtonProps={{ classes: inputIconStyle }}
                        InputProps={{ classes: datePickerStyle }}
                        shouldDisableDate={(value) => {
                          if (endDate) {
                            const date = new Date(value);
                            date.setHours(0, 0, 0, 0);
                            const endDateVar = new Date(endDate);
                            endDateVar.setHours(0, 0, 0, 0);
                            return date.getTime() > endDateVar.getTime();
                          }
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                <div className={ReportPageStyle.side_filter_input}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      label="End Date"
                      size="small"
                      id="endTime"
                      variant="inline"
                      format="MM/dd/yyyy"
                      inputVariant="outlined"
                      placeholder="MM/DD/YYYY"
                      disableToolbar
                      autoOk
                      value={endDate}
                      onChange={onEndChange}
                      maxDateMessage={null}
                      minDateMessage={null}
                      invalidDateMessage={null}
                      KeyboardButtonProps={{ classes: inputIconStyle }}
                      InputProps={{ classes: datePickerStyle }}
                      shouldDisableDate={(value) => {
                        if (startDate) {
                          const date = new Date(value);
                          date.setHours(0, 0, 0, 0);
                          const startDateVar = new Date(startDate);
                          startDateVar.setHours(0, 0, 0, 0);
                          return date.getTime() < startDateVar.getTime();
                        }
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Fragment>
            )}
          </div>
          <div className={ReportPageStyle.main_view_container}>
            <FilterListComponent list={getFilterIds()} onClick={handleFilterClick} />
            <div className={ReportPageStyle.filter_bar_container}>
              <span className={`fa fa-search ${ReportPageStyle.filter_bar_icon}`} />
              <input
                title="Filter Reports"
                value={filterInput}
                placeholder="Type to filter reports"
                onChange={({ currentTarget }) => {
                  setFilterInput(currentTarget.value);
                }}
                className={ReportPageStyle.filter_bar}
              />
            </div>
            <ListView
              headerStructureList={listViewHeaders}
              dataList={filteredDataList}
              handleClick={listViewClick}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default withRouter(ReportsPageComponent);
