import React, { Component, Fragment } from 'react';
import { Card, CardBody, Input } from 'mdbreact';
import { Modal, Alert } from 'react-bootstrap';
import UserProfilePageStyle from './UserProfilePage.module.css';
import { isValidPhoneNumber } from 'react-phone-number-input';
import ProfileForm from '../../components/ProfileForm';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

import AlertService from '../../services/AlertService';
import RestService from '../../services/RestService';
import AuthService from '../../services/AuthService';
import ConfigurationService from '../../services/ConfigurationService';
import { LocalCacheService } from '../../services/local-cache-service';
import { UserProfile } from '../../domains/user-profile';
import { XemelgoService } from 'services/XemelgoService';
import { SessionStorageService } from 'services/session-storage-service';

const attributeNameToProfileNameMap = {
  given_name: 'givenName',
  family_name: 'familyName',
  email: 'email',
  phone_number: 'phone',
  'custom:profile_image_url': 'imageOptional'
};

/**
 * This method contains the knowledge of how data is stored in the state.
 *  It compares the default value and newly entered value and construct proper payload if value has changed.
 * @param state
 * @return {*}
 */
function buildUpdateAttributePayload(state) {
  // reverse the map: profile name attribute name
  const nameMap = Object.keys(attributeNameToProfileNameMap).reduce((map, key) => {
    const value = attributeNameToProfileNameMap[key];
    map[value] = key;
    return map;
  }, {});

  const profileFieldNames = Object.keys(nameMap);

  // compare new and existing value, only update when new value is different than existing one
  let payload = null;
  profileFieldNames.forEach((field) => {
    let newValue = state.payload[field];

    if (!newValue) {
      return;
    }

    newValue = newValue.trim();
    const existingValue =
      state.current[field] && typeof state.current[field] !== 'object'
        ? state.current[field].trim()
        : '';
    if (newValue === existingValue) {
      return;
    }

    // only create payload if there is at least one field has changed
    if (!payload) {
      payload = {};
    }
    const attributeName = nameMap[field];
    payload[attributeName] = newValue;
  });

  return payload;
}

function mapSessionInfoToProfileInfo(sessionInfo) {
  const profileInfo = {};
  const attributeMap = sessionInfo.attributes;

  if (attributeMap) {
    const nameMap = attributeNameToProfileNameMap;
    Object.entries(nameMap).forEach(([attributeName, profileName]) => {
      profileInfo[profileName] = attributeMap[attributeName];
    });
  }

  return profileInfo;
}

/**
 * React's component simple form
 * @param className
 * @param disabled
 * @param value
 * @param label
 * @param id
 * @param onInput
 * @param type
 * @param hint
 * @return {*}
 * @constructor
 */

export default class UserProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: {},
      payload: {},
      editable: {
        givenName: true,
        familyName: true,
        email: false,
        phone: true
      },
      customerConfiguration: undefined,
      inputEnabled: false,
      isModal: false,
      showTestModal: false,
      passwordChangeSuccess: false,
      imageOptional: undefined,
      imageOptionalInput: undefined,
      file: '',
      imageObject: '',
      isValidPhoneNumber: true
    };

    this.onFileChange = this.onFileChange.bind(this);
  }

  componentDidMount() {
    this.fetchUserProfile();
  }

  async fetchUserProfile() {
    const userProfile = LocalCacheService.loadUserProfile();
    const profileInfo = mapSessionInfoToProfileInfo(userProfile);
    const config = await ConfigurationService.getConfiguration();

    await this.setState({
      current: { ...profileInfo },
      payload: {},
      inputEnabled: false,
      config: config
    });
  }

  async editImage() {
    const context = this;

    await RestService.configure(ConfigurationService.getRestServiceConfiguration());
    await RestService.uploadImage('/images', this.state.file, this.state.file.type).then(
      (img) => {
        const payload = { ...context.state.payload };
        payload.imageOptional = img.url;

        context.setState({
          payload
        });
      },
      (err) => {
        alert(`Fail to upload image: ${err}`);
      }
    );
  }

  /**
   * handle input change event
   * @param event
   */
  handleChange = (id, value) => {
    this.setState({
      payload: {
        ...this.state.payload,
        [id]: value
      }
    });
  };

  /**
   * listen to new file upload event
   * @param event
   */
  onFileChange = (event) => {
    if (event && event.target && event.target.files) {
      this.setState({
        file: event.target.files[0]
      });
    }
  };

  /**
   * Open password change modal
   * @param event
   * @return {Promise<void>}
   */
  handleChangePasswordModal = async (event) => {
    event.preventDefault();

    this.setState({ isModal: true });
  };

  /**
   * Toggle password change modal
   * @return {Promise<void>}
   */
  toggleModal = async () => {
    this.setState({
      isModal: !this.state.isModal
    });
  };

  toggleTestModal = () => {
    this.setState({
      showTestModal: !this.state.showTestModal
    })
  }

  /**
   * Perform password change
   * @param event
   * @return {Promise<void>}
   */
  handleChangePassword = async (event) => {
    event.preventDefault();
    const context = this;
    AuthService.changePassword(
      this.state.payload.currentPassword,
      this.state.payload.newPassword
    ).then(
      () => {
        context.setState({ isModal: false, passwordChangeSuccess: true });
        context.setState({
          payload: {}
        });
      },
      (err) => {
        if(err.message === "Incorrect username or password."){
          alert("Incorrect Password Provided");
        }else{
          alert(err.message);
        }
      }
    );
  };

  /**
   * Switch to edit profile mode
   * @param event
   * @return {Promise<void>}
   */
  handleSubmitEditProfile = async (event) => {
    event.preventDefault();
    if (!this.state.imageOptional || !this.state.imageOptional.trim()) {
      this.setState({
        imageOptional: require('../../img/default_user_profile.png')
      });
    }
    this.setState({
      inputEnabled: true
    });
  };

  /**
   * Cancel the edit form
   * @param event
   * @return {Promise<void>}
   */
  handleCancelEditProfile = async (event) => {
    event.preventDefault();
    this.setState({
      inputEnabled: false,
      payload: {},
      file: null
    });
  };

  /**
   * Update profile if there is any change made
   * @param event
   * @return {Promise<void>}
   */
  handleSubmitChangeProfile = async (event) => {
    event.preventDefault();
    if (this.state.file) {
      await this.editImage();
    }
    // validate phone number format
    if (this.state.phoneNumberInput && this.state.phoneNumberInput.trim()) {
      const phoneNumber = this.state.phoneNumberInput.replace(/\D/g, '');
      if (phoneNumber.length < 10) {
        alert(
          `Incorrect phone number format ${this.state.phoneNumberInput}. Phone number must contains country code and area code.`
        );
        return;
      }
    }
    const payload = buildUpdateAttributePayload(this.state);
    if (!payload) {
      // when there is no change in profile, do nothing
      return;
    }
    try {
      const updatedSessionInfo = await AuthService.updateAttributes(payload);
      const updateProfile = UserProfile.parse(updatedSessionInfo);
      LocalCacheService.saveUserProfile(updateProfile);
      const userProfile = LocalCacheService.loadUserProfile();
      if (payload.phone_number) {
        const result = await AlertService.getNotificationRecipients();
        const emailId = result ? result.getEmails() : userProfile.getEmail();
        const newPayload = {};
        if (this.isEmail(emailId)) {
          newPayload.email = emailId;
        }
        newPayload.phoneNumber = payload.phone_number;
        await AlertService.updateNotificationSubscription(newPayload);
      }
      await this.fetchUserProfile();
    } catch (err) {
      alert(`Something is wrong: ${err.message}`);
      console.error(err);
    }
  };

  /**
   * validate and ensure new password and confirm new password match
   * @return {string|boolean}
   */
  isValidPasswordForm = () => {
    return (
      this.state.payload.currentPassword &&
      this.state.payload.currentPassword.length > 0 &&
      this.state.payload.newPassword &&
      this.state.payload.newPassword.length > 0 &&
      this.state.payload.newPassword === this.state.payload.confirmNewPassword
    );
  };

  /**
   * Create a top banner indicating operation succeeds
   * @return {*}
   */
  createPasswordChangeSuccessBanner = () => {
    return (
      <Alert
        className={UserProfilePageStyle.change_password_alerts}
        variant="success"
        onClose={this.handleDismiss}
        dismissible
      >
        <h4>Success!</h4>
        <p>Your password has been changed!</p>
      </Alert>
    );
  };

  /**
   * Handle cancellation of password change operation
   */
  handleDismiss = () => {
    this.setState({
      passwordChangeSuccess: false,
      payload: {}
    });
  };

  canBeSubmitted = () => {
    const errors = this.validateCreateUserForm();
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return !isDisabled;
  };

  shouldMarkError = (field) => {
    const errors = this.validateCreateUserForm();
    const hasError = errors[field];
    return hasError;
  };

  validatePhoneNumber = () => {
    let valid = true;
    if (this.state.payload.phone) {
      valid = isValidPhoneNumber(this.state.payload.phone);
    }
    this.setState({ isValidPhoneNumber: valid });
    return valid;
  };

  isEmail = (email) => {
    return /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+).([a-zA-Z]{2,5})$/g.test(email);
  };

  validateCreateUserForm = () => {
    return {
      givenName: false,
      familyName: false,
      username: /\s/g.test(this.state.payload.username),
      email: this.state.payload.email && !this.isEmail(this.state.payload.email),
      phone: this.state.payload.phone && !this.state.isValidPhoneNumber
    };
  };

  handleTestSwitch = () => {
    const currentMode = SessionStorageService.getTestMode();
    let nextMode;
    if(currentMode === 'Prod') {
      nextMode = 'Test';
      //switches scenario to 1 which makes xemelgo-client query test data
      XemelgoService.getClient().setScenario('1');
    } else {
      nextMode = 'Prod';
      XemelgoService.getClient().setScenario('0');
    }
    SessionStorageService.setTestMode(nextMode);
    window.location.reload();
  };

  renderSwitchIcon = () => {
    return (
      <div onClick={this.toggleTestModal} className={UserProfilePageStyle.swap_button}>
        <SwapHorizIcon />
      </div>
    );
  };

  render() {
    const { current, passwordChangeSuccess, config, inputEnabled, editable, showTestModal, isModal } = this.state;
    const profileImage = current.imageOptional
      ? current.imageOptional
      : require('../../img/default_user_profile.png');
    const userProfile = LocalCacheService.loadUserProfile();
    const isAdmin = userProfile.isUserSuperAdmin();
    const testMode = SessionStorageService.getTestMode() === 'Test';
    let switchMessage;

    if(testMode) {
      switchMessage = 'You are about to switch back to the production instance. The page will be refreshed and you will no longer see test data.'
    } else {
      switchMessage = 'You are about to switch to the test instance. The page will be refreshed and you will no longer see production data.'
    }
    return (
      <Fragment>
        {passwordChangeSuccess === true && this.createPasswordChangeSuccessBanner()}
        <Card className={UserProfilePageStyle.profile_card}>
          <div className={UserProfilePageStyle.user_profile_title}>
            <p className={UserProfilePageStyle.profile_title_text}>
              <AccountBoxIcon fontSize="large"  className={UserProfilePageStyle.profile_icon}/>
              Your Profile
            </p>
            {isAdmin && config && config.configData.webClient.testMode && this.renderSwitchIcon()}
          </div>
          <div className={UserProfilePageStyle.main_card}>
            <div className={UserProfilePageStyle.responsive_container}>
              <img src={profileImage} alt="profile pic" />
            </div>
            <CardBody>
              <div>
                <ProfileForm
                  clean={!inputEnabled}
                  handleChange={this.handleChange}
                  shouldMarkError={this.shouldMarkError}
                  hint={current}
                  disabled={!inputEnabled}
                  editable={editable}
                  validatePhoneNumber={this.validatePhoneNumber}
                />
              </div>

              {inputEnabled && (
                <div className={UserProfilePageStyle.profile_form_buttons}>
                  <button className="cancel-button" onClick={this.handleCancelEditProfile}>
                    Cancel
                  </button>
                  <button
                    disabled={!this.canBeSubmitted()}
                    className="default-button"
                    onClick={this.handleSubmitChangeProfile}
                  >
                    Save
                  </button>
                </div>
              )}

              {!inputEnabled && (
                <div className={UserProfilePageStyle.profile_form_buttons}>
                  <div onClick={this.handleChangePasswordModal} className={UserProfilePageStyle.change_password}>
                    Change Password
                  </div>
                  <button
                    type="submit"
                    onClick={this.handleSubmitEditProfile}
                    className="default-button"
                  >
                    Edit Profile
                  </button>
                </div>
              )}
            </CardBody>

            <Modal show={showTestModal} onHide={this.toggleTestModal}>
              <Modal.Header closeButton>
                <Modal.Title className={UserProfilePageStyle.change_password_title}>Switching Modes</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  {switchMessage}
                </div>
                <div>
                  Press confirm to proceed
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button className="cancel-button" onClick={this.toggleTestModal}>
                  Cancel
                </button>
                <button
                  className="default-button"
                  onClick={this.handleTestSwitch}
                >
                  Confirm
                </button>
              </Modal.Footer>
            </Modal>

            <Modal show={isModal} onHide={this.toggleModal}>
              <Modal.Header closeButton>
                <Modal.Title className={UserProfilePageStyle.change_password_title}>Change Password</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className={UserProfilePageStyle.form_group}>
                  <p
                    style={{
                      textAlign: 'center',
                      color: 'black'
                    }}
                  >
                    Please enter your old password followed by your new password.
                  </p>
                  <Input
                    className={UserProfilePageStyle.input_password}
                    label="Current Password"
                    id="currentPassword"
                    onInput={(event) => this.handleChange(event.target.id, event.target.value)}
                    type="password"
                  />
                  <Input
                    className={UserProfilePageStyle.input_password}
                    label="New Password"
                    id="newPassword"
                    onInput={(event) => this.handleChange(event.target.id, event.target.value)}
                    type="password"
                  />
                  <Input
                    className={UserProfilePageStyle.input_password}
                    label="Confirm New Password"
                    id="confirmNewPassword"
                    onInput={(event) => this.handleChange(event.target.id, event.target.value)}
                    type="password"
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button className="cancel-button" onClick={this.toggleModal}>
                  Cancel
                </button>
                <button
                  className="default-button"
                  disabled={!this.isValidPasswordForm()}
                  onClick={this.handleChangePassword}
                >
                  Save
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </Card>
      </Fragment>
    );
  }
}
