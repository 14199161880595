/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'mdbreact';
import { Link, withRouter } from 'react-router-dom';
import TrackPageComponentStyle from './TrackPageComponent.module.css';

const TrackPageComponent = ({
  overviewComponent,
  groupbyComponent,
  sidebarFeatureButtons,
  buttonTitle,
  buttonOnClick,
  breadcrumbsComponent,
  dataViewComponent,
  history,
  fullScreenMode
}) => {
  return (
    <Card className={TrackPageComponentStyle.card_container}>
      <CardBody className={TrackPageComponentStyle.card_body}>
        {overviewComponent}
        {!fullScreenMode && <div className={TrackPageComponentStyle.seperator} />}
        <div className={TrackPageComponentStyle.bottom_component_style}>
          {groupbyComponent && (
            <div className={TrackPageComponentStyle.side_bar_style}>
              {buttonOnClick ? (
                <div className={TrackPageComponentStyle.create_button} onClick={buttonOnClick}>
                  {buttonTitle}
                </div>
              ) : (
                buttonTitle && (
                  <Link
                    className={TrackPageComponentStyle.create_button}
                    to={`${history.location.pathname}/create`}
                  >
                    {buttonTitle}
                  </Link>
                )
              )}
              {sidebarFeatureButtons}
              {groupbyComponent}
            </div>
          )}
          {groupbyComponent && (
            <div
              className={`${TrackPageComponentStyle.seperator} ${TrackPageComponentStyle.seperator_vertical}`}
            />
          )}
          <div className={TrackPageComponentStyle.data_view_group}>
            {!fullScreenMode && breadcrumbsComponent}
            <div className={TrackPageComponentStyle.data_view_container}>{dataViewComponent}</div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default withRouter(TrackPageComponent);

TrackPageComponent.defaultProps = {
  overviewComponent: undefined,
  groupbyComponent: undefined,
  buttonTitle: '',
  sidebarFeatureButtons: null,
  breadcrumbsComponent: undefined,
  dataViewComponent: undefined,
  history: {}
};

TrackPageComponent.propTypes = {
  overviewComponent: PropTypes.object,
  groupbyComponent: PropTypes.object,
  buttonTitle: PropTypes.string,
  sidebarFeatureButtons: PropTypes.array,
  breadcrumbsComponent: PropTypes.object,
  dataViewComponent: PropTypes.object,
  history: PropTypes.object
};
