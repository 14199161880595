import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';

import { ThemeProvider } from '@material-ui/core/styles';

import theme from './MaterialTheme';

/*
This component displays an input field

Inputs
  * isRequired - If input is required
  * valid - If curretn input is valid
  * label - Text displayed as label of input
  * id - Identifier of the input field
  * placeholder - Placeholder value
  * helperText - Text displayed below the input
  * errorMessage - Displayed below the input when input is invalid

InputField Properties
  * Variant - standard, outlined
  * Clear icon - if value exists clear button will clear field
*/

/*
Styling
*/
export const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    fieldset {
      border-color: rgba(0, 0, 0, 0.54);
      border-width: 1px;
    }
  }
  .MuiInputLabel-root {
    color: black;
    font-size: 1.1em;
  }
`;

export default class InputField extends PureComponent {
  handleClearInput() {
    const { data, changeHandler } = this.props;
    changeHandler(data.id, '');
  }

  render() {
    const { data, changeHandler, style, width } = this.props;
    const CustomField = style;
    return (
      <ThemeProvider theme={theme}>
        <CustomField
          fullWidth={width ? false : true}
          style={{ width: width }}
          required={data.isRequired}
          error={!data.valid}
          variant="outlined"
          label={data.label}
          id={data.id}
          value={data.value}
          onChange={(event) => changeHandler(event.target.id, event.target.value)}
          placeholder={data.placeholder}
          helperText={data.valid ? data.helperText : data.errorMessage}
          onBlur={(event) => changeHandler(event.target.id, event.target.value.trim())}
          InputProps={{
            inputProps: {
              tabIndex: data.tabIndex || -1
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton size="small" onClick={() => this.handleClearInput()} tabIndex={-1}>
                  <ClearIcon fontSize="small" hidden={!data.value} />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </ThemeProvider>
    );
  }
}

InputField.propTypes = {
  data: PropTypes.shape({
    render: PropTypes.func.isRequired,
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    valid: PropTypes.bool,
    errorMessage: PropTypes.string,
    helperText: PropTypes.string,
    placeholder: PropTypes.string,
    isRequired: PropTypes.bool,
    validationRules: PropTypes.array
  }).isRequired,
  changeHandler: PropTypes.func.isRequired,
  style: PropTypes.object.isRequired
};
