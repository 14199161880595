/**
 * @typedef Route
 * @param { null | number } exitTime
 * @param { string } location
 *
 * @param { Route[] } routes
 * @param locations
 */
export const getLatestLocationFromRoute = (routes, locations) => {
  // either we can one without exitTime or the last route in the list
  const latestRoute = routes.find((route) => !route.exitTime) ?? routes[routes.length - 1];

  if (!latestRoute) {
    return undefined;
  }

  const latestRouteLocationName = latestRoute.location;
  const foundLocation = locations.find((location) => {
    const name = location.getName();
    return name === latestRouteLocationName;
  });

  return foundLocation;
};
