/* eslint-disable react/display-name */
import React, {useEffect, useState, useRef, useCallback} from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import TrackPageComponentStyle from '../../components/TrackPageComponents/track-page-component/TrackPageComponent.module.css';
import AssetOverViewComponent from '../../components/TrackPageComponents/Asset/asset-overview-component/AssetOverviewComponent';
import TrackPageComponent from '../../components/TrackPageComponents/track-page-component/TrackPageComponent';
import GroupbySideBarComponent from '../../components/TrackPageComponents/groupby-sidebar-component/GroupbySidebarComponent';
import {
  getCustomerLogo,
  getFormattedDate,
  getStackedXemelgoLogo,
  getStatusFlags
} from '../../common/Utilities';
import TrackPageDataViewComponent from '../../components/TrackPageComponents/track-page-data-view-component/TrackPageDataViewComponent';
import GridCardContentDefault from '../../components/TrackPageComponents/Asset/grid-card-contents/grid-card-content-default/GridCardContentDefault';
import GridCardContentGroupby from '../../components/TrackPageComponents/Asset/grid-card-contents/grid-card-content-groupby/GridCardContentGroupby';
import GridCardComponent from '../../components/grid-card-component/GridCardComponent';
import Style from '../../components/TrackPageComponents/Order/css/TrackPageGridViewComponent.module.css';
import { useXemelgoClient } from '../../services/xemelgo-service';
import BreadcrumbsComponent from '../../components/breadcrumbs-component/BreadcrumbsComponent';
import './style.css';
import { useAppConfigProvider } from '../../services/soft-cache-service';
import { LocationFloorMapWithTooltip } from './features/location-floor-map-with-tooltip';
import { XemelgoService } from '../../services/XemelgoService';
import {getLocations} from "../../services/location-service";
import {getLocationPinColor} from "./get-location-pin-color";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function guidGenerator() {
  var S4 = function() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4();
}

const APP_ID = 'asset';
const TRACK_PAGE_CONFIG = 'trackPage';
const STATUS_MAP = 'statusFlagMap';
const POSSIBLE_DETECTOR_LOCATIONS = 'possibleDetectorLocations';

const AssetTrackPageFeature = ({ history }) => {
  const bulkUpdateItemsButton = {
    id: 'bulk-update-items',
    display: 'Bulk Update',
    onClick: () => handleBulkUpdateItemsClick()
  };

  const sidebarFeatureButtonMap = {
    'bulk-update-items': bulkUpdateItemsButton
  };

  const breadcrumbsDataListRef = useRef();

  const handleBreadCrumbsClick = (id) => {
    const { current } = breadcrumbsDataListRef;
    while (current.length && current[current.length - 1].id !== id) {
      current.pop();
    }
    setBreadcrumbsDataList(current);
  };

  const viewAllAssetsTab = {
    id: 'all-assets',
    display: 'All Assets',
    action: () => {
      handleTabClick('all-assets', overallDataRef.current.tracksItem);
      setFocusedTab(null);
      setBreadcrumbsDataList([]);
      history.replace(`${history.location.pathname}`);
    }
  };

  const groupByAssetTypeTab = {
    id: 'type',
    display: 'Type',
    action: () => {
      handleTabClick('type', overallDataRef.current.types);
      setFocusedTab(null);
      setBreadcrumbsDataList([
        {
          id: 'type',
          value: 'Type',
          onClick: () => {
            handleBreadCrumbsClick('type');
            handleTabClick('type', overallDataRef.current.types);
            setFocusedTab(null);
          }
        }
      ]);
      history.replace(`${history.location.pathname}?group=type`);
    }
  };

  const groupByLocationTab = {
    id: 'location',
    display: 'Location',
    action: () => {
      handleTabClick('location', overallDataRef.current.locations);
      setFocusedTab(null);
      setBreadcrumbsDataList([
        {
          id: 'location',
          value: 'Location',
          onClick: () => {
            handleBreadCrumbsClick('location');
            handleTabClick('location', overallDataRef.current.locations);
            setFocusedTab(null);
          }
        }
      ]);
      history.replace(`${history.location.pathname}?group=location`);
    }
  };

  const viewTabsMap = {
    'all-assets': viewAllAssetsTab
  };

  const groupByTabsMap = {
    location: groupByLocationTab,
    type: groupByAssetTypeTab
  };

  const viewHeaderAndTabs = {
    id: 'view',
    display: 'View',
    tabStructure: []
  };

  const groupByHeaderAndTabs = {
    id: 'groupby',
    display: 'Group By',
    tabStructure: []
  };

  const SolutionConfigProvider = useAppConfigProvider(APP_ID);
  const TrackPageConfig = SolutionConfigProvider.getValue(TRACK_PAGE_CONFIG, 'object');
  const SolutionStatuses = SolutionConfigProvider.getValue(STATUS_MAP, 'object');
  const [currentTab, setCurrentTab] = useState('all-assets');
  const [focusedTab, setFocusedTab] = useState('all-assets'); // This is used only when currentTab is null but you want the side bar to focused on specific id
  const overallDataRef = useRef({});
  const [currentDataList, setCurrentDataList] = useState([]);
  const [logoData, setLogoData] = useState(null);
  const [listLoaded, setListLoaded] = useState(false);
  const [client] = useState(useXemelgoClient());
  const [breadcrumbsDataList, setBreadcrumbsDataList] = useState([]);
  const [configProvider] = useState(useAppConfigProvider('assetTrackPage'));
  const [floorMapCategory, setFloorMapCategory] = useState(null);
  const [selectedLocationForFloorMap, setSelectedLocationForFloorMap] = useState(null);
  const [floorMapImageUrl, setFloorMapImageUrl] = useState(null);
  // const [ setDefaultViewMode] = useState(null);
  const [overviewData, setOverviewData] = useState({
    topMetricsDataList: [],
    bottomMetricsDataList: []
  });
  const [overallListData, setOverallListData] = useState({});
  const [gridViewConfig, setGridViewConfig] = useState({});
  const [listViewConfig, setListViewConfig] = useState({});
  const [groupbyStructure, setGroupByStructure] = useState(null);
  const [gridViewSortSchemaConfig, setGridViewSortSchemaConfig] = useState([]);

  useEffect(() => {
    if (!configProvider) {
      return;
    }

    const category = configProvider.getValue('floorMapCategory', 'string', 'Facility');
    setFloorMapCategory(category);
  }, [configProvider]);

  useEffect(() => {
    if (!floorMapCategory) {
      return;
    }

    const locationClient = XemelgoService.getClient().getLocationClient();
    getLocations(locationClient, floorMapCategory).then((result) => {
      const location = result[0];
      if (location) {
        const imageUrl = location.getImagePath();
        setSelectedLocationForFloorMap(location);
        setFloorMapImageUrl(imageUrl);
      }
    });
  }, [floorMapCategory]);

  const handleTabClick = (tabId, dataList) => {
    setCurrentTab(tabId);
    setCurrentDataList(dataList);
  };

  const handleBulkUpdateItemsClick = async () => {
    history.push(`${history.location.pathname}/bulk-update`);
  };

  const processQueryPayload = (payload, data) => {
    let tabId = null;
    let dataList = [];
    const newBreadcrumbsDataList = [];
    switch (payload.group) {
      case 'location':
        tabId = 'location';
        dataList = data.locations;
        newBreadcrumbsDataList.push({
          id: 'location',
          value: 'Location',
          onClick: () => {
            handleBreadCrumbsClick('location');
            handleTabClick('location', data.locations);
            setFocusedTab(null);
          }
        });
        break;
      case 'type':
        tabId = 'type';
        dataList = data.types;
        newBreadcrumbsDataList.push({
          id: 'type',
          value: 'Type',
          onClick: () => {
            handleBreadCrumbsClick('type');
            handleTabClick('type', data.types);
            setFocusedTab(null);
          }
        });
        break;
      default:
        tabId = null;
        dataList = data.tracksItem;
        break;
    }
    if (payload.id) {
      setFocusedTab(tabId);
      const result = dataList.find((each) => each.id === payload.id);
      if (result) {
        tabId = null;
        dataList = result.tracksItem;
        const breadcrumbItem = {
          id: payload.id,
          value: result.name
        };
        if (history.location.search.includes('type')) {
          breadcrumbItem.linkIconTitle = 'Click to view item type details';
          breadcrumbItem.linkIconClickFn = () => {
            history.push(`${history.location.pathname}/itemType/detail?id=${payload.id}`);
          };
        }
        newBreadcrumbsDataList.push(breadcrumbItem);
      } else {
        history.replace(`${history.location.pathname}?group=${payload.group}`);
      }
    }
    setBreadcrumbsDataList(newBreadcrumbsDataList);
    handleTabClick(tabId, dataList);
  };

  useEffect(() => {
    breadcrumbsDataListRef.current = breadcrumbsDataList;
  });

  useEffect(() => {
    const onLoad = async () => {
      const {
        groupbyEnabled,
        possibleGroupbyTabs,
        defaultTab,
        possibleViewTabs,
        defaultViewMode: newDefaultViewMode,
        gridViewConfig: newGridViewConfig,
        listViewConfig: newListViewConfig,
        additionalAttributeMap
      } = TrackPageConfig;

      const possibleLocationCategories = SolutionConfigProvider.getValue(
        POSSIBLE_DETECTOR_LOCATIONS,
        'array'
      );

      const gridViewSortSchema = newGridViewConfig.sortSchema || [];

      if (defaultTab && defaultTab !== 'all-assets') {
        history.replace(`${history.location.pathname}?group=${defaultTab}`);
        setFocusedTab(defaultTab);
        setCurrentTab(defaultTab);
      }

      const tempGroupByStructure = [];
      const tempGroupByTabStructure = [];
      const tempViewTabStructure = [];

      for (const tabId of possibleViewTabs) {
        tempViewTabStructure.push(viewTabsMap[tabId]);
      }
      viewHeaderAndTabs.tabStructure = tempViewTabStructure;
      tempGroupByStructure.push(viewHeaderAndTabs);

      if (groupbyEnabled && possibleGroupbyTabs.length) {
        for (const grouByTabId of possibleGroupbyTabs) {
          tempGroupByTabStructure.push(groupByTabsMap[grouByTabId]);
        }
        groupByHeaderAndTabs.tabStructure = tempGroupByTabStructure;
        tempGroupByStructure.push(groupByHeaderAndTabs);
      }

      if (!groupbyStructure) setGroupByStructure(tempGroupByStructure);

      const TrackPageClient = await client.getTrackPageClient();
      const data = await TrackPageClient.getAssetPageData(
        ['location', 'type'],
        possibleLocationCategories,
        additionalAttributeMap
      );
      const logo = (await getCustomerLogo()) || getStackedXemelgoLogo('dark');
      const tempOverview = {
        numOfMissing: 0,
        numOfAsset: 0,
        numOfWeeklyMissing: 'N/A'
      };
      tempOverview.numOfAsset = data.tracksItem.length;
      data.tracksItem.forEach((each) => {
        if (each.isMissing) {
          tempOverview.numOfMissing += 1;
        }
        each.statusFlags = getStatusFlags(each.statusFlags, SolutionStatuses);
      });

      // setDefaultViewMode(newDefaultViewMode);
      setGridViewConfig(newGridViewConfig);
      setListViewConfig(newListViewConfig);
      setGridViewSortSchemaConfig(gridViewSortSchema);

      getCustomerLogo().then((logo) => {
        if (!logo) {
          logo = getStackedXemelgoLogo('dark');
        }
        setLogoData(logo);
      });

      loadContent();
    };

    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  const loadContent = async () => {
    const possibleLocationCategories = SolutionConfigProvider.getValue(
      POSSIBLE_DETECTOR_LOCATIONS,
      'array'
    );

    const { additionalAttributeMap } = TrackPageConfig;

    const TrackPageClient = await client.getTrackPageClient();
    const data = await TrackPageClient.getAssetPageData(
      ['location', 'type'],
      possibleLocationCategories,
      additionalAttributeMap
    );
    const tempOverview = {
      numOfMissing: 0,
      numOfAsset: 0,
      numOfWeeklyMissing: 'N/A'
    };
    tempOverview.numOfAsset = data.tracksItem.length;
    data.tracksItem.forEach((each) => {
      if (each.isMissing) {
        tempOverview.numOfMissing += 1;
      }
      each.statusFlags = getStatusFlags(each.statusFlags, SolutionStatuses);
    });

    setOverviewData(tempOverview);
    setOverallListData(data);
    setListLoaded(true);
    overallDataRef.current = data;
  };

  useEffect(() => {
    if (Object.keys(overallListData).length) {
      const queryPaylod = queryString.parse(history.location.search);
      if (Object.entries(queryPaylod).length) {
        processQueryPayload(queryPaylod, overallListData);
      } else {
        setCurrentDataList(overallListData.tracksItem);
        setCurrentTab(null);
      }
    }
  }, [overallListData]);

  const getSortSchema = () => {
    const currentSortSchema =
      (currentTab
        ? gridViewSortSchemaConfig[currentTab]
        : gridViewSortSchemaConfig['all-assets']) || [];
    const processedSortSchema = [...currentSortSchema];

    if (currentSortSchema && currentSortSchema.length > 0) {
      currentSortSchema.forEach((item, i) => {
        const { type, id } = item;

        switch (type) {
          case 'number':
            processedSortSchema[i].compareFunc = (a, b, reverse) => {
              if (a[id] === b[id]) {
                return 0;
              }
              if (!a[id]) {
                return reverse ? -1 : 1;
              }
              if (!b[id]) {
                return reverse ? 1 : -1;
              }
              return a[id] < b[id] ? -1 : 1;
            };
            break;
          case 'character':
            processedSortSchema[i].compareFunc = (a, b, reverse) => {
              if (!a[id]) {
                return reverse ? -1 : 1;
              }
              if (!b[id]) {
                return reverse ? 1 : -1;
              }
              if (a[id].toLowerCase() === b[id].toLowerCase()) {
                return 0;
              }
              return a[id].toLowerCase() < b[id].toLowerCase() ? -1 : 1;
            };
            break;
          case 'status':
            processedSortSchema[i].compareFunc = (a, b) => {
              const statusLevelCalculator = (statusId) => {
                switch (statusId) {
                  case 'missing':
                    return 256;
                  case 'usageCritical':
                    return 128;
                  case 'eolCritical':
                    return 64;
                  case 'refreshCritical':
                    return 32;
                  case 'critical':
                    return 16;
                  case 'refreshWarning':
                    return 8;
                  case 'eolWarning':
                    return 4;
                  case 'warning':
                    return 2;
                  case 'healthy':
                    return 1;
                  default:
                    return 0;
                }
              };
              let aLevel = 0;
              let bLevel = 0;
              a &&
                a.statusFlags.forEach((each) => {
                  aLevel += statusLevelCalculator(each.id);
                });
              b &&
                b.statusFlags.forEach((each) => {
                  bLevel += statusLevelCalculator(each.id);
                });
              return aLevel < bLevel ? 1 : -1;
            };
            break;
          default:
            break;
        }
      });
    }

    return processedSortSchema || [];
  };

  const getSidebarFeatureButtons = () => {
    const { sidebarFeatureButtons } = TrackPageConfig;

    return (
      sidebarFeatureButtons &&
      sidebarFeatureButtons.map((featureButton) => {
        const featureButtonDetail = sidebarFeatureButtonMap[featureButton.id];

        return (
          featureButtonDetail && (
            <div
              key={featureButton.id}
              name={featureButton.display}
              className={TrackPageComponentStyle.create_button}
              onClick={featureButtonDetail.onClick}
            >
              {featureButton.display}
            </div>
          )
        );
      })
    );
  };

  const filterFunction = (input, each) => {
    let match = false;
    const lowerCaseInput = input.toLowerCase();
    const { name, sensorProfile, lastKnownLocation, statusFlags } = each;
    switch (currentTab) {
      case 'type':
      case 'location':
        if (name && name.toLowerCase().includes(lowerCaseInput)) {
          match = true;
        }
        break;
      default:
        if (
          (name && name.toLowerCase().includes(lowerCaseInput)) ||
          (sensorProfile &&
            sensorProfile.vid &&
            sensorProfile.vid.toLowerCase().includes(lowerCaseInput)) ||
          (lastKnownLocation.name && lastKnownLocation.name.toLowerCase().includes(lowerCaseInput))
        ) {
          match = true;
        }
        statusFlags.forEach((eachflag) => {
          if (eachflag.displayText.toLowerCase().includes(lowerCaseInput)) {
            match = true;
          }
        });
        break;
    }
    return match;
  };

  // TODO: this function needs to be change when ListView get refactored
  const listViewFilterFunction = (input, each) => {
    let match = false;
    const lowerCaseInput = input.toLowerCase();
    const {
      name,
      lastKnownLocationName,
      lastKnownLocationIdentifier,
      statusFlags = [],
      sensor_profile_vid
    } = each;

    switch (currentTab) {
      case 'type':
        if (
          (name && name.toLowerCase().includes(lowerCaseInput)) ||
          (statusFlags &&
            statusFlags.length > 0 &&
            statusFlags.find(
              (flag) => flag.displayText && flag.displayText.toLowerCase().includes(lowerCaseInput)
            ))
        ) {
          match = true;
        }
        break;

      case 'location':
        if (
          (name && name.toLowerCase().includes(lowerCaseInput)) ||
          (statusFlags &&
            statusFlags.length > 0 &&
            statusFlags &&
            statusFlags.find(
              (flag) => flag.displayText && flag.displayText.toLowerCase().includes(lowerCaseInput)
            ))
        ) {
          match = true;
        }
        break;
      default:
        if (
          (name && name.toLowerCase().includes(lowerCaseInput)) ||
          (sensor_profile_vid && sensor_profile_vid.toLowerCase().includes(lowerCaseInput)) ||
          (lastKnownLocationName && lastKnownLocationName.toLowerCase().includes(lowerCaseInput)) ||
          (lastKnownLocationIdentifier &&
            lastKnownLocationIdentifier.toLowerCase().includes(lowerCaseInput)) ||
          (statusFlags &&
            statusFlags.length > 0 &&
            statusFlags &&
            statusFlags.find(
              (flag) => flag.displayText && flag.displayText.toLowerCase().includes(lowerCaseInput)
            ))
        ) {
          match = true;
        }
        break;
    }
    return match;
  };

  const renderGridCardComponentDefault = (eachItemData, id, containerStyle) => {
    const currentGridViewConfig = gridViewConfig[currentTab || 'all-assets'] || {};
    const { title = {}, subTitle = {}, bottomContentList = [], showStatusFlags, image } =
      currentGridViewConfig || {};
    return (
      <GridCardComponent
        key={id}
        onClick={() => {
          history.push(`${history.location.pathname}/detail?itemId=${eachItemData.id}`);
        }}
        containerStyle={containerStyle}
        color={
          eachItemData.statusFlags && eachItemData.statusFlags.length
            ? eachItemData.statusFlags[0].color
            : '#00B200'
        }
        gridCardContent={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <GridCardContentDefault
            image={eachItemData.imagePath || logoData}
            title={{ ...title, value: eachItemData[title.id] }}
            subTitle={{ ...subTitle, value: eachItemData[subTitle.id] }}
            bottomContentList={bottomContentList?.map((each) => {
              return { ...each, value: eachItemData[each.id] };
            })}
            showStatusFlags={showStatusFlags}
            statusFlags={eachItemData.statusFlags}
          />
        }
      />
    );
  };

  const renderGridCardComponentGroupby = (eachData, id, containerStyle) => {
    const currentGridViewConfig = gridViewConfig[currentTab] || {};
    const { title = {}, contentList = [] } = currentGridViewConfig || {};
    const {
      missingCount,
      eolCriticalCount,
      eolWarningCount,
      refreshWarningCount,
      refreshCriticalCount,
      usageCriticalCount,
      tracksItem
    } = eachData;

    let healthColor = '#00B200';
    if (eolWarningCount > 0 || refreshWarningCount > 0) {
      healthColor = 'orange';
    }
    if (
      missingCount > 0 ||
      eolCriticalCount > 0 ||
      refreshCriticalCount > 0 ||
      usageCriticalCount > 0
    ) {
      healthColor = '#F62227';
    }

    return (
      <GridCardComponent
        onClick={() => {
          history.replace(`${history.location.pathname}${history.location.search}&id=${id}`);
          handleTabClick(null, tracksItem);
          const breadcrumbItem = {
            id,
            value: eachData.name
          };
          if (history.location.search.includes('type')) {
            breadcrumbItem.linkIconTitle = 'Click to view item type details';
            breadcrumbItem.linkIconClickFn = () => {
              history.push(`${history.location.pathname}/itemType/detail?id=${id}`);
            };
          }
          breadcrumbsDataList.push(breadcrumbItem);
          setBreadcrumbsDataList(breadcrumbsDataList);
        }}
        key={id}
        color={healthColor}
        containerStyle={containerStyle}
        gridCardContent={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <GridCardContentGroupby
            title={{ ...title, value: eachData[title.id] }}
            contentList={contentList?.map((each) => {
              return { ...each, value: eachData[each.id] };
            })}
          />
        }
      />
    );
  };

  const renderGridCard = () => {
    switch (currentTab) {
      case 'location':
      case 'type':
        return renderGridCardComponentGroupby;
      default:
        return renderGridCardComponentDefault;
    }
  };

  const listViewHeaderStructureListControl = (groupby) => {
    const currentListViewHeaderConfig = listViewConfig[groupby || 'all-assets'];
    if (!currentListViewHeaderConfig) {
      return [];
    }

    currentListViewHeaderConfig
      .sort((a, b) => a.index - b.index)
      .forEach((eachHeader) => {
        const { type } = eachHeader;
        switch (type) {
          case 'statusText':
            eachHeader.renderComponent = (statusList) => {
              return (
                statusList &&
                Array.isArray(statusList) &&
                statusList.length > 0 &&
                statusList.map((eachStatus) => (
                  <div
                    key={guidGenerator()}
                    className={Style.list_view_status}
                    style={{ backgroundColor: eachStatus && eachStatus.color }}
                  >
                    {eachStatus ? eachStatus.displayText : '-'}
                  </div>
                ))
              );
            };
            break;
          case 'date':
            eachHeader.renderComponent = (data) => {
              return data ? (
                <p>{getFormattedDate(data, eachHeader.format || 'MMM D')}</p>
              ) : (
                <p>{eachHeader.defaultValue || '-'}</p>
              );
            };
            break;
          default:
            eachHeader.renderComponent = (data) => {
              return <p>{data || eachHeader.defaultValue || '-'}</p>;
            };
            break;
        }
      });

    return currentListViewHeaderConfig;
  };

  return (
    <TrackPageComponent
      overviewComponent={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <AssetOverViewComponent
          leftComponent={
            floorMapImageUrl && (
              <LocationFloorMapWithTooltip
                imageUrl={floorMapImageUrl}
                location={selectedLocationForFloorMap}
                className="asset-floor-plan"
                getLocationPinColorFn={getLocationPinColor}
                onPinClick={(id) => {
                  // handleTabClick('location', overallDataRef.current.locations);
                  // handleTabClick(null, currentDataList[id].tracksItem);
                  history.replace(`${history.location.pathname}?group=location&id=${id}`);
                  window.location.reload(true);
                }}
              />
            )
          }
          logoImage={logoData}
          numOfAsset={overviewData.numOfAsset}
          numOfMissing={overviewData.numOfMissing}
          numOfWeeklyMissing={overviewData.numOfWeeklyMissing}
        />
      }
      buttonTitle="+ Add Asset"
      groupbyComponent={
        !listLoaded ? (
          <Skeleton count={3} />
        ) : (
          <GroupbySideBarComponent
            tabStructure={groupbyStructure}
            focusedTab={currentTab || focusedTab}
          />
        )
      }
      sidebarFeatureButtons={getSidebarFeatureButtons()}
      breadcrumbsComponent={
        <BreadcrumbsComponent
          dataList={breadcrumbsDataList}
          onHomeClick={() => {
            handleTabClick('all-assets', overallDataRef.current.tracksItem);
            setFocusedTab(null);
            setCurrentTab(null);
            setBreadcrumbsDataList([]);
            history.replace(`${history.location.pathname}`);
          }}
        />
      }
      dataViewComponent={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <TrackPageDataViewComponent
          dataList={currentDataList}
          sortSchema={getSortSchema()}
          filterFunc={filterFunction}
          listViewFilterFunc={listViewFilterFunction} // needs to be refactor
          gridCardComponent={renderGridCard()}
          listViewStructure={listViewHeaderStructureListControl(currentTab)} // needs to be refactor
          listViewDataList={listViewDataListControl(currentTab, currentDataList)} // needs to be refactor
          // needs to be refactor
          handleListViewClick={
            currentTab
              ? ({ currentTarget, name }) => {
                  const splitId = currentTarget.id.split(' ');
                  const currentId = splitId[0];
                  const location = currentDataList.findIndex((each) => each.id === currentId);
                  handleTabClick(null, currentDataList[location].tracksItem);
                  history.replace(
                    `${history.location.pathname}${history.location.search}&id=${currentId}`
                  );
                  breadcrumbsDataList.push({
                    id: currentId,
                    value: name
                  });
                  setBreadcrumbsDataList(breadcrumbsDataList);
                }
              : ({ currentTarget }) => {
                  const splitId = currentTarget.id.split(' ');
                  const currentId = splitId[0];
                  history.push(`${history.location.pathname}/detail?itemId=${currentId}`);
                }
          }
          loading={!listLoaded}
        />
      }
    />
  );
};

export default withRouter(AssetTrackPageFeature);

// TODO: the functions below need to be refactor!!!!!!!!!!!!!!!!!!!!!!!!

const listViewDataListControl = (groupby, dataList) => {
  switch (groupby) {
    case 'location':
    case 'type':
      return dataList.map((each) => {
        const {
          id,
          name,
          identifier,
          lastUpdatedTime,
          missingCount,
          eolCriticalCount,
          eolWarningCount,
          refreshWarningCount,
          refreshCriticalCount,
          usageCriticalCount,
          totalCount
        } = each;
        let statusFlags = [{ id: 'healthy', displayText: 'Healthy', color: '#00B200', value: 0 }];
        if (eolWarningCount > 0 || refreshWarningCount > 0) {
          statusFlags = [{ id: 'warning', displayText: 'Warning', color: 'orange', value: 1 }];
        }
        if (
          missingCount > 0 ||
          eolCriticalCount > 0 ||
          refreshCriticalCount > 0 ||
          usageCriticalCount > 0
        ) {
          statusFlags = [{ id: 'critical', displayText: 'Critical', color: 'red', value: 2 }];
        }

        return {
          id,
          name,
          identifier,
          lastUpdatedTime,
          statusFlags,
          missingCount,
          eolCriticalCount,
          eolWarningCount,
          refreshWarningCount,
          refreshCriticalCount,
          usageCriticalCount,
          totalCount
        };
      });
    default:
      return dataList;
  }
};
