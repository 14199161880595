const pinMap = {
  'b0c0566d-8b36-c35e-c179-579ab72d993a': {
    x: 250,
    y: 80,
    color: 'green'
  },
  'dac0566d-8ad0-5ef2-255c-4309e704ee1c': {
    x: 650,
    y: 720,
    color: 'green'
  },
  '1cc05674-815b-9cf0-8cb9-2658a3159c43': {
    x: 1300,
    y: 650,
    color: '#f20202'
  },
  '18c0566d-8af8-0de4-31b5-f49fd014a585': {
    x: 970,
    y: 450,
    color: 'green'
  }
};

const injectImagePathToResponse = (locationResponse, locationId, injectingImagePath) => {
  const { id } = locationResponse;
  if (id === locationId) {
    // eslint-disable-next-line no-param-reassign
    locationResponse.getImagePath = () => injectingImagePath;
    return locationResponse;
  }

  const parent = locationResponse.getParent();
  if (parent) {
    const modifiedParent = injectImagePathToResponse(parent, locationId, injectingImagePath);
    // eslint-disable-next-line no-param-reassign
    locationResponse.getParent = () => modifiedParent;
    return locationResponse;
  }
  return locationResponse;
};

const injectPinLocationToResponse = (locationResponse) => {
  const { data } = locationResponse;
  const { id } = data;
  const pin = data.pin ?? pinMap[id];

  // eslint-disable-next-line no-param-reassign
  locationResponse.getPin = () => pin;
  data.pin = pin;
  // eslint-disable-next-line no-param-reassign
  locationResponse.data = data;
  return locationResponse;
};

/**
 * @param locationClient
 * @param categoryName
 * @return {Promise<*>}
 */
export const getLocations = (locationClient, categoryName) => {
  return locationClient.getLocationsOfCategory(categoryName).then((responses) => {
    const xformedResponses = responses.map((response) => {
      let modifiedResponse = injectImagePathToResponse(
        response,
        '7ec0566c-58ea-8999-657b-4acea8366e01',
        'https://xemelgo-customer-images.s3.us-west-2.amazonaws.com/hitachihightech/floor_map.png'
      );

      modifiedResponse = injectPinLocationToResponse(modifiedResponse);
      return modifiedResponse;
    });

    return xformedResponses;
  });
};

/**
 * @param locationClient
 * @param parentLocationId
 * @return {Promise<*>}
 */
export const getChildrenLocation = (locationClient, parentLocationId) => {
  return locationClient
    .getLocationChildrenByParentId(parentLocationId)
    .then((containedLocations) => {
      return containedLocations.map((loc) => {
        // eslint-disable-next-line no-param-reassign
        loc = injectPinLocationToResponse(loc);
        return loc;
      });
    });
};
