import React, { useState, useEffect } from 'react';
import AutoSizeTextArea from '../../components/AutoSizeTextArea/AutoSizeTextArea';
import Style from './BomManagementFeatureStyle.module.css';
import { useBOMManagementContext } from '../../context/BOMManagementContext/BOMManagementContext';
import Skeleton from 'react-loading-skeleton';
import EditButton from '../../components/EditButton/EditButton';

const AdditionalInformationConfig = {
  status: {
    type: 'status',
    label: 'Status',
    index: 1
  },
  quantity: {
    type: 'number',
    label: 'Minimum Quantity',
    index: 2,
    editable: true
  },
  unit: {
    label: 'Unit Measure',
    editable: true,
    index: 3
  },
  description: {
    label: 'Remarks',
    editable: true,
    index: 4
  }
};

const BOMAdditionalInformation = React.forwardRef(
  ({ onSaveClick, loading, hideEditButton }, ref) => {
    const { additionalInformation } = useBOMManagementContext();
    const [informationFields, setInformationFields] = useState({});
    const [isEditMode, setIsEditMode] = useState(false);

    const resetInformationFields = () => {
      const newInformationFields = {
        ...AdditionalInformationConfig
      };
      Object.keys(newInformationFields).forEach((eachAttributes) => {
        newInformationFields[eachAttributes].value = additionalInformation[
          eachAttributes
        ]?.toString();
      });
      setInformationFields(newInformationFields);
    };

    useEffect(() => {
      resetInformationFields();
    }, [additionalInformation]);

    const handleSaveClick = async () => {
      await onSaveClick(informationFields);
      setIsEditMode(false);
    };

    ref.current = {
      onSaveClick: handleSaveClick,
      setEditingMode: (newIsEditMode) => {
        setIsEditMode(newIsEditMode);
      }
    };

    return (
      <div className={Style.additional_info_container}>
        <div className={`${Style.title_container} ${Style.flex_row}`}>
          <p className={Style.title_text}>Additional Information</p>
          <div className={`${Style.edit_button_container} ${Style.flex_row}`}>
            <EditButton
              show={!loading && !hideEditButton}
              editMode={isEditMode}
              onEditClick={() => {
                setIsEditMode(true);
              }}
              onDiscardClick={() => {
                resetInformationFields();
                setIsEditMode(false);
              }}
              onSaveClick={handleSaveClick}
            />
          </div>
        </div>
        <div className={`${Style.additional_info_fields_container} ${Style.flex_row}`}>
          {Object.keys(informationFields)
            .sort((f1, f2) => informationFields[f1].index - informationFields[f2].index)
            .map((eachAttribute) => {
              const { type = 'text', label = '', value = '', editable = false } = informationFields[
                eachAttribute
              ];
              if (eachAttribute === 'description') {
                return null;
              }
              switch (type) {
                case 'status':
                  return (
                    <div
                      key={eachAttribute}
                      className={`${Style.additional_info_field} ${Style.flex_column}`}
                    >
                      <p className={Style.label}>{label}</p>
                      {loading ? (
                        <Skeleton containerClassName={Style.loading_bar} height={28} />
                      ) : (
                        <div
                          className={Style.status_container}
                          style={{ backgroundColor: value === 'active' ? '#00B200' : 'red' }}
                        >
                          <p className={Style.status_text}>{value}</p>
                        </div>
                      )}
                    </div>
                  );
                case 'text':
                case 'number':
                default:
                  return (
                    <div
                      key={eachAttribute}
                      className={`${Style.additional_info_field} ${Style.flex_column}`}
                    >
                      <p className={Style.label}>{label}</p>
                      {loading ? (
                        <Skeleton containerClassName={Style.loading_bar} height={28} />
                      ) : (
                        <div
                          className={`${Style.additional_info_input_container} ${Style.flex_row}`}
                        >
                          <AutoSizeTextArea
                            minRows={1}
                            maxRows={1}
                            numberOnly={type === 'number'}
                            onChangeText={(text) => {
                              const newInformationFields = { ...informationFields };
                              newInformationFields[eachAttribute].value = text;
                              setInformationFields(newInformationFields);
                            }}
                            value={!isEditMode ? value || '-' : value}
                            newLineAllowed={false}
                            readOnly={!isEditMode}
                            disabled={false}
                          />
                        </div>
                      )}
                    </div>
                  );
              }
            })}
          <div className={`${Style.additional_info_field} ${Style.flex_column}`}>
            <p className={Style.label}>Remarks</p>
            {loading ? (
              <Skeleton height={28} count={3} />
            ) : (
              <div
                className={`${
                  isEditMode
                    ? Style.additional_info_remark_input_container
                    : Style.additional_info_remark_input_container_not_edit
                } ${Style.flex_row}`}
              >
                <AutoSizeTextArea
                  minRows={4}
                  maxRows={8}
                  value={
                    informationFields?.description?.value
                      ? informationFields.description.value
                      : isEditMode && informationFields?.description?.value
                        ? informationFields.description.value
                        : '-'
                  }
                  onChangeText={(text) => {
                    const newInformationFields = { ...informationFields };
                    newInformationFields.description.value = text;
                    setInformationFields(newInformationFields);
                  }}
                  newLineAllowed
                  readOnly={!isEditMode}
                  disabled={false}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default BOMAdditionalInformation;
