/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect, useRef, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';
import AddInventoryFeatureV2 from './AddInventoryFeatureV2';
import GeneralizedModal from '../../components/modal/GeneralizedModal';
import TrackPageComponent from '../../components/TrackPageComponents/track-page-component/TrackPageComponentV2';
import TrackPageComponentStyle from '../../components/TrackPageComponents/track-page-component/TrackPageComponentV2.module.css';
import GridCardContentGroupbyItemType from '../../components/TrackPageComponents/Inventory/grid-card-contents/grid-card-content-groupby-item-type/GridCardContentGroupbyItemType';
import {
  getCustomerLogo,
  getFormattedDate,
  getStackedXemelgoLogo,
  parseQueryString,
  stringifyToQueryString,
  findThreshold,
  getValueOrDefault,
  pluralizeWord
} from '../../common/Utilities';
import GridCardComponent from '../../components/grid-card-component/GridCardComponent';
import { useXemelgoClient } from '../../services/xemelgo-service';
import BreadcrumbsComponent from '../../components/breadcrumbs-component/BreadcrumbsComponentV2';
import { useAppConfigProvider, useConfigurationProvider } from '../../services/soft-cache-service';
import InventoryOverviewComponent from '../../components/TrackPageComponents/Inventory/inventory-overview-component/InventoryOverviewComponent';
import CheckBoxListComponent from '../../components/checkbox-list-component/CheckBoxListComponent';
import ConfigurationService from '../../services/ConfigurationService';
import 'react-loading-skeleton/dist/skeleton.css';

const UNKNOWN_LOCATION_ID = 'unknown-location-112358';

const APP_ID = 'inventory';
const POSSIBLE_DETECTOR_LOCATIONS = 'possibleDetectorLocations';
const LOCATION_DISPLAY_LABEL_MAP = 'locationDisplayLabelMap';
const TRACK_PAGE_CONFIG = 'trackPage';
const LOADING = 'LOADING';

const INCOMING = 'incoming';
const ONHAND = 'onhand';
const CUSTOMER_ONHAND = 'customer_onhand';
const REMOVED = 'removed';

const mainColor = '#297AD9';
const secondaryColor = '#D5E5F8';

const healthPriorityMap = {
  Critical: 0,
  Warning: 1,
  Healthy: 2,
  Empty: 2
};

const healthColorMap = {
  Critical: '#F62227',
  Warning: '#F5b052',
  Normal: '#C6CBD4',
  Healthy: '#00B200',
  Overstock: '#4298FA',
  Empty: '#D3D3D3'
};

const InventoryTrackPageFeature = ({ history }) => {
  const resetInventoryButton = {
    id: 'reset-items',
    display: 'Reset Items',
    onClick: () => {
      setShowResetItemsModalFlag(true);
    }
  };

  const deleteItemTypesButton = {
    id: 'delete-item-types',
    display: `Delete ${pluralizeWord(itemTypeLabel || 'Item Type')}`,
    onClick: () => handleDeleteItemTypesClick()
  };

  const bulkUpdateItemsButton = {
    id: 'bulk-update-items',
    display: 'Bulk Update',
    onClick: () => handleBulkUpdateItemsClick()
  };

  const sidebarFeatureButtonMap = {
    'reset-items': resetInventoryButton,
    'delete-item-types': deleteItemTypesButton,
    'bulk-update-items': bulkUpdateItemsButton
  };

  const configProvider = useAppConfigProvider(APP_ID);
  const TrackPageConfig = configProvider.getValue(TRACK_PAGE_CONFIG, 'object');
  const LocationRoleConfig = useConfigurationProvider().config.features.locationRole || {};

  const urlRef = useRef({ pathname: history.location.pathname, search: history.location.search });

  const [showDeleteItemTypesModalFlag, setShowDeleteItemTypesModalFlag] = useState(false);
  const [showResetItemsModalFlag, setShowResetItemsModalFlag] = useState(false);
  const [loadingItemTypesFlag, setLoadingItemTypesFlag] = useState(false);
  const [itemTypeListToDelete, setItemTypeListToDelete] = useState(null);
  const [selectedItemTypesMap, setSelectedItemTypesMap] = useState({});
  const [statusTab, setStatusTab] = useState('stock');
  const [loading, setLoading] = useState(true);
  const [overallOverviewData, setOverallOverviewData] = useState({});
  const [trackPageClient] = useState(useXemelgoClient().getTrackPageClient());
  const [ItemTypeClient] = useState(useXemelgoClient().getItemTypeClient());
  const [SensorProfileClient] = useState(useXemelgoClient().getSensorProfileClient());
  const [ItemClient] = useState(useXemelgoClient().getItemClient());
  const [PublishClient] = useState(useXemelgoClient().getPublishClient());
  const [InventoryClient] = useState(useXemelgoClient().getInventoryClient());

  const [logoData, setLogoData] = useState(null);
  const [itemTypeLabel, setItemTypeLabel] = useState('Item Type');
  const [metricsMap, setMetricsMap] = useState({});
  const [possibleSecondaryView, setPossibleSecondaryView] = useState({});
  const [gridViewConfig, setGridViewConfig] = useState({});
  const [listViewConfig, setListViewConfig] = useState({});

  const [sideFilterStructureMap, setSideFilterStructureMap] = useState({});
  const [listViewMode, setListViewMode] = useState(true);
  const [currentViewTab, setCurrentViewTab] = useState('item-type');
  const [mainFilterIdList, setMainFilterIdList] = useState([]);
  const [locationsMap, setLocationsMap] = useState({});
  const [targetLocationBased, setTargetLocationBased] = useState(false);
  const prevListViewModeRef = useRef();
  const prevViewTabRef = useRef();
  const [breadcrumbsDataList, setBreadcrumbsDataList] = useState([]);
  const [currentData, setCurrentData] = useState({});
  const [locationDisplayLabelMap, setLocationDisplayLabelMap] = useState({});
  const [headerMetricsMap, setHeaderMetricsMap] = useState({});
  const [showOnboardingModalV2, setShowOnboardingModalV2] = useState(false);
  const overallOverviewDataRef = useRef();
  const [overviewLoaded, setOverviewLoaded] = useState(false);
  const [listLoaded, setListLoaded] = useState(false);
  const [commonData, setCommonData] = useState();
  const [contentMetadataList, setContentMetadataList] = useState([]);
  const [contentMetricsData, setContentMetricsData] = useState({});
  const [contentListData, setContentListData] = useState([]);
  const [filterConfig, setFilterConfig] = useState({});
  const [overviewMetricsDataList, setOverviewMetricsDataList] = useState([]);
  const [overviewChartDataList, setOverviewChartDataList] = useState([]);
  const [additionalAttributeMap, setAdditionalAttributeMap] = useState({});
  const [includeItemOrder, setIncludeItemOrder] = useState(false);

  const calculateLocationsMap = (
    itemTypes = [],
    tracksItem = [],
    locationMapOfConsumedItemType = {}
  ) => {
    // create itemTypeMap as calculation helper
    const allItemTypeMap = itemTypes.reduce((accumulator, eachItem) => {
      const { id } = eachItem;
      accumulator[id] = {
        ...eachItem,
        incomingItemCount: 0,
        stockItemCount: 0,
        totalItemCount: 0,
        consumedItemCount: 0,
        recentlyAddedItemCount: 0,
        typeLastUpdatedTime: null,
        totalQuantity: 0,
        totalWeight: 0,
        minStockThreshold: null,
        expiredItemCount: 0,
        expiringSoonItemCount: 0,
        burnStockItemCount: 0,
        onHandItemCount: 0,
        stockStatus: 'Healthy',
        expiryStatus: 'Healthy'
      };
      return accumulator;
    }, {});

    // create locationMap to store items/item-type
    const allLocationMap = commonData.locations.reduce((accumulator, current) => {
      const { id, name, identifier, category } = current;
      const locationIdList = [id];
      let directParentId;
      let directParentName;
      let directParentIdentifier;

      // filter the locationTree with only the trees related to each of the location
      const filteredLocationTree = commonData.locationTree.filter((eachTree) =>
        eachTree.includes(id)
      );
      filteredLocationTree.forEach((eachTree) => {
        const findIndex = eachTree.findIndex((eachId) => eachId === id);

        // find the child location ids
        if (findIndex !== eachTree.length - 1) {
          eachTree.slice(findIndex + 1, eachTree.length).forEach((eachId) => {
            if (!locationIdList.includes(eachId)) {
              locationIdList.push(eachId);
            }
          });
        }

        // find the direct parent location
        if (eachTree[eachTree.length - 1] === id) {
          const parentLocation = commonData.locations.find((each) => {
            return each.id === eachTree[eachTree.length - 2];
          });
          if (parentLocation) {
            directParentId = parentLocation.id;
            directParentIdentifier = parentLocation.identifier;
            directParentName = parentLocation.name;
          }
        }
      });
      accumulator[id] = {
        id,
        name,
        identifier,
        category,
        childLocationIds: locationIdList,
        childLocations: [],
        directParentIdentifier,
        directParentName,
        directParentId,
        totalItemCount: 0,
        totalItemTypeCount: 0,
        recentlyAddedItemCount: 0,
        typeLastUpdatedTime: null,
        totalQuantity: 0,
        totalWeight: 0,
        consumedItemCount: 0,
        incomingItemCount: 0,
        stockItemCount: 0,
        burnStockItemCount: 0,
        onHandItemCount: 0,
        outOfStockItemTypeCount: 0,
        lowStockItemTypeCount: 0,
        burnStockItemTypeCount: 0,
        expiredItemCount: 0,
        expiringSoonItemCount: 0,
        totalLotCount: 0,
        expiredLotCount: 0,
        expiringSoonLotCount: 0,
        stockStatus: id === UNKNOWN_LOCATION_ID ? 'Empty' : 'Healthy',
        expiryStatus: id === UNKNOWN_LOCATION_ID ? 'Empty' : 'Healthy',
        itemTypeMap: {},
        lotNumberMap: {}
      };
      return accumulator;
    }, {});

    const filteredLocationMapOfConsumedItemType = _.cloneDeep(locationMapOfConsumedItemType);
    // filter locationMapOfConsumedItemType with side filter
    Object.keys(filteredLocationMapOfConsumedItemType).forEach((eachLocationKey) => {
      filteredLocationMapOfConsumedItemType[
        eachLocationKey
      ] = filteredLocationMapOfConsumedItemType[eachLocationKey].filter((eachItemTypeId) => {
        return (
          !sideFilterStructureMap['item-type'].idList.length ||
          sideFilterStructureMap['item-type'].idList.includes(eachItemTypeId)
        );
      });
    });

    // filter Items based on the main filter and side filter
    const allFilters = {
      ...sideFilterStructureMap,
      main: {
        idList: mainFilterIdList
      }
    };
    let filteredItems = tracksItem;
    Object.keys(allFilters).forEach((eachFilterKey) => {
      const { idList } = allFilters[eachFilterKey];
      filteredItems = filteredItems.filter((eachItem) => {
        const { tags, properties = {} } = eachItem;
        const { targetLocationId, lastKnownLocationId, isConsumed, processState } = properties;
        // Don't filter consumed items in target location mode if process state is present
        if (
          targetLocationBased &&
          isConsumed &&
          targetLocationId !== lastKnownLocationId &&
          processState === null
        ) {
          return false;
        }
        if (eachFilterKey === 'main') {
          return !idList.length ? true : idList.every((eachId) => tags.includes(eachId));
        }
        return !idList.length ? true : idList.some((eachId) => tags.includes(eachId));
      });
    });

    const today = new Date().setHours(0, 0, 0, 0);
    const week = 7 * 24 * 60 * 60 * 1000;

    // put item and item type into the corresponding location
    filteredItems.forEach((eachItem) => {
      const { properties } = eachItem;
      const {
        lastKnownLocationId,
        targetLocationId,
        processStateInitialLocationId,
        processStateDetectedAtId,
        processState,
        itemTypeIdentifier,
        itemTypeId,
        hasExited,
        isConsumed,
        consumedTime,
        isRecentlyAdded,
        expiryDate,
        lotNumber = '',
        quantity,
        pounds_ts,
        lastUpdatedTime
      } = properties;

      // If the item has a process state, it is in process state mode regardless of whether target location mode is enabled
      const processStateBased = processState !== null;

      // Items with an incoming process state will be shown in their initial location as incoming. Otherwise, they will
      // be shown at the last location they were detected at that was part of the process state tracking.
      // Process state will override the target based location mode.  If neither are present, the
      // it will default to the last known location.
      const locationId = processStateDetectedAtId
        ? processState === INCOMING
          ? processStateInitialLocationId
          : processStateDetectedAtId
        : targetLocationBased
        ? targetLocationId
        : lastKnownLocationId;

      if (allLocationMap[locationId]) {
        const currentLocationObject = allLocationMap[locationId];

        // Processing Lot Number View
        const lotNumberIdentifier = `${itemTypeId},${lotNumber}`;
        if (!currentLocationObject.lotNumberMap[lotNumberIdentifier]) {
          // We are making a logic assumption that every item of the same lot and same itemType
          // would have the same expiration date
          currentLocationObject.lotNumberMap[lotNumberIdentifier] = {
            ...properties,
            id: lotNumberIdentifier,
            consumedItemCount: 0,
            totalItemCount: 0
          };
          currentLocationObject.totalLotCount += 1;

          // if Lot Number not provided, it means it is a grouping of items without lot number
          // remove value of node_start_time, expiryDate, daysToExpiry
          if (!lotNumber) {
            currentLocationObject.lotNumberMap[lotNumberIdentifier].node_start_time = null;
            currentLocationObject.lotNumberMap[lotNumberIdentifier].expiryDate = null;
            currentLocationObject.lotNumberMap[lotNumberIdentifier].daysToExpiry = null;
          }

          if (lotNumber) {
            if (expiryDate && expiryDate < today) {
              currentLocationObject.expiredLotCount += 1;
            } else if (expiryDate && expiryDate < today + week) {
              currentLocationObject.expiringSoonLotCount += 1;
            }
          }
        }

        const currentItemLotObject = currentLocationObject.lotNumberMap[lotNumberIdentifier];
        if (isConsumed) {
          currentItemLotObject.consumedItemCount += 1;
        }

        // Proccessing ItemType View
        if (!currentLocationObject.itemTypeMap[itemTypeId]) {
          currentLocationObject.itemTypeMap[itemTypeId] = {
            ...allItemTypeMap[itemTypeId]
          };
          currentLocationObject.itemTypeMap[itemTypeId].items = [];
          currentLocationObject.itemTypeMap[itemTypeId].minStockThreshold = findThreshold(
            itemTypeIdentifier,
            commonData.ruleConditions,
            locationId
          );
          currentLocationObject.totalItemTypeCount += 1;
        }

        const currentItemTypeObject = currentLocationObject.itemTypeMap[itemTypeId];

        if (processStateBased) {
          if (processState !== REMOVED) {
            currentItemTypeObject.items.push(properties);
            currentItemTypeObject.totalItemCount += 1;
            currentItemLotObject.totalItemCount += 1;

            if (quantity) {
              currentItemTypeObject.totalQuantity += quantity;
            }

            if (pounds_ts) {
              currentItemTypeObject.totalWeight += pounds_ts;
            }

            if (currentItemTypeObject.typeLastUpdatedTime < lastUpdatedTime) {
              currentItemTypeObject.typeLastUpdatedTime = lastUpdatedTime;
            }

            if (expiryDate && expiryDate < today) {
              currentItemTypeObject.expiredItemCount += 1;
            } else if (expiryDate && expiryDate < today + week) {
              currentItemTypeObject.expiringSoonItemCount += 1;
            }
          }

          switch (processState) {
            case INCOMING:
              currentItemTypeObject.incomingItemCount += 1;
              break;
            case ONHAND:
              currentItemTypeObject.stockItemCount += 1;
              break;
            case CUSTOMER_ONHAND:
              currentItemTypeObject.burnStockItemCount += 1;
              break;
          }

          if (isConsumed && consumedTime > today - week) {
            currentItemTypeObject.consumedItemCount += 1;
          }
        } else {
          if (!hasExited && (targetLocationBased || !isConsumed)) {
            currentItemTypeObject.items.push(properties);
            currentItemTypeObject.totalItemCount += 1;
            currentItemLotObject.totalItemCount += 1;

            if (quantity) {
              currentItemTypeObject.totalQuantity += quantity;
            }

            if (pounds_ts) {
              currentItemTypeObject.totalWeight += pounds_ts;
            }

            if (currentItemTypeObject.typeLastUpdatedTime < lastUpdatedTime) {
              currentItemTypeObject.typeLastUpdatedTime = lastUpdatedTime;
            }
          }

          if (!hasExited) {
            if (locationId !== UNKNOWN_LOCATION_ID) {
              // calculate counts relative to stock
              if (!isConsumed && lastKnownLocationId !== locationId) {
                currentItemTypeObject.incomingItemCount += 1;
              } else if (isConsumed) {
                if (targetLocationBased) {
                  currentItemTypeObject.burnStockItemCount += 1;
                }

                if (consumedTime > today - week) {
                  currentItemTypeObject.consumedItemCount += 1;
                }
              } else {
                currentItemTypeObject.stockItemCount += 1;
              }

              // calculate counts relative to expiry
              if (targetLocationBased || !isConsumed) {
                if (expiryDate && expiryDate < today) {
                  currentItemTypeObject.expiredItemCount += 1;
                } else if (expiryDate && expiryDate < today + week) {
                  currentItemTypeObject.expiringSoonItemCount += 1;
                }
              }
            }
          } else if (consumedTime > today - week) {
            currentItemTypeObject.consumedItemCount += 1;
          }
        }

        if (currentItemLotObject.totalItemCount == 0) {
          delete currentLocationObject.lotNumberMap[lotNumberIdentifier];
        }

        if (!currentLocationObject.items) {
          currentLocationObject.items = [];
        }
        currentLocationObject.items.push(properties);

        if (isRecentlyAdded) {
          currentItemTypeObject.recentlyAddedItemCount += 1;
        }
      }
    });

    let processedChildLocationsList = Object.keys(allLocationMap)
      .filter((eachLocationKey) => {
        return allLocationMap[eachLocationKey].childLocationIds.length === 1;
      })
      .map((eachLocationKey) => {
        if (filteredLocationMapOfConsumedItemType[eachLocationKey]) {
          filteredLocationMapOfConsumedItemType[eachLocationKey].forEach((eachItemTypeId) => {
            if (!allLocationMap[eachLocationKey].itemTypeMap) {
              allLocationMap[eachLocationKey].itemTypeMap = {};
            }
            if (!allLocationMap[eachLocationKey].itemTypeMap[eachItemTypeId]) {
              allLocationMap[eachLocationKey].itemTypeMap[eachItemTypeId] = {
                ...allItemTypeMap[eachItemTypeId]
              };
              allLocationMap[eachLocationKey].totalItemTypeCount += 1;
            }
          });
        }

        const { itemTypeMap = {} } = allLocationMap[eachLocationKey];

        Object.keys(itemTypeMap).forEach((eachItemTypeId) => {
          const {
            expiredItemCount,
            expiringSoonItemCount,
            stockItemCount,
            incomingItemCount,
            minStockThreshold,
            totalItemCount,
            consumedItemCount,
            recentlyAddedItemCount,
            burnStockItemCount
          } = itemTypeMap[eachItemTypeId];

          itemTypeMap[eachItemTypeId].onHandItemCount += stockItemCount + burnStockItemCount;
          const { onHandItemCount } = itemTypeMap[eachItemTypeId];

          // Calculate Status for each item type
          if (onHandItemCount <= 0) {
            itemTypeMap[eachItemTypeId].stockStatus = 'Critical';
          } else if (minStockThreshold && onHandItemCount <= minStockThreshold) {
            itemTypeMap[eachItemTypeId].stockStatus = 'Warning';
          }

          if (expiredItemCount > 0) {
            itemTypeMap[eachItemTypeId].expiryStatus = 'Critical';
          } else if (expiringSoonItemCount > 0) {
            itemTypeMap[eachItemTypeId].expiryStatus = 'Warning';
          } else if (!stockItemCount) {
            itemTypeMap[eachItemTypeId].expiryStatus = 'Empty';
          }

          // Calculate Status, Low Stock Count, Out of Stock Count, expiring Soon Count, expired Count for each leaf location.

          if (eachLocationKey === UNKNOWN_LOCATION_ID) {
            allLocationMap[eachLocationKey].expiryStatus = 'Empty';
            allLocationMap[eachLocationKey].stockStatus = 'Empty';
          } else {
            if (
              healthPriorityMap[itemTypeMap[eachItemTypeId].expiryStatus] <
              healthPriorityMap[allLocationMap[eachLocationKey].expiryStatus]
            ) {
              allLocationMap[eachLocationKey].expiryStatus =
                itemTypeMap[eachItemTypeId].expiryStatus;
            }

            if (
              healthPriorityMap[itemTypeMap[eachItemTypeId].stockStatus] <
              healthPriorityMap[allLocationMap[eachLocationKey].stockStatus]
            ) {
              allLocationMap[eachLocationKey].stockStatus = itemTypeMap[eachItemTypeId].stockStatus;
            }

            allLocationMap[eachLocationKey].recentlyAddedItemCount += recentlyAddedItemCount;
            allLocationMap[eachLocationKey].burnStockItemCount += burnStockItemCount;
            allLocationMap[eachLocationKey].onHandItemCount += onHandItemCount;
            allLocationMap[eachLocationKey].expiringSoonItemCount += expiringSoonItemCount;
            allLocationMap[eachLocationKey].expiredItemCount += expiredItemCount;
            allLocationMap[eachLocationKey].stockItemCount += stockItemCount;
            allLocationMap[eachLocationKey].incomingItemCount += incomingItemCount;

            if (!onHandItemCount) {
              allLocationMap[eachLocationKey].outOfStockItemTypeCount += 1;
            } else if (minStockThreshold && onHandItemCount <= minStockThreshold) {
              allLocationMap[eachLocationKey].lowStockItemTypeCount += 1;
            }

            if (burnStockItemCount > 0) {
              allLocationMap[eachLocationKey].burnStockItemTypeCount += 1;
            }
          }

          allLocationMap[eachLocationKey].consumedItemCount += consumedItemCount;
          allLocationMap[eachLocationKey].totalItemCount += totalItemCount;
        });
        return allLocationMap[eachLocationKey];
      });

    while (processedChildLocationsList.length) {
      let parentLocationsList = [];
      processedChildLocationsList.forEach((eachChildLocation) => {
        const { id: childLocationId, directParentId } = eachChildLocation;
        let parentIndex = parentLocationsList.findIndex((each) => {
          return each.id === directParentId;
        });
        if (parentIndex === -1) {
          if (allLocationMap[directParentId]) {
            parentLocationsList.push(allLocationMap[directParentId]);
            parentIndex = parentLocationsList.length - 1;
          }
        }

        if (parentIndex !== -1) {
          const {
            expiredItemCount,
            expiringSoonItemCount,
            outOfStockItemTypeCount,
            onHandItemCount,
            burnStockItemTypeCount,
            lowStockItemTypeCount,
            incomingItemCount,
            stockItemCount,
            totalItemCount,
            totalItemTypeCount,
            expiryStatus,
            stockStatus,
            consumedItemCount,
            recentlyAddedItemCount,
            burnStockItemCount,
            totalLotCount,
            expiredLotCount,
            expiringSoonLotCount
          } = eachChildLocation;

          if (
            healthPriorityMap[expiryStatus] <
            healthPriorityMap[parentLocationsList[parentIndex].expiryStatus]
          ) {
            parentLocationsList[parentIndex].expiryStatus = expiryStatus;
          }

          if (
            healthPriorityMap[stockStatus] <
            healthPriorityMap[parentLocationsList[parentIndex].stockStatus]
          ) {
            parentLocationsList[parentIndex].stockStatus = stockStatus;
          }

          parentLocationsList[parentIndex].burnStockItemCount += burnStockItemCount;
          parentLocationsList[parentIndex].recentlyAddedItemCount += recentlyAddedItemCount;
          parentLocationsList[parentIndex].consumedItemCount += consumedItemCount;
          parentLocationsList[parentIndex].expiredItemCount += expiredItemCount;
          parentLocationsList[parentIndex].expiringSoonItemCount += expiringSoonItemCount;
          parentLocationsList[parentIndex].outOfStockItemTypeCount += outOfStockItemTypeCount;
          parentLocationsList[parentIndex].burnStockItemTypeCount += burnStockItemTypeCount;
          parentLocationsList[parentIndex].onHandItemCount += onHandItemCount;
          parentLocationsList[parentIndex].lowStockItemTypeCount += lowStockItemTypeCount;
          parentLocationsList[parentIndex].incomingItemCount += incomingItemCount;
          parentLocationsList[parentIndex].stockItemCount += stockItemCount;
          parentLocationsList[parentIndex].totalItemCount += totalItemCount;
          parentLocationsList[parentIndex].totalItemTypeCount += totalItemTypeCount;
          parentLocationsList[parentIndex].totalLotCount += totalLotCount;
          parentLocationsList[parentIndex].expiredLotCount += expiredLotCount;
          parentLocationsList[parentIndex].expiringSoonLotCount += expiringSoonLotCount;

          if (directParentId === parentLocationsList[parentIndex].id) {
            parentLocationsList[parentIndex].childLocations.push(eachChildLocation);
          }
        }
        allLocationMap[childLocationId] = eachChildLocation;
      });
      processedChildLocationsList = parentLocationsList;
      parentLocationsList = [];
    }

    return allLocationMap;
  };

  const getExportCsvItemTypeHeaders = () => {
    /* to use item type list view config to get item type headers to be sent
     * to InventoryTrackPageComponentV2 as additional attributes to export in CSV
     * ONLY when in the "Item" view.
     */
    if (
      currentViewTab === 'all-items' &&
      TrackPageConfig &&
      TrackPageConfig.exportItemTypeAttributesInCSV &&
      TrackPageConfig.listViewConfig &&
      TrackPageConfig.listViewConfig['item-type'] &&
      TrackPageConfig.listViewConfig['item-type'].stock
    ) {
      const itemTypeListViewHeaderConfig = TrackPageConfig.listViewConfig['item-type'].stock || {};
      const itemListViewConfig = TrackPageConfig.listViewConfig['all-items'] || {};
      /* offset is used to set the index of the item type attributes so they appear
         after the item attributes when exported as CSV */
      const offset = Object.keys(itemListViewConfig).length;

      return Object.keys(itemTypeListViewHeaderConfig || {})
        .sort((a, b) => {
          return itemTypeListViewHeaderConfig[a].index - itemTypeListViewHeaderConfig[b].index;
        })
        .map((eachId, index) => {
          const itemTypeId = `itemType-${eachId}`;
          const tempObject = { ...itemTypeListViewHeaderConfig[eachId] };
          // set new index value to order the columns in CSV file
          tempObject.index = index + offset;
          return { id: itemTypeId, ...tempObject };
        });
    }
    return null;
  };

  const calculateData = (sourceData = null) => {
    const overviewHelperData = {
      totalItemCount: 0,
      lowStockItemTypeCount: 0,
      outOfStockItemTypeCount: 0,
      burnStockItemTypeCount: 0,
      expiredItemCount: 0,
      expiringSoonItemCount: 0,
      consumedItemCount: 0,
      recentlyAddedItemCount: 0,
      stockItemCount: 0,
      totalItemTypeCount: 0
    };
    // calculate the health color indicator for header
    const getHealthColor = (eachLocationData) => {
      const { stockStatus, expiryStatus } = eachLocationData;
      let healthColor;
      if (statusTab === 'stock') {
        healthColor = healthColorMap[stockStatus];
      } else if (statusTab === 'expiration') {
        healthColor = healthColorMap[expiryStatus];
      }
      return healthColor;
    };

    // calculate the header title for header
    const getHeaderTitle = (eachLocationData) => {
      const { name, directParentName, category } = eachLocationData;
      const locationName = eachLocationData[locationDisplayLabelMap[category]];
      return `${locationName || name}${directParentName ? ` (${directParentName})` : ''}`;
    };

    const getDataList = (eachLocationData, locationFilterKey) => {
      const { items = [], itemTypeMap = {}, lotNumberMap = {} } = eachLocationData;

      if (currentViewTab === 'all-items') {
        const itemTypeListViewConfig = getValueOrDefault(
          () => TrackPageConfig.listViewConfig['item-type'].stock,
          {}
        );
        const tempItems = [];
        items.forEach((item, i) => {
          if (
            !item.hasExited &&
            (targetLocationBased || item.processState !== null || !item.isConsumed)
          ) {
            /* add item type attributes into the item object if config specifies
               to include item type attributes while exporting CSV */
            if (
              TrackPageConfig &&
              TrackPageConfig.exportItemTypeAttributesInCSV &&
              item.itemTypeId &&
              itemTypeMap[item.itemTypeId] &&
              typeof itemTypeMap[item.itemTypeId] === 'object'
            ) {
              const tempItemObject = { ...item };
              Object.keys(itemTypeListViewConfig).forEach((attributeId, i) => {
                /* To prevent item object's values being overwritten by item type
                 * object's values when both objects have one or more same keys,
                 * append "itemType-" prefix to the item type object's keys
                 * while adding them to the item object. Ex: "identifier" */
                tempItemObject[`itemType-${attributeId}`] =
                  itemTypeMap[item.itemTypeId][attributeId];
              });
              tempItems.push({ ...tempItemObject });
            } else {
              tempItems.push({ ...item });
            }
          }
        });

        return [...tempItems];
      }

      if (currentViewTab === 'item-lot') {
        return Object.keys(lotNumberMap).map((eachLotIdenitifer) => {
          return { ...lotNumberMap[eachLotIdenitifer], locationFilterKey };
        });
      }

      return Object.keys(itemTypeMap).map((eachItemTypeId) => {
        return { ...itemTypeMap[eachItemTypeId], locationFilterKey };
      });
    };

    const getSubHeaderList = (eachLocationData, parentIdList) => {
      const { childLocations } = eachLocationData;

      return childLocations.map((eachChildLocation) => {
        const { id } = eachChildLocation;
        const headerId = `${!!parentIdList.length && `${parentIdList.join()},`}${id}`;

        return {
          id: headerId,
          headerTitle: getHeaderTitle(eachChildLocation),
          healthColor: getHealthColor(eachChildLocation),
          metricsList: getContentMetricsMetadata(eachChildLocation),
          subHeaderList: getSubHeaderList(eachChildLocation, parentIdList.concat(id)),
          dataList: getDataList(eachChildLocation, headerId)
        };
      });
    };

    const { idList: locationIdList = [] } = sideFilterStructureMap.location || {};
    const currentLocationId = mainFilterIdList.length && mainFilterIdList[0];
    const { childLocationIds = [] } = sourceData[currentLocationId] || {};
    const dataList = Object.keys(sourceData)
      .filter((eachLocationKey) => {
        return (
          !mainFilterIdList.length ||
          (childLocationIds.includes(eachLocationKey) &&
            (childLocationIds.length === 1 || eachLocationKey !== currentLocationId))
        );
      })
      .filter((eachLocationKey) => {
        return !locationIdList.length || locationIdList.includes(eachLocationKey);
      })
      .map((eachLocationKey) => {
        const locationData = sourceData[eachLocationKey];
        const { id } = locationData;

        let hide = false;
        if (!mainFilterIdList && locationData.directParentId) {
          hide = true;
        } else if (
          mainFilterIdList[0] !== locationData.directParentId &&
          mainFilterIdList[0] !== locationData.id
        ) {
          hide = true;
        }
        if (locationIdList.includes(eachLocationKey)) {
          hide = false;
        }

        return {
          id,
          headerTitle: getHeaderTitle(locationData),
          healthColor: getHealthColor(locationData),
          metricsList: getContentMetricsMetadata(locationData),
          subHeaderList: getSubHeaderList(locationData, [id]),
          dataList: getDataList(locationData, id),
          hide
        };
      });

    Object.keys(sourceData)
      .filter((eachLocationKey) => {
        return !mainFilterIdList.length || childLocationIds.includes(eachLocationKey);
      })
      .filter((eachLocationKey) => {
        if (locationIdList.length) {
          return locationIdList.includes(eachLocationKey);
        }
        return (
          sourceData[eachLocationKey].childLocationIds.length === 1 &&
          eachLocationKey !== UNKNOWN_LOCATION_ID
        );
      })
      .forEach((eachLocationKey) => {
        const {
          consumedItemCount,
          expiredItemCount,
          stockItemCount,
          totalItemTypeCount,
          recentlyAddedItemCount,
          expiringSoonItemCount,
          lowStockItemTypeCount,
          outOfStockItemTypeCount,
          burnStockItemTypeCount,
          totalItemCount
        } = sourceData[eachLocationKey];

        if (eachLocationKey !== UNKNOWN_LOCATION_ID) {
          overviewHelperData.lowStockItemTypeCount += lowStockItemTypeCount;
          overviewHelperData.outOfStockItemTypeCount += outOfStockItemTypeCount;
          overviewHelperData.burnStockItemTypeCount += burnStockItemTypeCount;
          overviewHelperData.expiringSoonItemCount += expiringSoonItemCount;
          overviewHelperData.consumedItemCount += consumedItemCount;
          overviewHelperData.expiredItemCount += expiredItemCount;
          overviewHelperData.stockItemCount += stockItemCount;
        }
        overviewHelperData.recentlyAddedItemCount += recentlyAddedItemCount;
        overviewHelperData.totalItemTypeCount += totalItemTypeCount;
        overviewHelperData.totalItemCount += totalItemCount;
      });

    const {
      totalItemCount,
      lowStockItemTypeCount,
      outOfStockItemTypeCount,
      burnStockItemTypeCount,
      expiredItemCount,
      expiringSoonItemCount,
      consumedItemCount,
      stockItemCount,
      totalItemTypeCount,
      recentlyAddedItemCount
    } = overviewHelperData;

    const overviewData = {
      averageConsumptionRate: `${(consumedItemCount / 7).toFixed(2)} unit(s)/day`,
      averageStockCount: totalItemTypeCount ? (stockItemCount / totalItemTypeCount).toFixed(2) : 0,
      expiredCount: expiredItemCount,
      totalStockCount: stockItemCount,
      totalItemCount,
      lowStockItemTypeCount,
      expiredItemCount,
      expiringSoonItemCount,
      outOfStockItemTypeCount,
      burnStockItemTypeCount,
      consumedItemCount,
      recentlyAddedItemCount,
      totalItemTypeCount
    };

    const overviewDataList = (Object.keys(metricsMap) || [])
      .sort((a, b) => {
        return metricsMap[a].index - metricsMap[b].index;
      })
      .map((eachKey) => {
        return {
          id: eachKey,
          title: metricsMap[eachKey].label,
          value: overviewData[eachKey] || '--'
        };
      });

    let chartDataList =
      statusTab === 'expiration'
        ? [
            ['Status', 'Items'],
            ['Expired', expiredItemCount],
            ['Expiring Soon', expiringSoonItemCount],
            ['Good', totalItemCount - expiredItemCount - expiringSoonItemCount]
          ]
        : [
            ['Status', 'Items'],
            ['Out of Stock', outOfStockItemTypeCount],
            ['Low Stock', lowStockItemTypeCount],
            ['Good', totalItemTypeCount - outOfStockItemTypeCount - lowStockItemTypeCount]
          ];

    if (
      currentLocationId === UNKNOWN_LOCATION_ID ||
      (locationIdList.length === 1 && locationIdList.includes(UNKNOWN_LOCATION_ID))
    ) {
      chartDataList = [
        ['Status', 'items'],
        ['', 0],
        ['', 0],
        ['', 0],
        ['Not Applicable', 1]
      ];
    }

    return { dataList, overviewDataList, chartDataList };
  };

  /**
   * Once the overview/metrics response data is processed, calculate the display data.
   * Update the header metrics with the final values and status color.
   */
  useEffect(() => {
    if (Object.keys(currentData).length) {
      const { dataList, overviewDataList, chartDataList } = calculateData(currentData);

      const getMetrics = (headers) => {
        let newMetrics = {};
        (headers || []).forEach((header) => {
          newMetrics[header.id] = { healthColor: header.healthColor };
          header.metricsList.forEach((eachMetric) => {
            newMetrics[header.id][eachMetric.id] = eachMetric?.value;
          });
          newMetrics = Object.assign({}, newMetrics, getMetrics(header.subHeaderList));
        });
        return newMetrics;
      };

      setContentMetricsData(getMetrics(dataList));
      setOverviewMetricsDataList(overviewDataList);
      setOverviewChartDataList(chartDataList);
    }
  }, [headerMetricsMap, targetLocationBased, currentViewTab, mainFilterIdList, currentData]);

  /**
   * Once the overview/metrics data is loaded, process the data into the locations map
   */
  useEffect(() => {
    if (
      Object.keys(overallOverviewData).length &&
      commonData &&
      Object.keys(sideFilterStructureMap).length
    ) {
      const {
        itemTypes = [],
        tracksItem = [],
        locationMapOfConsumedItemType = {}
      } = overallOverviewData;

      setCurrentData(calculateLocationsMap(itemTypes, tracksItem, locationMapOfConsumedItemType));
    }
  }, [
    mainFilterIdList,
    sideFilterStructureMap,
    currentViewTab,
    overallOverviewData,
    commonData,
    metricsMap
  ]);

  useEffect(() => {
    const { pathname, search } = urlRef.current;

    if (history.location.pathname !== pathname || history.location.search !== search) {
      if (!history.location.search) {
        setLoading(true);
        onLoad();
      } else if (
        Object.keys(overallOverviewData).length &&
        commonData &&
        Object.keys(locationsMap).length &&
        Object.keys(locationDisplayLabelMap).length
      ) {
        const queryPayload = parseQueryString(history.location.search);
        try {
          processQueryPayload(
            queryPayload,
            overallOverviewData,
            commonData.locations,
            commonData.locationTree,
            locationsMap,
            locationDisplayLabelMap
          );
        } catch {
          onLoad();
        }
      }
      urlRef.current = history.location;
    }
    // eslint-disable-next-line
  }, [history.location, loading]);

  useEffect(() => {
    if (!showOnboardingModalV2) {
      window.fcWidget.show();
      setLoading(true);
      onLoad();
    }
  }, [showOnboardingModalV2]);

  const onLoad = async () => {
    const newLocationDisplayLabelMap = configProvider.getValue(
      LOCATION_DISPLAY_LABEL_MAP,
      'object'
    );

    const {
      targetLocationBased: newTargetLocationBased,
      metricsMap: newMetricsMap,
      possibleSecondaryView: newPossibleSecondaryView,
      gridViewConfig: newGridViewConfig,
      listViewConfig: newListViewConfig,
      headerMetricsMap: newHeaderMetricsMap,
      additionalAttributeMap: newAdditionalAttributeMap = { items: [], itemTypes: [] },
      filterConfig: newFilterConfig = { 'main-view': [], 'selected-view': [] },
      itemTypeLabel: newItemTypeLabel = 'Item Type'
    } = TrackPageConfig;

    // TODO: Don't hard to tenant specific logic in code, include as a config option
    const tenant = await ConfigurationService.getTenantId();
    const isHisco = tenant.includes('hisco');
    const includeItemOrder = !isHisco;

    setItemTypeLabel(newItemTypeLabel);
    setHeaderMetricsMap(newHeaderMetricsMap);
    setTargetLocationBased(newTargetLocationBased);
    setFilterConfig(newFilterConfig);
    setAdditionalAttributeMap(newAdditionalAttributeMap);
    setIncludeItemOrder(includeItemOrder);
    setMetricsMap(newMetricsMap);
    setPossibleSecondaryView(newPossibleSecondaryView);
    setGridViewConfig(newGridViewConfig);
    setListViewConfig(newListViewConfig);
    setLocationDisplayLabelMap(newLocationDisplayLabelMap);

    getCustomerLogo().then((logo) => {
      if (!logo) {
        logo = getStackedXemelgoLogo();
      }
      setLogoData(logo);
    });

    loadLocations(isHisco).then(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (commonData) {
      loadListHeaderContent();
    }
  }, [commonData, sideFilterStructureMap, statusTab, currentViewTab, listViewMode]);

  /**
   * Load the location data used by the overview and listing
   */
  const loadLocations = async (isHisco) => {
    // get possible locations for inventory solution
    const possibleLocations = configProvider.getValue(POSSIBLE_DETECTOR_LOCATIONS, 'array');
    const locationRoleClasses = TrackPageConfig.locationRole?.classes || ['Inventory'];
    const locationRoleNames = TrackPageConfig.locationRole?.names || [];
    const locationRoleActions = TrackPageConfig.locationRole?.actions || ['add'];

    const inventoryPageData = await InventoryClient.getInventoryTrackPageCommonData(
      possibleLocations,
      { classes: locationRoleClasses, names: locationRoleNames, actions: locationRoleActions },
      LocationRoleConfig.enabled,
      isHisco
    );

    const { locations, locationTree } = inventoryPageData;

    const newLocationsMap = locations.reduce((accumulator, current) => {
      const { id, name, identifier, category } = current;
      const locationIdList = [id];
      let directParentId;
      let directParentName;
      let directParentIdentifier;
      const filteredLocationTree = locationTree.filter((eachTree) => eachTree.includes(id));
      filteredLocationTree.forEach((eachTree) => {
        const findIndex = eachTree.findIndex((eachId) => eachId === id);
        if (findIndex !== eachTree.length - 1) {
          eachTree.slice(findIndex + 1, eachTree.length).forEach((eachId) => {
            if (!locationIdList.includes(eachId)) {
              locationIdList.push(eachId);
            }
          });
        }

        if (eachTree[eachTree.length - 1] === id) {
          const parentLocation = locations.find((each) => {
            return each.id === eachTree[eachTree.length - 2];
          });
          if (parentLocation) {
            directParentId = parentLocation.id;
            directParentIdentifier = parentLocation.identifier;
            directParentName = parentLocation.name;
          }
        }
      });
      accumulator[id] = {
        name,
        identifier,
        category,
        items: [],
        childLocations: locationIdList,
        directParentIdentifier,
        directParentName,
        directParentId
      };
      return accumulator;
    }, {});

    setLocationsMap(newLocationsMap);
    setCommonData(inventoryPageData);
  };

  useEffect(() => {
    if (commonData) {
      loadOverviewContent();
    }
  }, [commonData]);

  /**
   * Load the overview/metrics data
   */
  const loadOverviewContent = async () => {
    const inventoryPageData = await InventoryClient.getInventoryTrackPageData(
      targetLocationBased,
      commonData.locations,
      commonData.locationTree,
      additionalAttributeMap,
      filterConfig,
      includeItemOrder,
      TrackPageConfig.itemClasses,
      LocationRoleConfig.enabled
    );

    overallOverviewDataRef.current = inventoryPageData;
    setOverallOverviewData(inventoryPageData);
    setOverviewLoaded(true);
  };

  /**
   * Load the headers for the list view.
   */
  const loadListHeaderContent = async () => {
    const { dataList } = calculateData(calculateLocationsMap());

    setContentListData([]);
    setContentMetadataList(dataList);
    setListLoaded(true);
  };

  /**
   * Build the header metrics
   *
   * @param {*} eachLocationData If location data is provided, the header metrics
   * will include the current value of the metric. Otherwise, it will have a
   * placeholder. This is used prior to the data being available.
   * @returns List of header metrics
   */
  const getContentMetricsMetadata = (eachLocationData = null) => {
    let metricsList;
    if (Object.keys(headerMetricsMap) && headerMetricsMap[currentViewTab]) {
      const currentMetricsMap = headerMetricsMap[currentViewTab][statusTab] || {};
      metricsList = Object.keys(currentMetricsMap)
        .sort((a, b) => {
          const aIndex = currentMetricsMap[a].index;
          const bIndex = currentMetricsMap[b].index;
          return aIndex - bIndex;
        })
        .map((eachMetricsKey) => {
          const { label } = currentMetricsMap[eachMetricsKey];
          return {
            id: eachMetricsKey,
            label,
            value: eachLocationData ? eachLocationData[eachMetricsKey] : '...'
          };
        });
    }

    return metricsList;
  };

  useEffect(() => {
    if (Object.keys(listViewConfig).length && commonData && Object.keys(locationsMap).length) {
      const { defaultViewMode } = TrackPageConfig;

      let queryPayload = parseQueryString(history.location.search);

      const breadcrumbIds = queryPayload.breadcrumbIds
        ? JSON.parse(queryPayload.breadcrumbIds)
        : [];

      let { status, listView = defaultViewMode === 'listview' } = queryPayload;

      prevListViewModeRef.current = listView;

      if ((typeof status === 'object' && !status) || typeof status === 'undefined') {
        status = possibleSecondaryView.tabs[0].id;
      }

      queryPayload = parseQueryString(history.location.search);
      const queryString = stringifyToQueryString({
        ...queryPayload,
        listView,
        breadcrumbIds: JSON.stringify(
          breadcrumbIds.length ? breadcrumbIds : [possibleSecondaryView.tabs[0].id]
        ),
        status
      });
      history.replace(`${history.location.pathname}?${queryString}`);

      queryPayload = parseQueryString(history.location.search);

      processQueryPayload(
        queryPayload,
        overallOverviewData,
        commonData.locations,
        commonData.locationTree,
        locationsMap,
        locationDisplayLabelMap
      );
    }
  }, [overallOverviewData, commonData, locationsMap, locationDisplayLabelMap, listViewConfig]);

  const getSidebarFeatureButtons = () => {
    const { sidebarFeatureButtons } = TrackPageConfig;

    return (
      sidebarFeatureButtons &&
      sidebarFeatureButtons.map((featureButton) => {
        const featureButtonDetail = sidebarFeatureButtonMap[featureButton.id];

        return (
          featureButtonDetail && (
            <div
              key={featureButton.id}
              name={featureButton.display}
              className={TrackPageComponentStyle.create_button}
              onClick={featureButtonDetail.onClick}
            >
              {featureButton.display}
            </div>
          )
        );
      })
    );
  };

  const resetItemsFn = async () => {
    setLoading(true);
    try {
      await trackPageClient.resetInactiveTrackingSessions(LocationRoleConfig.enabled);
    } catch (e) {
      console.error('Error Resetting Items', e);
    } finally {
      setShowResetItemsModalFlag(false);
      setLoading(false);
      await onLoad();
    }
  };

  const resetItemsModalButtons = () => {
    return [
      {
        id: 'cancel-button',
        title: 'Cancel',
        onClick: () => {
          setShowResetItemsModalFlag(false);
        },
        className: 'cancel-button'
      },
      {
        id: 'submit-button',
        disabled: loading,
        title: 'Confirm',
        onClick: () => {
          resetItemsFn();
        },
        className: 'confirm-delete-button'
      }
    ];
  };

  const resetItemsModalBody = () => (
    <p className={TrackPageComponentStyle.modal_body_heading}>
      Are you sure you want to reset all items?
    </p>
  );

  const renderResetItemsModal = () => {
    return (
      <GeneralizedModal
        title="Reset Items"
        modalBodyComponent={resetItemsModalBody()}
        modalFooterButtons={resetItemsModalButtons()}
        showModal={showResetItemsModalFlag}
      />
    );
  };

  const handleDeleteItemTypesClick = async () => {
    setShowDeleteItemTypesModalFlag(true);
    setLoadingItemTypesFlag(true);
    const itemTypesListToDelete = await ItemTypeClient.listItemTypesAndItems('Inventory', [
      'Inventory'
    ]);
    setItemTypeListToDelete(itemTypesListToDelete);
    setLoadingItemTypesFlag(false);
  };

  const handleBulkUpdateItemsClick = async () => {
    history.push(`${history.location.pathname}/bulk-update`);
  };

  const handleDeleteCheckBoxClick = (event) => {
    const selectedItemTypeId = event.target.name;
    const value = event.target.checked;
    const { ...selecteditemTypesMapCopy } = selectedItemTypesMap;

    if (selectedItemTypeId === 'select-all-option') {
      itemTypeListToDelete.forEach(async (itemType) => {
        selecteditemTypesMapCopy[itemType.id] = value;
      });
    }
    selecteditemTypesMapCopy[selectedItemTypeId] = value;

    setSelectedItemTypesMap(selecteditemTypesMapCopy);
  };

  const removeItemTypesFn = async () => {
    setLoading(true);
    const trackingSessionIds = [];
    const itemTypeIds = [];
    const itemIds = [];
    const sensorProfileIds = [];

    itemTypeListToDelete.forEach((itemType) => {
      if (selectedItemTypesMap[itemType.id]) {
        itemTypeIds.push(itemType.id);
        if (itemType.customFields.hasInstance && itemType.customFields.hasInstance.length > 0) {
          const items = itemType.customFields.hasInstance;
          items.forEach((item) => {
            itemIds.push(item.id);
            if (item.associatedWithSession && item.associatedWithSession.length > 0) {
              trackingSessionIds.push(item.associatedWithSession[0].id);
            }
            if (item.hasSensorProfile && item.hasSensorProfile.length > 0) {
              sensorProfileIds.push(item.hasSensorProfile[0].id);
            }
          });
        }
      }
    });

    try {
      const context = { actions: { endTrackingSession: true } };
      while (trackingSessionIds.length) {
        let trackingSessionIdsPartial = trackingSessionIds.splice(0, 40);
        await PublishClient.userEvent(trackingSessionIdsPartial, null, context);
      }

      for (const spId of sensorProfileIds) {
        try {
          await SensorProfileClient.removeSensorProfile(spId, false);
        } catch (e) {
          console.log('e', e);
          return;
        }
      }

      for (const itemId of itemIds) {
        try {
          await ItemClient.removeItem(itemId, false);
        } catch (e) {
          console.log('e', e);
          return;
        }
      }

      for (const itemTypeId of itemTypeIds) {
        try {
          await ItemTypeClient.removeItemType(itemTypeId, false);
        } catch (e) {
          console.log('e', e);
          return;
        }
      }
    } catch (e) {
      console.error('Error removing item types!', e);
    } finally {
      setSelectedItemTypesMap({});
      setShowDeleteItemTypesModalFlag(false);
      setLoading(true);
      await onLoad();
    }
  };

  const deleteItemTypesModalButtons = () => {
    return [
      {
        id: 'cancel-button',
        title: 'Cancel',
        onClick: () => {
          setShowDeleteItemTypesModalFlag(false);
          setSelectedItemTypesMap({});
        },
        className: 'cancel-button'
      },
      {
        id: 'submit-button',
        title: 'Delete',
        disabled:
          loadingItemTypesFlag ||
          !itemTypeListToDelete ||
          itemTypeListToDelete.length === 0 ||
          !Object.values(selectedItemTypesMap).includes(true) ||
          loading,
        onClick: () => {
          removeItemTypesFn();
        },
        className: 'confirm-delete-button'
      }
    ];
  };

  const deleteItemTypesModalBody = () => {
    return loadingItemTypesFlag ? (
      <p>Loading all item types...</p>
    ) : itemTypeListToDelete && itemTypeListToDelete.length > 0 ? (
      <div>
        <p className={TrackPageComponentStyle.modal_body_heading}>
          {`Select the ${pluralizeWord(itemTypeLabel || 'Item Type')} that you want to delete:`}
        </p>
        <div className={TrackPageComponentStyle.checkbox_container}>
          <label key="select-all-option">
            <input
              id="select-all-option"
              name="select-all-option"
              className={TrackPageComponentStyle.checkbox_style}
              checked={selectedItemTypesMap['select-all-option']}
              onChange={handleDeleteCheckBoxClick}
              type="checkbox"
            />
            Select All
          </label>

          {itemTypeListToDelete.length > 0 &&
            itemTypeListToDelete.map((itemType) => (
              <label key={itemType.id}>
                <input
                  id={itemType.id}
                  name={itemType.id}
                  className={TrackPageComponentStyle.checkbox_style}
                  checked={selectedItemTypesMap[itemType.id]}
                  onChange={handleDeleteCheckBoxClick}
                  type="checkbox"
                />
                {itemType.identifier}
                {`(${itemType.customFields.hasInstance &&
                  itemType.customFields.hasInstance.length} Units)`}
              </label>
            ))}
        </div>
      </div>
    ) : (
      <p>{` No ${pluralizeWord(itemTypeLabel || 'Item Type')} found. `}</p>
    );
  };

  const renderRemoveItemTypesModal = () => {
    return (
      <GeneralizedModal
        title={`Delete ${pluralizeWord(itemTypeLabel || 'Item Type')}`}
        modalBodyComponent={deleteItemTypesModalBody()}
        modalFooterButtons={deleteItemTypesModalButtons()}
        showModal={showDeleteItemTypesModalFlag}
      />
    );
  };

  const onBreadcrumbsClick = (id) => {
    const queryPayload = parseQueryString(history.location.search);
    delete queryPayload.filterIds;
    let { locationId, typeId } = queryPayload;

    let breadcrumbIds = queryPayload.breadcrumbIds ? JSON.parse(queryPayload.breadcrumbIds) : [];
    const index = breadcrumbIds.findIndex((eachId) => {
      return eachId === id;
    });
    if (index !== -1) {
      breadcrumbIds = breadcrumbIds.slice(0, index + 1);

      typeId = (
        overallOverviewDataRef.current.itemTypes.find((eachItem) => {
          return eachItem.id === breadcrumbIds[breadcrumbIds.length - 1];
        }) || {}
      ).id;

      if (typeId) {
        locationId = breadcrumbIds[breadcrumbIds.length - 2];
      } else {
        locationId = (
          overallOverviewDataRef.current.locations.find((eachLocation) => {
            return eachLocation.id === breadcrumbIds[breadcrumbIds.length - 1];
          }) || {}
        ).id;
      }
    }
    const queryString = stringifyToQueryString({
      ...queryPayload,
      breadcrumbIds: JSON.stringify(breadcrumbIds),
      viewTab: prevViewTabRef.current,
      listView: prevListViewModeRef.current,
      locationId,
      typeId
    });

    history.push(`${history.location.pathname}?${queryString}`);
  };

  const processQueryPayload = (
    payload,
    data,
    locations,
    locationTree,
    locationsMapParam,
    locationDisplayLabelMapParam
  ) => {
    const { locationId, typeId, viewTab, listView: currentListViewMode, status } = payload;
    const { itemTypeLabel: newItemTypeLabel } = TrackPageConfig;

    const { itemTypes = [] } = data;

    const breadcrumbIds = payload.breadcrumbIds ? JSON.parse(payload.breadcrumbIds) : [];
    if (!breadcrumbIds.length) {
      breadcrumbIds.push('home');
    }
    const newBreadcrumbsDataList = breadcrumbIds.map((eachId) => {
      const itemTypeResult = itemTypes.find((eachType) => {
        return eachType.id === eachId;
      });

      const locationResult = locations.find((eachLocation) => {
        return eachLocation.id === eachId;
      });
      if (itemTypeResult) {
        return {
          id: eachId,
          value: itemTypeResult.identifier,
          onClick: onBreadcrumbsClick,
          linkIconTitle: 'Click to view item type details',
          linkIconClickFn: (id) => {
            history.push(`${history.location.pathname}/itemType/detail?id=${id}`);
          }
        };
      }
      if (locationResult) {
        return { id: eachId, value: locationResult.name, onClick: onBreadcrumbsClick };
      }

      return {
        id: eachId,
        onClick: onBreadcrumbsClick,
        home: true
      };
    });

    setBreadcrumbsDataList(newBreadcrumbsDataList);

    const filterIds = Array.isArray(payload.filterIds)
      ? payload.filterIds
      : payload.filterIds
      ? [payload.filterIds]
      : [];

    setStatusTab(status);
    setCurrentViewTab(viewTab || 'item-type');
    setListViewMode(currentListViewMode);

    const location = locations.find((each) => each.id === locationId);
    const itemType = itemTypes.find((each) => each.id === typeId);

    const newMainFilterIdList = [];
    if (location) {
      newMainFilterIdList.push(location.id);
    }
    if (itemType) {
      newMainFilterIdList.push(itemType.id);
    }
    setMainFilterIdList(newMainFilterIdList);

    // calculating side filter structure map

    const newFilterMap = {};
    newFilterMap['item-type'] = {
      id: 'item-type',
      label: newItemTypeLabel || itemTypeLabel,
      idList: [],
      checkboxList: itemTypes.map((each) => {
        const { id, identifier } = each;
        return { id, label: identifier, value: filterIds.includes(id) };
      })
    };
    newFilterMap['item-type'].checkboxList.forEach((each) => {
      if (each.value) {
        newFilterMap['item-type'].idList.push(each.id);
      }
    });

    const locationIdList = locationId
      ? [locationId]
      : locations.map((eachLocation) => {
          return eachLocation.id;
        });
    const filteredLocationTree = locationTree.filter((eachTree) => eachTree.includes(locationId));
    filteredLocationTree.forEach((eachTree) => {
      const findIndex = eachTree.findIndex((eachId) => eachId === locationId);
      if (findIndex !== eachTree.length - 1) {
        eachTree.slice(findIndex, eachTree.length).forEach((eachId) => {
          if (!locationIdList.includes(eachId)) {
            locationIdList.push(eachId);
          }
        });
      }
    });

    newFilterMap.location = {
      id: 'location',
      label: 'Location',
      idList: [],
      checkboxList: locationId
        ? locationsMapParam[locationId].childLocations.reduce((accumulator, eachId) => {
            if (!newMainFilterIdList.includes(eachId)) {
              accumulator.push({
                id: eachId,
                label: getLocationName(eachId, locationsMapParam, locationDisplayLabelMapParam),
                value: filterIds.includes(eachId)
              });
            }
            return accumulator;
          }, [])
        : locations.map((each) => {
            const { id } = each;
            return {
              id,
              label: getLocationName(id, locationsMapParam, locationDisplayLabelMapParam),
              value: filterIds.includes(id)
            };
          })
    };
    const filter = typeId && locationId ? 'selected-view' : 'main-view';
    const currentFilters = filterConfig[filter];
    const { filterMap = {} } = data;

    if (currentFilters) {
      currentFilters.forEach((filter) => {
        const { id, label } = filter;
        newFilterMap[id] = {
          id,
          label,
          idList: [],
          checkboxList: filterMap[id]
            ? filterMap[id].map((each) => {
                const formattedValue = id + each;
                return {
                  id: formattedValue,
                  label: `${each}`,
                  value: filterIds.includes(`${formattedValue}`)
                };
              })
            : []
        };
        newFilterMap[id].checkboxList.forEach((each) => {
          if (each.value) {
            newFilterMap[id].idList.push(each.id);
          }
        });
      });
    }

    newFilterMap.location.checkboxList.forEach((each) => {
      if (each.value) {
        newFilterMap.location.idList.push(each.id);
      }
    });

    newFilterMap.location.checkboxList.sort((a, b) => {
      if (a.id === UNKNOWN_LOCATION_ID || b.id === UNKNOWN_LOCATION_ID) {
        return a.id === UNKNOWN_LOCATION_ID ? 1 : -1;
      }
      return a.label.localeCompare(b.label, undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    });
    setSideFilterStructureMap(newFilterMap);
  };

  const getSortSchema = () => {
    return [
      {
        id: 'identifier',
        value: itemTypeLabel,
        compareFunc: (a, b) => {
          return a.identifier.localeCompare(b.identifier, undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        },
        type: 'character',
        default: true
      },
      {
        id: 'total units',
        value: 'Total Units',
        compareFunc: (a, b) => {
          return a.totalItemCount - b.totalItemCount;
        },
        type: 'number'
      },
      {
        id: 'status',
        value: 'Status',
        compareFunc: (a, b) => {
          const statusLevelCalculator = (id = '') => {
            switch (id.toLowerCase()) {
              case 'critical':
                return 3;
              case 'warning':
                return 2;
              case 'healthy':
                return 1;
              default:
                return 0;
            }
          };
          let aLevel = 0;
          let bLevel = 0;
          if (statusTab === 'stock') {
            aLevel = statusLevelCalculator(a.stockStatus);
            bLevel = statusLevelCalculator(b.stockStatus);
            return bLevel - aLevel;
          }
          aLevel = statusLevelCalculator(a.expiryStatus);
          bLevel = statusLevelCalculator(b.expiryStatus);
          return bLevel - aLevel;
        },
        type: 'number'
      }
    ];
  };

  const onItemTypeClick = (typeId, locationId) => {
    const locationIdArray = locationId.split(',');
    prevViewTabRef.current = currentViewTab;
    prevListViewModeRef.current = listViewMode;

    const queryPayload = parseQueryString(history.location.search);
    delete queryPayload.filterIds;
    const breadcrumbIds = queryPayload.breadcrumbIds ? JSON.parse(queryPayload.breadcrumbIds) : [];

    locationIdArray.forEach((eachLocationId) => {
      if (!breadcrumbIds.includes(eachLocationId)) {
        breadcrumbIds.push(eachLocationId);
      }
    });

    if (!breadcrumbIds.includes(typeId)) {
      breadcrumbIds.push(typeId);
    }

    const queryString = stringifyToQueryString({
      ...queryPayload,
      locationId: locationIdArray[locationIdArray.length - 1],
      breadcrumbIds: JSON.stringify(breadcrumbIds),
      itemType: true,
      typeId,
      viewTab: 'all-items',
      listView: true
    });

    history.push(`${history.location.pathname}?${queryString}`);
  };

  const renderGridCardComponentItemType = (eachItemData, id, containerStyle) => {
    let color;
    const { expiryStatus, stockStatus, locationFilterKey } = eachItemData;
    if (statusTab === 'expiration') {
      color = healthColorMap[expiryStatus];
    } else if (statusTab === 'stock') {
      color = healthColorMap[stockStatus];
    }

    if (locationFilterKey === UNKNOWN_LOCATION_ID) {
      color = healthColorMap.Empty;
    }

    let currentGridViewConfig = gridViewConfig[currentViewTab] || {};
    const match = possibleSecondaryView.tabs?.find((each) => {
      return each.id === statusTab;
    });
    if (match) {
      currentGridViewConfig = currentGridViewConfig[statusTab];
    }

    const {
      title = {},
      image,
      contentList = [],
      progressBarEnabled,
      showTotalQuantity,
      showTotalWeight
    } = currentGridViewConfig || {};

    return (
      <GridCardComponent
        key={id}
        onClick={() => {
          onItemTypeClick(id, locationFilterKey);
        }}
        containerStyle={containerStyle}
        color={color}
        gridCardContent={
          <GridCardContentGroupbyItemType
            progressBarEnabled={progressBarEnabled}
            color={color}
            statusTab={statusTab}
            data={eachItemData}
            image={image ? eachItemData[image.id] || logoData : null}
            title={{ ...title, value: eachItemData[title.id] }}
            contentList={contentList?.map((each) => {
              return { ...each, value: eachItemData[each.id] };
            })}
            showTotalQuantity={showTotalQuantity}
            showTotalWeight={showTotalWeight}
          />
        }
      />
    );
  };

  const listViewHeaderStructureListControl = (groupby) => {
    let currentListViewHeaderConfig = listViewConfig[groupby] || {};

    if (
      possibleSecondaryView.tabs?.find((each) => {
        return each.id === statusTab;
      }) &&
      currentListViewHeaderConfig[statusTab]
    ) {
      currentListViewHeaderConfig = currentListViewHeaderConfig[statusTab];
    }
    return Object.keys(currentListViewHeaderConfig || {})
      .sort((a, b) => {
        return currentListViewHeaderConfig[a].index - currentListViewHeaderConfig[b].index;
      })
      .map((eachId) => {
        const { type, format = 'YYYY-MM-DD' } = currentListViewHeaderConfig[eachId];
        let renderComponent;
        switch (type) {
          case 'expiryDateCount':
            renderComponent = (data) => {
              return typeof data === 'number'
                ? data > 0
                  ? `${data} day(s)`
                  : 'Item has Expired'
                : 'No Expiry Date';
            };
            break;
          case 'date':
            renderComponent = (data) => {
              return data ? getFormattedDate(data, format) : '-';
            };
            break;
          default:
            renderComponent = (data) => {
              return data || typeof data === 'number' ? data : '-';
            };
            break;
        }
        return { id: eachId, ...currentListViewHeaderConfig[eachId], renderComponent };
      });
  };

  const handleViewTabClick = (id) => {
    const queryPayload = parseQueryString(history.location.search);
    if (id === 'all-items' || id === 'item-lot') {
      queryPayload.listView = true;
      prevListViewModeRef.current = listViewMode;
    } else {
      queryPayload.listView = prevListViewModeRef.current;
    }
    const queryString = stringifyToQueryString({
      ...queryPayload,
      viewTab: id
    });
    history.replace(`${history.location.pathname}?${queryString}`);
  };

  const getLocationName = (locationId, map, locationDisplayLabelMapParam) => {
    const { name, directParentName, category } = map[locationId];
    const locationName = map[locationId][locationDisplayLabelMapParam[category]];
    return `${locationName || name}${directParentName ? ` (${directParentName})` : ''}`;
  };

  const getStatusViewTabStructure = () => {
    if (TrackPageConfig.hidePossibleSecondaryView) {
      return [];
    }
    const { label, tabs = [] } = possibleSecondaryView;
    const statusViewTabStructure = [
      {
        id: label,
        display: label,
        tabStructure: tabs.map((each) => {
          const { id, label: eachLabel } = each;
          return {
            id,
            display: eachLabel,
            action: () => {
              setStatusTab(id);
              const queryPayload = parseQueryString(history.location.search);

              if (id === 'stock' && currentViewTab === 'item-lot') {
                queryPayload.viewTab = 'item-type';
              }

              const queryString = stringifyToQueryString({
                ...queryPayload,
                status: id
              });
              history.replace(`${history.location.pathname}?${queryString}`);
            }
          };
        })
      }
    ];
    return statusViewTabStructure;
  };

  const filterFunction = (input, each = {}) => {
    let match = false;
    const { identifier } = each;
    const lowerCaseInput = input.toLowerCase();

    if (identifier && identifier.toLowerCase().includes(lowerCaseInput)) {
      match = true;
    }
    return match;
  };

  const gridViewfilterFunction = (input, each = {}) => {
    let currentGridViewConfig = gridViewConfig[currentViewTab] || {};
    if (currentViewTab !== 'all-items') {
      currentGridViewConfig = currentGridViewConfig[statusTab] || {};
    }

    const keysToCheckList = [currentGridViewConfig.title.id];

    const { contentList } = currentGridViewConfig;
    if (contentList) {
      const additionalkeysToCheckList = currentGridViewConfig.contentList?.map((key) => key.id);
      keysToCheckList.push(...additionalkeysToCheckList);
    }

    const lowerCaseInput = input.toLowerCase();
    let match = false;
    keysToCheckList.forEach((eachKey) => {
      let eachValue = each[eachKey];
      if (typeof eachValue !== 'string') {
        eachValue = String(eachValue);
      }
      if (eachValue && eachValue.toLowerCase().includes(lowerCaseInput)) {
        match = true;
      }
    });

    return match;
  };

  const listViewFilterFunction = (input, each) => {
    let currentListViewConfig = listViewConfig[currentViewTab] || {};
    if (currentViewTab !== 'all-items') {
      currentListViewConfig = currentListViewConfig[statusTab] || {};
    }

    const keysToCheckList = Object.keys(currentListViewConfig).reduce((accumulator, currentKey) => {
      if (
        !currentListViewConfig[currentKey].type ||
        currentListViewConfig[currentKey].type === 'string'
      ) {
        accumulator.push(currentKey);
      }
      return accumulator;
    }, []);

    const lowerCaseInput = input.toLowerCase();
    let match = false;
    keysToCheckList.forEach((eachKey) => {
      let eachValue = each[eachKey];
      if (typeof eachValue !== 'string') {
        eachValue = String(eachValue);
      }
      if (eachValue && eachValue.toLowerCase().includes(lowerCaseInput)) {
        match = true;
      }
    });

    return match;
  };

  /**
   * In header based mode, the track page component needs to be provided the data
   *
   * @returns item/item type data
   */
  const exportCSV = async () => {
    setLoading(true);
    const { dataList } = calculateData(currentData);
    setLoading(false);
    return dataList;
  };

  /**
   * Callback from the TrackPageComponent when the headers are expanded.
   *
   * @param {string} locationId location id
   * @param {boolean} newIsExpanded true if header is expanded
   */
  const generateUpdateExpansionStateCallback = (locationId, newIsExpanded = true) => {
    if (newIsExpanded) {
      // Put a loading circle while we lookup the data
      const item = {};
      item[locationId] = LOADING;
      item.time = Date.now();
      setContentListData((prevContentListData) => [
        ...prevContentListData.filter((data) => !Object.keys(data).includes(locationId)),
        item
      ]);

      // Find the data if we have it yet
      const headerLocationId = locationId.split(',').pop();
      const { dataList } = calculateData(currentData);
      const locationData = dataList.find((data) => data.id === headerLocationId);
      if (locationData) {
        item[locationId] = locationData?.dataList || [];
        item.time = Date.now();
        setContentListData((prevContentListData) => [
          ...prevContentListData.filter((data) => !Object.keys(data).includes(locationId)),
          item
        ]);
      }
    }
  };

  return (
    <Fragment>
      <TrackPageComponent
        additionalExportCsvHeaders={getExportCsvItemTypeHeaders()}
        filterFunc={gridViewfilterFunction}
        listViewFilterFunc={listViewFilterFunction}
        sidebarFeatureButtons={getSidebarFeatureButtons()}
        mainColor={mainColor}
        secondaryColor={secondaryColor}
        sortSchema={getSortSchema()}
        statusTab={statusTab}
        statusViewTabStructure={getStatusViewTabStructure()}
        removeFilterClick={(ids) => {
          const queryPayload = parseQueryString(history.location.search);
          const filterIds = Array.isArray(queryPayload.filterIds)
            ? queryPayload.filterIds
            : queryPayload.filterIds
            ? [queryPayload.filterIds]
            : [];
          const queryString = stringifyToQueryString({
            ...queryPayload,
            filterIds: filterIds.filter((id) => !ids.includes(id))
          });
          history.replace(`${history.location.pathname}?${queryString}`);
        }}
        currentFilterList={Object.keys(sideFilterStructureMap).reduce((accumulator, eachKey) => {
          const { checkboxList } = sideFilterStructureMap[eachKey];
          checkboxList.forEach((eachCheckbox) => {
            const { id, label, value } = eachCheckbox;
            if (value) {
              accumulator.push({ id, label, sort: eachKey });
            }
          });
          return accumulator;
        }, [])}
        backComponentProps={{
          enabled: !!mainFilterIdList.length,
          title: (breadcrumbsDataList[breadcrumbsDataList.length - 2] || {}).value || 'Home',
          onClick: () => {
            const queryPayload = parseQueryString(history.location.search);
            const breadcrumbIds = queryPayload.breadcrumbIds
              ? JSON.parse(queryPayload.breadcrumbIds)
              : [];
            onBreadcrumbsClick(breadcrumbIds[breadcrumbIds.length - 2]);
          }
        }}
        onHeaderClick={(id) => {
          prevViewTabRef.current = currentViewTab;
          prevListViewModeRef.current = listViewMode;
          const queryPayload = parseQueryString(history.location.search);
          const breadcrumbIds = queryPayload.breadcrumbIds
            ? JSON.parse(queryPayload.breadcrumbIds)
            : [];

          const idArray = id.split(',');

          idArray.forEach((eachId) => {
            if (!breadcrumbIds.includes(eachId)) {
              breadcrumbIds.push(eachId);
            }
          });

          if (queryPayload.locationId !== idArray[idArray.length - 1]) {
            delete queryPayload.filterIds;
            const queryString = stringifyToQueryString({
              ...queryPayload,
              locationId: idArray[idArray.length - 1],
              breadcrumbIds: JSON.stringify(breadcrumbIds)
            });
            history.push(`${history.location.pathname}?${queryString}`);
          }
        }}
        listViewMode={listViewMode}
        setListViewMode={(enabled) => {
          setListViewMode(enabled);
          const queryPayload = parseQueryString(history.location.search);
          const queryString = stringifyToQueryString({ ...queryPayload, listView: enabled });
          history.replace(`${history.location.pathname}?${queryString}`);
        }}
        canSwitchListViewMode={currentViewTab !== 'all-items' && currentViewTab !== 'item-lot'}
        renderDataList={contentMetadataList}
        title="Inventory"
        buttonTitle="+ Add Item"
        buttonFunction={
          TrackPageConfig.onboardingVersion === 'V2'
            ? () => {
                window.fcWidget.hide();
                setShowOnboardingModalV2(true);
              }
            : null
        }
        disableAddButton={TrackPageConfig.disableAddButton}
        titleIcon={
          <img
            src={require('../../img/inventory_icon_blue.png')}
            width="45px"
            height="45px"
            style={{ marginBottom: '2px' }}
          />
        }
        filterComponent={
          !listLoaded ? (
            <Skeleton count={5} />
          ) : (
            <CheckBoxListComponent
              filterStructureMap={sideFilterStructureMap}
              onChange={(newSideFilterStructureMap) => {
                const queryPayload = parseQueryString(history.location.search);
                const filterIds = Object.keys(newSideFilterStructureMap).reduce(
                  (accumulator, eachKey) => {
                    const { idList } = newSideFilterStructureMap[eachKey];
                    idList.forEach((eachId) => {
                      accumulator.push(eachId);
                    });
                    return accumulator;
                  },
                  []
                );
                const queryString = stringifyToQueryString({ ...queryPayload, filterIds });
                history.replace(`${history.location.pathname}?${queryString}`);
              }}
            />
          )
        }
        overviewComponent={
          <InventoryOverviewComponent
            overviewDataList={overviewMetricsDataList}
            itemTypeLabel={itemTypeLabel}
            chartDataList={overviewChartDataList}
            title={
              possibleSecondaryView
                ? possibleSecondaryView.tabs?.find((each) => each.id === statusTab).label
                : 'Stock'
            }
          />
        }
        breadcrumbsComponent={<BreadcrumbsComponent dataList={breadcrumbsDataList} />}
        currentViewTab={currentViewTab}
        hideViewButtons={TrackPageConfig.hideViewButtons}
        viewTabStructure={
          mainFilterIdList.length !== 2
            ? [
                {
                  id: 'all-items',
                  label: 'Item',
                  onClick: () => {
                    handleViewTabClick('all-items');
                  }
                },
                {
                  id: 'item-type',
                  label: itemTypeLabel,
                  onClick: () => {
                    handleViewTabClick('item-type');
                  }
                },
                statusTab === 'expiration'
                  ? {
                      id: 'item-lot',
                      label: 'Lot',
                      onClick: () => {
                        handleViewTabClick('item-lot');
                      }
                    }
                  : {}
              ]
            : []
        }
        gridCardComponent={renderGridCardComponentItemType}
        listViewStructure={listViewHeaderStructureListControl(currentViewTab)}
        handleListViewClick={({ id, locationFilterKey }) => {
          if (currentViewTab === 'all-items') {
            history.push(`${history.location.pathname}/item/detail?itemId=${id}`);
          } else {
            if (currentViewTab === 'item-lot') {
              id = id.split(',')[0];
            }
            onItemTypeClick(id, locationFilterKey);
          }
        }}
        generateExpansionUpdateFunc={generateUpdateExpansionStateCallback}
        contentLoading={loading}
        headerMetricsList={getContentMetricsMetadata()}
        headerMetricsData={contentMetricsData}
        headerBasedContentList={contentListData}
        onExportCSV={exportCSV}
        shouldAutoExpandFirstRow={false}
        useSkeleton
        exportCsvButtonReady={Object.keys(currentData).length}
      />
      {renderRemoveItemTypesModal()}
      {renderResetItemsModal()}

      {showOnboardingModalV2 && (
        <AddInventoryFeatureV2 onClose={() => setShowOnboardingModalV2(false)} />
      )}
    </Fragment>
  );
};
export default withRouter(InventoryTrackPageFeature);
