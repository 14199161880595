import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { InputLabel, MenuItem, Select } from '@material-ui/core';
import TextareaAutosize from 'react-textarea-autosize';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { KeyboardArrowDownRounded, KeyboardArrowUpRounded, MoreVert } from '@material-ui/icons';

import LoadingCircle from '../../../loading/LoadingCircle';
import DetailCard from '../../DetailCard';
import DataTable from '../../DataTable';
import PartIssueRouteHistoryTable from '../../PartIssueRouteHistoryTable';
import ProgressGraph from '../../../RouteTracker/ProgressGraph';
import Style from './css/PartDetailPage.module.css';

import { LocalCacheService } from '../../../../services/local-cache-service';
import { useXemelgoClient } from '../../../../services/xemelgo-service';
import {
  getFormattedDate,
  getCurrentTimestamp,
  getStatusFlags,
  msToTime
} from '../../../../common/Utilities';
import {
  useAppConfigProvider,
  useConfigurationProvider
} from '../../../../services/soft-cache-service';

const APP_ID = 'order';
const PART_CONFIG = 'part';
const DETECTOR_LOCATION_CONFIG = 'possibleDetectorLocations';
const STATUS_MAP = 'statusFlagMap';
const DETAILS_PAGE_CONFIG = 'detailsPage';
const ITEM_ATTRIBUTE_CONFIG = 'attributeMap';
const ASSOCIATED_ORDER_TABLE_CONFIG = 'associatedOrderTable';
const SHIPMENT_HISTORY_TABLE_CONFIG = 'shipmentHistoryTable';
const REPORT_ISSUE_CONFIG = 'reportIssue';
const POSSIBLE_ISSUE_TYPES = 'possibleIssueTypes';
const POSSIBLE_ISSUE_CODES_MAP = 'possibleIssueCodesMap';
const MARK_AS_VERIFIED_CONFIG = 'markAsVerified';

const ASSOCIATED_ORDER_IDENTIFIER_KEY = 'identifier';
const ASSOCIATED_ORDER_TRAVELLER_ID_KEY = 'travellerId';

const TIME_DURATION_KEY = 'timeDuration';

const ISSUE_DATA_LIST_KEY = 'issueList';

const PartDetailPage = ({ history }) => {
  const [PartClient] = useState(useXemelgoClient().getPartClient());
  const [LocationClient] = useState(useXemelgoClient().getLocationClient());
  const [IssueClient] = useState(useXemelgoClient().getIssueClient());
  const [PublishClient] = useState(useXemelgoClient().getPublishClient());
  const [ShipmentClient] = useState(useXemelgoClient().getShipmentClient());

  const configProvider = useAppConfigProvider(APP_ID);
  const SolutionTypes = useConfigurationProvider().config.solutionType;
  const SolutionConfig = useConfigurationProvider().config.solutionConfiguration;
  const ShipmentsV2Config = useConfigurationProvider().config.webClient.appConfigurationMap
    .shipments;
  const ShipmentsV1Config = SolutionConfig.shipments;
  // TODO: Move Shipments track page V1 config to webClient like V2 and change this. This is only temporay.
  const ShipmentsTrackingItemClasses =
    ShipmentsV1Config?.shipmentsTrackingItemClasses ||
    ShipmentsV2Config?.trackPage?.shipmentsTrackingItemClasses ||
    [];
  const OrderStatusFlagMap = configProvider.getValue(STATUS_MAP, 'object');
  const PartConfig = configProvider.getValue(PART_CONFIG, 'object');
  const DetectorLocations = PartConfig[DETECTOR_LOCATION_CONFIG];
  const AvailableStatusFlags = PartConfig[STATUS_MAP] || {};
  const DetailsPageConfig = PartConfig[DETAILS_PAGE_CONFIG];
  const PartAttributeConfig = DetailsPageConfig[ITEM_ATTRIBUTE_CONFIG];
  const AssociatedWorkOrderTableConfig = DetailsPageConfig[ASSOCIATED_ORDER_TABLE_CONFIG];
  const ShipmentHistoryTableConfig = DetailsPageConfig[SHIPMENT_HISTORY_TABLE_CONFIG] || [];
  const ReportIssueConfig = DetailsPageConfig[REPORT_ISSUE_CONFIG] || {};
  const PossibleIssueTypes = ReportIssueConfig[POSSIBLE_ISSUE_TYPES] || [];
  const PossibleIssueCodesMap = ReportIssueConfig[POSSIBLE_ISSUE_CODES_MAP] || {};
  const MarkAsVerifiedConfig = DetailsPageConfig[MARK_AS_VERIFIED_CONFIG] || {
    disabled: true,
    adminOnly: true
  };

  const PartRouteTitleList = [
    {
      key: 'location',
      value: 'Location'
    },
    {
      key: 'entryTime',
      value: 'Entry Time',
      format: 'hh:mm A MMM DD',
      type: 'date'
    },
    { key: 'exitTime', value: 'Exit Time', format: 'hh:mm A MMM DD', type: 'date' },
    { key: 'timeDuration', value: 'Time Duration' }
  ];

  const IssueHistoryTitleList = [
    { key: 'issueCode', value: 'Issue' },
    { key: 'commentsList', value: 'Comments', type: 'list' },
    { key: 'lastUpdatedTime', value: 'Last Updated', format: 'hh:mm A MMM DD', type: 'date' },
    {
      key: 'more-button',
      type: 'more-button',
      options: [{ key: 'add-comment', label: 'Add Comment' }]
    }
  ];

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [partId, setPartId] = useState(null);
  const [attributeList, setAttributeList] = useState([]);
  const [titleStructure, setTitleStructure] = useState({});
  const [contentStructureList, setContentStructureList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [partDetails, setPartDetails] = useState({});
  const [associatedOrderDetails, setAssociatedOrderDetails] = useState([]);
  const [associatedOrderTableTitleList, setAssociatedOrderTableTitleList] = useState([]);
  const [showAssociatedOrderTable, setShowAssociatedOrderTable] = useState(true);
  const [shipmentHistoryTitleList, setShipmentHistoryTitleList] = useState([]);
  const [shipmentHistory, setShipmentHistory] = useState([]);
  const [showShipmentData, setShowShipmentData] = useState(false);
  const [rawShipmentHistory, setRawShipmentHistory] = useState([]);
  const [showReportIssueModal, setShowReportIssueModal] = useState(false);
  const [issueTypeId, setIssueTypeId] = useState(
    PossibleIssueTypes?.length === 1 ? PossibleIssueTypes[0].id : null
  );
  const [issueCode, setIssueCode] = useState(null);
  const [commentBoxText, setCommentBoxText] = useState('');
  const [partRouteHistory, setPartRouteHistory] = useState([]);
  const [partRouteNodeStructureList, setPartRouteNodeStructureList] = useState([]);
  const [showAddLocationModal, setShowAddLocationModal] = useState(false);
  const [addLocationId, setAddLocationId] = useState(null);
  const [showAddCommentToIssueModal, setShowAddCommentToIssueModal] = useState(false);
  const [addCommentToIssueId, setAddCommentToIssueId] = useState(null);
  const [showMarkAsVerifiedModal, setShowMarkAsVerifiedModal] = useState(false);

  const userProfile = LocalCacheService.loadUserProfile();
  const isAdmin = userProfile.isUserSuperAdmin();

  const IsPartReceivedAndVerified = useMemo(() => {
    return !!(
      rawShipmentHistory &&
      rawShipmentHistory.length > 0 &&
      rawShipmentHistory[rawShipmentHistory.length - 1].shipmentStatus === 'Received - Verified'
    );
  }, [rawShipmentHistory]);

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // reset issue code drop down
    setIssueCode(null);
  }, [issueTypeId]);

  const buttonOnClickFunctionMap = {
    'report-issue': () => setShowReportIssueModal(true),
    'add-comment': (event) => handleAddCommentButtonClick(event)
  };

  const onLoad = async () => {
    setLoading(true);
    const payload = queryString.parse(history.location.search);
    const { itemId } = payload;
    setPartId(itemId);
    try {
      const locations = await getLocationList(DetectorLocations);
      if (!locations) {
        throw 'API error getting locations.';
      }
      setLocationList(locations);
    } catch (e) {
      throw e;
    }

    try {
      const partMetaData = await getPartMetadata(itemId, PartAttributeConfig);
      if (!partMetaData) {
        throw 'API error getting part meta data.';
      }
      const { rawPartDetails, titleStruct, contentStruct } = partMetaData;
      setPartDetails(rawPartDetails);
      setTitleStructure(titleStruct);
      setContentStructureList(contentStruct);
    } catch (e) {
      throw e;
    }

    try {
      const { titleList, data } = await getAssociatedWorkOrderDetails(
        itemId,
        AssociatedWorkOrderTableConfig
      );
      if (!titleList || !data) {
        throw 'API error getting associated order data.';
      }
      setAssociatedOrderTableTitleList(titleList);
      setAssociatedOrderDetails(data);
    } catch (e) {
      throw e;
    }

    try {
      const showShipmentData =
        SolutionTypes.includes('shipments') && ShipmentsTrackingItemClasses.includes('Part');

      if (showShipmentData) {
        const { titleList, data, rawShipmentHistoryData } = await getPartShipmentHistory(
          itemId,
          ShipmentHistoryTableConfig
        );
        if (!titleList || !data) {
          throw 'API error getting shipment history data.';
        }
        setShowShipmentData(showShipmentData);
        setShipmentHistoryTitleList(titleList);
        setShipmentHistory(data);
        setRawShipmentHistory(rawShipmentHistoryData);
      }
    } catch (e) {
      throw e;
    }

    try {
      const { partRouteData, nodeStructureList } = await getPartRouteData(itemId, true);
      setPartRouteHistory(partRouteData);
      setPartRouteNodeStructureList(nodeStructureList || []);
    } catch (e) {
      throw e;
    }

    setLoading(false);
  };

  const getLocationList = async (locationCategories = []) => {
    try {
      const locations = await LocationClient.getLocationsOfCategory(locationCategories);
      if (!locations) {
        throw 'Error getting locations.';
      }
      return locations;
    } catch (e) {
      throw e;
    }
  };

  const getPartMetadata = async (itemId, attributeMapConfig = {}) => {
    try {
      const rawPartDetails = await PartClient.getPartDetailsById(itemId, attributeMapConfig);
      if (!rawPartDetails) {
        throw 'Error getting part details.';
      }
      const processedAttributeList = getAttributeList(rawPartDetails, attributeMapConfig);

      const titleStruct = {
        key: processedAttributeList[0].key,
        name: processedAttributeList[0].name,
        value: processedAttributeList[0].value,
        editable: processedAttributeList[0].editable
      };
      const contentStruct = processedAttributeList.slice(1);

      return {
        rawPartDetails,
        titleStruct,
        contentStruct
      };
    } catch (e) {
      throw e;
    }
  };

  const getAssociatedWorkOrderDetails = async (partId, associatedOrderTableConfig) => {
    try {
      const processedAssociatedOrderTableAttributeList = associatedOrderTableConfig?.attributeList?.map(
        (column) => {
          return { value: column.label, ...column };
        }
      );
      const displayOrderTypes = associatedOrderTableConfig?.orderTypes || [];

      const rawWorkOrderList = await PartClient.getAssociatedWorkOrderByPartId(partId);
      const filteredWorkOrderList = rawWorkOrderList.filter((order) =>
        displayOrderTypes.includes(order.type)
      );

      const processedAssociatedOrderDetails = processAssociatedOrderDetails(
        filteredWorkOrderList,
        processedAssociatedOrderTableAttributeList
      );
      return {
        titleList: processedAssociatedOrderTableAttributeList,
        data: processedAssociatedOrderDetails
      };
    } catch (e) {
      throw e;
    }
  };

  const getPartShipmentHistory = async (itemId, shipmentHistoryTableConfig = []) => {
    const processedShipmentHistoryTableConfig = shipmentHistoryTableConfig
      .map((column) => {
        return { value: column.label, ...column };
      })
      .sort((a, b) => a.index - b.index);

    try {
      const rawShipmentHistoryData = await PartClient.getShipmentHistoryForPartByPartId(itemId);
      if (!rawShipmentHistoryData) {
        throw 'Error getting shipment history.';
      }

      const processedShipmentHistoryData = processDataListUsingTitleList(
        rawShipmentHistoryData,
        processedShipmentHistoryTableConfig
      );

      return {
        titleList: processedShipmentHistoryTableConfig,
        data: processedShipmentHistoryData,
        rawShipmentHistoryData
      };
    } catch (e) {
      throw e;
    }
  };

  const getAttributeList = (item = {}, attributeMapConfig = {}) => {
    let attributeList = [];

    attributeList = Object.keys(attributeMapConfig).map((eachId) => {
      const { type = 'input', format, index, label, numberOnly = false } = attributeMapConfig[
        eachId
      ];

      switch (type) {
        case 'button':
          return {
            key: eachId,
            name: attributeMapConfig[eachId].label,
            onClick: buttonOnClickFunctionMap[eachId] || (() => {}),
            type,
            index
          };

        default:
          return {
            key: eachId,
            name: label,
            value: item[eachId] === 0 ? item[eachId] : item[eachId] || '',
            editable: attributeMapConfig[eachId].editable?.single,
            numberOnly,
            type,
            format,
            index
          };
      }
    });

    attributeList = attributeList.sort((a, b) => {
      return a.index - b.index;
    });

    setAttributeList(attributeList);
    return attributeList;
  };

  const processAssociatedOrderDetails = (filteredWorkOrderList, attributeList) => {
    const processedDataObject = {};
    const orderList = [];

    filteredWorkOrderList.forEach((rawWorkOrderInfo, i) => {
      attributeList.forEach((titleObject) => {
        const { type, key, format = 'hh mm A DD MMM, YYYY' } = titleObject;
        switch (titleObject.type) {
          case 'link':
            if (rawWorkOrderInfo[key]) {
              processedDataObject[key] = {
                id: key,
                displayText: rawWorkOrderInfo[key]
              };
              if (key === ASSOCIATED_ORDER_IDENTIFIER_KEY) {
                processedDataObject[
                  key
                ].link = `/work-order/detail?itemId=${rawWorkOrderInfo[ASSOCIATED_ORDER_TRAVELLER_ID_KEY]}`;
              }
            }
            break;
          case 'status':
            const statusObjectList = [];
            rawWorkOrderInfo[key] &&
              rawWorkOrderInfo[key].forEach((status) => {
                if (OrderStatusFlagMap[status]) {
                  statusObjectList.push({
                    id: status,
                    displayText: OrderStatusFlagMap[status].displayText,
                    color: OrderStatusFlagMap[status].color
                  });
                }
              });
            processedDataObject[key] = [...statusObjectList];
            break;
          case 'date':
            processedDataObject[key] = rawWorkOrderInfo[key]
              ? getFormattedDate(rawWorkOrderInfo[key], format)
              : '-';
            break;
          default:
            processedDataObject[key] = rawWorkOrderInfo[key] || '-';
            break;
        }
      });
      orderList.push({ ...processedDataObject });
    });

    return [...orderList];
  };

  const processAssociatedOrderTableTitleList = (data) => {};

  const getPartRouteData = async (partId, showIssues = false) => {
    try {
      const rawPartRoute = await PartClient.getPartRouteByPartId(partId, showIssues);
      if (!rawPartRoute) {
        throw 'Error getting part route history.';
      }
      const processedPartRouteHistoryData = processPartRouteHistoryData(
        rawPartRoute,
        PartRouteTitleList
      );
      const nodeStructureList = [];
      processedPartRouteHistoryData.forEach((each, index) => {
        // skip the row created to display issues that occured before the first detection
        if (each.id !== 'issues-before-detection') {
          nodeStructureList.push({
            route: each.location,
            sequence_number: index,
            done: true,
            processing: !each.exitTime || each.exitTime === '-'
          });
        }
      });
      return { partRouteData: processedPartRouteHistoryData, nodeStructureList };
    } catch (e) {
      setError({
        title: 'Part route error',
        message: e
      });
    }
  };

  const processDataListUsingTitleList = (rawDataList, titleList) => {
    return rawDataList.map((dataObject = {}) => {
      const processedDataObject = getProcessedDataObject(dataObject, titleList);
      return { ...processedDataObject };
    });
  };

  const handleAddCommentButtonClick = (event) => {
    const issueId = event.target.id;
    setAddCommentToIssueId(issueId);
    setShowAddCommentToIssueModal(true);
  };

  const getProcessedDataObject = (rawDataObject, titleList) => {
    const processedDataObject = {};
    const { id } = rawDataObject;
    titleList.forEach((title) => {
      const { type = 'text', format = 'hh:mm A on DD/MM/YY', key } = title;
      const { id } = rawDataObject;

      switch (type) {
        case 'date':
          processedDataObject[key] = rawDataObject[key]
            ? getFormattedDate(rawDataObject[key], format)
            : '-';
          break;
        case 'more-button':
          processedDataObject[key] = title.options?.map((option = {}) => {
            return {
              id,
              ...option,
              onClick: buttonOnClickFunctionMap[option.key]
            };
          });
          break;
        default:
          processedDataObject[key] =
            key === TIME_DURATION_KEY
              ? msToTime((rawDataObject.exitTime || Date.now()) - rawDataObject.entryTime)
              : rawDataObject[key] || '-';
      }
    });
    return {
      id: rawDataObject.id,
      ...processedDataObject
    };
  };

  const processPartRouteHistoryData = (rawPartHistory, partRouteTitleList) => {
    return rawPartHistory.map((eventObject = {}) => {
      const processedDataObject = getProcessedDataObject(eventObject, partRouteTitleList);
      return {
        ...processedDataObject,
        issues: {
          titleList: IssueHistoryTitleList,
          dataList: processDataListUsingTitleList(
            eventObject[ISSUE_DATA_LIST_KEY],
            IssueHistoryTitleList
          )
        }
      };
    });
  };

  const handleUpdateItem = async (updatedInfo) => {
    const { identifier } = updatedInfo;
    const titleLabel = attributeList[0].name;
    if (identifier && identifier.trim() === '') {
      const errorObject = {
        title: `Empty ${titleLabel}`,
        message: `Please enter a value for the ${titleLabel}`
      };
      setError(errorObject);
      return;
    }
    setLoading(true);

    const propertiesToUpdate = { ...updatedInfo };

    // Change numbers to int for BE:
    Object.keys(propertiesToUpdate).forEach((attributeKey) => {
      const attributeInfo = PartAttributeConfig[attributeKey];
      if (attributeInfo?.numberOnly) {
        let tmp = parseInt(propertiesToUpdate[attributeKey]);
        if (propertiesToUpdate[attributeKey].trim() === '') {
          tmp = null;
        } else if (isNaN(tmp)) {
          // must alert user that we're not saving their invalid input
          const errorObject = {
            title: `Type Check Failed`,
            message: `Please enter a numerical value for ${attributeInfo?.name}`
          };
          setError(errorObject);

          delete propertiesToUpdate[attributeKey];
          setLoading(false);
          return;
        }
        propertiesToUpdate[attributeKey] = tmp;
      }
    });

    if (Object.keys(propertiesToUpdate).length === 0) {
      setLoading(false);
      return;
    }
    try {
      await PartClient.updatePart(partId, propertiesToUpdate);
    } catch (e) {
      const errorObject = {
        title: `Update Failed`,
        message: `Something went wrong updating item. ${e || ''}`
      };
      setError(errorObject);
      setLoading(false);
    } finally {
      onLoad();
    }
  };

  const handleSubmitIssue = async (event) => {
    // NOTE - temporarily allowing reporting issue only for item.
    if (issueTypeId === 'item-issue') {
      setLoading(true);
      const issue_identifier = `${partId}-${issueCode}-${getCurrentTimestamp()}`;
      const issuePayload = {
        identifier: issue_identifier,
        issue_code: issueCode,
        comment: commentBoxText
      };

      try {
        const result = await IssueClient.createIssueForItem(partId, issuePayload);
        if (!result || !result.createIssue || !result.createIssue.id) {
          throw 'API error reporting issue.';
        }
        setShowReportIssueModal(false);
        if (PossibleIssueTypes?.length > 1) {
          setIssueTypeId(null);
        }
        setIssueCode(null);
        setCommentBoxText('');
        onLoad();
      } catch (e) {
        setLoading(false);
        setError({
          title: 'Error reporting issue',
          message: `${e} Please contact Xemelgo Support for assistance.`
        });
        throw e;
      }
    } else {
      setShowReportIssueModal(false);
      setIssueTypeId(null);
      setIssueCode(null);
      setCommentBoxText('');
    }
  };

  const handleAddLocation = async () => {
    try {
      setLoading(true);
      await PublishClient.publishUserEvent([partId], addLocationId);
      setShowAddLocationModal(false);
      setAddLocationId(null);
    } catch (e) {
      setError({
        title: 'Error adding location.',
        message: e
      });
    } finally {
      onLoad();
    }
  };

  const renderReportIssueModal = () => {
    return (
      <Modal
        show={showReportIssueModal}
        centered
        backdrop="static"
        backdropClassName={Style.backdrop}
      >
        <Modal.Header className={Style.modal_header}>
          <Modal.Title className={Style.modal_title}>Report an Issue</Modal.Title>
        </Modal.Header>
        <Modal.Body className={Style.modal_body}>
          <InputLabel required style={{ color: '#343434', fontFamily: 'Avenir' }}>
            Issue Type
          </InputLabel>
          <Select
            variant="outlined"
            style={{ fontFamily: 'Avenir', color: '#000' }}
            className={Style.dropdown_style}
            value={issueTypeId}
            onChange={(event) => {
              setIssueTypeId(event.target.value);
            }}
          >
            {PossibleIssueTypes?.map((each) => {
              return (
                <MenuItem key={each.id} value={each.id}>
                  {each.value}
                </MenuItem>
              );
            })}
          </Select>
          <InputLabel required style={{ color: '#343434', fontFamily: 'Avenir' }}>
            Issue
          </InputLabel>
          <Select
            variant="outlined"
            style={{ fontFamily: 'Avenir', color: '#000' }}
            className={!issueTypeId ? Style.dropdown_disabled_style : Style.dropdown_style}
            value={issueCode}
            onChange={(event) => {
              setIssueCode(event.target.value);
            }}
            disabled={!issueTypeId}
          >
            {PossibleIssueCodesMap[issueTypeId]?.map((each) => {
              return (
                <MenuItem key={each} value={each}>
                  {each}
                </MenuItem>
              );
            })}
          </Select>
          <div className={Style.commentBoxContainer}>
            <InputLabel required style={{ color: '#343434', fontFamily: 'Avenir' }}>
              Comments
            </InputLabel>
            <TextareaAutosize
              id="commentBoxText"
              className={Style.comment_box_input}
              value={commentBoxText}
              onChange={(event) => setCommentBoxText(event.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className={Style.modal_footer}>
          <button
            type="button"
            className={`${Style.button} ${Style.cancel_button}`}
            onClick={() => {
              if (PossibleIssueTypes?.length > 1) {
                setIssueTypeId(null);
              }
              setIssueCode(null);
              setCommentBoxText('');
              setShowReportIssueModal(false);
            }}
          >
            Cancel
          </button>
          <button
            disabled={loading || !issueTypeId || !issueCode || !commentBoxText.trim()}
            type="button"
            className={`${Style.button} ${Style.danger_button}`}
            onClick={() => {
              handleSubmitIssue();
            }}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderAddLocationModal = () => {
    return (
      <Modal
        show={showAddLocationModal}
        centered
        backdrop="static"
        backdropClassName={Style.backdrop}
      >
        <Modal.Header className={Style.modal_header}>
          <Modal.Title className={Style.modal_title}>Add Location</Modal.Title>
        </Modal.Header>
        <Modal.Body className={Style.modal_body}>
          <InputLabel style={{ color: '#343434', fontFamily: 'Avenir' }}>
            Select a location
          </InputLabel>
          <Select
            style={{ fontFamily: 'Avenir', color: '#000' }}
            variant="outlined"
            className={Style.dropdown_style}
            value={addLocationId}
            onChange={(event) => {
              setAddLocationId(event.target.value);
            }}
          >
            {locationList &&
              locationList.map((each = {}) => {
                return (
                  <MenuItem key={each.id} value={each.id}>
                    {each.name}
                  </MenuItem>
                );
              })}
          </Select>
        </Modal.Body>
        <Modal.Footer className={Style.modal_footer}>
          <button
            type="button"
            className={`${Style.button} ${Style.cancel_button}`}
            onClick={() => {
              setAddLocationId('');
              setShowAddLocationModal(false);
            }}
          >
            Cancel
          </button>
          <button
            disabled={loading || !addLocationId}
            type="button"
            className={`${Style.button} ${Style.save_button}`}
            onClick={handleAddLocation}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  const getShowHideAssociatedOrderTableButton = () => {
    return (
      <div style={{ display: 'flex' }}>
        <div
          role="button"
          className={Style.show_hide_button}
          onClick={() =>
            setShowAssociatedOrderTable((showAssociatedOrderTable) => {
              return !showAssociatedOrderTable;
            })
          }
        >
          {showAssociatedOrderTable ? 'Hide' : 'Show'}
          {showAssociatedOrderTable ? (
            <KeyboardArrowUpRounded className={Style.show_hide_arrow} />
          ) : (
            <KeyboardArrowDownRounded className={Style.show_hide_arrow} />
          )}
        </div>
      </div>
    );
  };

  const renderAddCommentToIssueModal = () => (
    <Modal
      show={showAddCommentToIssueModal}
      centered
      backdrop="static"
      backdropClassName={Style.backdrop}
    >
      <Modal.Header>
        <Modal.Title>Add a comment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{`You may add a comment for the issue ${issueCode} below`}</div>
        <div className={Style.commentBoxContainer}>
          <TextareaAutosize
            id="commentBoxText"
            className={Style.comment_box_input}
            value={commentBoxText}
            onChange={(event) => setCommentBoxText(event.target.value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className={`${Style.button} ${Style.cancel_button}`}
          onClick={() => {
            setCommentBoxText('');
            setShowAddCommentToIssueModal(false);
          }}
        >
          Cancel
        </button>
        <button
          disabled={loading || !commentBoxText}
          type="button"
          className={`${Style.button} ${Style.save_button}`}
          onClick={() => {
            handleAddCommentToIssueSubmit();
          }}
        >
          Submit
        </button>
      </Modal.Footer>
    </Modal>
  );

  const renderMarkAsVerifiedModal = () => (
    <Modal
      show={showMarkAsVerifiedModal}
      centered
      backdrop="static"
      backdropClassName={Style.backdrop}
    >
      <Modal.Header>
        <Modal.Title style={{ fontWeight: '600' }}>Mark as verified</Modal.Title>
      </Modal.Header>
      {IsPartReceivedAndVerified ? (
        <Modal.Body>
          <div>Part has already been received and verified.</div>
        </Modal.Body>
      ) : (
        <Modal.Body>
          <div>Mark part as received and verified?</div>
          <div className={Style.commentBoxContainer}>
            <InputLabel required style={{ color: '#343434', fontFamily: 'Avenir' }}>
              Initials
            </InputLabel>
            <input
              type="text"
              id="commentBoxText"
              className={Style.initials_input}
              value={commentBoxText}
              onChange={(event) => setCommentBoxText(event.target.value)}
              multiline={false}
            />
          </div>
        </Modal.Body>
      )}
      <Modal.Footer>
      {IsPartReceivedAndVerified ? (
          <button
            type="button"
            className={`${Style.button} ${Style.save_button}`}
            onClick={() => {
              setCommentBoxText('');
              setShowMarkAsVerifiedModal(false);
            }}
          >
            OK
          </button>
      ) : (
        <div style={{ display: 'flex' }}>
          <button
            type="button"
            className={`${Style.button} ${Style.cancel_button}`}
            onClick={() => {
              setCommentBoxText('');
              setShowMarkAsVerifiedModal(false);
            }}
          >
            Cancel
          </button>
          <button
            disabled={loading || !commentBoxText.trim()}
            type="button"
            className={`${Style.button} ${Style.save_button}`}
            onClick={handleMarkAsVerified}
          >
            Submit
          </button>
        </div>
      )}
      </Modal.Footer>
    </Modal>
  );

  const handleAddCommentToIssueSubmit = async () => {
    if (addCommentToIssueId) {
      setLoading(true);
      const comment = commentBoxText.replace('/\r?\n|\r/', '');
      try {
        const result = await IssueClient.appendToIssueComment(addCommentToIssueId, comment);
        setShowAddCommentToIssueModal(false);
        setCommentBoxText('');
        setAddCommentToIssueId(null);
      } catch (e) {
        throw e;
      } finally {
        onLoad();
      }
    }
  };

  const handleMarkAsVerified = async () => {
    if (MarkAsVerifiedConfig?.locationId) {
      const {
        trackingSessionId,
        containerId
      } = await PartClient.getShipmentInfoForMarkingAsVerified(partId);

      const itemObject = { trackingSessionId, id: partId, containedIn: containerId };

      try {
        setLoading(true);
        await ShipmentClient.verifyShipment(
          [itemObject],
          MarkAsVerifiedConfig.locationId,
          commentBoxText.trim()
        );
        setShowMarkAsVerifiedModal(false);
        setCommentBoxText('');
        onLoad();
      } catch (e) {
        setLoading(false);
        window.alert(`Error: ${e}`);
      }
    }
  };

  if (loading) {
    return <LoadingCircle />;
  }

  return (
    <Fragment>
      <div className={Style.content_holder}>
        <div className={Style.detail_group}>
          <DetailCard
            statusList={getStatusFlags(partDetails.status_flags || [], AvailableStatusFlags)}
            titleStructure={titleStructure}
            detailStructureList={contentStructureList}
            onSubmit={handleUpdateItem}
          />
        </div>
        <div className={Style.table_group}>
          <div className={Style.table_container}>
            <div className={Style.title_group}>
              <div style={{ display: 'flex' }}>
                <div className={Style.title_label}>Associated Order</div>
                {getShowHideAssociatedOrderTableButton()}
              </div>
            </div>
            {showAssociatedOrderTable ? (
              <DataTable
                titleList={associatedOrderTableTitleList}
                dataList={associatedOrderDetails}
              />
            ) : null}
          </div>
          <div className={Style.table_container}>
            <div className={Style.title_group}>
              <div className={Style.title_label}>Part Route</div>
              {partDetails && partDetails.is_active && (
                <div
                  role="button"
                  tabIndex={-1}
                  onClick={() => setShowAddLocationModal(true)}
                  className={Style.add_location_btn}
                >
                  Add Location
                </div>
              )}
            </div>
            {partRouteNodeStructureList.length > 0 && (
              <ProgressGraph stages={partRouteNodeStructureList} currStage={0} />
            )}
            <PartIssueRouteHistoryTable
              titleList={PartRouteTitleList}
              dataList={partRouteHistory}
            />
          </div>
          {showShipmentData ? (
            <div className={Style.table_container}>
              <div className={Style.title_group}>
                <div className={Style.title_label}>Shipment History</div>
                {partDetails?.is_active &&
                  !MarkAsVerifiedConfig.disabled &&
                  (!MarkAsVerifiedConfig.adminOnly || isAdmin) && (
                    <div
                      role="button"
                      tabIndex={-1}
                      onClick={() => {
                        setShowMarkAsVerifiedModal(true);
                      }}
                      className={Style.add_location_btn}
                    >
                      Mark as Verified
                    </div>
                  )}
              </div>
              <DataTable titleList={shipmentHistoryTitleList} dataList={shipmentHistory} />
            </div>
          ) : null}
        </div>
      </div>
      {renderReportIssueModal()}
      {renderAddLocationModal()}
      {renderAddCommentToIssueModal()}
      {renderMarkAsVerifiedModal()}
    </Fragment>
  );
};

export default withRouter(PartDetailPage);
