import React, { useState, Fragment } from 'react';
import PropTypes, { object } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useStore } from 'react-hookstore';
import { fullScreenModeStore } from '../../state-managements/stores/full-screen-mode-store';
import { useDisplayBannerContext } from '../../context/DisplayBannerContext/DisplayBannerContext';
import MainAppComponent from '../../components/main-app-component/MainAppComponent';
import HeaderComponent from '../../components/header-component/HeaderComponent';
import SideNavigationBar from '../side-navigation-bar/SideNavigationBar';
import RenderComponent from '../../components/render-component/RenderComponent';
import DisplayBanner from '../../components/display-banner/DisplayBanner';
import MainAppFeatureStyle from './MainAppFeature.module.css';

const MainAppFeature = ({ render, tabStructure, history }) => {
  const defaultTab = history.location.pathname.split('/')[1];
  const [fullScreenMode] = useStore(fullScreenModeStore);
  const [sideNavigationCollapsed, setSideNavigationCollapsed] = useState(true);
  const testingMode = sessionStorage.getItem('CustomerTestMode') === 'Test';
  let mainAppStyle;
  let headerContainerStyle;
  let sideNavigationContainerStyle;

  const { showBanner, setShowBanner, bannerHasError, bannerTitle } = useDisplayBannerContext();

  if (fullScreenMode) {
    if (testingMode) {
      mainAppStyle = MainAppFeatureStyle.main_app_component_full_screen_container_test;
      headerContainerStyle = MainAppFeatureStyle.header_fullscreen_container_test;
    } else {
      mainAppStyle = MainAppFeatureStyle.main_app_component_full_screen_container;
    }
  } else if (testingMode) {
    if (sideNavigationCollapsed) {
      mainAppStyle = MainAppFeatureStyle.main_app_component_container_test_collapsed;
      sideNavigationContainerStyle = MainAppFeatureStyle.side_navigation_container_test_collapsed;
    } else {
      sideNavigationContainerStyle = MainAppFeatureStyle.side_navigation_container_test;
      mainAppStyle = MainAppFeatureStyle.main_app_component_container_test;
    }
    headerContainerStyle = MainAppFeatureStyle.header_component_container_test;
  } else {
    if (sideNavigationCollapsed) {
      mainAppStyle = MainAppFeatureStyle.main_app_component_container_collapsed;
      sideNavigationContainerStyle = MainAppFeatureStyle.side_navigation_container_collapsed;
    } else {
      mainAppStyle = MainAppFeatureStyle.main_app_component_container;
      sideNavigationContainerStyle = MainAppFeatureStyle.side_navigation_container;
    }
    headerContainerStyle = MainAppFeatureStyle.header_component_container;
  }
  return (
    <MainAppComponent
      containerStyle={mainAppStyle}
      headerComponent={() => {
        return (
          <HeaderComponent containerStyle={headerContainerStyle} fullScreenMode={fullScreenMode} />
        );
      }}
      sideNavigationComponent={() => {
        return (
          !fullScreenMode && (
            <SideNavigationBar
              hasCollapseIcon
              collapsed={sideNavigationCollapsed}
              onCollapse={() => {
                setSideNavigationCollapsed(!sideNavigationCollapsed);
              }}
              tabStructure={tabStructure}
              containerStyle={sideNavigationContainerStyle}
              defaultTab={defaultTab}
            />
          )
        );
      }}
      renderComponent={() => {
        return (
          <Fragment>
            <div
              className={`${MainAppFeatureStyle.banner_container} ${!showBanner &&
                MainAppFeatureStyle.banner_container_collapsed}`}
            >
              {showBanner && (
                <DisplayBanner
                  bannerError={bannerHasError}
                  onCloseBanner={() => {
                    setShowBanner(false);
                  }}
                  bannerMessage={bannerTitle}
                />
              )}
            </div>
            <RenderComponent
              containerStyle={MainAppFeatureStyle.render_component_container}
              renderer={render}
            />
          </Fragment>
        );
      }}
    />
  );
};

export default withRouter(MainAppFeature);

MainAppFeature.propTypes = {
  render: PropTypes.element.isRequired,
  tabStructure: PropTypes.arrayOf(object).isRequired
};
