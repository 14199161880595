import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputField, { StyledTextField } from './InputField';
import DateField from './DateField';
import CheckboxGroupField from './CheckboxGroupField';
import SearchAndSubmit from '../SearchAndSubmit';
import BasicAddFormStyle from './css/BasicAddForm.module.css';
import MUiSearchDropdown from '../MUiSearchDropdown';

export default class BasicAddForm extends Component {
  render() {
    // changeHandler is a function that updates this components parent with the values of child form components
    const { formControls, changeHandler, formContainerStyle, MUISearchLists } = this.props;
    return (
      <div className={formContainerStyle}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className={BasicAddFormStyle.basic_add_form_style}
        >
          {Object.keys(formControls).map((formData) => {
            const data = formControls[formData];
            return (
              <div key={data.id} className={BasicAddFormStyle.form_content_with_error}>
                {data.render(data, changeHandler, MUISearchLists)}
              </div>
            );
          })}
        </form>
      </div>
    );
  }
}

export function useInputField(fieldParams, changeHandler) {
  return (
    <InputField
      key={fieldParams.id}
      data={fieldParams}
      changeHandler={changeHandler}
      style={StyledTextField}
      width={fieldParams.width}
    />
  );
}

export function useDateField(fieldParams, changeHandler) {
  return <DateField key={fieldParams.id} data={fieldParams} changeHandler={changeHandler} />;
}

export function useCheckboxGroupField(fieldParams, changeHandler) {
  return <CheckboxGroupField data={fieldParams} changeHandler={changeHandler} />;
}

export function useSearchableDropdown(fieldParams, changeHandler) {
  const { value, searchList, id, label, containerStyle } = fieldParams;
  return (
    <SearchAndSubmit
      value={value}
      searchMap={searchList}
      selectItem={(event) => changeHandler(id, event)}
      handleInputChange={(event) => changeHandler(id, event)}
      label={label}
      showSearchList
      containerStyle={containerStyle}
      styledTextField={StyledTextField}
    />
  );
}

export function useMUiSearchDropdown(fieldParams, changeHandler, MUISearchLists) {
  const { id, label } = fieldParams;
  const searchList = MUISearchLists[id];
  return (
    <MUiSearchDropdown
      searchMap={searchList}
      selectItem={(event) => changeHandler(id, event)}
      label={label}
      data={fieldParams}
    />
  );
}

BasicAddForm.propTypes = {
  formControls: PropTypes.any.isRequired,
  changeHandler: PropTypes.func.isRequired,
  formContainerStyle: PropTypes.string
};

BasicAddForm.defaultProps = {
  formContainerStyle: ''
};
