import React from 'react';
import CheckboxStyle from './Checkbox.module.css';
import PropTypes from 'prop-types';

export const Checkbox = ({ onClick, isChecked }) => {
  return (
    <div className={CheckboxStyle.checkbox_outer} onClick={onClick}>
      <div
        className={isChecked ? CheckboxStyle.checkbox_checked : CheckboxStyle.checkbox_unchecked}
      />
    </div>
  );
};

Checkbox.propTypes = {
  isChecked: PropTypes.bool,
  onClick: PropTypes.func
};
