import React from 'react';
import PropTypes from 'prop-types';
import Selector from './Selector';
import InfoCard from './InfoCard';
import Style from './AddInventoryV2Components.module.css';

const ItemTypeInformation = ({ itemTypes, onSelect, selectedItemType }) => (
  <div className={Style.item_information_container}>
    <p className={Style.info_content_title}>Item Information</p>
    <p className={Style.info_content_label}>Item *</p>
    <Selector
      options={itemTypes}
      onSelect={(_, itemType) => {
        onSelect(itemType);
      }}
      value={selectedItemType?.name}
      placeholder="Select an item"
    />
    <div className={Style.item_information_card_container}>
      {!!Object.keys(selectedItemType).length && (
        <div className={Style.item_information_card}>
          <div className={Style.item_type_image_container}>
            <img className={Style.item_type_image} src={selectedItemType.image_path.value} />
          </div>
          <InfoCard info={selectedItemType} />
        </div>
      )}
    </div>
  </div>
);

ItemTypeInformation.defaultProps = {
  selectedItemType: {}
};

ItemTypeInformation.propTypes = {
  itemTypes: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedItemType: PropTypes.object
};

export default ItemTypeInformation;
