import React, { useState, useRef, useEffect } from 'react';
import ScreenFrame from 'components/ScreenFrame/ScreenFrame';
import Style from './ManagementDashboardScreen.module.css';
import { ReplayOutlined, ChevronLeft, ChevronRight } from '@material-ui/icons';
import FloorMap from '../../components/FloorMap/FloorMap';
import { useAppConfigProvider } from '../../services/soft-cache-service';

const APP_ID = 'managementDashboard';
const MAP_CONFIG = 'mapConfig';

const TitleRightComponent = () => {
  return (
    <div className={`${Style.flex_row} ${Style.title_right_container}`}>
      <div>
        <p>Last Updated:</p>
        <p>03/19/2022 1:00PM</p>
      </div>
      <button className={`${Style.title_right_reload_button}`}>
        <ReplayOutlined />
        <p>Reload</p>
      </button>
    </div>
  );
};

const ManagementDashboardScreen = ({ history }) => {
  const configProvider = useAppConfigProvider(APP_ID);

  const mapConfig = configProvider.getValue(MAP_CONFIG, 'object') || {};

  const informationContainerRef = useRef(null);
  const [isInformationHide, setIsInformationHide] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState('');
  const [colorMap, setColorMap] = useState({
    '#4B9910': {
      label: 'Healthy',
      locations: ['departmentBid', 'department5id', 'zone1id', 'zone2id', 'zone4id']
    },
    '#F6B044': {
      label: 'Warning',
      locations: ['department2Id', 'department3Id', 'zone3id', 'zone5id']
    },
    '#FF3D46': {
      label: 'Critical',
      locations: ['department4Id', 'FacilityId', 'zone6id', 'zone7id', 'zone8id']
    }
  });

  return (
    <ScreenFrame
      verticalExpandDisabled
      title="Management Dashboard"
      color="#C8CBD3"
      titleIconComponent={() => {
        return (
          <img
            src={require('../../img/management-dashboard_icon_light_gray.png')}
            width="40x"
            height="40px"
          />
        );
      }}
      titleRightComponent={<TitleRightComponent />}
    >
      <FloorMap
        mapStyleURL={mapConfig.mapStyleURL}
        floorPlanTilesetURL={mapConfig.floorPlanTilesetURL}
        locationsSourceDatasetURL={mapConfig.locationsSourceDatasetURL}
        locationsSourceDatasetName={mapConfig.locationsSourceDatasetName}
        initialViewStates={mapConfig.initialViewStates}
        colorMap={colorMap}
        mapCameraPadding={!isInformationHide && { right: 500 }}
        mapNavigationControlClassName={`${Style.map_control} ${isInformationHide &&
          Style.map_control_collapsed}`}
        mapColorIndicatorClassName={`${Style.map_control} ${isInformationHide &&
          Style.map_control_collapsed}`}
        popupEnabled
        popupRenderer={(locationId) => {
          return <div>{locationId}</div>;
        }}
        onLocationClicked={(locationId) => {
          if (selectedLocationId !== locationId) {
            setSelectedLocationId(locationId);
          }
        }}
      >
        <button
          className={`${Style.flex_row} ${
            Style.information_collapsible_button
          } ${isInformationHide && Style.information_collapsible_button_collapsed}`}
          onClick={() => {
            setIsInformationHide((currentValue) => {
              return !currentValue;
            });
          }}
        >
          {isInformationHide ? <ChevronLeft /> : <ChevronRight />}
        </button>
        <div
          ref={informationContainerRef}
          className={`${Style.information_container} ${isInformationHide &&
            Style.information_container_collapsed}`}
        >
          <div className={Style.information}>Information</div>
        </div>
      </FloorMap>
    </ScreenFrame>
  );
};

export default ManagementDashboardScreen;
