// a hack
const securityLocation = {
  '58bfa12a-ab50-181e-7407-68ec70b25f74': true
};

const isLocationSecurityLocation = (locationDomain) => {
  const id = locationDomain.getId();
  return securityLocation[id];
};

export const getLocationPinColor = (locationDomain, stats, defaultColor) => {
  if (!isLocationSecurityLocation(locationDomain)) {
    return defaultColor;
  }

  const { total } = stats;
  if (!total) {
    // when the total is 0
    return 'green';
  }

  return 'red';
};
