import React, { useState, useEffect, useRef, useMemo, Fragment } from 'react';
import Style from './CreateWorkOrderModal.module.css';
import './CreateWorkOrderModal.css';
import { ModalForm } from 'components/modal-form';
import { CloseRounded } from '@material-ui/icons';
import { useHistory } from 'react-router';
import AutoSizeTextArea from 'components/AutoSizeTextArea/AutoSizeTextArea';
import SearchDropdown from 'components/SearchDropdown/SearchDropdown';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';
import {
  useBOMManagementContext,
  BOMManagementContextProvider
} from '../../context/BOMManagementContext/BOMManagementContext';
import BOMCreateOrderOperationTable from '../bom-management-feature/BOMCreateOrderOperationTable';
import BOMCreateOrderInputPartTable from '../bom-management-feature/BOMCreateOrderInputPartTable';
import { useXemelgoClient } from '../../services/xemelgo-service';
import { DatePicker, DateTimePicker } from '../../components/DateTimePicker/DateTimePicker';
import { useAppConfigProvider } from '../../services/soft-cache-service';
import { naturalSort } from '../../common/Utilities';

const APP_ID = 'order';
const FEATURE_ID = 'addOrder';

const CreateWorkOrderModalHeader = ({ onCloseClick }) => {
  return (
    <div className={`${Style.header_container} ${Style.flex_column}`}>
      <button className={`${Style.header_close_button} ${Style.flex_row}`} onClick={onCloseClick}>
        <CloseRounded className={Style.header_close_button_icon} />
      </button>
      <div className={`${Style.header_title_container} ${Style.flex_row}`}>
        <div className={Style.header_title_icon_container}>
          <img
            src={require('../../img/work-order_icon_green.png')}
            width="50x"
            height="40px"
            style={{ marginBottom: '2px' }}
          />
        </div>
        <p>New Work Order</p>
      </div>
    </div>
  );
};

const CreateWorkOrderModalFooter = ({ onDiscardClick, onSaveClick, isCreateButtonDisabled }) => {
  return (
    <div className={`${Style.footer_container} ${Style.flex_row}`}>
      <button onClick={onDiscardClick}>Discard</button>
      <button disabled={isCreateButtonDisabled} onClick={onSaveClick}>
        Create
      </button>
    </div>
  );
};

const getOptionsByApiKey = async (xemelgoClient, apiKey, fields) => {
  switch (apiKey) {
    case 'getItemTypes':
      const itemTypeClient = xemelgoClient.getItemTypeClient();

      const itemTypes = await itemTypeClient.listItemTypes([
        'id',
        'identifier',
        'image_path',
        ...fields
      ]);
      return naturalSort(
        itemTypes.map((eachItemType) => {
          return {
            ...eachItemType,
            ...eachItemType.data,
            label: eachItemType.identifier || '',
            value: eachItemType.identifier
          };
        }),
        'label'
      );

    default:
      return [];
  }
};

const CreateWorkOrderModal = ({ onCloseClick }) => {
  const inputPartTableRef = useRef();
  const operationTableRef = useRef();

  const configProvider = useAppConfigProvider(APP_ID);
  const history = useHistory();
  const xemelgoClient = useXemelgoClient();
  const [bomClient] = useState(xemelgoClient.getBomClient());
  const [workOrderClient] = useState(xemelgoClient.getWorkOrderClient());
  const [taskClient] = useState(xemelgoClient.getTaskClient());

  const {
    additionalInformation,
    setAdditionalInformation,
    inputPartGroupInfo,
    setInputPartGroupInfo,
    setOperationInfo
  } = useBOMManagementContext();

  const [inputPartGroupInfoOriginal, setInputPartGroupInfoOriginal] = useState([]);

  const [attributeGroup, setAttributeGroup] = useState([]);
  const [bomIdMap, setBomIdMap] = useState({});
  const [isBomEnabled, setIsBomEnabled] = useState(true);
  const [isWorkOrderSectionLoading, setIsWorkOrderSectionLoading] = useState(true);
  const [isInputPartTableLoading, setIsInputPartTableLoading] = useState(true);
  const [isOperationTableLoading, setIsOperationTableLoading] = useState(true);
  const [selectedItemType, setSelectedItemType] = useState({});

  const [bomSectionData, setBomSectionData] = useState({
    due_date: undefined,
    start_date: undefined,
    estimated_date: undefined,
    quantity: '',
    comments: ''
  });

  const [bomSectionDataErrorMap, setBomSectionDataErrorMap] = useState({
    quantity: {
      error: false,
      errorMessage: ''
    }
  });

  const onLoad = async () => {
    const { attributeGroups = [], bomEnabled = false } =
      configProvider.getValue(FEATURE_ID, 'object') || {};
    setIsBomEnabled(bomEnabled);
    const newAttributeGroup = [];
    for (
      let attributeGroupIndex = 0;
      attributeGroupIndex < attributeGroups.length;
      attributeGroupIndex++
    ) {
      const attributeMap = {};
      const attributeKeys = Object.keys(attributeGroups[attributeGroupIndex]);
      for (
        let attributeKeyIndex = 0;
        attributeKeyIndex < attributeKeys.length;
        attributeKeyIndex++
      ) {
        const currentAttribute = _.cloneDeep(
          attributeGroups[attributeGroupIndex][attributeKeys[attributeKeyIndex]]
        );
        const { type } = currentAttribute;
        let initialValue;
        switch (type) {
          case 'date':
          case 'datetime':
            initialValue = undefined;
            break;
          case 'api-dropdown':
            initialValue = {};
            const { apiKey, apiFields } = currentAttribute;
            currentAttribute.options = await getOptionsByApiKey(xemelgoClient, apiKey, apiFields);
            break;
          case 'dropdown':
            initialValue = {};
            break;
          default:
            initialValue = '';
            break;
        }
        currentAttribute.value = initialValue;
        attributeMap[attributeKeys[attributeKeyIndex]] = currentAttribute;
      }
      newAttributeGroup.push(attributeMap);
    }

    setIsWorkOrderSectionLoading(false);
    setIsInputPartTableLoading(false);
    setIsOperationTableLoading(false);

    setAttributeGroup(newAttributeGroup);
  };
  useEffect(() => {
    onLoad();
  }, []);

  const fetchBOMInformation = async (itemTypeId) => {
    setIsInputPartTableLoading(true);
    setIsOperationTableLoading(true);
    setOperationInfo([]);
    setInputPartGroupInfo([]);
    const newBomIdMap = await bomClient.getBomIdsMapUsingItemTypeId(itemTypeId);
    if (!newBomIdMap.bomId) {
      setIsInputPartTableLoading(false);
      setIsOperationTableLoading(false);
    } else {
      const newAdditionalInformation = await bomClient.getAdditionalInfoByBomId(newBomIdMap.bomId);
      setAdditionalInformation(newAdditionalInformation);
    }
    setBomIdMap({ ...newBomIdMap });
  };

  const fetchInputParts = async () => {
    setIsInputPartTableLoading(true);

    const inputPartGroupList = await bomClient.getInputPartsByBomId(bomIdMap.bomId);
    setInputPartGroupInfoOriginal(_.cloneDeep(inputPartGroupList));
    inputPartGroupList.forEach((eachInputGroup) => {
      eachInputGroup.itemTypes.forEach((eachItemType) => {
        eachItemType.quantity =
          bomSectionData.quantity * 1
            ? bomSectionData.quantity * 1 * eachItemType.quantity
            : eachItemType.quantity;
      });
    });

    setInputPartGroupInfo(inputPartGroupList);

    setIsInputPartTableLoading(false);
  };

  const fetchOperations = async () => {
    setIsOperationTableLoading(true);
    const newOperationInfo = await bomClient.getRoutingsByBomId(bomIdMap.bomId);
    setOperationInfo(newOperationInfo);
    setIsOperationTableLoading(false);
  };

  useEffect(() => {
    if (selectedItemType.id && isBomEnabled) {
      fetchBOMInformation(selectedItemType.id);
    }
  }, [selectedItemType]);

  useEffect(() => {
    if (bomIdMap?.bomId) {
      fetchInputParts();
      fetchOperations();
    }
  }, [bomIdMap]);

  // update quantity and comments of BOM Section Data after fetching bom information
  useEffect(() => {
    bomSectionData.quantity = additionalInformation.quantity?.toString() || '';
    bomSectionData.comments = additionalInformation.description || '';
    setBomSectionData({ ...bomSectionData });
  }, [additionalInformation]);

  const validateWorkOrderFields = () => {
    let hasError = false;
    attributeGroup.forEach((eachAttributeGroup) => {
      Object.keys(eachAttributeGroup).forEach((eachAttributeId) => {
        const eachAttribute = eachAttributeGroup[eachAttributeId];
        const { required, value, type, label } = eachAttribute;
        if (required) {
          const errorMessage = `${label} is a required field.`;
          switch (type) {
            case 'api-dropdown':
            case 'dropdown':
              if (Object.keys(value).length < 3) {
                eachAttribute.error = true;
                eachAttribute.errorMessage = errorMessage;
                hasError = true;
              }
              break;
            default:
              if (!value) {
                eachAttribute.error = true;
                eachAttribute.errorMessage = errorMessage;
                hasError = true;
              }
              break;
          }
        }
      });
    });
    if (hasError) {
      setAttributeGroup([...attributeGroup]);
    }
    return !hasError;
  };

  const validateBOMFields = () => {
    let hasError = false;
    if (!(bomSectionData.quantity * 1)) {
      bomSectionDataErrorMap.quantity.error = true;
      bomSectionDataErrorMap.quantity.errorMessage = `Qty is a required field`;
      hasError = true;
    } else if (
      additionalInformation.quantity &&
      bomSectionData.quantity * 1 < additionalInformation.quantity
    ) {
      bomSectionDataErrorMap.quantity.error = true;
      bomSectionDataErrorMap.quantity.errorMessage = `Minimum qty is ${additionalInformation.quantity}`;
      hasError = true;
    }
    if (hasError) {
      setBomSectionDataErrorMap({ ...bomSectionDataErrorMap });
    }
    return !hasError;
  };

  const overallCycleTime = useMemo(() => {
    return (bomSectionData.quantity * 1 * (selectedItemType.cycle_time_ts * 1)).toFixed(2) * 1;
  }, [bomSectionData.quantity, selectedItemType]);

  const estimatedDateTime = useMemo(() => {
    return overallCycleTime ? bomSectionData.start_date + overallCycleTime * 1000 * 60 * 60 : null;
  }, [overallCycleTime, bomSectionData.start_date]);

  const renderWorkOrderSection = () => {
    return (
      <div
        className={`${Style.work_order_information} ${Style.flex_column}`}
        style={!isBomEnabled ? { flex: 1 } : {}}
      >
        <p>Work order information</p>
        {isWorkOrderSectionLoading ? (
          <div className={`${Style.input_group_list} ${Style.grid}`}>
            {[...Array(6)].map((_, index) => {
              return (
                <div key={index} className={`${Style.flex_column}`}>
                  <Skeleton height={28} />
                </div>
              );
            })}
          </div>
        ) : (
          <Fragment>
            {attributeGroup.map((eachAttributeGroup, groupIndex) => {
              return (
                <div key={groupIndex} className={`${Style.input_group_list} ${Style.grid}`}>
                  {Object.keys(eachAttributeGroup).map((eachAttributeKey) => {
                    const {
                      label,
                      value,
                      editable,
                      type,
                      options,
                      dependentOn,
                      apiKey,
                      required,
                      error,
                      errorMessage
                    } = eachAttributeGroup[eachAttributeKey];

                    return (
                      <div key={label} className={`${Style.flex_column}`}>
                        <div className={Style.flex_row}>
                          <p className={`${Style.attribute_label}`}>{`${label}`}</p>
                          {required && (
                            <p className={`${Style.attribute_label} ${Style.label_asterisk}`}>*</p>
                          )}
                        </div>
                        {(() => {
                          switch (type) {
                            case 'date':
                              return (
                                <DatePicker
                                  value={
                                    dependentOn
                                      ? attributeGroup[groupIndex][dependentOn]?.value[
                                          eachAttributeKey
                                        ] || undefined
                                      : value
                                  }
                                  error={error}
                                  errorMessage={errorMessage}
                                  readOnly={!!dependentOn || !editable}
                                  onTimeChange={(timestamp) => {
                                    if (timestamp) {
                                      attributeGroup[groupIndex][eachAttributeKey].error = false;
                                      attributeGroup[groupIndex][eachAttributeKey].errorMessage =
                                        '';
                                    }
                                    attributeGroup[groupIndex][eachAttributeKey].value = timestamp;
                                    setAttributeGroup([...attributeGroup]);
                                  }}
                                />
                              );

                            case 'datetime':
                              return (
                                <DateTimePicker
                                  value={
                                    dependentOn
                                      ? attributeGroup[groupIndex][dependentOn]?.value[
                                          eachAttributeKey
                                        ] || undefined
                                      : value
                                  }
                                  error={error}
                                  errorMessage={errorMessage}
                                  readOnly={!!dependentOn || !editable}
                                  onTimeChange={(timestamp) => {
                                    if (timestamp) {
                                      attributeGroup[groupIndex][eachAttributeKey].error = false;
                                      attributeGroup[groupIndex][eachAttributeKey].errorMessage =
                                        '';
                                    }
                                    attributeGroup[groupIndex][eachAttributeKey].value = timestamp;
                                    setAttributeGroup([...attributeGroup]);
                                  }}
                                />
                              );
                            case 'api-dropdown':
                              return (
                                <SearchDropdown
                                  error={error}
                                  errorMessage={errorMessage}
                                  options={options}
                                  selectedItem={value}
                                  onItemSelected={(newItem) => {
                                    if (newItem) {
                                      attributeGroup[groupIndex][eachAttributeKey].error = false;
                                      attributeGroup[groupIndex][eachAttributeKey].errorMessage =
                                        '';
                                    }
                                    attributeGroup[groupIndex][eachAttributeKey].value = newItem;
                                    setAttributeGroup([...attributeGroup]);
                                    if (apiKey === 'getItemTypes') {
                                      setSelectedItemType(newItem);
                                    }
                                  }}
                                />
                              );
                            case 'dropdown':
                              return (
                                <SearchDropdown
                                  error={error}
                                  errorMessage={errorMessage}
                                  options={options}
                                  selectedItem={value}
                                  onItemSelected={(newItem) => {
                                    if (newItem) {
                                      attributeGroup[groupIndex][eachAttributeKey].error = false;
                                      attributeGroup[groupIndex][eachAttributeKey].errorMessage =
                                        '';
                                    }
                                    attributeGroup[groupIndex][eachAttributeKey].value = newItem;
                                    setAttributeGroup([...attributeGroup]);
                                  }}
                                />
                              );
                            default:
                              return (
                                <AutoSizeTextArea
                                  error={error}
                                  errorMessage={errorMessage}
                                  readOnly={!!dependentOn || !editable}
                                  backgroundColor={(dependentOn || !editable) && '#fbfafa'}
                                  value={
                                    dependentOn
                                      ? attributeGroup[groupIndex][dependentOn]?.value[
                                          eachAttributeKey
                                        ] || '--'
                                      : value
                                  }
                                  onChangeText={(newText) => {
                                    if (newText) {
                                      attributeGroup[groupIndex][eachAttributeKey].error = false;
                                      attributeGroup[groupIndex][eachAttributeKey].errorMessage =
                                        '';
                                    }
                                    attributeGroup[groupIndex][eachAttributeKey].value = newText;
                                    setAttributeGroup([...attributeGroup]);
                                  }}
                                />
                              );
                          }
                        })()}
                      </div>
                    );
                  })}
                </div>
              );
            })}
            {isBomEnabled && (
              <Fragment>
                <div className={`${Style.input_group_list} ${Style.grid}`}>
                  <div className={`${Style.flex_column}`}>
                    <div className={`${Style.flex_row}`}>
                      <p className={`${Style.attribute_label}`}>Qty</p>
                      <p className={`${Style.attribute_label} ${Style.label_asterisk}`}>*</p>
                    </div>

                    {isInputPartTableLoading ? (
                      <Skeleton height={40} />
                    ) : (
                      <AutoSizeTextArea
                        numberOnly
                        error={bomSectionDataErrorMap.quantity.error}
                        errorMessage={bomSectionDataErrorMap.quantity.errorMessage}
                        value={bomSectionData.quantity}
                        onChangeText={(newText) => {
                          if (newText) {
                            bomSectionDataErrorMap.quantity.error = false;
                            bomSectionDataErrorMap.quantity.errorMessage = '';
                          }
                          bomSectionData.quantity = newText;
                          const inputPartGroupList = _.cloneDeep(inputPartGroupInfoOriginal);
                          inputPartGroupList.forEach((eachInputGroup) => {
                            eachInputGroup.itemTypes.forEach((eachItemType) => {
                              eachItemType.quantity =
                                newText * 1
                                  ? newText * 1 * eachItemType.quantity
                                  : eachItemType.quantity;
                            });
                          });
                          setInputPartGroupInfo([...inputPartGroupList]);
                          setBomSectionData({ ...bomSectionData });
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className={`${Style.input_group_list} ${Style.grid}`}>
                  <div className={`${Style.flex_column}`}>
                    <p className={`${Style.attribute_label}`}>Part Cycle Time (Hours)</p>
                    <AutoSizeTextArea
                      backgroundColor="#fbfafa"
                      readOnly
                      value={selectedItemType?.cycle_time_ts || '--'}
                    />
                  </div>
                  <div className={`${Style.flex_column}`}>
                    <p className={`${Style.attribute_label}`}>Overall Cycle Time (Hours)</p>
                    <AutoSizeTextArea
                      backgroundColor="#fbfafa"
                      readOnly
                      value={overallCycleTime || '--'}
                    />
                  </div>
                </div>
                <div className={`${Style.input_group_list} ${Style.grid}`}>
                  <div className={`${Style.flex_column}`}>
                    <p className={`${Style.attribute_label}`}>Due Date</p>
                    <DatePicker
                      value={bomSectionData.due_date}
                      onTimeChange={(timestamp) => {
                        bomSectionData.due_date = timestamp;
                        setBomSectionData({ ...bomSectionData });
                      }}
                    />
                  </div>
                  <div className={`${Style.flex_column}`}>
                    <p className={`${Style.attribute_label}`}>Start Date & Time</p>
                    <DateTimePicker
                      value={bomSectionData.start_date}
                      onTimeChange={(timestamp) => {
                        bomSectionData.start_date = timestamp;
                        setBomSectionData({ ...bomSectionData });
                      }}
                    />
                  </div>
                  <div className={`${Style.flex_column}`}>
                    <p className={`${Style.attribute_label}`}>Estimated Date & Time</p>
                    <DateTimePicker readOnly value={estimatedDateTime} />
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    );
  };

  const onDiscardClick = () => {
    if (onCloseClick) {
      onCloseClick();
    } else {
      history.goBack();
    }
  };

  const onCreateClick = async () => {
    let canSubmit = true;
    canSubmit = validateWorkOrderFields();
    if (isBomEnabled) {
      canSubmit = canSubmit && validateBOMFields();
    }

    if (canSubmit) {
      setIsWorkOrderSectionLoading(true);
      setIsInputPartTableLoading(true);
      setIsOperationTableLoading(true);
      const payload = {
        task_fields: {
          category: 'Tracked Work Order'
        }
      };
      attributeGroup.forEach((eachGroupAttributes) => {
        Object.keys(eachGroupAttributes)
          .filter((eachAttributeKey) => {
            return !eachGroupAttributes[eachAttributeKey].skipForCreation;
          })
          .forEach((eachAttributeKey) => {
            const { type, value, numberOnly, isTSProperty } = eachGroupAttributes[eachAttributeKey];
            let finalValue;
            switch (type) {
              case 'dropdown':
              case 'api-dropdown':
                finalValue = value.value;
                break;

              case 'date':
              case 'datetime':
              default:
                finalValue = numberOnly ? value * 1 : value;
                break;
            }
            if (eachAttributeKey === 'output_items') {
              payload[eachAttributeKey] = [{ itemType_identifier: finalValue }];
            } else {
              payload.task_fields[eachAttributeKey] = finalValue;
            }
          });
      });
      payload.output_items[0].quantity = 1;
      payload.output_items[0].unit = '';
      payload.output_items[0].id = selectedItemType.id;

      if (isBomEnabled) {
        payload.output_items[0].quantity = bomSectionData.quantity * 1;
        payload.output_items[0].unit = additionalInformation.unit;
        payload.output_items[0].id = selectedItemType.id;
        payload.task_fields = {
          ...payload.task_fields,
          due_date: bomSectionData.due_date,
          start_date: bomSectionData.start_date,
          status: 'Planned',
          ...payload.ts_prop
        };

        const inputPartTableData = inputPartTableRef.current.onSaveClick();

        payload.input_items = Object.keys(inputPartTableData)
          .filter((eachInputGroupId) => {
            return eachInputGroupId !== 'rank';
          })
          .map((eachInputGroupId) => {
            const { rank } = inputPartTableData[eachInputGroupId];
            const [inputPartId] = Object.keys(inputPartTableData[eachInputGroupId]).filter(
              (eachInputPartId) => {
                return (
                  eachInputPartId !== 'createNew' &&
                  inputPartTableData[eachInputGroupId][eachInputPartId].rank === rank // only show the item type matches the rank
                );
              }
            );

            const { id, itemTypeId, quantity, unit } = inputPartTableData[eachInputGroupId][
              inputPartId
            ];
            return { id, itemTypeId, quantity, unit, rank };
          });
        payload.comments = bomSectionData.comments;

        const createdWO = await workOrderClient.createWorkOrderV2(
          payload.identifier,
          payload.task_fields,
          bomIdMap.bomId,
          bomIdMap.routingId,
          payload.output_items[0].id,
          payload.output_items[0].quantity,
          payload.output_items[0].unit
        );

        if (createdWO?.createWorkOrder?.id) {
          const {
            taskBillOfMaterialsId,
            inputGroupIds
          } = await workOrderClient.getTaskInputGroupIdsByTaskId(createdWO.createWorkOrder.id);
          await workOrderClient.createTravellerForTask(createdWO.createWorkOrder.identifier, createdWO.createWorkOrder.id);
          await workOrderClient.updateTaskInputGroups(
            createdWO.createWorkOrder.id,
            taskBillOfMaterialsId,
            inputGroupIds,
            payload.input_items
          );
        }
      } else {
        const createdWO = await workOrderClient.createWorkOrderV2(
          payload.identifier,
          payload.task_fields,
          undefined, // bom ID
          undefined, // routing ID
          payload.output_items[0].id,
          payload.output_items[0].quantity,
          payload.output_items[0].unit
        );
        if(createdWO?.createWorkOrder?.id) {
          await workOrderClient.createTravellerForTask(createdWO.createWorkOrder.identifier, createdWO.createWorkOrder.id);
        }
      }
      setIsWorkOrderSectionLoading(true);
      setIsInputPartTableLoading(true);
      setIsOperationTableLoading(true);
      onCloseClick(true);
    }
  };

  return (
    <ModalForm
      show
      prefix="create_work_order-modal"
      className={`${Style.modal} ${Style.flex_column}`}
      title={
        <CreateWorkOrderModalHeader
          onCloseClick={() => {
            if (onCloseClick) {
              onCloseClick();
            } else {
              history.goBack();
            }
          }}
        />
      }
      body={
        <div className={`${Style.body_container} ${Style.flex_column}`}>
          {renderWorkOrderSection()}
          {isBomEnabled && (
            <div className={`${Style.bom_table_list} ${Style.flex_column}`}>
              <BOMCreateOrderInputPartTable
                ref={inputPartTableRef}
                loading={isInputPartTableLoading}
              />
              <BOMCreateOrderOperationTable
                ref={operationTableRef}
                loading={isOperationTableLoading}
              />
              {!isWorkOrderSectionLoading && (
                <div className={`${Style.remark_container}`}>
                  <p>Remarks</p>
                  {isInputPartTableLoading ? (
                    [...new Array(3)].map((_, index) => {
                      return <Skeleton key={index} height={28} />;
                    })
                  ) : (
                    <AutoSizeTextArea
                      newLineAllowed
                      minRows={5}
                      maxRows={5}
                      value={bomSectionData.comments}
                      onChangeText={(newText) => {
                        bomSectionData.comments = newText;
                        setBomSectionData({ ...bomSectionData });
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      }
      footer={
        <CreateWorkOrderModalFooter
          onDiscardClick={onDiscardClick}
          onSaveClick={onCreateClick}
          isCreateButtonDisabled={
            isWorkOrderSectionLoading || isOperationTableLoading || isInputPartTableLoading
          }
        />
      }
    />
  );
};

export default (props) => {
  return (
    <BOMManagementContextProvider>
      <CreateWorkOrderModal {...props} />
    </BOMManagementContextProvider>
  );
};
