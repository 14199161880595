import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AutoSizeTextArea from '../AutoSizeTextArea/AutoSizeTextArea';
import SearchDropdown from '../SearchDropdown/SearchDropdown';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import MyFacilityV2Style from './MyFacilityV2Components.module.css';

const DetectorGroup = ({
  properties,
  onGroupChange,
  onDetectorChange,
  detectorGroups,
  handleAddGroup,
  handleRemoveGroup,
  handleAddDetector,
  handleRemoveDetector,
  classToModeMap
}) => {
  const [detectorClass, setDetectorClass] = useState([]);

  useEffect(() => {
    const classProperty = properties.find((property) => property.name === 'class');
    const { options = [] } = classProperty || {};
    const tempDetectorClass = [];
    options.forEach((option) => {
      const { key, value } = option;
      tempDetectorClass.push({
        id: key,
        label: value,
        value
      });
    });

    setDetectorClass(tempDetectorClass);
  }, [properties]);

  const renderDetectors = (key) => {
    const { detectors } = detectorGroups[key];
    const detectorsToRender = detectors.map((detector, index) => {
      return (
        <div key={`detector${index}`} className={MyFacilityV2Style.flex}>
          <div className={MyFacilityV2Style.detector_input_group_container}>
            {`Detector ${index + 1}:`}
            <div className={MyFacilityV2Style.detector_input_container}>
              <div className={MyFacilityV2Style.detector_group_input}>
                Serial Number/MAC Address *
                <AutoSizeTextArea
                  onChangeText={(text) => {
                    onDetectorChange(key, index, 'vid', text);
                  }}
                  value={detector.vid}
                  error={detector.error}
                />
              </div>
              <div
                className={`${MyFacilityV2Style.detector_input} ${MyFacilityV2Style.detector_group_input}`}
              >
                <div>Vendor</div>
                <div className={MyFacilityV2Style.normal_text}>
                  {detector.vid && detector.vendor}
                </div>
              </div>
            </div>
          </div>
          <div
            className={MyFacilityV2Style.remove_icon_container}
            onClick={() => {
              handleRemoveDetector(key, index);
            }}
          >
            <CloseRoundedIcon fontSize="inherit" />
          </div>
        </div>
      );
    });

    return (
      <Fragment>
        {detectorsToRender}
        <div
          className={MyFacilityV2Style.add_button_container}
          onClick={() => {
            handleAddDetector(key);
          }}
        >
          <div className={MyFacilityV2Style.add_icon_container}>
            <AddRoundedIcon fontSize="inherit" />
          </div>
          <div className={MyFacilityV2Style.add_detector_text}>Add Detector</div>
        </div>
      </Fragment>
    );
  };

  const renderDetectorGroups = () => {
    const detectorGroupKeys = Object.keys(detectorGroups).sort((a, b) => {
      if (a < b) {
        return -1;
      } else {
        return 1;
      }
    });

    return detectorGroupKeys.map((key, index) => {
      const currentDetector = detectorGroups[key];
      const { class: currentClass, mode } = currentDetector;
       return(
        <div
          style={{
            borderBottom: index < detectorGroupKeys.length - 1 && 'solid #E2E2EA'
          }}
          className={MyFacilityV2Style.detector_group_container}
          key={`group${key}`}
        >
          <div className={MyFacilityV2Style.input_group}>
            <div className={MyFacilityV2Style.detector_group_input}>
              Detector Type*
              <SearchDropdown
                withoutOptionFilter
                options={detectorClass}
                selectedItem={currentClass}
                onItemSelected={(newItem) => {
                  onGroupChange(key, 'class', newItem);
                }}
              />
            </div>
            {classToModeMap[currentClass?.id] && classToModeMap[currentClass?.id].length ? (
              <div className={MyFacilityV2Style.detector_group_input}>
                Mode*
                <SearchDropdown
                  withoutOptionFilter
                  options={classToModeMap[currentClass.id]}
                  selectedItem={mode}
                  onItemSelected={(newItem) => {
                    onGroupChange(key, 'mode', newItem);
                  }}
                />
              </div>
            ) : (
              <div></div>
            )}
            <div
              onClick={() => {
                if (index === 0) {
                  handleAddGroup();
                } else {
                  handleRemoveGroup(key);
                }
              }}
              className={MyFacilityV2Style.detector_group_button}
            >
              {index === 0 ? '+ Add Group' : '- Remove Group'}
            </div>
          </div>
          {currentDetector.class && renderDetectors(key)}
        </div>
      );
    })
  };

  return (
    <div className={MyFacilityV2Style.detector_container}>
      Detector Information
      {renderDetectorGroups()}
    </div>
  );
};

export default DetectorGroup;

DetectorGroup.defaultProps = {
  properties: [],
  detectorGroups: {},
  onGroupChange: () => {},
  onDetectorChange: () => {},
  handleAddGroup: () => {},
  handleRemoveGroup: () => {},
  handleAddDetector: () => {},
  handleRemoveDetector: () => {},
  classToModeMap: {}
};

DetectorGroup.propTypes = {
  properties: PropTypes.array,
  detectorGroups: PropTypes.object,
  onGroupChange: PropTypes.func,
  onDetectorChange: PropTypes.func,
  handleAddGroup: PropTypes.func,
  handleRemoveGroup: PropTypes.func,
  handleAddDetector: PropTypes.func,
  handleRemoveDetector: PropTypes.func,
  classToModeMap: PropTypes.object
};
