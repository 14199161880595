import React from 'react';
import AutoSizeTextArea from '../AutoSizeTextArea/AutoSizeTextArea';
import SearchDropdown from '../SearchDropdown/SearchDropdown';
import MyFacilityV2Style from './MyFacilityV2Components.module.css';
import PropTypes from 'prop-types';

const InputGroup = ({ title, properties, onChange, values, placeholders }) => {
  const renderInput = (property) => {
    if (property.options) {
      return (
        <SearchDropdown
          withoutOptionFilter
          options={property.options}
          selectedItem={values[property.name]}
          onItemSelected={(newItem) => {
            onChange(property.name, newItem);
          }}
          error={property.error}
          disabled={property.disabled}
          placeholder={placeholders[property.name]}
        />
      );
    } else {
      return (
        <AutoSizeTextArea
          value={values[property.name] === undefined ? placeholders[property.name] : values[property.name]}
          onChangeText={(text) => {
            onChange(property.name, text);
          }}
          error={property.error}
          disabled={property.disabled}
        />
      );
    }
  };

  return (
    <div className={MyFacilityV2Style.body_container}>
      {title}
      <div className={MyFacilityV2Style.input_group}>
        {properties.map((property) => {
          return (
            <div key={property.name} className={MyFacilityV2Style.input_container}>
              {`${property.displayName}${property.optional ? '' : '*'}`}
              {renderInput(property)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

InputGroup.defaultProps = {
  title: '',
  properties: [],
  onChange: () => {},
  values: {},
  placeholders: {}
};

InputGroup.propTypes = {
  title: PropTypes.string,
  properties: PropTypes.array,
  onChange: PropTypes.func,
  values: PropTypes.object,
  placeholders: PropTypes.object
};

export default InputGroup;
