import React, { memo, useEffect } from 'react';
import { useStore } from 'react-hookstore';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import './LoginPage.css';
import { loginStateStore, LoginStateAction } from '../../state-managements/stores/login-store';
import { getStackedXemelgoLogo } from '../../common/Utilities';
import AuthService from '../../services/AuthService';
import { LoginForm } from './login-form';
import { NewPasswordForm } from './new-password-form';
import LoadingCircle from '../../components/loading/LoadingCircle';
import { ForgotPasswordForm } from './forgot-password-form';
import { ClientConfigurationService } from '../../services/ClientConfigurationService';
import { userProfileStore } from '../../state-managements/stores/user-profile-store';
import { configStore } from '../../state-managements/stores/config-store';
import { LocalCacheService } from '../../services/local-cache-service';
import { UserProfile } from '../../domains/user-profile';
import { UserConfiguration } from '../../domains/user-configuration';
import LoginService from '../../services/LoginService';
import ConfigurationService from '../../services/ConfigurationService';
import { ForgotPasswordVerificationForm } from './forgot-password-verification-form';

const Index = memo(({ history }) => {
  const [loginState, loginStateDispatcher] = useStore(loginStateStore);
  const [, setUserProfile] = useStore(userProfileStore);
  const [, setUserConfig] = useStore(configStore);
  const sessionInfo = sessionStorage.getItem('sessionInfo');

  useEffect(() => {
    const config = ConfigurationService.getAuthConfiguration();
    AuthService.configure(config);

    if (sessionInfo) {
      ClientConfigurationService.getConfig().then((customerConfig) => {
        const rawConfig = customerConfig.getRawData();
        const userConfig = UserConfiguration.parse(rawConfig);
        const landingUrl = userConfig.getLandingPage();
        history.push(landingUrl);
      });
    }
    // eslint-disable-next-line
  }, []);

  const handleForgotPassword = () => {
    loginStateDispatcher({ type: LoginStateAction.IS_FORGOT_PASSWORD_STATE });
  };

  const handleSubmit = async (username, password) => {
    const userInfo = await AuthService.login(username.toLowerCase(), password);
    const { passwordChangeNeeded } = userInfo;
    if (passwordChangeNeeded) {
      loginStateDispatcher({ type: LoginStateAction.IS_NEW_PASSWORD_STATE });
      await AuthService.logout();
    } else {
      const customerConfig = await ClientConfigurationService.getConfig();
      const rawConfig = customerConfig.getRawData();
      const userConfig = UserConfiguration.parse(rawConfig);
      let userTestConfig;
      if (rawConfig && rawConfig.webClient && rawConfig.webClient.testMode) {
        const customerTestConfig = await ClientConfigurationService.getTestConfig();
        const rawTestConfig = customerTestConfig.getRawData();
        userTestConfig = UserConfiguration.parse(rawTestConfig);
        LocalCacheService.saveUserTestConfig(userTestConfig);
      }
      const userProfile = UserProfile.parse(userInfo);
      LocalCacheService.saveUserConfig(userConfig);
      LocalCacheService.saveUserProfile(userProfile);
      setUserProfile(userProfile);
      setUserConfig(userConfig);
      const landingUrl = userConfig.getLandingPage();
      history.push(landingUrl);
    }
  };

  const handleSubmitNewPassword = async (username, newPassword) => {
    try {
      const userInfo = await AuthService.handleNewPasswordChallenge(newPassword);
      const userProfile = UserProfile.parse(userInfo);
      const customerConfig = await ClientConfigurationService.getConfig();
      const rawConfig = customerConfig.getRawData();
      const userConfig = UserConfiguration.parse(rawConfig);
      if (rawConfig && rawConfig.webClient && rawConfig.webClient.testMode) {
        const customerTestConfig = await ClientConfigurationService.getTestConfig();
        const rawTestConfig = customerTestConfig.getRawData();
        const userTestConfig = UserConfiguration.parse(rawTestConfig);
        LocalCacheService.saveUserTestConfig(userTestConfig);
      }
      LocalCacheService.saveUserConfig(userConfig);
      LocalCacheService.saveUserProfile(userProfile);
      setUserProfile(userProfile);
      setUserConfig(userConfig);
      const landingUrl = userConfig.getLandingPage();
      await LoginService.registerUsername(username.toLowerCase());
      history.push(landingUrl);
    } catch (error) {
      alert(`Oops! Something is wrong: ${error.message}`);
    }
  };

  const handleSubmitForgotPassword = async (username) => {
    await AuthService.forgotPassword(username);
    loginStateDispatcher({ type: LoginStateAction.IS_FORGOT_PASSWORD_VERIFICATION_STATE });
  };

  const handleSubmitForgotPasswordVerification = async (
    username,
    verificationCode,
    newPassword
  ) => {
    await AuthService.forgotPasswordSubmit(username, verificationCode, newPassword);
    loginStateDispatcher({ type: LoginStateAction.IS_LOGIN_STATE });
  };

  if (loginState.newPassword) {
    return (
      <NewPasswordForm
        onSubmit={handleSubmitNewPassword}
        onCancel={() => loginStateDispatcher({ type: LoginStateAction.IS_LOGIN_STATE })}
        logo={getStackedXemelgoLogo('light')}
      />
    );
  }
  if (loginState.forgotPassword) {
    return (
      <ForgotPasswordForm
        onSubmit={handleSubmitForgotPassword}
        onCancel={() => loginStateDispatcher({ type: LoginStateAction.IS_LOGIN_STATE })}
        logo={getStackedXemelgoLogo('light')}
      />
    );
  }
  if (loginState.forgotPasswordVerification) {
    return (
      <ForgotPasswordVerificationForm
        onSubmit={handleSubmitForgotPasswordVerification}
        logo={getStackedXemelgoLogo('light')}
      />
    );
  }

  if (sessionInfo) {
    return <LoadingCircle />;
  }
  return <LoginForm logo={getStackedXemelgoLogo('light')} fnSubmit={handleSubmit} fnForgotPassword={handleForgotPassword} />;
});

Index.propTypes = {
  history: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default withRouter(Index);
