import React, { PureComponent, Fragment } from 'react';
import Style from './css/DataTable.module.css';

export default class DataTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openKeys: []
    };
  }

  renderColumnTitle = (titleList, secondary) => {
    return (
      <div className={secondary ? Style.time_tracking_header_row : Style.tracking_links_header}>
        {titleList.map((eachTitle) => (
          <div className={secondary ? Style.time_duration : Style.time_detail} key={eachTitle.key}>
            {eachTitle.value}
          </div>
        ))}
      </div>
    );
  };

  renderSecondaryRowData = (rowData) => {
    return (
      <div className={Style.time_tracking_data_row}>
        {rowData.map((row) => {
          return (
            <div key={row.key} className={Style.time_duration}>
              {row.data || '-'}
            </div>
          );
        })}
      </div>
    );
  };

  renderStatus = (statusList = []) => {
    return (
      <div>
        {statusList.map((status = {}) => (
          <div
            key={status.id}
            className={Style.status}
            style={{ color: '#fff', width: 'fit-content', backgroundColor: status.color }}
          >
            {status.displayText}
          </div>
        ))}
      </div>
    );
  };

  renderLink = (data = {}) => {
    return (
      <a
        className={Style.underline_text}
        key={data.id}
        title="Click to view details"
        href={data.link}
      >
        {data.displayText}
      </a>
    );
  };

  renderColumnData = (type = 'text', data) => {
    switch (type) {
      case 'status':
        return this.renderStatus(data || []);
      case 'link':
        return this.renderLink(data || {});
      default:
        return data || '-';
    }
  };

  renderRowData = (rowData, titleList, index) => {
    const { openKeys } = this.state;
    const open = openKeys.findIndex((eachKey) => eachKey === index);
    return (
      <div key={index}>
        <div className={Style.tracking_department_link_row}>
          {titleList.map((eachTitle) => {
            const type = eachTitle.type || 'text';
            return (
              <div key={eachTitle.key} className={Style.time_duration}>
                {this.renderColumnData(type, rowData[eachTitle.key])}
                {eachTitle.extraData && rowData.extraData && (
                  <div
                    className={Style.show_button}
                    onClick={() => {
                      if (open !== -1) {
                        openKeys.splice(open, 1);
                        this.setState({ openKeys: [...openKeys] });
                      } else {
                        this.setState({
                          openKeys: openKeys.concat(index)
                        });
                      }
                    }}
                  >
                    {open ? 'Show' : 'Hide'}
                  </div>
                )}
              </div>
            );
          })}
        </div>

        {open !== -1 && (
          <Fragment>
            {this.renderColumnTitle(rowData.extraData, true)}
            {this.renderSecondaryRowData(rowData.extraData)}
          </Fragment>
        )}
      </div>
    );
  };

  renderEmptyRow = () => {
    return (
      <div key={1}>
        <div className={Style.tracking_department_link_row_empty}>No data to show</div>
      </div>
    );
  };

  render() {
    const { dataList = [], titleList = [] } = this.props;

    return (
      <div className={Style.tracking_departments_links}>
        {this.renderColumnTitle(titleList)}
        <div className={Style.tracking_departments_links_detail}>
          {dataList.length > 0
            ? dataList.map((rowData, index) => {
                return this.renderRowData(rowData, titleList, index);
              })
            : this.renderEmptyRow()}
        </div>
      </div>
    );
  }
}
