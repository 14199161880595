import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import SearchDropdown from '../add-page-component/add-page-inputs/SearchDropdown';

const useDropdownInputStyle = makeStyles({
  root: {
    width: '235px',
    height: '42.4px',
    backgroundColor: '#FFFFFF'
  },
  notchedOutline: {
    border: '1.5px solid #E2E2EA',
    borderRadius: '3px'
  }
});

const useDropdownContainerStyle = makeStyles({
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      paddingLeft: 10,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: '1.5px solid #E2E2EA',
      borderRadius: '3px'
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1.5px solid #258FE0',
      borderRadius: '3px'
    }
  },
  endAdornment: {
    '& .MuiSvgIcon-root': {
      fill: '#258FE0'
    }
  }
});

const Selector = ({ options, onSelect, value, placeholder, error }) => {
  const dropdownInputStyle = useDropdownInputStyle();
  const dropdownContainerClasses = useDropdownContainerStyle();
  return (
    <SearchDropdown
      options={options}
      onChange={onSelect}
      value={value}
      placeholder={placeholder}
      error={error}
      dropdownContainerClasses={dropdownContainerClasses}
      dropdownContainerStyle={{ width: '235px' }}
      InputContainerProps={{ classes: dropdownInputStyle, disableUnderline: true }}
    />
  );
};

Selector.defaultProps = {
  placeholder: '',
  error: false
};

Selector.propTypes = {
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.bool
};

export default Selector;
