import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const MainAppComponent = ({
  headerComponent,
  sideNavigationComponent,
  renderComponent,
  containerStyle
}) => {
  return (
    <Fragment>
      {headerComponent && headerComponent()}
      {sideNavigationComponent && sideNavigationComponent()}
      <div className={containerStyle}>{renderComponent()}</div>
    </Fragment>
  );
};

export default MainAppComponent;

MainAppComponent.defaultProps = {
  containerStyle: null
};

MainAppComponent.propTypes = {
  headerComponent: PropTypes.func.isRequired,
  sideNavigationComponent: PropTypes.func.isRequired,
  renderComponent: PropTypes.func.isRequired,
  containerStyle: PropTypes.string
};
