import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { getStackedXemelgoLogo, uploadImageToS3, imageValidityCheck } from 'common/Utilities';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { MoreHoriz } from '@material-ui/icons';
import { Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'mdbreact';
import DetailScreen from './DetailScreen';
import { useXemelgoClient } from '../services/xemelgo-service';
import Style from './ItemTypeDetailScreen.module.css';
import { useAppConfigProvider } from '../services/soft-cache-service';
import { LocalCacheService } from '../services/local-cache-service';

import CONFIGURATION from '../configuration.json';

const APP_ID = 'itemTypesManagement';
const FEATURE_ID = 'itemTypeDetail';

export default ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const configProvider = useAppConfigProvider(APP_ID);
  const ItemTypeDetailScreenConfig = configProvider.getValue(FEATURE_ID, 'object') || {
    imageConfig: {},
    attributeMap: {},
    tabs: []
  };
  const userProfile = LocalCacheService.loadUserProfile();
  const tenantId = userProfile.getTenantId();
  const defaultXemelgoLogo = getStackedXemelgoLogo('dark');

  const [itemTypeDetailInfo, setItemTypeDetailInfo] = useState({});

  const [itemTypeId, setItemTypeId] = useState(null);
  const [itemTypeClient] = useState(useXemelgoClient().getItemTypeClient());
  const [image, setImage] = useState(null);
  const [imageFileToUpload, setImageFileToUpload] = useState(null);
  const [detailInfo, setDetailInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const { tabs: tabsConfig = [] } = ItemTypeDetailScreenConfig;
    const { search } = location;
    const { id: newItemTypeId } = queryString.parse(search);

    // update url to the detail tab if default is provided
    const defaultTab = tabsConfig.find((each) => {
      return each.default;
    });
    if (defaultTab) {
      history.replace(`${defaultTab.path}?id=${newItemTypeId}`);
    }

    const image = getStackedXemelgoLogo('dark');
    setImage(image);
    setItemTypeId(newItemTypeId);
  }, []);

  const fetchItemType = async () => {
    const attributeList = ['id', ...Object.keys(ItemTypeDetailScreenConfig?.attributeMap)];
    if (ItemTypeDetailScreenConfig?.imageConfig?.id) {
      attributeList.push(ItemTypeDetailScreenConfig?.imageConfig?.id);
    }
    const { itemTypes: itemTypeList } = await itemTypeClient.getItemTypesByIds(
      itemTypeId,
      attributeList
    );

    setItemTypeDetailInfo(itemTypeList[0]);
  };

  const onLoad = async () => {
    if (itemTypeId) {
      setIsLoading(true);
      await fetchItemType();
    }
  };

  useEffect(() => {
    onLoad();
  }, [itemTypeId]);

  useEffect(() => {
    const newDetailInfo = Object.keys(ItemTypeDetailScreenConfig?.attributeMap).reduce(
      (accumulator, eachKey) => {
        accumulator[eachKey] = {
          ...ItemTypeDetailScreenConfig?.attributeMap[eachKey],
          value: itemTypeDetailInfo[eachKey]?.toString() || ''
        };
        return accumulator;
      },
      {}
    );

    if (itemTypeDetailInfo[ItemTypeDetailScreenConfig?.imageConfig?.id]) {
      const image_path = itemTypeDetailInfo[ItemTypeDetailScreenConfig.imageConfig.id];
      imageValidityCheck(image_path, (isValid) => {
        setImage(
          isValid
            ? itemTypeDetailInfo[ItemTypeDetailScreenConfig.imageConfig?.id]
            : defaultXemelgoLogo
        );
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }

    setDetailInfo(newDetailInfo);
  }, [itemTypeDetailInfo]);

  const tabs = useMemo(() => {
    const { tabs: tabsConfig = [] } = ItemTypeDetailScreenConfig;
    return tabsConfig
      .map(({ path, title, index }) => {
        return { pathname: path, title, index };
      })
      .sort((a, b) => {
        return a.index - b.index;
      });
  }, [ItemTypeDetailScreenConfig?.tabs]);

  const onEditSave = async (newDetailInfo) => {
    setDetailInfo({ ...newDetailInfo });
    const hasUpdate = Object.keys(newDetailInfo).reduce((accumulator, eachKey) => {
      return accumulator || newDetailInfo[eachKey].value !== detailInfo[eachKey].value;
    }, false);

    const hasImagePath =
      ItemTypeDetailScreenConfig?.imageConfig?.id &&
      itemTypeDetailInfo[ItemTypeDetailScreenConfig.imageConfig.id];

    if (imageFileToUpload && imageFileToUpload.length > 0) {
      setIsLoading(true);

      const imageFile = imageFileToUpload[0].file;
      const uploadParams = {
        Bucket: CONFIGURATION.s3_customer_images_configuration.bucket_name,
        Key: `${CONFIGURATION.production ? 'prod' : 'dev'}/${tenantId}/itemType/${itemTypeId}.png`,
        Body: imageFile
      };

      try {
        await uploadImageToS3(uploadParams);
        /* the setImage has ?t=${new Date().getTime() added to it to force the <img/> component to refresh
         * and load the new image that was uploaded, since the imagePath of the item type does not change on
         * uploading a new image
         */
        setImage(
          `${
            itemTypeDetailInfo[ItemTypeDetailScreenConfig.imageConfig.id]
          }?t=${new Date().getTime()}`
        );
        setImageFileToUpload(null);
        if (!hasImagePath) {
          await itemTypeClient.updateItemType(itemTypeId, {
            image_path: `https://${CONFIGURATION.s3_customer_images_configuration.bucket_name}.s3-${
              CONFIGURATION.s3_customer_images_configuration.region
            }.amazonaws.com/${
              CONFIGURATION.production ? 'prod' : 'dev'
            }/${tenantId}/itemType/${itemTypeId}.png`
          });
        }
        await fetchItemType();
      } catch (e) {}
    }

    if (hasUpdate) {
      setIsLoading(true);

      await itemTypeClient.updateItemType(
        itemTypeId,
        Object.keys(newDetailInfo).reduce((accumulator, eachKey) => {
          if (newDetailInfo[eachKey].editable) {
            switch (ItemTypeDetailScreenConfig?.attributeMap[eachKey].type) {
              case 'number':
                accumulator[eachKey] = newDetailInfo[eachKey].value * 1;
                break;
              default:
                accumulator[eachKey] = newDetailInfo[eachKey].value;
                break;
            }
          }
          return accumulator;
        }, {})
      );
      await fetchItemType();
      setIsLoading(false);
    }
  };

  const dropdownMenu = () => {
    return (
      <Dropdown>
        <DropdownToggle nav className={Style.toggle}>
          <MoreHoriz />
        </DropdownToggle>

        <DropdownMenu>
          <DropdownItem
            className={Style.red_text}
            onClick={async () => {
              await itemTypeClient.removeItemType(itemTypeId);
              history.goBack();
            }}
          >
            Delete Item Type
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  return (
    <Fragment>
      {/* <div className={`${Style.alert_card}`}>
        <h5>Submission Successful</h5>
        <p>Your BOM was created successfully</p>
      </div> */}
      <DetailScreen
        isLoading={isLoading}
        TitleIconComponent={() => (
          <img
            src={require('../img/item-types-management_icon_light_gray.png')}
            width="35x"
            height="35px"
            style={{ marginBottom: '2px' }}
          />
        )}
        title="Manage Item Types"
        tabs={tabs}
        data={detailInfo}
        image={image}
        imageFileToUpload={imageFileToUpload}
        setImageFileToUploadFn={setImageFileToUpload}
        onEditSave={onEditSave}
        DropdownComponent={dropdownMenu}
      >
        {!!ItemTypeDetailScreenConfig.tabs?.length && children}
      </DetailScreen>
    </Fragment>
  );
};
