import React, { useState, useEffect, Fragment } from 'react';
import { Card, CardTitle, CardBody, Row, Col } from 'mdbreact';
import { useXemelgoClient } from '../services/xemelgo-service';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { getStatusFlags, naturalSort } from 'common/Utilities';
import ConfigurationService from '../services/ConfigurationService';
import Style from './SearchBar.module.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const PAGE_LIMIT = 50;
const STRING_MAX_LENGTH = 35;
const MIN_SEARCH_LENGTH = 3;

const SearchBarAndResults = ({ placeholder }) => {
  const [SearchClient] = useState(useXemelgoClient().getSearchClient());
  const [queryString, setQueryString] = useState('');
  const [value, setValue] = useState('');
  const [availableFlags, setAvailableFlags] = useState({});
  const [searchFocused, setSearchFocused] = useState(false);
  const [searchItemSelected, setSearchItemSelected] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [continueQuery, setContinueQuery] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [orderUseV2, setOrderUseV2] = useState(false);

  const onLoad = async () => {
    const availableFlags = await ConfigurationService.getStatusConfiguration();
    setAvailableFlags(availableFlags);

    const { configData } = await ConfigurationService.getFullConfiguration();
    const { useV2 = false } = configData.webClient?.appConfigurationMap?.order || {};
    setOrderUseV2(useV2);
  };

  useEffect(() => {
    onLoad();
  }, []);

  /* What to display in search bar once the user clicks a suggestion */
  const getSuggestionValue = (suggestion) =>
    `${getItemClass(suggestion)}: ${suggestion.identifier}`;

  /*
   * Issue the query and save the response to the search result 'queue'.
   *
   * @param {*} query - string to search for
   */
  const getSearchResults = async (query) => {
    // Issue the query to the backend
    const data = await SearchClient.getItemsByFullTextSearch(query, PAGE_LIMIT, 0);
    const { results = [] } = data || {};

    // Add the search results to the list to be processed
    const searchResult = {
      timestamp: new Date().getTime(),
      query,
      results
    };
    setSearchResults((prevSearchResults) => [...prevSearchResults, searchResult]);
  };

  // Since onSuggestionsFetchRequested() is called everytime the user types a keystroke
  // we want to add a slight delay to minimize the number of queries being issued
  useEffect(() => {
    const timeOutId = setTimeout(async () => {
      if (queryString && queryString.length >= MIN_SEARCH_LENGTH) {
        // If there is a query string, lookup the first page of results
        getSearchResults(queryString, 0);
      } else {
        // If no query string, clear out the previous results
        setSuggestions([]);
      }
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [queryString]);

  // Process the search results 'queue'
  useEffect(() => {
    if (searchResults.length) {
      // Make a copy of the current search results
      const searchResultsCopy = searchResults || [];

      // Keep track of search result timestamps that have been processed
      const timestamps = [];

      searchResultsCopy.forEach((searchResult) => {
        const { timestamp, query, results } = searchResult;

        // If the query string still matches the active query, then process the results
        if (query === queryString) {
          setSuggestions(naturalSort(results, 'identifier'));
          setContinueQuery(false);
        }
        timestamps.push(timestamp);
      });

      // Remove any search results that were processed
      setSearchResults((prevSearchResults) => [
        ...prevSearchResults.filter((results) => !timestamps.includes(results.timestamp))
      ]);
    }
  }, [queryString, searchResults]);

  const onSuggestionsFetchRequested = async ({ value, reason }) => {
    // If the query string has changed, clear out suggestions and put up the loader
    if (value !== queryString) {
      setSuggestions([]);
      setContinueQuery(true);
    }
    // Save the query string and let the other code process that change
    setQueryString(value);
  };

  const getItemClass = (item) => {
    switch (item.itemClass) {
      case 'Traveller':
        return 'Order';
      case 'Part':
      case 'Asset':
        return item.itemClass;
      default:
        return 'Item';
    }
  };

  /* Shorten display values to STRING_MAX_LENGTH. Add ... at end if shortened */
  const shortenName = (name) => {
    return name.length > STRING_MAX_LENGTH
      ? `${name.substring(0, STRING_MAX_LENGTH - 3)}...`
      : name;
  };

  const shouldRenderSuggestions = (value) => {
    return value.length >= MIN_SEARCH_LENGTH;
  };

  /* How to render each suggestion in dropdown */
  const renderSuggestion = (suggestion) => {
    const parts = suggestion.customFields.searchData.parts.filter(
      (part) =>
        (part.name && part.name.toUpperCase().includes(queryString.toUpperCase())) ||
        (part.identifier && part.identifier.toUpperCase().includes(queryString.toUpperCase()))
    );
    const { searchData = {} } = suggestion.customFields;
    const { sensorProfile = '-Not Provided-', status_flags = [] } = searchData;
    let flagList = [];

    switch (suggestion.itemClass) {
      case 'Traveller':
        flagList = availableFlags.order;
        break;
      case 'Asset':
        flagList = availableFlags.asset;
        break;
      case 'Inventory':
        flagList = availableFlags.inventory;
        break;
      case 'Part':
        flagList = availableFlags.part;
        break;
      default:
        break;
    }
    let statusFlags = getStatusFlags(status_flags, flagList);

    if (orderUseV2 && suggestion.itemClass === 'Traveller') {
      statusFlags = [];
    }

    const detailsPageLink = getDetailsPageLink(suggestion);

    return (
      <a href={detailsPageLink}>
        <Card className={Style.search_suggestion_card}>
          <div className={Style.inside_suggestion_card}>
            <CardTitle>
              <Row className={Style.search_card_title}>
                <Col size="7" className={Style.suggestion_title}>
                  {`${getItemClass(suggestion)}: ${shortenName(suggestion.identifier)}`}
                </Col>
                <Col size="5" className={Style.search_card_flags_results}>
                  {statusFlags.map((flag, index) => (
                    <Fragment key={index}>
                      <font style={{ color: flag.color }}>{`${flag.displayText}`} </font>
                      <font>{`${index < statusFlags.length - 1 ? '-' : ''}`} </font>
                    </Fragment>
                  ))}
                </Col>
              </Row>
            </CardTitle>
            <CardBody className={Style.search_card_body}>
              <div>{`Tag: ${shortenName(sensorProfile || '-Not Provided-')}`}</div>
              <div style={{ fontWeight: 'bolder' }}>
                {parts.length > 0 &&
                  `Contains part(s):
                ${parts.map((part) =>
                  part.name && part.name.toUpperCase().includes(value.toUpperCase())
                    ? part.name
                    : part.identifier
                )}`}
              </div>
            </CardBody>
          </div>
        </Card>
      </a>
    );
  };

  const renderSkeletonSuggestions = () => {
    return (
      <div>
        {[...Array(5).keys()].map((i) => {
          return (
            <Card key={`skeleton-${i}`} className={Style.search_suggestion_card}>
              <div className={Style.inside_suggestion_card}>
                <CardTitle>
                  <Row className={Style.search_card_title}>
                    <Col size="7" className={Style.suggestion_title}>
                      <Skeleton width="35ch" />
                    </Col>
                    <Col size="5" className={Style.search_card_flags_results}>
                      <Skeleton width="35ch" />
                    </Col>
                  </Row>
                </CardTitle>
                <CardBody className={Style.search_card_body}>
                  <Skeleton width="50ch" />
                </CardBody>
              </div>
            </Card>
          );
        })}
      </div>
    );
  };

  const getDetailsPageLink = (suggestion) => {
    switch (suggestion.itemClass) {
      case 'Part':
        return `/work-order/part/detail?itemId=${suggestion.id}`;
      case 'Traveller':
        return `/work-order/detail?itemId=${suggestion.id}`;
      case 'Asset':
        return `/asset/detail?itemId=${suggestion.id}`;
      case 'Inventory':
      case 'Stock':
        if (!suggestion.data.itemTypeName) {
          return `/inventory/itemType/detail?id=${suggestion.customFields.itemTypeId}`;
        }
        return `/inventory/item/detail?itemId=${suggestion.id}`;
      default:
        return `/${suggestion.itemClass}/detail?itemId=${suggestion.id}`;
    }
  };

  const onChange = (event, { newValue, method }) => {
    setValue(newValue);
  };

  const onFocus = (event) => {
    setSearchFocused(true);
  };

  const onBlur = (event) => {
    setSearchFocused(false);
  };

  const suggestionSelectedFn = (event, { suggestion }) => {
    setSearchItemSelected(true);
    window.location.href = getDetailsPageLink(suggestion);
  };

  const renderSuggestionsContainer = ({ containerProps, children, query }) => {
    if (!searchItemSelected && searchFocused && query.trim().length > 0) {
      return (
        <div {...containerProps}>
          {children ? (
            <div>
              {<div className={Style.search_results_overlay}>{children}</div>}
              {continueQuery && renderSkeletonSuggestions()}
            </div>
          ) : query.length < MIN_SEARCH_LENGTH ? (
            <div className={Style.no_results_overlay}>
              Type text to search (at least {MIN_SEARCH_LENGTH} characters)
            </div>
          ) : continueQuery ? (
            renderSkeletonSuggestions()
          ) : (
            <div className={Style.no_results_overlay}>No Matches Found</div>
          )}
        </div>
      );
    }
  };

  const renderInputField = (inputProps) => {
    return (
      <div className={Style.input_container}>
        <span className={`fa fa-search ${Style.input_icon}`} />
        <input className={Style.auto_suggest_input} {...inputProps} />
      </div>
    );
  };

  const inputProps = {
    placeholder,
    value,
    onChange,
    onBlur,
    onFocus,
    tabIndex: -1
  };

  return (
    <div className={Style.search_container}>
      <Autosuggest
        suggestions={suggestions}
        highlightFirstSuggestion
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={() => {}}
        onSuggestionSelected={suggestionSelectedFn}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        renderInputComponent={renderInputField}
        renderSuggestionsContainer={renderSuggestionsContainer}
        shouldRenderSuggestions={shouldRenderSuggestions}
        theme={{
          container: Style.auto_suggest_container,
          input: Style.auto_suggest_input,
          suggestionsContainer: Style.auto_suggest_suggestion_container,
          suggestionHighlighted: Style.auto_suggest_highlight_background,
          suggestionsList: Style.auto_suggest_list_style
        }}
      />
    </div>
  );
};

SearchBarAndResults.defaultProps = {
  placeholder: 'Search Xemelgo'
};

SearchBarAndResults.propTypes = {
  placeholder: PropTypes.string
};

export default SearchBarAndResults;
