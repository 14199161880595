import React from 'react';
import PropTypes from 'prop-types';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import TabListComponent from '../../components/tab-list-component/TabListComponent';
import SideNavigationBarStyle from './SideNavigationBar.module.css';

const SideNavigationBar = ({
  containerStyle,
  tabStructure,
  defaultTab,
  onCollapse,
  collapsed,
  hasCollapseIcon
}) => {
  return (
    <div className={containerStyle}>
      {hasCollapseIcon && (
        <div className={SideNavigationBarStyle.collapse_icon_container} onClick={onCollapse}>
          {collapsed ? <ChevronRight /> : <ChevronLeft />}
        </div>
      )}
      <TabListComponent
        containerStyle={SideNavigationBarStyle.tab_container}
        tabStructure={tabStructure}
        tabStyle={SideNavigationBarStyle.tab_style}
        focusedTabStyle={SideNavigationBarStyle.focused_tab_style}
        tabListStyle={SideNavigationBarStyle.tab_list_style}
        defaultTab={defaultTab}
      />
    </div>
  );
};

export default SideNavigationBar;

SideNavigationBar.defaultProps = {
  containerStyle: null,
  defaultTab: null,
  onCollapse: () => {},
  collapsed: false,
  hasCollapseIcon: false
};

SideNavigationBar.propTypes = {
  containerStyle: PropTypes.string,
  tabStructure: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultTab: PropTypes.string,
  onCollapse: PropTypes.func,
  collapsed: PropTypes.bool,
  hasCollapseIcon: PropTypes.bool
};
