import React from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { CheckCircleOutlined, CancelOutlined } from '@material-ui/icons';
import Style from './DisplayBanner.module.css';

const DisplayBanner = ({ bannerError, onCloseBanner, bannerMessage }) => {
  return (
    <Alert variant={bannerError ? 'danger' : 'success'} onClose={onCloseBanner} dismissible>
      <div className={Style.flex}>
        {bannerError ? <CancelOutlined /> : <CheckCircleOutlined />}
        <p className={Style.message}>{bannerMessage}</p>
      </div>
    </Alert>
  );
};

export default DisplayBanner;

DisplayBanner.propTypes = {
  bannerError: PropTypes.bool.isRequired,
  onCloseBanner: PropTypes.func.isRequired,
  bannerMessage: PropTypes.string.isRequired
};
