import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import InventoryTrackPageFeature from '../../features/inventory-track-page-feature/InventoryTrackPageFeature';
import AddInventoryFeature from '../../features/inventory-track-page-feature/AddInventoryFeature';
import ItemTypeDetailFeature from '../../features/inventory-track-page-feature/ItemTypeDetailFeature';
import ItemDetailPage from '../../components/TrackPageComponents/Inventory/ItemDetailPage';
import BulkUpdateItemsFeature from '../../features/inventory-track-page-feature/BulkUpdateItemsFeature';

const InventoryApp = () => {
  return (
    <Fragment>
      <Route exact path="/inventory" component={InventoryTrackPageFeature} />
      <Route path="/inventory/create" component={AddInventoryFeature} />
      <Route path="/inventory/bulk-update" component={BulkUpdateItemsFeature} />
      <Route path="/inventory/itemType/detail" component={ItemTypeDetailFeature} />
      <Route path="/inventory/item/detail" component={ItemDetailPage} />
    </Fragment>
  );
};

export default InventoryApp;
