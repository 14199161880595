import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Style from './NotFoundComponent.module.css';
import { XButton } from '../xemelgo-button/index';

const NotFoundComponent = ({ path, buttonLabel, mainText, subText }) => {
  return (
    <div className={Style.not_found_container}>
      <p className={Style.not_found_main_text}>{mainText}</p>
      <p className={Style.not_found_sub_text}>{subText}</p>
      <Link to={path}>
        <XButton label={buttonLabel} className={`${Style.save_button} ${Style.button}`} />
      </Link>
    </div>
  );
};

NotFoundComponent.defaultProps = {
  path: '/',
  buttonLabel: 'button Label',
  mainText: 'here is the main text',
  subText: 'here is the sub text'
};

NotFoundComponent.propTypes = {
  path: PropTypes.string,
  buttonLabel: PropTypes.string,
  mainText: PropTypes.string,
  subText: PropTypes.string
};

export default NotFoundComponent;
