export const getDistinctLocationCategories = (locations) => {
  const list = [];
  locations.forEach((location) => {
    const category = location.getCategory();
    list.push(category);
  });

  const set = new Set(list);
  return [...set];
};
