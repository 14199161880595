/**
 * @typedef SectionPayload
 * @property id { string }
 * @property vid { string }
 * @property vendor { string }
 * @property class { string }
 */

/**
 * @typedef VendorOption
 * @property prefix { string }
 * @property value { string }
 * @property class { string }
 */

/**
 * Returns a default vendor option. The default vendor option is extracted based on either the by the value of
 *  defaultOptionValue or by a first non-prefix option.
 * @param vendorOptions { VendorOption[] }
 * @param defaultOptionName { string }
 * @return { VendorOption | null }
 */
const getDefaultVendorOption = (vendorOptions, defaultOptionName) => {
  if (defaultOptionName) {
    return vendorOptions.find(
      (option) => option.value.toUpperCase() === defaultOptionName.toUpperCase()
    );
  }

  const nonPrefixOptions = vendorOptions.filter((option) => !option.prefix);
  return nonPrefixOptions[0];
};

/**
 *
 * @param vid { string }
 * @param vendorOptions { VendorOption[] }
 * @return { VendorOption | null }
 */
const getVendorOptionFromVid = (vid, vendorOptions) => {
  if (!vid) {
    return null;
  }

  return vendorOptions.find((option) => {
    if (!option.prefix) {
      return false;
    }

    return vid.toUpperCase().startsWith(option.prefix.toUpperCase());
  });
};

/**
 *
 * @param sectionPayloads { SectionPayload[] }
 * @param sectionId { string }
 * @param propertyName { string }
 * @param value { any }
 * @return { [SectionPayload[], number] }
 */
export const registerUpdatedPayloadToSectionPayloads = (
  sectionPayloads,
  sectionId,
  propertyName,
  value
) => {
  const clonedSectionPayloads = [...sectionPayloads];
  const foundIndex = clonedSectionPayloads.findIndex((payload) => payload.id === sectionId);

  const payload = clonedSectionPayloads[foundIndex] || { id: sectionId };
  payload[propertyName] = value;

  if (foundIndex !== -1) {
    clonedSectionPayloads[foundIndex] = payload;
  } else {
    clonedSectionPayloads.push(payload);
  }

  // the index is either the index we found for existing, or the last index of the list
  const finalIndex = foundIndex !== -1 ? foundIndex : clonedSectionPayloads.length - 1;
  return [clonedSectionPayloads, finalIndex];
};

/**
 *
 * @param payload { SectionPayload }
 * @param vendorOptions { VendorOption[] }
 * @param defaultVendorOptionName { string }
 * @return { SectionPayload }
 */
export const autoPopulatePayload = (payload, vendorOptions, defaultVendorOptionName) => {
  if (!vendorOptions || vendorOptions.length === 0) {
    return payload;
  }

  const { vid } = payload;
  if (!vid) {
    return payload;
  }

  const foundVendorOption = getVendorOptionFromVid(vid, vendorOptions);
  if (foundVendorOption) {
    const clonedPayload = { ...payload };
    clonedPayload.vendor = foundVendorOption.value;
    clonedPayload.class = foundVendorOption.class;
    return clonedPayload;
  }

  const defaultOption = getDefaultVendorOption(vendorOptions, defaultVendorOptionName);
  if (defaultOption) {
    const clonedPayload = { ...payload };
    clonedPayload.vendor = defaultOption.value;
    clonedPayload.class = defaultOption.class;
    return clonedPayload;
  }

  return payload;
};
