import React, { Fragment } from 'react';
import ImageUploading from 'react-images-uploading';
import Style from './ImageUploadAndDisplayComponentStyle.module.css';

const ImageUploadAndDisplayComponent = ({ imageFileToUpload, onChange, maxNumberOfFiles = 1, acceptedFileTypes }) => (
  <ImageUploading
    multiple={!!(maxNumberOfFiles > 1)}
    value={imageFileToUpload}
    onChange={onChange}
    maxNumber={maxNumberOfFiles}
    dataURLKey="data_url"
    acceptType={acceptedFileTypes}
  >
    {({
      imageList,
      onImageUpload,
      onImageRemoveAll,
      onImageUpdate,
      onImageRemove,
      isDragging,
      dragProps,
      errors
    }) => (
      <div>
        {imageList?.length === 0 && (
          <>
            <div onClick={onImageUpload} {...dragProps} className={Style.image_upload_component}>
              <p className={Style.image_upload_component_text}>
                Click here or drag and drop an image to upload
              </p>
            </div>
            {errors && (
              <div className={Style.error_container}>
                {errors.maxNumber && (
                  <span>Number of selected images exceed allowed limit of 1.</span>
                )}
                {errors.acceptType && (
                  <span>
                    Your selected file type is not allowed. Please use a .jpg / .png file.
                  </span>
                )}
              </div>
            )}
          </>
        )}
        {imageList.map((image, index) => (
          <div key={index} className={Style.image_preview_container}>
            <img
              src={image.data_url}
              className={Style.image_preview}
              alt="Invalid Type. Try again."
            />
            <div style={{ marginTop: '1em' }}>
              <button className={Style.choose_another_button} onClick={() => onImageUpdate(index)}>
                Choose another
              </button>
            </div>
          </div>
        ))}
      </div>
    )}
  </ImageUploading>
);

export default ImageUploadAndDisplayComponent;
