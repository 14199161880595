import { Form, FormGroup, Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Div } from '../../../../../components/div';
import './style.css';
import { InteractableLocationFloorPlan } from '../interactable-location-floor-plan';
import { PinOperations } from '../pin-operations';

const removeItemFromArray = (array, isRequestingElementFn) => {
  const index = array.findIndex((element) => {
    return isRequestingElementFn(element);
  });

  const clonedArray = [...array];
  if (index < 0) {
    return clonedArray;
  }

  clonedArray.splice(index, 1);
  return clonedArray;
};

export const EditLocationFormBody = ({
  categoryDisplayName,
  properties,
  location,
  innerLocations,
  submitTicket,
  onNewSubmitTicket,
  editError
}) => {
  const [locationCategory, setLocationCategory] = useState('Locations');
  const [showChildrenLocations, setShowChildrenLocations] = useState(false);
  const [pinningPlan, setPinningPlan] = useState(null);
  const [pinMap, setPinMap] = useState({});

  // keep track of new pin requests to generate payloads upon save
  const [pinningRequests, setPinningRequests] = useState([]);
  const [removePinRequests, setRemovePinRequests] = useState([]);
  const [fieldPayload, setFieldPayload] = useState({});
  const [parentLocation, setParentLocation] = useState(null);
  const [identifier, setLocationIdentifier] = useState(null);
  const [hasNewSubmitRequest, setHasNewSubmitRequest] = useState(false);

  /**
   * Name: prepareParentLocations
   */
  useEffect(() => {
    let cancelled = false;

    const parent = location.getParent();
    setLocationIdentifier(location.getIdentifier());
    if (!cancelled) {
      setParentLocation(parent);
    }

    return () => {
      cancelled = true;
    };
  }, [location]);

  /**
   * Name: registerNewSubmitRequest
   */
  useEffect(() => {
    let cancelled = false;

    if (!cancelled) {
      if (submitTicket) {
        setHasNewSubmitRequest(true);
      }
    }

    return () => {
      cancelled = true;
    };
  }, [submitTicket]);

  /**
   * Name: handleSubmitRequest
   */
  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (!hasNewSubmitRequest) {
      return cancelCallback;
    }

    if (!cancelled) {
      onNewSubmitTicket(fieldPayload, pinningRequests, removePinRequests);
      setHasNewSubmitRequest(false);
    }

    return cancelCallback;
  }, [onNewSubmitTicket, hasNewSubmitRequest, pinningRequests, fieldPayload, removePinRequests]);

  useEffect(() => {
    if (!innerLocations || innerLocations.length === 0) {
      return;
    }

    const l = innerLocations[0];
    const category = l.getCategory();
    setLocationCategory(category);

    const locationPins = innerLocations
      .filter((loc) => {
        const { data } = loc;
        return data.pin;
      })
      .map((loc) => {
        const { pin } = loc.data;
        const id = loc.getId();
        const name = loc.getName();
        const { x, y, color } = pin;
        return { x, y, color, name, id };
      });

    const locationPinMap = locationPins.reduce((map, pin) => {
      const clonedMap = { ...map };
      const { id } = pin;
      clonedMap[id] = pin;
      return clonedMap;
    }, {});

    setPinMap(locationPinMap);
    setShowChildrenLocations(innerLocations.length > 0);
  }, [innerLocations]);

  const onPinDropped = useCallback(
    (pin) => {
      setPinningPlan(null);

      setPinningRequests((existingRequests) => {
        const newList = [...existingRequests, { ...pin }];
        return newList;
      });

      setPinMap((existingMap) => {
        const { id } = pin;
        const newMap = { ...existingMap, [id]: pin };
        return newMap;
      });
      // onNewPinRequests({ ...pin });
    },
    [setPinningRequests, setPinMap]
  );

  const onRequestPinRemovedCallback = useCallback(
    (removingPin) => {
      let needRemovingRequest = false;
      // check if the request to remove pin is not a pin we are requesting to add. If so, remove them from the list
      setPinningRequests((requests) => {
        const foundIndex = requests.findIndex((pin) => {
          return pin.id === removingPin.id;
        });

        // this means that the removing pin is not the pin just be proposing to set
        if (foundIndex === -1) {
          needRemovingRequest = true;
          return requests;
        }

        const clonedList = [...requests];
        clonedList.splice(foundIndex, 1);
        return clonedList;
      });

      // add removing request
      if (needRemovingRequest) {
        setRemovePinRequests((requests) => {
          const clonedRequests = [...requests];
          clonedRequests.push(removingPin);
          return clonedRequests;
        });
      }

      // remove the pin on showing on the map;
      setPinMap((map) => {
        const clonedMap = { ...map };
        delete clonedMap[removingPin.id];
        return clonedMap;
      });
    },
    [setPinningRequests, setRemovePinRequests, setPinMap]
  );

  const togglePinningPlan = useCallback(
    (loc) => {
      const id = loc.getId();

      const { id: currentPinPlanId } = pinningPlan || {};
      if (id === currentPinPlanId) {
        setPinningPlan(null);
        return;
      }

      const name = loc.getName();
      setPinningPlan({ id, name });
    },
    [pinningPlan]
  );

  /**
   * Track any value updated
   * @param event
   * @param propertyId
   */
  const onFieldChangeCallback = useCallback(
    (event, propertyId) => {
      const { value } = event.target;
      setFieldPayload((payload) => {
        const clonedPayload = { ...payload };
        if (value === null || value === undefined || value.trim() === '') {
          delete clonedPayload[propertyId];
        } else {
          clonedPayload[propertyId] = value;
        }

        return clonedPayload;
      });
    },
    [setFieldPayload]
  );

  const onColorChangeWorkflowSignaled = useCallback(
    (updatedPin) => {
      setPinMap((existingPinMap) => {
        const clonedMap = { ...existingPinMap };
        const { id } = updatedPin;
        clonedMap[id] = { ...updatedPin };
        return clonedMap;
      });

      setPinningRequests((existingRequests) => {
        const newList = removeItemFromArray(existingRequests, (pin) => {
          return pin.id === updatedPin.id;
        });

        newList.push({ ...updatedPin });
        return newList;
      });
    },
    [setPinMap, setPinningRequests]
  );

  return (
    <Form className="location-edit-form">
      <Div className="left-pane">
        {editError && (
          <Div className="error-message-group">
            <Div className="error-title">Error</Div>
            <Div className="error-message">{editError}</Div>
          </Div>
        )}
        <Div class="edit-properties">
          {parentLocation && (
            <FormGroup>
              <Label for="parent-location" className="form-label">
                {`${parentLocation.getCategory()} Name`}
              </Label>
              <Input
                disabled
                type="textArea"
                className="form-input"
                placeholder={parentLocation.getName()}
              />
            </FormGroup>
          )}

          {properties.map((property) => {
            const { id: propertyId, displayName: propertyDisplayName } = property;
            return (
              <FormGroup key={propertyId}>
                <Label for={propertyId} className="form-label">
                  {`${categoryDisplayName} ${propertyDisplayName}`}
                </Label>
                <Input
                  disabled={!property.__updatable}
                  type="textArea"
                  className="form-input"
                  name={propertyId}
                  id={propertyId}
                  placeholder={location[propertyId]}
                  onChange={(event) => onFieldChangeCallback(event, propertyId)}
                />
              </FormGroup>
            );
          })}
        </Div>
        {showChildrenLocations && (
          <Div className="inner-locations-group">
            <Div className="inner-locations-title">{locationCategory}</Div>
            <Div className="inner-locations-records">
              {innerLocations.map((l) => {
                const name = l.getName();
                const id = l.getId();
                const pin = pinMap[id];
                const waitingForPinning = pinningPlan && pinningPlan.id === id;
                return (
                  <Div className="inner-location-instance" key={id}>
                    <Div className="inner-location-instance-name">{name}</Div>
                    {location.getImagePath() && (
                      <Div className="inner-location-instance-pin-operations">
                        <PinOperations
                          pin={pin}
                          onSetPinClicked={() => togglePinningPlan(l)}
                          inDropPinMode={waitingForPinning}
                          onCancelDropPinMode={() => togglePinningPlan(l)}
                          onColorChanged={onColorChangeWorkflowSignaled}
                          onRemovePin={onRequestPinRemovedCallback}
                        />
                      </Div>
                    )}
                  </Div>
                );
              })}
            </Div>
          </Div>
        )}
      </Div>

      <Div className="right-pane">
        <InteractableLocationFloorPlan
          imageUrl={location.getImagePath()}
          pinRequest={pinningPlan}
          pins={Object.values(pinMap)}
          onPinDrop={onPinDropped}
        />
      </Div>
    </Form>
  );
};

EditLocationFormBody.defaultProps = {
  innerLocations: [],
  submitTicket: null,
  onNewSubmitTicket: () => {}
};

EditLocationFormBody.propTypes = {
  categoryDisplayName: PropTypes.string.isRequired,
  properties: PropTypes.arrayOf(PropTypes.any).isRequired,
  innerLocations: PropTypes.arrayOf(
    PropTypes.shape({
      getId: PropTypes.func.isRequired,
      getName: PropTypes.func.isRequired,
      getCategory: PropTypes.func.isRequired,
      data: PropTypes.shape({
        pin: PropTypes.shape({
          x: PropTypes.number.isRequired,
          y: PropTypes.number.isRequired,
          color: PropTypes.string.isRequired
        })
      })
    })
  ),
  location: PropTypes.shape({
    getImagePath: PropTypes.func.isRequired,
    getId: PropTypes.func.isRequired,
    getName: PropTypes.func.isRequired,
    getCategory: PropTypes.func.isRequired,
    getParent: PropTypes.func.isRequired
  }).isRequired,
  submitTicket: PropTypes.string,
  onNewSubmitTicket: PropTypes.func
};
