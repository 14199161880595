// import './mocks/axios-interceptor';
import 'babel-polyfill'; // IE11 Compatibility Import for 'Promise' and 'Object'
import React from 'react';
import ReactDOM from 'react-dom';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './index.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import LoginPage from './pages/login';
import 'mapbox-gl/dist/mapbox-gl.css';

import AuthorizedProcessAppRoute from './routes/AuthorizedProcessAppRoute';

import MainApp from './MainApp';
import registerServiceWorker from './registerServiceWorker';
import { UserProfile } from './domains/user-profile';
import { LocalCacheService } from './services/local-cache-service';
import { SessionStorageService } from 'services/session-storage-service';
import ConfigurationService from './services/ConfigurationService';
import AuthService from './services/AuthService';
import RestService from './services/RestService';

AuthService.configure(ConfigurationService.getAuthConfiguration());
AuthService.refreshToken()
  .then((sessionInfo) => {
    const userProfile = UserProfile.parse(sessionInfo);
    LocalCacheService.saveUserProfile(userProfile);
    ConfigurationService.getRestServiceConfiguration().then((restConfiguration) => {
      RestService.configure(restConfiguration);
    });
  })
  .catch(() => {
    AuthService.logout();
    LocalCacheService.clearAllCaches();
    SessionStorageService.clearAllCaches();
  })
  .finally(() => {
    ReactDOM.render(
      <Router>
        <Route exact path="/login" component={LoginPage} />
        <AuthorizedProcessAppRoute path="/" component={MainApp} />
      </Router>,
      document.getElementById('root')
    );
  });

registerServiceWorker();
