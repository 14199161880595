import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AutoSizeTextArea from '../components/AutoSizeTextArea/AutoSizeTextArea';
import Style from './DetailScreen.module.css';
import Skeleton from 'react-loading-skeleton';
import { getFormattedDate } from 'common/Utilities';
import 'react-loading-skeleton/dist/skeleton.css';
import EditButton from 'components/EditButton/EditButton';
import ImageUploadAndDisplayComponent from 'components/image-upload-and-display-component/ImageUploadAndDisplayComponent';

const Tab = ({ pathname, title, active }) => {
  return (
    <Link
      className={`${Style.tab_link} ${active && Style.tab_link_active}`}
      to={(location) => {
        return { pathname, search: location.search };
      }}
      replace
    >
      {title}
    </Link>
  );
};

export default ({
  children,
  tabs = [],
  data,
  title,
  TitleIconComponent,
  onEditSave,
  image,
  imageFileToUpload,
  setImageFileToUploadFn,
  isLoading,
  DropdownComponent
}) => {
  const location = useLocation();
  const [isEditMode, setIsEditMode] = useState(false);
  const [dataFields, setDataFields] = useState({});

  useEffect(() => {
    setDataFields({ ...data });
  }, [data, isEditMode]);

  const renderPrimaryFields = (attributeKey, attributeData) => {
    return (
      <Fragment key={attributeKey}>
        <p className={`${Style.primary_label}`}>{`${attributeData.label}:`}</p>
        <div className={`${Style.primary_value_container}`}>
          <AutoSizeTextArea
            fontSize={18}
            fontWeight="500"
            maxRows={2}
            numberOnly={attributeData.type === 'number'}
            readOnly={!attributeData.editable || !isEditMode || attributeData.type === 'date'}
            value={
              isEditMode || attributeData.value
                ? attributeData.type !== 'date'
                  ? attributeData.value
                  : getFormattedDate(attributeData.value)
                : '-'
            }
            onChangeText={(newText) => {
              setDataFields({
                ...dataFields,
                [attributeKey]: { ...attributeData, value: newText }
              });
            }}
          />
        </div>
      </Fragment>
    );
  };

  const renderFields = (attributeKey, attributeData) => {
    return (
      <Fragment key={attributeKey}>
        <p className={`${Style.normal_label}`}>{`${attributeData.label}:`}</p>
        <div className={`${Style.normal_value_container}`}>
          <AutoSizeTextArea
            numberOnly={attributeData.type === 'number'}
            maxRows={2}
            readOnly={!attributeData.editable || !isEditMode || attributeData.type === 'date'}
            value={
              isEditMode || attributeData.value
                ? attributeData.type !== 'date'
                  ? attributeData.value
                  : getFormattedDate(attributeData.value)
                : '-'
            }
            onChangeText={(newText) => {
              setDataFields({
                ...dataFields,
                [attributeKey]: { ...attributeData, value: newText }
              });
            }}
          />
        </div>
      </Fragment>
    );
  };

  const TabsList = useMemo(() => {
    if (tabs?.length) {
      return () => {
        return (
          <div className={`${Style.tabs} ${Style.flex_row}`}>
            {tabs.map(({ pathname, title }) => {
              return (
                <Tab
                  key={pathname}
                  pathname={pathname}
                  title={title}
                  active={location.pathname === pathname}
                />
              );
            })}
            <span className={`${Style.tabs_empty_space}`} />
          </div>
        );
      };
    }
    return null;
  }, [location]);

  const onImageAdd = (imageList) => {
    setImageFileToUploadFn(imageList);
  };

  const ImageDisplay = () => {
    return isEditMode ? (
      <ImageUploadAndDisplayComponent
        imageFileToUpload={imageFileToUpload}
        onChange={onImageAdd}
        maxNumberOfFiles={1}
        acceptedFileTypes={['jpg', 'png', 'jpeg']}
      />
    ) : (
      <img
        loading="eager"
        className={`${Style.detail_image}`}
        alt="Can't load the image"
        src={image}
      />
    );
  };

  return (
    <div className={`${Style.grid} ${Style.page_frame}`}>
      <div className={Style.title_container}>
        <div className={Style.title_icon} style={{ color: '#c8cbd3' }}>
          <TitleIconComponent />
        </div>
        <p className={Style.title}>{title}</p>
      </div>
      <div className={`${Style.page_content_container} ${Style.grid}`}>
        <div className={`${Style.left_content_container} ${Style.grid}`}>
          <div className={`${Style.edit_button_container} ${Style.flex_row}`}>
            <EditButton
              show={!isLoading}
              editMode={isEditMode}
              onEditClick={() => {
                setIsEditMode(true);
              }}
              onDiscardClick={() => {
                setDataFields({ ...data });
                setImageFileToUploadFn(null);
                setIsEditMode(false);
              }}
              onSaveClick={() => {
                setDataFields({ ...data });
                onEditSave(dataFields);
                setIsEditMode(false);
              }}
            />
          </div>
          {isLoading ? (
            <div className={Style.detail_image} style={{ minHeight: 250, position: 'relative' }}>
              <Skeleton className={Style.image_loading} />
            </div>
          ) : (
            <ImageDisplay />
          )}
          <div className={`${Style.primary_fields} ${Style.grid}`}>
            {isLoading ? (
              <div className={`${Style.primary_loading}`}>
                <Skeleton height={28} />
              </div>
            ) : (
              <Fragment>
                {Object.keys(dataFields)
                  .filter((eachKey) => {
                    return dataFields[eachKey].primary;
                  })
                  .sort((aKey, bKey) => {
                    return dataFields[aKey].index - dataFields[bKey].index;
                  })
                  .map((eachKey) => {
                    return renderPrimaryFields(eachKey, dataFields[eachKey]);
                  })}
                {DropdownComponent && !isEditMode && (
                  <div className={`${Style.dropdown_container} ${Style.flex_row}`}>
                    <DropdownComponent />
                  </div>
                )}
              </Fragment>
            )}
          </div>
          <div className={`${Style.normal_fields} ${Style.grid}`}>
            {isLoading ? (
              <div className={`${Style.normal_loading}`}>
                <Skeleton height={28} count={3} />
              </div>
            ) : (
              Object.keys(dataFields)
                .filter((eachKey) => {
                  return !dataFields[eachKey].primary;
                })
                .sort((aKey, bKey) => {
                  return dataFields[aKey].index - dataFields[bKey].index;
                })
                .map((eachKey) => {
                  return renderFields(eachKey, dataFields[eachKey]);
                })
            )}
          </div>
        </div>
        <div className={`${Style.right_content_container} ${Style.grid}`}>
          {TabsList && <TabsList />}
          <div className={`${Style.tab_content}`}>
            <div style={{ height: '100%' }}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
