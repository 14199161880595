import React, { useState, useEffect } from 'react';
import AutoSizeTextArea from '../../components/AutoSizeTextArea/AutoSizeTextArea';
import Style from './BomManagementFeatureStyle.module.css';
import { MoreHoriz, AddCircleOutlineTwoTone } from '@material-ui/icons';
import { Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'mdbreact';
import uuid from 'uuid';
import { useBOMManagementContext } from '../../context/BOMManagementContext/BOMManagementContext';
import Skeleton from 'react-loading-skeleton';
import EditButton from 'components/EditButton/EditButton';

const BOMManageOperationTable = React.forwardRef(
  ({ onSaveClick, loading, hideEditButton }, ref) => {
    const { operationInfo } = useBOMManagementContext();
    const [isEditMode, setIsEditMode] = useState(false);
    const [operationTableInfo, setOperationTableInfo] = useState({});

    const resetOperationTableInfo = () => {
      const newOperationTableInfo = operationInfo
        .sort((a, b) => {
          return a.rank - b.rank;
        })
        .reduce((accumulator, eachOperation) => {
          accumulator[eachOperation.id] = { ...eachOperation };
          return accumulator;
        }, {});
      setOperationTableInfo(newOperationTableInfo);
    };

    useEffect(() => {
      resetOperationTableInfo();
    }, [operationInfo]);

    const handleSaveClick = async () => {
      await onSaveClick(operationTableInfo);
      setIsEditMode(false);
    };

    const addItem = () => {
      const newOperationTableInfo = { ...operationTableInfo };
      newOperationTableInfo[uuid.v4()] = {
        rank: Object.keys(newOperationTableInfo).length
      };
      setOperationTableInfo(newOperationTableInfo);
    };

    const renderTableRows = (data = {}) => {
      if (loading) {
        return (
          <ul className={`${Style.table_fields} ${Style.flex_column}`}>
            {[...Array(2)].map((_, rowIndex) => {
              return (
                <li key={`${rowIndex}`}>
                  <ul>
                    {[...Array(4)].map((_, columnIndex) => {
                      return (
                        <li
                          key={`${rowIndex} ${columnIndex}`}
                          className={
                            columnIndex === 3
                              ? Style.more_button_column
                              : columnIndex === 0
                              ? Style.step_number
                              : ''
                          }
                        >
                          <Skeleton height={28} />
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            })}
          </ul>
        );
      }
      return (
        <ul className={`${Style.table_fields} ${Style.flex_column}`}>
          {Object.keys(data)
            .sort((aKey, bKey) => {
              return data[aKey].rank - data[bKey].rank;
            })
            .map((eachOperationId, index) => {
              const { name, rank, description } = data[eachOperationId];
              return (
                <li key={eachOperationId}>
                  <ul>
                    <li className={Style.step_number}>{index + 1}</li>

                    <li>
                      <AutoSizeTextArea
                        readOnly={!isEditMode}
                        value={isEditMode ? name : name || '--'}
                        onChangeText={(newText) => {
                          const newOperationTableInfo = { ...operationTableInfo };
                          newOperationTableInfo[eachOperationId].name = newText;
                          setOperationTableInfo(newOperationTableInfo);
                        }}
                      />
                    </li>
                    <li>
                      <AutoSizeTextArea
                        maxRows={3}
                        readOnly={!isEditMode}
                        value={isEditMode ? description : description || '--'}
                        onChangeText={(newText) => {
                          const newOperationTableInfo = { ...operationTableInfo };
                          newOperationTableInfo[eachOperationId].description = newText;
                          setOperationTableInfo(newOperationTableInfo);
                        }}
                      />
                    </li>
                    <li className={Style.more_button_column}>
                      {isEditMode && (
                        <Dropdown>
                          <DropdownToggle nav className={Style.toggle}>
                            <MoreHoriz className={Style.icon} />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              className={Style.blue_text}
                              onClick={() => {
                                const newOperationTableInfo = { ...operationTableInfo };
                                Object.keys(newOperationTableInfo)
                                  .sort((a, b) => {
                                    return (
                                      newOperationTableInfo[a].rank - newOperationTableInfo[b].rank
                                    );
                                  })
                                  .forEach((eachId, index) => {
                                    if (newOperationTableInfo[eachId].rank > rank) {
                                      newOperationTableInfo[eachId].rank = index + 1;
                                    }
                                  });
                                newOperationTableInfo[uuid.v4()] = {
                                  rank: rank + 1
                                };

                                setOperationTableInfo(newOperationTableInfo);
                              }}
                            >
                              Insert Step
                            </DropdownItem>
                            <DropdownItem
                              className={Style.red_text}
                              onClick={() => {
                                const newOperationTableInfo = { ...operationTableInfo };
                                Object.keys(newOperationTableInfo)
                                  .sort((a, b) => {
                                    return (
                                      newOperationTableInfo[a].rank - newOperationTableInfo[b].rank
                                    );
                                  })
                                  .forEach((eachId, index) => {
                                    if (newOperationTableInfo[eachId].rank > rank) {
                                      newOperationTableInfo[eachId].rank = index - 1;
                                    }
                                  });
                                delete newOperationTableInfo[eachOperationId];
                                setOperationTableInfo(newOperationTableInfo);
                              }}
                            >
                              Delete Step
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      )}
                    </li>
                  </ul>
                </li>
              );
            })}
        </ul>
      );
    };

    ref.current = {
      onSaveClick: handleSaveClick,
      addItem: addItem,
      setEditingMode: (newIsEditMode) => {
        setIsEditMode(newIsEditMode);
      }
    };

    return (
      <div className={Style.additional_info_container}>
        <div className={`${Style.title_container} ${Style.flex_row}`}>
          <p className={Style.title_text}>Operations</p>
          <div className={`${Style.edit_button_container} ${Style.flex_row}`}>
            <EditButton
              show={!loading && !hideEditButton}
              editMode={isEditMode}
              onEditClick={() => {
                setIsEditMode(true);
              }}
              onDiscardClick={() => {
                resetOperationTableInfo();
                setIsEditMode(false);
              }}
              onSaveClick={handleSaveClick}
            />
          </div>
        </div>
        <div className={`${Style.table_container}`}>
          <ul className={`${Style.table_headers} ${Style.flex_row}`}>
            <li className={Style.step_number}>Step #</li>
            <li>Step Name</li>
            <li>Instruction</li>
            <li className={Style.more_button_column} />
          </ul>
          {renderTableRows(operationTableInfo)}
          {isEditMode && (
            <button className={`${Style.table_button} ${Style.flex_row}`} onClick={addItem}>
              <AddCircleOutlineTwoTone className={Style.table_button_add_icon} />
              <p>Add another operation</p>
            </button>
          )}
        </div>
      </div>
    );
  }
);

export default BOMManageOperationTable;
