import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Div } from '../../../../components/div';
import { CollapsibleListView } from '../../../../components/collapsible-list-view';
import { RecordRows } from './record-rows';
import { GroupHeader } from './group-header';
import './style.css';
import { FeatureConfigurationProvider } from '../../../../domains/feature-configuration-provider';

export const ListResourceGroupPanel = ({
  configuration,
  resources,
  modelId,
  onRecordSelected,
  focus,
  onAddClicked
}) => {
  const [groupDisplayName, setGroupDisplayName] = useState(null);

  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (!configuration || !modelId) {
      return cancelCallback;
    }
    const configProvider = FeatureConfigurationProvider.parse(
      'ListResourceGroupPanel',
      configuration
    );

    const modelConfigProvider = configProvider.getModel(modelId);
    const groupName = modelConfigProvider.getValue('groupDisplayName', 'string');
    if (!cancelled) {
      setGroupDisplayName(groupName);
    }

    return cancelCallback;
  }, [modelId, configuration]);

  return (
    <Div className="my-facility-resources">
      <Div className="my-facility-resources-title-section">
        <Div className="my-facility-resources-title-header">{groupDisplayName}</Div>
        <Div className="my-facility-resources-title-subtitle">
          Set up and edit the structure of your organization
        </Div>
      </Div>
      <Div>
        <CollapsibleListView
          className="group-records"
          title={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <GroupHeader
              modelId={modelId}
              groupName={groupDisplayName}
              resourceCount={resources.length}
              configuration={configuration}
              onAddClick={onAddClicked}
            />
          }
          content={
            <RecordRows resources={resources} onSelectRecord={onRecordSelected} focus={focus} />
          }
        />
      </Div>
    </Div>
  );
};

ListResourceGroupPanel.defaultProps = {
  configuration: {},
  resources: [],
  onRecordSelected: () => {},
  focus: false,
  onAddClicked: () => {}
};

ListResourceGroupPanel.propTypes = {
  modelId: PropTypes.string.isRequired,
  configuration: PropTypes.shape({
    modelMap: PropTypes.objectOf(
      PropTypes.shape({
        displayName: PropTypes.string,
        groupDisplayName: PropTypes.string,
        canAttachDetector: PropTypes.bool,
        properties: PropTypes.objectOf(
          PropTypes.shape({
            __addable: PropTypes.bool,
            __optional: PropTypes.bool,
            __optionallyDependsOn: PropTypes.arrayOf(PropTypes.string),
            displayName: PropTypes.string,
            input: PropTypes.oneOfType([
              PropTypes.bool,
              PropTypes.string,
              PropTypes.arrayOf(
                PropTypes.shape({
                  key: PropTypes.string.isRequired,
                  value: PropTypes.string.isRequired
                })
              )
            ])
          })
        )
      })
    )
  }),
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  onRecordSelected: PropTypes.func,
  focus: PropTypes.bool,
  onAddClicked: PropTypes.func
};
