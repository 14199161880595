import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Checkbox } from '../checkbox-component/Checkbox';

// https://material-ui.com/components/tables/#tables

const DefaultRowPerPageOptions = [5, 10, 25];

const EnhancedTableHead = ({
  classes,
  order,
  orderBy,
  headerStyle,
  onRequestSort,
  hoverAvailable,
  headerStructureList,
  multiSelectEnabled,
  onHeaderMultiClick,
  isHeaderChecked
}) => {
  const createSortHandler = (property, disabled) => (event) => {
    if (!disabled) {
      onRequestSort(event, property);
    }
  };

  return (
    <TableHead>
      <TableRow>
        {multiSelectEnabled && (
          <TableCell
            key={`header checkbox`}
            id={`header checkbox`}
            scope="row"
            padding="normal"
            align="left"
            classes={{ root: classes.headerBorder }}
          >
            <Checkbox
              onClick={() => {
                onHeaderMultiClick(!isHeaderChecked);
              }}
              isChecked={isHeaderChecked}
            />
          </TableCell>
        )}
        {headerStructureList.map((headCell) => (
          <TableCell
            className={classes.headerBorder}
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id && !headCell.disabled}
              disabled={headCell.disabled}
              direction={order}
              style={headerStyle}
              onClick={createSortHandler(headCell.id, headCell.disabled)}
            >
              {headCell.label}
              {orderBy === headCell.id && !headCell.disabled && (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              )}
            </TableSortLabel>
          </TableCell>
        ))}
        {hoverAvailable && <TableCell className={classes.headerBorder} />}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  headerStructureList: PropTypes.array.isRequired,
  hoverAvailable: PropTypes.bool,
  headerStyle: PropTypes.object
};

EnhancedTableHead.defaultProps = {
  hoverAvailable: false,
  headerStyle: {}
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  rowTitle: {
    color: '#166ed5',
    cursor: 'pointer',
    fontSize: '16px',
    maxWidth: '300px'
  },
  rowData: {
    maxWidth: '120px'
  },
  errorRow: {
    borderColor: 'red',
    borderStyle: 'solid'
  },
  redColor: {
    color: '#F62227'
  },
  headerBorder: {
    borderTop: '1px solid rgba(224, 224, 224, 1)'
  },
  rowChecked: {
    backgroundColor: '#E7F0FA'
  }
}));

const ListView = ({
  flipSort = false,
  defaultColumnSort,
  paginationUpdateFunc = () => {},
  columnSortUpdateFunc = () => {},
  getLengthObject = {}, // contains a function and parameters to pass to it, such that max # rows can be computed by caller
  aPrioriPage = -1,
  shouldUseDash = false, // leaves null fields as blank
  shouldResetPageOnLoad = true,
  headerStructureList = [],
  dataList = [],
  onboarding,
  handleClick,
  handleDelete,
  onHoverRenderCondition,
  onHoverComponent,
  emptyListText = 'No Items',
  enablePagination = true,
  headerStyle,
  multiSelectEnabled,
  numOfItemsPerPage,
  onMultiClick
}) => {
  const getDefaultSortOrder = (headerStructureList) => {
    // Maintain backwards compatibility
    let sortKey;
    let sortDirection = 'asc';
    if (headerStructureList) {
      // Default to the first entry in case there is no default defined
      sortKey = headerStructureList[0]?.id;
      // Look for a header marked as the default for sorting
      headerStructureList.forEach((header) => {
        if (header.default) {
          sortKey = header.id;
          if (header.desc) {
            sortDirection = 'desc';
          } else {
            sortDirection = 'asc';
          }
        }
      });
    }
    return {
      key: sortKey,
      direction: sortDirection
    };
  };

  const alternateDefaultSort = getDefaultSortOrder(headerStructureList);
  const [totalNumRows, setTotalNumRows] = useState(0);
  const classes = useStyles();
  const [order, setOrder] = useState(
    defaultColumnSort?.direction || alternateDefaultSort?.direction
  );
  const [orderBy, setOrderBy] = useState(defaultColumnSort?.key || alternateDefaultSort?.key);
  const [page, setPage] = useState(0);
  const [itemHover, setItemHover] = useState('');
  const [stateDataList, setDataList] = useState(dataList);
  const [rowPerPageOptions, setRowPerPageOptions] = useState(DefaultRowPerPageOptions);
  const [rowsPerPage, setRowsPerPage] = useState(
    DefaultRowPerPageOptions[Math.floor(DefaultRowPerPageOptions.length / 2)]
  );

  useEffect(() => {
    if (numOfItemsPerPage) {
      if (rowPerPageOptions.indexOf(numOfItemsPerPage) === -1) {
        const newRowPerPageOptions = [...rowPerPageOptions, numOfItemsPerPage];
        newRowPerPageOptions.sort((a, b) => {
          return a - b;
        });
        setRowPerPageOptions(newRowPerPageOptions);
      }

      setRowsPerPage(numOfItemsPerPage);
    }
    if (defaultColumnSort?.key && defaultColumnSort?.direction) {
      setOrderBy(defaultColumnSort?.key);
      if (defaultColumnSort.direction === 'decr') {
        setOrder('desc');
        columnSortUpdateFunc(defaultColumnSort.key, defaultColumnSort.direction);
      }
      if (defaultColumnSort.direction === 'incr') {
        setOrder('asc');
        columnSortUpdateFunc(defaultColumnSort.key, defaultColumnSort.direction);
      }
    }

    if (aPrioriPage !== -1) {
      setPage(aPrioriPage);
    }

    if (Object.keys(getLengthObject).length) {
      const func = getLengthObject?.['function'];
      const param0 = getLengthObject?.['param0'];
      const param1 = getLengthObject?.['param1'];
      func(param0).then((res) => {
        let tot = 0;
        const posForVendorByStatus = res.partners[0]?.associatedWithPurchaseOrder;
        posForVendorByStatus.forEach((poStatusWithCount) => {
          if (param1.indexOf(poStatusWithCount?.order_status) !== -1) {
            tot += poStatusWithCount?.count;
          }
        });
        setTotalNumRows(tot);
      });
    }
  }, []);

  useEffect(() => {
    if (!defaultColumnSort) {
      const defaultSort = getDefaultSortOrder(headerStructureList);
      if (defaultSort.key) {
        setOrderBy(defaultSort.key);
        setOrder(defaultSort.direction);
      }
    }
  }, [headerStructureList]);

  if (stateDataList !== dataList) {
    setDataList(dataList);
    if (shouldResetPageOnLoad) {
      setPage(0);
    } else if (aPrioriPage > -1 && page !== aPrioriPage) {
      setPage(aPrioriPage);
    }
    if (stateDataList?.length > rowsPerPage && dataList?.length < rowsPerPage) {
      setPage(0);
      paginationUpdateFunc(0, rowsPerPage);
    }
  }

  const desc = (a, b, order_by) => {
    if (Array.isArray(a[order_by]) || Array.isArray(b[order_by])) {
      const statusLevelCalculator = (id) => {
        switch (id) {
          case 'delayed':
          case 'missing':
          case 'eolCritical':
          case 'refreshCritical':
          case 'critical':
          case 'time-sensitive':
            return 12;
          case 'overtime':
            return 8;
          case 'eolWarning':
          case 'refreshWarning':
          case 'expedited':
          case 'expiringSoon':
          case 'warning':
          case 'hot':
          case 'rework':
            return 2;
          case 'on-time':
          case 'healthy':
            return 1;
          default:
            return 0;
        }
      };
      let aLevel = 0;
      let bLevel = 0;
      a[order_by].forEach((each) => {
        aLevel += statusLevelCalculator(each.id);
      });
      b[order_by].forEach((each) => {
        bLevel += statusLevelCalculator(each.id);
      });

      return aLevel < bLevel ? 1 : -1;
    }

    if (
      a[order_by] &&
      b[order_by] &&
      (a[order_by].constructor === Object || b[order_by].constructor === Object)
    ) {
      return a[order_by].value < b[order_by].value ? 1 : -1;
    }

    if (isNaN(a[order_by]) || isNaN(b[order_by])) {
      if (flipSort) {
        return (flipSort ? -1 : 1) * (-(a[order_by] < b[order_by]) || +(a[order_by] > b[order_by]));
      }
      return String(a[order_by] || '').localeCompare(b[order_by] || '', 'en', {
        numeric: true,
        sensitivity: 'base'
      });
    }

    let metadata = {};
    (headerStructureList || []).forEach((headerStructure) => {
      if (headerStructure?.id === order_by) {
        metadata = headerStructure;
      }
    });
    if (metadata?.type === 'string') {
      return (
        (flipSort ? -1 : 1) *
        String(a?.[order_by] || '').localeCompare(String(b?.[order_by] || ''), undefined, {
          numeric: false,
          sensitivity: 'base'
        })
      );
    }

    return Number(b[order_by]) - Number(a[order_by]);
  };

  const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const or = cmp(a[0], b[0]);
      if (or !== 0) {
        return or;
      }
      return a[1] - b[1];
    });
    const x = stabilizedThis.map((el) => el[0]);
    return x;
  };

  const getSorting = (or) => {
    return or === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => desc(b, a, orderBy);
  };
  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
    columnSortUpdateFunc(property, isDesc ? 'incr' : 'decr');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    paginationUpdateFunc(newPage * rowsPerPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    paginationUpdateFunc(0, newRowsPerPage);
  };

  const pageData = useMemo(() => {
    return stableSort(stateDataList, getSorting(order)).slice(
      enablePagination ? page * rowsPerPage : 0,
      enablePagination ? page * rowsPerPage + rowsPerPage : stateDataList.length
    );
  }, [stateDataList, rowsPerPage, page, order]);

  if (!stateDataList.length) {
    return (
      <div
        style={{
          padding: '70px',
          color: 'gray',
          textAlign: 'center'
        }}
      >
        {emptyListText}
      </div>
    );
  }

  const getIsHeaderChecked = () => {
    let isChecked = true;
    pageData.forEach((row) => {
      if (!row.isChecked) {
        isChecked = false;
      }
    });
    return isChecked;
  };

  const onHeaderMultiClick = (value) => {
    onMultiClick(
      pageData.map((data) => data.id),
      value
    );
  };

  const getRowClass = (row) => {
    if (row.error) {
      return {
        root: classes.errorRow
      };
    } else if (row.isChecked) {
      return {
        root: classes.rowChecked
      };
    }
  };

  return (
    <div style={{ padding: '10px' }}>
      <div className={onboarding ? undefined : classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            headerStyle={headerStyle}
            onRequestSort={handleRequestSort}
            hoverAvailable={onHoverRenderCondition && true}
            headerStructureList={headerStructureList}
            multiSelectEnabled={multiSelectEnabled}
            onHeaderMultiClick={onHeaderMultiClick}
            isHeaderChecked={getIsHeaderChecked()}
          />
          <TableBody>
            {pageData.map((row, i) => {
              const rowKey = onboarding ? row.identifier : row.key || row.id;
              return (
                <TableRow
                  hover={!row.isChecked}
                  tabIndex={-1}
                  key={rowKey}
                  onMouseOver={(e) => {
                    setItemHover(row.id);
                  }}
                  onMouseLeave={(e) => {
                    setItemHover('');
                  }}
                  classes={getRowClass(row)}
                >
                  {multiSelectEnabled && (
                    <TableCell
                      key={`row ${i} col checkbox pag ${page}s`}
                      id={`${row.id} checkbox`}
                      scope="row"
                      padding="normal"
                      align="left"
                      classes={{ root: classes.rowData }}
                    >
                      <Checkbox
                        onClick={() => {
                          onMultiClick([row.id], !row.isChecked);
                        }}
                        isChecked={row.isChecked}
                      />
                    </TableCell>
                  )}
                  {headerStructureList.map((eachHeader, j) => {
                    let value = row[eachHeader.id];
                    if (value === undefined) {
                      value = '';
                    } else if (value === null) {
                      value = shouldUseDash ? '-' : '';
                    }
                    return (
                      <TableCell
                        key={`row ${i} col ${j} pag ${page}`}
                        onClick={
                          eachHeader.title &&
                          ((event) => {
                            return handleClick?.({ ...event, ...row });
                          })
                        }
                        name={eachHeader.id}
                        id={`${row.id} ${eachHeader.id} ${j} ${row.orderId}`}
                        scope="row"
                        padding="normal"
                        classes={{ root: eachHeader.title ? classes.rowTitle : classes.rowData }}
                        align="left"
                      >
                        {eachHeader.renderComponent
                          ? eachHeader.renderComponent(value, row.id)
                          : value}
                      </TableCell>
                    );
                  })}
                  {handleDelete && (
                    <TableCell
                      key={`${rowKey} Delete`}
                      onClick={() => handleDelete(row)}
                      scope="row"
                      padding="normal"
                      classes={{ root: classes.rowTitle }}
                      align="left"
                    >
                      <DeleteIcon className={classes.redColor} />
                    </TableCell>
                  )}
                  {row.errorMessage && (
                    <TableCell
                      key={`${rowKey} Error`}
                      scope="row"
                      padding="normal"
                      classes={{ root: classes.redColor }}
                      align="left"
                    >
                      <div>{row.errorMessage}</div>
                    </TableCell>
                  )}
                  {onHoverRenderCondition && (
                    <TableCell>{onHoverComponent(itemHover, row)}</TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      {enablePagination && (
        <TablePagination
          rowsPerPageOptions={rowPerPageOptions}
          component="div"
          count={totalNumRows ? totalNumRows : stateDataList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page'
          }}
          nextIconButtonProps={{
            'aria-label': 'next page'
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
};

export default ListView;
