import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import Style from './pagination.module.css';

const Pagination = ({ currentPage, pageCount, onClick }) => {
  return (
    <ReactPaginate
      previousLabel="<"
      nextLabel=">"
      breakLabel="...."
      pageCount={pageCount < 1 ? 1 : pageCount}
      forcePage={currentPage - 1 < 0 ? 0 : currentPage - 1}
      marginPagesDisplayed={1}
      pageRangeDisplayed={5}
      onPageChange={(event) => onClick(event.selected + 1)}
      containerClassName={Style.pagination}
      subContainerClassName={Style.pagination}
      pageClassName={Style.page}
      activeClassName={Style.active}
      breakClassName={Style.break}
      previousClassName={Style.page}
      nextClassName={Style.page}
      breakLinkClassName={Style.bold_text}
    />
  );
};

export default Pagination;

Pagination.defaultProps = {
  currentPage: 1,
  pageCount: 1,
  onClick: () => {}
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  pageCount: PropTypes.number,
  onClick: PropTypes.func
};
