import React from 'react';
import PropTypes from 'prop-types';
import { Selector } from './Selector';
import Style from './AddInventoryV2Components.module.css';

export const InfoCard = ({ info }) => (
  <div className={Style.info_card}>
    {Object.keys(info).map((prop) => {
      if (info[prop].hiddenOnInfoCard) {
        return;
      }
      return (
        <div className={Style.info_card_row}>
          <div className={Style.info_card_row_label_container}>
            <p className={Style.info_card_row_label}>{`${info[prop].label}:`}</p>
          </div>
          <div className={Style.info_card_row_value_container}>
            {info[prop].statusColor && (
              <div className={`${Style.status_icon} ${Style[info[prop].statusColor]}`} />
            )}
            <p className={Style.info_card_row_value}>{info[prop].value || '-'}</p>
          </div>
        </div>
      );
    })}
  </div>
);

InfoCard.propTypes = {
  info: PropTypes.object.isRequired
};

export default InfoCard;
