import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Div } from '../../../../../components/div';
import { XButton } from '../../../../../components/xemelgo-button';
import './style.css';

const AddSignBadge = () => {
  return (
    <Div className="add-sign">
      <Div className="add-sign-badge">+</Div>
    </Div>
  );
};

export const LocationGroupHeader = ({ modelId, groupName, resourceCount, onAddClick }) => {
  const [resourceCountLabel, setResourceCountLabel] = useState('0 resource');

  useEffect(() => {
    if (resourceCount !== 1) {
      setResourceCountLabel(`${resourceCount} resources`);
    } else {
      setResourceCountLabel(`${resourceCount} resource`);
    }
  }, [resourceCount]);

  const onClickCallback = (event) => {
    if (!event) {
      return;
    }

    event.stopPropagation();
    onAddClick(modelId);
  };

  return (
    <Div className="group-header">
      <Div className="group-header-label">
        <Div className="group-name">{groupName}</Div>
        <Div className="resource-count">{resourceCountLabel}</Div>
      </Div>
      <Div className="group-operation">
        <XButton label={<AddSignBadge />} onClick={onClickCallback} />
      </Div>
    </Div>
  );
};

LocationGroupHeader.defaultProps = {
  resourceCount: 0,
  onAddClick: () => {}
};

LocationGroupHeader.propTypes = {
  groupName: PropTypes.string.isRequired,
  modelId: PropTypes.string.isRequired,
  resourceCount: PropTypes.number,
  onAddClick: PropTypes.func
};
