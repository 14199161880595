import React, { PureComponent, Fragment } from 'react';
import Style from './css/DataTable.module.css';
import { getFormattedDate, msToTime } from '../../common/Utilities';

export default class TimeTrckingDataTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openKeys: []
    };
  }

  renderColumnTitle = (titleList) => {
    return (
      <div className={Style.tracking_links_header}>
        {titleList.map((eachTitle) => (
          <div className={Style.time_detail} key={eachTitle.key}>
            {eachTitle.value}
          </div>
        ))}
        <div className={Style.time_detail} />
      </div>
    );
  };

  renderRowData = (rowData, titleList) => {
    const { openKeys } = this.state;
    const open = openKeys.findIndex(
      (eachKey) => eachKey === `${rowData.location}${rowData.entryTime}`
    );
    return (
      <Fragment key={`${rowData.location}${rowData.entryTime}`}>
        <td className={Style.tracking_department_link_row}>
          {titleList.map((eachTitle) => {
            return (
              <div key={eachTitle.key} className={Style.time_duration}>
                {rowData[eachTitle.key]
                  ? eachTitle.type === 'time'
                    ? msToTime(rowData[eachTitle.key])
                    : rowData[eachTitle.key]
                  : '--'}
              </div>
            );
          })}
          {rowData.jobs && rowData.jobs.length ? (
            <div
              className={`${Style.time_duration} ${Style.show_button} `}
              onClick={() => {
                if (open !== -1) {
                  openKeys.splice(open, 1);
                  this.setState({ openKeys: [...openKeys] });
                } else {
                  this.setState({
                    openKeys: openKeys.concat(`${rowData.location}${rowData.entryTime}`)
                  });
                }
              }}
            >
              {open ? 'Show Details' : 'Hide Details'}
            </div>
          ) : (
            <div className={`${Style.time_duration} ${Style.show_button} `} />
          )}
        </td>
        {open !== -1 && (
          <div className={Style.time_tracking_table}>
            <div className={Style.time_tracking_header_row}>
              <div className={Style.time_duration}>Worker Name</div>
              <div className={Style.time_duration}>Status</div>
              <div className={Style.time_duration}>Start Time</div>
              <div className={Style.time_duration}>End Time</div>
              <div className={Style.time_duration}>Process Time</div>
            </div>
            {rowData.jobs
              ? rowData.jobs.map((job) => {
                  let color = '';
                  if (job.state === 'working') {
                    color = '#4B9910';
                  } else if (job.state === 'paused') {
                    color = '#F6B045';
                  } else {
                    color = '#116ED5';
                  }
                  return (
                    <div
                      key={job.id}
                      className={Style.time_tracking_data_row}
                      style={{ borderLeft: `4px solid ${color}` }}
                    >
                      <div className={Style.time_duration}>
                        {`${job.given_name} ${job.family_name}`}
                      </div>
                      <div className={Style.time_duration}>
                        <div
                          className={Style.time_tracking_status}
                          style={{ backgroundColor: color }}
                        >
                          {job.state === 'working'
                            ? 'In Progess'
                            : job.state === 'paused'
                            ? 'Paused'
                            : 'Stopped'}
                        </div>
                      </div>
                      <div className={Style.time_duration}>
                        {getFormattedDate(job.startTime, 'hh:mm A MMM DD')}
                      </div>
                      <div className={Style.time_duration}>
                        {job.state === 'paused' || !job.endTime
                          ? '--'
                          : getFormattedDate(job.endTime, 'hh:mm A MMM DD')}
                      </div>
                      <div className={Style.time_duration}>
                        {job.duration ? msToTime(job.duration) : '--'}
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        )}
      </Fragment>
    );
  };

  render() {
    const { dataList = [], titleList = [] } = this.props;

    return (
      <div className={Style.tracking_departments_links}>
        {this.renderColumnTitle(titleList)}
        <div className={Style.tracking_departments_links_detail}>
          {dataList.map((rowData) => {
            return this.renderRowData(rowData, titleList);
          })}
        </div>
      </div>
    );
  }
}
