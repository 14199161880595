import React, { useEffect, Fragment } from 'react';
import ItemTypeDetailScreen from 'screens/ItemTypeDetailScreen';
import { Route } from 'react-router-dom';
import BOMManagementFeature from '../features/bom-management-feature/BOMManagementFeature';
import { BOMManagementContextProvider } from '../context/BOMManagementContext/BOMManagementContext';

const ItemTypeDetailStackNavigation = () => {
  return (
    <Fragment>
      <Route
        path="/item-types-management/detail/BOM"
        children={({ match }) => {
          return (
            <div style={!match?.isExact ? { display: 'none' } : { height: '100%' }}>
              <BOMManagementFeature />
            </div>
          );
        }}
      />
    </Fragment>
  );
};

export default () => {
  return (
    <BOMManagementContextProvider>
      <ItemTypeDetailScreen>
        <ItemTypeDetailStackNavigation />
      </ItemTypeDetailScreen>
    </BOMManagementContextProvider>
  );
};
