import React from 'react';
import queryString from 'query-string';
import { Modal } from 'react-bootstrap';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextareaAutosize from 'react-textarea-autosize';
import { DropdownItem } from 'mdbreact';
import { XemelgoService } from '../../../services/XemelgoService';
import ConfigurationService from '../../../services/ConfigurationService';
import LoadingCircle from '../../loading/LoadingCircle';
import Style from './css/AssetDetailPage.module.css';
import DetailCard from '../DetailCard';
import DataTable from '../DataTable';
import NotFoundComponent from '../../not-found-component/NotFoundComponent';
import './css/asset-detail-page.css';

import {
  getFormattedDate,
  msToTime,
  getStatusFlags,
  getAttributeList
} from '../../../common/Utilities';
import { getLocations } from '../../../services/location-service';
import { getLatestLocationFromRoute } from './get-latest-location-from-route';
import { AssetOnMap } from './asset-on-map';

const defaultShipmentIssueOptionsList = [
  {
    id: 'broken',
    displayText: 'Broken Part(s)'
  }
];

const customerSpecificShipmentIssueOptionsList = [
  {
    id: 'damaged',
    displayText: 'Fallout'
  }
];

export default class AssetDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      titleStructure: {},
      contentStructureList: [],
      assetState: {},
      route: [],
      locations: [],
      markAsFoundOptions: [],
      routeTitleList: [],
      itemId: '',
      addLocation: false,
      locationIdToAdd: '',
      commentBoxText: '',
      loading: true,
      showShipmentIssueModal: false,
      selectedShipmentIssue: null,
      isSubmitting: false
    };

    this.renderDeleteAssetModal = this.renderDeleteAssetModal.bind(this);
  }

  componentDidMount() {
    const values = queryString.parse(window.location.search);
    const { itemId } = values;
    this.onLoad(itemId);
  }

  onLoad = async (itemId) => {
    const AssetClient = XemelgoService.getClient().getAssetClient();
    const LocationClient = XemelgoService.getClient().getLocationClient();
    const solutionType = await ConfigurationService.getSolutionType();
    const solutionConfiguration = await ConfigurationService.getSolutionConfiguration();
    const availableFlags = (await ConfigurationService.getStatusConfiguration('asset')) || {};
    const attributeMap = (await ConfigurationService.getSolutionAttributeMap('asset')) || {};
    const { defaultAttributeMap } = attributeMap;
    const shipmentsAppConfiguration = solutionConfiguration.uiPreferences.shipments;
    const possibleDetectorLocations = await ConfigurationService.getDetectorLocationCategoriesForSolution(
      'asset'
    );

    const showShipmentData =
      solutionType.includes('shipments') &&
      shipmentsAppConfiguration.shipmentsTrackingItemClasses.includes('Asset');
    const result =
      (await AssetClient.getAssetDetailsById(itemId, showShipmentData, defaultAttributeMap)) || {};
    const locations = await getLocations(LocationClient, possibleDetectorLocations);
    const { item = {}, shipmentHistory = [] } = result;
    const { is_active, route = [], status_flags = [], identifier, data = {} } = item;
    const { is_missing } = data;
    const assetState = { ...item, is_missing };
    const assetAttributeList = getAttributeList(attributeMap, item);
    const latestLocationRoute = getLatestLocationFromRoute(route, locations);

    const titleStructure = {
      key: assetAttributeList[0].id,
      name: assetAttributeList[0].name,
      value: assetAttributeList[0].value,
      editable: assetAttributeList[0].editable
    };
    const contentStructureList = assetAttributeList.slice(1);
    is_active &&
      contentStructureList.push({
        key: 'markAsMissingOrFound',
        name: is_missing ? 'Mark As Found' : 'Mark As Missing',
        type: 'button',
        onClick: () => {
          this.setState({ showMissingOrFoundModal: true });
        }
      });

    const routeTitleList = [
      {
        key: 'location',
        value: 'Location'
      },
      {
        key: 'entryTime',
        value: 'Entry Time'
      },
      { key: 'exitTime', value: 'Exit Time' },
      { key: 'timeDuration', value: 'Time Duration' }
    ];
    const shipmentTitleList = [
      {
        key: 'shipmentLocation',
        value: 'Location'
      },
      {
        key: 'eventTime',
        value: 'Event Time'
      },
      { key: 'eventDetails', value: 'Event Details', extraData: true },
      { key: 'detector', value: 'Detector' },
      { key: 'shipmentTimeDuration', value: 'Time Duration' }
    ];

    route
      .sort((a, b) => b.entryTime - a.entryTime)
      .forEach((each) => {
        const duration = each.exitTime
          ? msToTime(each.exitTime - each.entryTime)
          : msToTime(Date.now() - each.entryTime);

        each.timeDuration = duration;
        each.entryTime = getFormattedDate(each.entryTime, 'hh:mm A MMM D');
        each.exitTime = each.exitTime ? getFormattedDate(each.exitTime, 'hh:mm A MMM D') : '-';
      });

    if (status_flags.length > 0) {
      assetState.statusFlags = getStatusFlags(status_flags, availableFlags);
    }

    const newLocations = locations.filter(
      (eachLocation) => eachLocation.name !== assetState.last_known_location
    );

    const shipmentIssueOptions = [
      ...this.getDefaultShipmentIssueOptions(),
      ...this.getCustomerSpecificShipmentIssueOptions()
    ];

    // Process event time and duration for each event
    if (showShipmentData) {
      shipmentHistory.forEach((row, index) => {
        const currEvent = row.eventTime;
        const prevEvent = shipmentHistory[index + 1] && shipmentHistory[index + 1].eventTime;
        row.shipmentTimeDuration = msToTime((prevEvent || Date.now()) - currEvent);
        row.eventTime = getFormattedDate(row.eventTime, 'hh:mm A MMM D');
        const { condition, comment, submittedBy } = row.eventDetails;
        if (condition || comment || submittedBy) {
          row.extraData = [
            { key: 'condition', value: 'Condition', data: condition },
            { key: 'comment', value: 'Comment', data: comment },
            { key: 'submittedBy', value: 'Submitted By', data: submittedBy }
          ];
        }
        row.eventDetails = row.eventDetails.class;
      });
    }

    this.setState({
      itemId,
      assetState,
      showShipmentData,
      route,
      shipmentHistory,
      routeTitleList,
      shipmentTitleList,
      titleStructure,
      contentStructureList,
      shipmentIssueOptions,
      latestLocation: latestLocationRoute,
      markAsFoundOptions: [
        ...locations,
        { id: 'untrackedLocation', name: 'Other (Untracked Location)' }
      ],
      locations: newLocations,
      addLocation: false,
      locationIdToAdd: '',
      showDeleteAssetModal: false,
      loading: false,
      isSubmitting: false
    });
  };

  renderDeleteAssetModal = () => {
    const { showDeleteAssetModal, assetState } = this.state;

    return (
      <Modal
        centered
        backdrop="static"
        backdropClassName={Style.backdrop}
        show={showDeleteAssetModal}
      >
        <Modal.Header className="route-modal-header">
          <Modal.Title className="route-modal-title">
            {`Delete Asset ${assetState.identifier}?`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Deleting an asset would permanently remove the asset from Xemelgo.</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="cancel-button"
            onClick={() => {
              this.setState({ showDeleteAssetModal: false });
            }}
          >
            No, Keep Asset
          </button>
          <button className="confirm-delete-button" onClick={this.deleteAsset}>
            Yes, Delete Asset
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  handleCommentBox = ({ currentTarget }) => {
    this.setState({ [currentTarget.id]: currentTarget.value });
  };

  renderMarkAsMissingOrFoundModal = () => {
    const {
      showMissingOrFoundModal,
      assetState,
      locationIdToAdd,
      markAsFoundOptions,
      commentBoxText
    } = this.state;

    return (
      <Modal
        centered
        backdrop="static"
        backdropClassName={Style.backdrop}
        show={showMissingOrFoundModal}
      >
        <Modal.Header className="route-modal-header">
          <Modal.Title className="route-modal-title">
            {`Mark ${assetState.identifier} As ${assetState.is_missing ? 'Found' : 'Missing'}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!assetState.is_missing && (
            <p>
              You're about to mark
              <b style={{ fontWeight: 'bold' }}>{` ${assetState.identifier} `}</b>
              as Missing, are you sure you want to do this?
            </p>
          )}

          {assetState.is_missing && (
            <div>
              <InputLabel>{`Select location where ${assetState.identifier} was found`}</InputLabel>
              <Select
                variant="outlined"
                className={Style.location_dropdown}
                value={locationIdToAdd}
                onChange={(event) => {
                  this.setState({
                    locationIdToAdd: event.target.value
                  });
                }}
              >
                {markAsFoundOptions.map((each) => {
                  return (
                    <MenuItem key={each.id} value={each.id}>
                      {each.name}
                    </MenuItem>
                  );
                })}
              </Select>
              {locationIdToAdd === 'untrackedLocation' && (
                <div className={Style.commentBoxContainer}>
                  <InputLabel>Specify where asset was found (Optional)</InputLabel>
                  <TextareaAutosize
                    id="commentBoxText"
                    className={Style.detail_value_input}
                    value={commentBoxText}
                    onChange={this.handleCommentBox}
                  />
                </div>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="cancel-button"
            onClick={() => {
              this.setState({
                showMissingOrFoundModal: false,
                locationIdToAdd: '',
                commentBoxText: ''
              });
            }}
          >
            Cancel
          </button>
          <button className="confirm-delete-button" onClick={this.markAsMissingOrFound}>
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  markAsMissingOrFound = async () => {
    const { itemId, locationIdToAdd, commentBoxText, assetState, markAsFoundOptions } = this.state;
    const selectedLocation = markAsFoundOptions.find((option) => option.id === locationIdToAdd);

    const contextPayload = {
      missing: {
        itemIdentifier: assetState.identifier,
        value: !assetState.is_missing,
        comment: commentBoxText,
        lastSeenLocation: assetState.is_missing
          ? selectedLocation.name
          : assetState.data.last_known_location
      }
    };

    if (!assetState.is_missing) {
      contextPayload.missing.flipConditions = 'ManualOrAnyDetectionAtLocation';
    }

    const AssetClient = XemelgoService.getClient().getAssetClient();

    try {
      await AssetClient.markAssetAsMissingOrFound(
        itemId,
        locationIdToAdd === 'untrackedLocation' ? null : locationIdToAdd,
        contextPayload
      );
    } catch (e) {
      window.alert(e);
    } finally {
      this.setState({
        commentBoxText: '',
        showMissingOrFoundModal: false
      });
      await this.onLoad(itemId);
    }
  };

  deleteAsset = async () => {
    const { itemId } = this.state;
    const AssetClient = XemelgoService.getClient().getAssetClient();

    try {
      await AssetClient.deleteAsset(itemId);
    } catch (e) {
      console.log(e);
    } finally {
      window.history.back();
    }
  };

  renderAddLocationModal = (addLocation) => {
    const { locations, locationIdToAdd, isSubmitting } = this.state;

    return (
      <Modal centered show={addLocation} backdrop="static" backdropClassName={Style.backdrop}>
        <Modal.Header>
          <Modal.Title>Add Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputLabel>Select a location</InputLabel>
          <Select
            variant="outlined"
            className={Style.location_dropdown}
            value={locationIdToAdd}
            onChange={(event) => {
              this.setState({
                locationIdToAdd: event.target.value
              });
            }}
          >
            {locations.map((each) => {
              return (
                <MenuItem key={each.id} value={each.id}>
                  {each.name}
                </MenuItem>
              );
            })}
          </Select>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className={`${Style.button} ${Style.cancel_button}`}
            onClick={() =>
              this.setState({
                locationIdToAdd: '',
                addLocation: !addLocation
              })
            }
          >
            Cancel
          </button>
          <button
            disabled={isSubmitting || !locationIdToAdd}
            type="button"
            className={`${Style.button} ${Style.save_button}`}
            onClick={this.handleAddLocation}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  handleAddLocation = async () => {
    const { itemId, locationIdToAdd } = this.state;
    const publishClient = XemelgoService.getClient().getPublishClient();
    try {
      this.setState({ isSubmitting: true });
      await publishClient.publishUserEvent([itemId], locationIdToAdd);
    } catch (e) {
      console.log(e);
    } finally {
      await this.onLoad(itemId);
    }
  };

  handleSubmitDetail = async (dataForm, event) => {
    const { itemId } = this.state;
    const { identifier, expiry_date, refresh_date } = dataForm;
    const AssetClient = XemelgoService.getClient().getAssetClient();
    try {
      await AssetClient.updateAsset(
        itemId,
        identifier || undefined,
        expiry_date,
        refresh_date,
        dataForm.imagePath || undefined
      );
    } catch (e) {
      window.alert(e);
    } finally {
      await this.onLoad(itemId);
    }
  };

  getAdditionalMoreButtonOptions = () => {
    return (
      <DropdownItem
        className="dropdown-item"
        onClick={() => {
          this.setState({ showDeleteAssetModal: true });
        }}
      >
        <div className={Style.red_text}>Delete Asset</div>
      </DropdownItem>
    );
  };

  getDefaultShipmentIssueOptions = () => {
    return defaultShipmentIssueOptionsList;
  };

  getCustomerSpecificShipmentIssueOptions = () => {
    return customerSpecificShipmentIssueOptionsList;
  };

  reportShipmentIssue = async () => {
    const { itemId, selectedShipmentIssue, commentBoxText } = this.state;
    const context = {
      shipment: {
        value: 'Shipment Issue',
        condition: selectedShipmentIssue,
        comment: commentBoxText || ''
      }
    };

    const publishClient = XemelgoService.getClient().getPublishClient();
    try {
      this.setState({ isSubmitting: true });
      await publishClient.publishUserEvent([itemId], null, context);
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({
        showShipmentIssueModal: false,
        selectedShipmentIssue: null,
        commentBoxText: ''
      });
      await this.onLoad(itemId);
    }
  };

  renderShipmentIssueModal = () => {
    const {
      showShipmentIssueModal,
      assetState,
      shipmentIssueOptions,
      selectedShipmentIssue,
      commentBoxText,
      isSubmitting
    } = this.state;

    return (
      <Modal
        centered
        backdrop="static"
        backdropClassName={Style.backdrop}
        show={showShipmentIssueModal}
      >
        <Modal.Header className="route-modal-header">
          <Modal.Title className="route-modal-title">
            {`Report Issue With Shipment for ${assetState.identifier}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <InputLabel>What is wrong with the shipment?</InputLabel>
            <Select
              variant="outlined"
              className={Style.location_dropdown}
              value={selectedShipmentIssue}
              onChange={(event) => {
                this.setState({
                  selectedShipmentIssue: event.target.value
                });
              }}
            >
              {shipmentIssueOptions.map((each) => {
                return (
                  <MenuItem key={each.id} value={each.displayText}>
                    {each.displayText}
                  </MenuItem>
                );
              })}
            </Select>
            <div className={Style.commentBoxContainer}>
              <InputLabel>Comment (Optional)</InputLabel>
              <TextareaAutosize
                id="commentBoxText"
                className={Style.detail_value_input}
                value={commentBoxText}
                onChange={this.handleCommentBox}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="cancel-button"
            onClick={() => {
              this.setState({
                showShipmentIssueModal: false,
                selectedShipmentIssue: null,
                commentBoxText: ''
              });
            }}
          >
            Cancel
          </button>
          <button
            disabled={!selectedShipmentIssue || isSubmitting}
            className="confirm-delete-button"
            onClick={this.reportShipmentIssue}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  render() {
    const {
      titleStructure,
      contentStructureList,
      assetState,
      showShipmentData,
      route,
      shipmentHistory,
      routeTitleList,
      shipmentTitleList,
      addLocation,
      showShipmentIssueModal,
      loading,
      showMap,
      latestLocation
    } = this.state;

    if (loading) {
      return <LoadingCircle />;
    }

    if (!assetState?.id) {
      return (
        <NotFoundComponent
          path="/asset"
          buttonLabel="Go To Track Page"
          mainText="Sorry, the asset you have requested has been deleted."
          subText="Please click the following button to go back to track page."
        />
      );
    }

    return (
      <>
        <div className={Style.content_holder}>
          <div className={Style.detail_group}>
            <DetailCard
              imagePath={assetState.imagePath}
              isActive={assetState.is_active}
              statusList={assetState.statusFlags}
              titleStructure={titleStructure}
              detailStructureList={contentStructureList}
              onSubmit={this.handleSubmitDetail}
              getAdditionalMoreButtonOptions={this.getAdditionalMoreButtonOptions}
            />
          </div>
          <div className={Style.table_group}>
            <div className={Style.top_table}>
              <div className={Style.title_group}>
                <div className={Style.title_label}>
                  Asset route
                  {latestLocation && (
                    <button
                      type="button"
                      className="toggle-floor-plan-button"
                      onClick={() => this.setState((prevStat) => ({ showMap: !prevStat.showMap }))}
                    >
                      {showMap ? 'Hide Map' : 'Show Map'}
                    </button>
                  )}
                </div>
                {assetState.is_active && (
                  <div
                    role="button"
                    tabIndex={-1}
                    onClick={() => this.setState({ addLocation: !addLocation })}
                    className={Style.part_edit_btn}
                  >
                    Add Location
                  </div>
                )}
              </div>
              {!showMap ? (
                <DataTable titleList={routeTitleList} dataList={route} />
              ) : (
                <AssetOnMap location={latestLocation} />
              )}
            </div>
            {showShipmentData && (
              <div className={Style.bottom_table}>
                <div className={Style.title_group}>
                  <div className={Style.title_label}>Shipping History</div>
                  {assetState.is_active && (
                    <div
                      role="button"
                      tabIndex={-1}
                      onClick={() => {
                        this.setState({ showShipmentIssueModal: !showShipmentIssueModal });
                      }}
                      className={Style.part_edit_btn}
                    >
                      Issue with Shipment
                    </div>
                  )}
                </div>
                <DataTable titleList={shipmentTitleList} dataList={shipmentHistory} />
              </div>
            )}
          </div>
        </div>
        {this.renderAddLocationModal(addLocation)}
        {this.renderMarkAsMissingOrFoundModal()}
        {this.renderDeleteAssetModal()}
        {this.renderShipmentIssueModal()}
      </>
    );
  }
}
