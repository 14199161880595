import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TextInput from '../add-page-component/add-page-inputs/TextInput';
import TabListComponent from '../tab-list-component/TabListComponent';
import LoadingCircle from '../loading/LoadingCircle';
import Selector from './Selector';
import InfoCard from './InfoCard';
import Style from './AddInventoryV2Components.module.css';

const useTextInputStyle = makeStyles({
  root: {
    width: ({ width }) => width || '73px',
    height: '42.4px',
    border: '1.5px solid #E2E2EA',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF'
  },
  focused: {
    border: '1.5px solid #258FE0',
    borderRadius: '3px'
  }
});

const PrintContent = ({
  quantity,
  onChangeQuantity,
  printers,
  selectedPrinter,
  onSelectPrinter,
  contentLoading,
  printerError,
  inputStyle
}) => (
  <Fragment>
    <p className={Style.info_content_label}>Qty *</p>
    <div className={Style.text_input_container}>
      <TextInput
        id="qty"
        numberOnly
        placeholder="--"
        value={quantity}
        variant="standard"
        InputProps={{ classes: inputStyle, disableUnderline: true }}
        inputProps={{
          style: {
            padding: 10
          }
        }}
        onChange={(_, value) => {
          onChangeQuantity(parseInt(value));
        }}
      />
    </div>
    <p className={Style.info_content_label}>Printer *</p>
    <div className={Style.dropdown_input_container}>
      <Selector
        options={printers}
        onSelect={(_, printer) => {
          onSelectPrinter(printer);
        }}
        value={selectedPrinter?.name}
        placeholder="Select a printer"
        error={printerError}
      />
    </div>
    <div className={Style.info_card}>
      {contentLoading ? (
        <LoadingCircle shouldCenter isSmall />
      ) : (
        <InfoCard info={selectedPrinter} />
      )}
    </div>
  </Fragment>
);

const AddContent = ({
  stations,
  selectedStation,
  onSelectStation,
  tag,
  onChangeTag,
  inputStyle,
  onScanTag,
  isTagInputEditable,
  contentLoading,
  loadingMessage,
  setLoadingMessage
}) => (
  <Fragment>
    <p className={Style.info_content_label}>Station *</p>
    <div className={Style.dropdown_input_container}>
      <Selector
        options={stations}
        onSelect={(_, station) => {
          if (!station) {
            onSelectStation({});
            return;
          }
          onSelectStation(station);
        }}
        value={Object.keys(selectedStation || {}).length ? selectedStation : undefined}
        placeholder="Select a reader"
      />
    </div>
    <p className={Style.info_content_label}>Tag *</p>
    <div className={Style.add_content}>
      <div className={Style.text_input_container}>
        <TextInput
          id="tag"
          placeholder="RFID Tag #"
          value={tag}
          variant="standard"
          disabled={!isTagInputEditable}
          InputProps={{ classes: inputStyle, disableUnderline: true }}
          inputProps={{
            style: {
              padding: 10
            }
          }}
          onChange={(_, value) => {
            onChangeTag(value);
          }}
        />
      </div>
      <div
        className={Style.scan_button}
        disabled={contentLoading || !stations.length}
        onClick={() => {
          setLoadingMessage('Scanning for RFID Tag...');
          onScanTag();
        }}
      >
        Scan
      </div>
    </div>
    {contentLoading && (
      <div>
        <LoadingCircle
          shouldCenter
          isSmall
          message={loadingMessage}
          messageStyle="loading_message"
        />
      </div>
    )}
  </Fragment>
);

const AdditionalInformation = ({
  enableOnboardingToLocation,
  locations,
  selectedLocation,
  onSelectLocation,
  onboardingModeTabs,
  selectedOnboardingMode,
  quantity,
  onChangeQuantity,
  stations,
  selectedStation,
  onSelectStation,
  tag,
  onChangeTag,
  onScanTag,
  isTagInputEditable,
  printers,
  selectedPrinter,
  onSelectPrinter,
  contentLoading
}) => {
  const qtyInputStyle = useTextInputStyle();
  const tagInputStyle = useTextInputStyle({ width: '270px' });

  const [loadingMessage, setLoadingMessage] = useState('');

  const showLocationSelector = enableOnboardingToLocation && selectedOnboardingMode === 'print';
  const printerError = selectedPrinter?.message?.statusColor === 'red' || false;

  return (
    <div className={Style.additional_information_container}>
      <p className={Style.info_content_title}>Additional Information</p>
      <div>
        {showLocationSelector && (
          <div>
            <p className={Style.info_content_label}>Location</p>
            <Selector
              options={locations}
              onSelect={(_, location) => {
                onSelectLocation(location);
              }}
              value={Object.keys(selectedLocation || {}).length ? selectedLocation : undefined}
              placeholder="Select a location"
            />
          </div>
        )}
        <div className={Style.main_container}>
          <div className={Style.left_container}>
            <TabListComponent
              tabStructure={onboardingModeTabs}
              focusedTab={selectedOnboardingMode}
              tabStyle={Style.tab_style}
              tabListStyle={Style.tab_list_style}
              focusedTabStyle={Style.focused_tab_style}
            />
          </div>
          <div className={Style.right_container}>
            {selectedOnboardingMode === 'print' ? (
              <PrintContent
                quantity={quantity}
                onChangeQuantity={onChangeQuantity}
                printers={printers}
                selectedPrinter={selectedPrinter}
                onSelectPrinter={onSelectPrinter}
                contentLoading={contentLoading}
                printerError={printerError}
                inputStyle={qtyInputStyle}
              />
            ) : (
              <AddContent
                stations={stations}
                selectedStation={selectedStation}
                onSelectStation={onSelectStation}
                tag={tag}
                onChangeTag={onChangeTag}
                inputStyle={tagInputStyle}
                onScanTag={onScanTag}
                isTagInputEditable={isTagInputEditable}
                contentLoading={contentLoading}
                loadingMessage={loadingMessage}
                setLoadingMessage={setLoadingMessage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

AdditionalInformation.defaultProps = {
  enableOnboardingToLocation: false,
  selectedLocation: {},
  selectedOnboardingMode: {},
  quantity: 0,
  selectedStation: {},
  tag: '',
  selectedPrinter: {},
  contentLoading: false
};

AdditionalInformation.propTypes = {
  enableOnboardingToLocation: PropTypes.bool,
  locations: PropTypes.array.isRequired,
  selectedLocation: PropTypes.object,
  onSelectLocation: PropTypes.func.isRequired,
  onboardingModeTabs: PropTypes.array.isRequired,
  selectedOnboardingMode: PropTypes.object,
  quantity: PropTypes.number,
  onChangeQuantity: PropTypes.func.isRequired,
  stations: PropTypes.array.isRequired,
  selectedStation: PropTypes.object,
  onSelectStation: PropTypes.func.isRequired,
  tag: PropTypes.string,
  onChangeTag: PropTypes.func.isRequired,
  onScanTag: PropTypes.func.isRequired,
  isTagInputEditable: PropTypes.func.isRequired,
  printers: PropTypes.array.isRequired,
  selectedPrinter: PropTypes.object,
  onSelectPrinter: PropTypes.func.isRequired,
  contentLoading: PropTypes.bool
};

export default AdditionalInformation;
