import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import PurchaseOrderTrackPageFeature from '../../features/purchase-order-track-page-feature/PurchaseOrderTrackPageFeature';

const PurchaseOrderApp = () => {
  return (
    <Fragment>
      <Route exact path="/purchase-order" component={PurchaseOrderTrackPageFeature} />
    </Fragment>
  );
};

export default PurchaseOrderApp;
