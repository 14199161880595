import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from '../../common/Utilities';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import PurchaseOrderDetailStyle from './PurchaseOrderDetailsComponent.module.css';

export const PurchaseOrderDetailHeader = ({
  onClose,
  minimalHeader,
  title,
  orderStatusLabel,
  orderStatusColor,
  fulfillmentStatusLabel,
  fulfillmentStatusColor,
  amount,
  isCancelled,
  isNew
}) => {
  return (
    <div className={PurchaseOrderDetailStyle.header_container}>
      <div style={{ float: 'right', paddingRight: '1%' }}>
        <div
          onClick={() => {
            onClose();
          }}
          className={PurchaseOrderDetailStyle.close_icon}
        >
          <CloseRoundedIcon />
        </div>
      </div>
      <div className={PurchaseOrderDetailStyle.title_container}>
        <div className={PurchaseOrderDetailStyle.po_title_container}>
          <div className={PurchaseOrderDetailStyle.po_icon}>
            <img
              src={require('../../img/purchase-order_icon_purple.png')}
              alt="purchase-order-icon"
              width="50px"
              height="50px"
            />
          </div>
          {!minimalHeader && (
            <>
              <p className={PurchaseOrderDetailStyle.po_identifier}>
                {isNew ? 'New Purchase Order' : `PO #${title}`}
              </p>
              <div
                className={PurchaseOrderDetailStyle.status_box}
                style={{ backgroundColor: orderStatusColor }}
              >
                {orderStatusLabel}
              </div>
              <div
                className={PurchaseOrderDetailStyle.status_box}
                style={{ backgroundColor: fulfillmentStatusColor }}
              >
                {fulfillmentStatusLabel}
              </div>
            </>
          )}
        </div>
        {!minimalHeader && (
          <div className={PurchaseOrderDetailStyle.title_total}>
            {isCancelled ? 'Total Cancelled Amount' : 'Total Amount'}
            <p className={PurchaseOrderDetailStyle.header_amount}>{formatCurrency(amount)}</p>
          </div>
        )}
      </div>
    </div>
  );
};

PurchaseOrderDetailHeader.propTypes = {
  onClose: PropTypes.func,
  minimalHeader: PropTypes.bool,
  title: PropTypes.string,
  orderStatusLabel: PropTypes.string,
  orderStatusColor: PropTypes.string,
  fulfillmentStatusLabel: PropTypes.string,
  fulfillmentStatusColor: PropTypes.string,
  isCancelled: PropTypes.bool,
  isNew: PropTypes.bool,
  amount: PropTypes.number,
  currencyType: PropTypes.string
};
