import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Style from './generalizedModal.module.css';

const GeneralizedModal = ({ showModal, title, modalBodyComponent, modalFooterButtons }) => {
  return (
    <Modal centered backdrop="static" backdropClassName={Style.backdrop} show={showModal}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalBodyComponent}</Modal.Body>

      <Modal.Footer>
        {modalFooterButtons &&
          modalFooterButtons.map((footerButton) => (
            <button
              key={footerButton.id}
              className={footerButton.className}
              onClick={footerButton.onClick}
              disabled={footerButton.disabled}
            >
              {footerButton.title}
            </button>
          ))}
      </Modal.Footer>
    </Modal>
  );
};

export default GeneralizedModal;

GeneralizedModal.defaultProps = {
  title: '',
  showModal: false,
  modalBodyComponent: null,
  modalFooterButtons: [
    {
      title: 'Cancel',
      onClick: () => {},
      className: null
    }
  ]
};

GeneralizedModal.propTypes = {
  title: PropTypes.string,
  showModal: PropTypes.bool,
  modalBodyComponent: PropTypes.object,
  modalFooterButtons: PropTypes.array
};
