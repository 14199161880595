import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, Input, FormHelperText, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import TextInput from '../add-page-component/add-page-inputs/TextInput';
import EditableTableComponentV2Style from './EditableTableComponentV2.module.css';

const useInputStyle = makeStyles({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    border: '1.5px solid #E2E2EA',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    width: '70px',
    height: '27px'
  },
  focused: {
    border: '1.5px solid #166ED5',
    borderRadius: '3px'
  },
  error: {
    border: '1.5px solid #F62227',
    borderRadius: '3px'
  }
});

const useFormHelperStyle = makeStyles({
  root: {
    width: '70px'
  }
});

const useSuffixFormHelperStyle = makeStyles({
  root: {
    width: 0,
    flexGrow: 1
  }
});

const useAutoCompleteStyle = makeStyles({
  root: {
    width: '100%'
  },
  inputRoot: {
    height: '27px'
  },
  input: {
    height: '27px',
    fontSize: '0.9em'
  },
  focused: {},
  listbox: {
    width: '260px'
  },
  paper: {
    width: '260px'
  },
  popper: {
    width: '260px'
  },
  groupUl: {
    borderBottom: '2px solid #E2E2EA'
  },
  option: {
    padding: 0
  },
  popupIndicator: {
    height: '27px'
  }
});

const useAutoCompleteInputStyle = makeStyles({
  root: { height: '27px', width: '100%', border: '1.5px solid #E2E2EA', borderRadius: '3px' },
  focused: {
    height: '27px',
    width: '100%',
    border: '1.5px solid #166ED5',
    borderRadius: '3px'
  },
  error: {
    height: '27px',
    width: '100%',
    border: '1.5px solid #F62227',
    borderRadius: '3px'
  }
});

export const EditableTableComponentV2 = ({
  headerConfig,
  dataList,
  editedValueMap,
  errorMap,
  addItem,
  disableAdd
}) => {
  const inputStyle = useInputStyle();
  const formHelperStyle = useFormHelperStyle();
  const suffixFormHelperStyle = useSuffixFormHelperStyle();
  const autoCompleteStyle = useAutoCompleteStyle();
  const autoCompleteInputStyle = useAutoCompleteInputStyle();

  const renderHeader = () => {
    const headersToRender = [
      <th key="#" className={EditableTableComponentV2Style.number_header}>
        #
      </th>
    ];
    for (const header of headerConfig) {
      const { displayName, rightAlign, width } = header;
      headersToRender.push(
        <th
          key={displayName}
          className={EditableTableComponentV2Style.bold_text}
          style={{ textAlign: rightAlign && 'right', width }}
        >
          {displayName}
        </th>
      );
    }
    return headersToRender;
  };

  const renderRow = (rowData) => {
    let rowToRender = [];
    for (const cell of headerConfig) {
      const {
        id,
        editable,
        input,
        display,
        rightAlign,
        getSubText,
        getAdditionalStyles,
        onChange,
        onBlur,
        getDataList,
        renderOptions,
        labelId,
        colorId
      } = cell;

      let canEdit = editable;
      if (typeof editable === 'function') {
        canEdit = editable(rowData);
      }

      let suffix = cell.suffix;
      if (typeof suffix === 'function') {
        suffix = suffix(rowData);
      }

      const additionalStyles = getAdditionalStyles && getAdditionalStyles(rowData);

      const subText = getSubText && getSubText(rowData, editedValueMap[rowData.id]);

      const value =
        editedValueMap[rowData.id] && editedValueMap[rowData.id][id] !== undefined
          ? editedValueMap[rowData.id][id]
          : rowData[id];

      const error = errorMap[rowData.id] && errorMap[rowData.id][id];
      //render edit inputs
      if (canEdit) {
        switch (input) {
          case 'dropdown':
            rowToRender.push(
              <td key={`${id}-${rowData.id}`}>
                <div
                  style={{
                    float: rightAlign && 'right',
                    ...additionalStyles
                  }}
                  className={EditableTableComponentV2Style.text_input_container}
                >
                  <Autocomplete
                    options={getDataList && getDataList()}
                    disableClearable
                    classes={autoCompleteStyle}
                    renderInput={(params) => {
                      const { InputProps = {}, inputProps = {} } = params;
                      InputProps.disableUnderline = true;
                      InputProps.classes = autoCompleteInputStyle;
                      inputProps.style = { padding: 0 };
                      return (
                        <TextField
                          {...params}
                          InputProps={{ ...InputProps }}
                          inputProps={{ ...inputProps }}
                          error={!!error}
                          helperText={error}
                        />
                      );
                    }}
                    onChange={(event, value) => {
                      onChange(rowData.id, id, value);
                    }}
                    renderOption={renderOptions}
                    value={value || null}
                  />
                </div>
              </td>
            );
            break;
          case 'text':
            rowToRender.push(
              <td key={`${id}-${rowData.id}`}>
                <div
                  style={{
                    float: rightAlign && 'right',
                    ...additionalStyles
                  }}
                  className={EditableTableComponentV2Style.text_input_container}
                >
                  {suffix ? (
                    <FormControl error={!!error}>
                      <div style={{ display: 'inline-block' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Input
                            classes={inputStyle}
                            disableUnderline
                            id={`${rowData.id}%${id}`}
                            value={value}
                            onBlur={() => {
                              onBlur(rowData.id, id);
                            }}
                            inputProps={{
                              style: {
                                textAlign: rightAlign && 'right',
                                padding: '0.3em',
                                fontSize: '0.9em',
                                lineHeight: '27px',
                                height: '27px'
                              }
                            }}
                            onChange={(event) => {
                              onChange(rowData.id, id, event.target.value);
                            }}
                          />
                          {suffix}
                        </div>
                        <div style={{ display: 'flex' }}>
                          {error && (
                            <FormHelperText classes={suffixFormHelperStyle}>{error}</FormHelperText>
                          )}
                        </div>
                      </div>
                    </FormControl>
                  ) : (
                    <TextInput
                      id={`${rowData.id}%${id}`}
                      value={value}
                      onBlur={() => {
                        onBlur(rowData.id, id);
                      }}
                      size="small"
                      variant="standard"
                      InputProps={{ classes: inputStyle, disableUnderline: true }}
                      inputProps={{
                        style: {
                          textAlign: rightAlign && 'right',
                          padding: '0.3em',
                          fontSize: '0.9em',
                          lineHeight: '27px',
                          height: '27px'
                        }
                      }}
                      FormHelperTextProps={{
                        classes: formHelperStyle
                      }}
                      onChange={(id, value) => {
                        const splitId = id.split('%');
                        onChange(splitId[0], splitId[1], value);
                      }}
                      error={!!error}
                      errorMessage={error}
                    />
                  )}
                </div>
              </td>
            );
            break;
          default:
            break;
        }
      } else {
        if (typeof display === 'function') {
          rowToRender.push(<td key={`${id}${rowData.id}`}>{display(rowData)}</td>);
        } else {
          switch (display) {
            case 'text':
              rowToRender.push(
                <td key={`${id}-${rowData.id}`}>
                  <div className={EditableTableComponentV2Style.text_container}>
                    <div
                      style={{
                        float: rightAlign && 'right',
                        ...additionalStyles
                      }}
                      className={EditableTableComponentV2Style.main_text_container}
                    >
                      {value}
                      {suffix}
                    </div>
                    {subText}
                  </div>
                </td>
              );
              break;
            case 'status':
              rowToRender.push(
                <td key={`${id}-${rowData.id}`}>
                  <div
                    style={{
                      float: rightAlign && 'right',
                      backgroundColor: rowData[colorId],
                      ...additionalStyles
                    }}
                    className={EditableTableComponentV2Style.status}
                  >
                    {rowData[labelId]}
                  </div>
                </td>
              );
              break;
            default:
              break;
          }
        }
      }
    }
    return rowToRender;
  };

  const renderRows = () => {
    const rowsToRender = [];
    for (let row = 1; row < dataList.length + 1; row++) {
      rowsToRender.push(
        <tr key={row} className={EditableTableComponentV2Style.table_row}>
          <td key="num" className={EditableTableComponentV2Style.text_container}>
            <div className={EditableTableComponentV2Style.number}>{row}</div>
          </td>
          {renderRow(dataList[row - 1])}
        </tr>
      );
    }
    return rowsToRender;
  };

  return (
    <>
      <table className={EditableTableComponentV2Style.table}>
        <thead>
          <tr className={EditableTableComponentV2Style.table_row}>{renderHeader()}</tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>
      {!disableAdd && (
        <div className={EditableTableComponentV2Style.add_item_container} onClick={addItem}>
          <div className={EditableTableComponentV2Style.add_item_icon}>
            <AddRoundedIcon fontSize="inherit" />
          </div>
          <div style={{ fontWeight: 'bold', paddingTop: '2px' }}>Add another item</div>
        </div>
      )}
    </>
  );
};

EditableTableComponentV2.defaultProps = {
  headerConfig: [],
  dataList: [],
  editedValueMap: {}
};

EditableTableComponentV2.propTypes = {
  headerConfig: PropTypes.array,
  dataList: PropTypes.array,
  editedValueMap: PropTypes.object,
  errorMap: PropTypes.object,
  disableAdd: PropTypes.bool
};
