import React from 'react';
import queryString from 'query-string';
import './AlertsPage.css';
import RuleConfigurationSection from './RuleConfigurationSection';
import TabbedSectionComponent from '../../components/vertical-tab-with-contents/TabbedSectionComponent';
import NotificationSettings from './NotificationSettings';
// import Notifications from './Notifications';

export default class AlertsPage extends React.Component {
  render() {
    /*
     * to navigate to Notifications page from
     * "See all Notifications" button under the 'bell' icon
     */

    let activeTab = 0;
    if (window.location.search) {
      activeTab = +queryString.parse(window.location.search).activetab;
    }

    const tabContents = [
      {
        name: 'Rules',
        component: RuleConfigurationSection
      },
      // Temp commenting out until in-app notifications are implemented
      // {
      //   name: 'Notifications',
      //   component: Notifications
      // },
      {
        name: 'Notification Settings',
        component: NotificationSettings
      }
    ];

    return (
      <TabbedSectionComponent
        className="rule-tab-selection"
        tabContents={tabContents}
        activetab={activeTab}
      />
    );
  }
}
