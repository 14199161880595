import React, { useState, useEffect } from 'react';
import { Tabs, Tab, TabList } from 'react-web-tabs';
import { Search } from '@material-ui/icons';
import PropTypes from 'prop-types';
import './SearchableTabList.css';

const SearchableTabList = ({
  tabStructure,
  onSelect,
  searchPlaceholder,
  defaultLength,
  containerStyle,
  tabListStyle,
  tabListHeaderStyle,
  tabStyle,
  defaultTab,
  focusedTab,
  focusedTabStyle,
  disabledTabStyle
}) => {
  const initialFocusId = focusedTab || defaultTab;
  const [focusId, setFocusId] = useState(initialFocusId);
  const [defaultId, setDefaultId] = useState(defaultTab);
  const [searchString, setSearchString] = useState('');
  const [seeMore, setSeeMore] = useState(false);

  useEffect(() => {
    if (focusId !== focusedTab && focusedTab) {
      setFocusId(focusedTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedTab]);

  useEffect(() => {
    if (defaultId !== defaultTab) {
      setDefaultId(defaultTab);
      setFocusId(defaultTab);
    }
    // eslint-disable-next-line
  }, [defaultTab]);

  const RenderTab = ({ id, display, isDisabled, action }) => (
    <Tab
      tabFor={id}
      name={id}
      className={`${tabStyle} ${id === focusId && focusedTabStyle} ${isDisabled &&
        disabledTabStyle}`}
      onClick={() => {
        if (typeof action === 'function') {
          setFocusId(id);
          action();
        }
      }}
    >
      {display}
    </Tab>
  );

  const { title, tabs } = tabStructure;

  return (
    <div>
      <Tabs className={containerStyle}>
        <p className={tabListHeaderStyle}>{title}</p>
        <div className="group_container">
          <Search className="search_icon" fontSize="small" />
          <input
            onChange={({ currentTarget }) => setSearchString(currentTarget.value)}
            placeholder={searchPlaceholder}
            className="search_input"
          />
        </div>
        <TabList className={tabListStyle}>
          {tabs.map((tab, index) => {
            const { id, identifier, isDisabled } = tab;
            if (searchString || seeMore || index <= defaultLength) {
              if (identifier.toLowerCase().includes(searchString.toLowerCase())) {
                return (
                  <RenderTab
                    key={id}
                    id={id}
                    display={identifier}
                    isDisabled={isDisabled}
                    action={() => onSelect(tab)}
                  />
                );
              }
            }
          })}
        </TabList>
      </Tabs>
      {!searchString && tabs.length > defaultLength && (
        <p
          className="more"
          onClick={() => {
            setSeeMore(!seeMore);
          }}
        >
          {seeMore ? 'Collapse' : 'See more'}
        </p>
      )}
    </div>
  );
};

export default SearchableTabList;

SearchableTabList.defaultProps = {
  focusedTab: null,
  searchPlaceholder: 'Search',
  defaultLength: 3,
  defaultTab: null,
  tabListStyle: null,
  tabListHeaderStyle: null,
  containerStyle: null,
  tabStyle: null,
  focusedTabStyle: null,
  disabledTabStyle: ''
};

SearchableTabList.propTypes = {
  tabStructure: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  searchPlaceholder: PropTypes.string,
  defaultLength: PropTypes.number,
  containerStyle: PropTypes.string,
  tabListStyle: PropTypes.string,
  tabListHeaderStyle: PropTypes.string,
  focusedTabStyle: PropTypes.string,
  disabledTabStyle: PropTypes.string,
  tabStyle: PropTypes.string,
  defaultTab: PropTypes.string,
  focusedTab: PropTypes.string
};
