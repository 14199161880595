/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'mdbreact';
import { withRouter } from 'react-router-dom';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import ShipmentsTrackPageComponentStyle from './ShipmentsTrackPageComponent.module.css';

const ShipmentsTrackPageComponent = ({
  title,
  summaryTilesContainerComponent,
  paginationComponent,
  autoScrollComponent,
  textFilterComponent,
  shipmentListViewComponent,
  shipmentListViewTopContainerContent,
  sortDropdownComponent
}) => {

  return (
    <Fragment>
      <div className={ShipmentsTrackPageComponentStyle.tile_container}>
        {summaryTilesContainerComponent && summaryTilesContainerComponent()}
      </div>
      <h3 className={ShipmentsTrackPageComponentStyle.item_description}>{title}</h3>
      <div className={ShipmentsTrackPageComponentStyle.flex_direction_row}>
        {textFilterComponent && textFilterComponent()}
        <div className={ShipmentsTrackPageComponentStyle.dropdown_icon}>
          {sortDropdownComponent && sortDropdownComponent()}
        </div>
        <div className={ShipmentsTrackPageComponentStyle.pagination_container}>
          {autoScrollComponent && autoScrollComponent()}
          {paginationComponent && paginationComponent()}
        </div>
      </div>

      <Card
        className={`${ShipmentsTrackPageComponentStyle.content_holder} ${ShipmentsTrackPageComponentStyle.dashboardPage}`}
      >
        <CardBody className={ShipmentsTrackPageComponentStyle.dashboard_card}>
          <div className={`ag-theme-balham ${ShipmentsTrackPageComponentStyle.overall_container}`}>
            <div className={ShipmentsTrackPageComponentStyle.top_container}>
              {shipmentListViewTopContainerContent && shipmentListViewTopContainerContent()}
            </div>

            {shipmentListViewComponent && shipmentListViewComponent()}
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default withRouter(ShipmentsTrackPageComponent);

ShipmentsTrackPageComponent.defaultProps = {
  title: 'Shipments',
  summaryTilesContainerComponent: undefined,
  paginationComponent: undefined,
  autoScrollComponent: undefined,
  shipmentListViewComponent: undefined,
  shipmentListViewTopContainerContent: undefined
};

ShipmentsTrackPageComponent.propTypes = {
  title: PropTypes.string,
  summaryTilesContainerComponent: PropTypes.func,
  paginationComponent: PropTypes.func,
  autoScrollComponent: PropTypes.func,
  shipmentListViewComponent: PropTypes.func,
  shipmentListViewTopContainerContent: PropTypes.func
};