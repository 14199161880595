import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import OrderTrackPageFeature from '../../features/order-track-page-feature/OrderTrackPageFeature';
import BulkUpdateOrdersFeature from '../../features/order-track-page-feature/BulkUpdateOrdersFeature';
import OrderDetailPage from '../../components/TrackPageComponents/Order/OrderDetailPage';
import PartDetailPage from '../../components/TrackPageComponents/Order/Part/PartDetailPage';
import AddOrderFeature from '../../features/order-track-page-feature/AddOrderFeature';

const OrderApp = () => {
  return (
    <Fragment>
      <Route exact path="/work-order" component={OrderTrackPageFeature} />
      <Route path="/work-order/create" component={AddOrderFeature} />
      <Route path="/work-order/detail" component={OrderDetailPage} />
      <Route path="/work-order/part/detail" component={PartDetailPage} />
      <Route path="/work-order/bulk-update" component={BulkUpdateOrdersFeature} />
    </Fragment>
  );
};

export default OrderApp;
