import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PopperFormStyle from './PopperFormComponents.module.css';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';

const useFormHelperStyle = makeStyles({
  root: {
    marginTop: '-2px'
  },
  error: {
    color: '#F62227'
  }
});

export const AddressFormComponent = ({ title, closeFunc, submitFunc }) => {
  const formHelperStyle = useFormHelperStyle();
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [stateValue, setStateValue] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [errorMap, setErrorMap] = useState({});

  const submit = () => {
    const tempErrorMap = {};
    if (!streetAddress.trim()) {
      tempErrorMap.streetAddress = 'Street Address cannot be empty';
    }
    if (!city.trim()) {
      tempErrorMap.city = 'City cannot be empty';
    }
    if (!stateValue.trim()) {
      tempErrorMap.stateValue = 'State cannot be empty';
    }
    if (!zipCode.trim()) {
      tempErrorMap.zipCode = 'Zip Code cannot be empty';
    }

    if (!Object.keys(tempErrorMap).length) {
      submitFunc({
        address_line_0: streetAddress,
        locality: city,
        admin_area: stateValue,
        postal_code: zipCode
      });
    } else {
      setErrorMap(tempErrorMap);
    }
  };

  return (
    <div
      className={PopperFormStyle.address_form_container}
      style={{ height: !!Object.keys(errorMap).length ? '350px' : '265px' }}
    >
      {title || 'Edit Address'}
      <p className={`${PopperFormStyle.form_title} ${PopperFormStyle.label_text}`}>
        Street Address
      </p>
      <input
        className={PopperFormStyle.popper_form_input}
        onChange={(event) => {
          setStreetAddress(event.target.value);
        }}
        style={{ border: errorMap.streetAddress ? '1.5px solid #F62227' : '1.5px solid #E2E2EA' }}
        value={streetAddress}
      />
      <FormHelperText error={!!errorMap.streetAddress} classes={formHelperStyle}>
        {errorMap.streetAddress}
      </FormHelperText>
      <div className={PopperFormStyle.address_row}>
        <div className={`${PopperFormStyle.city_container} ${PopperFormStyle.label_text}`}>
          City
          <input
            className={PopperFormStyle.popper_form_input}
            onChange={(event) => {
              setCity(event.target.value);
            }}
            style={{ border: errorMap.city ? '1.5px solid #F62227' : '1.5px solid #E2E2EA' }}
            value={city}
          />
          <FormHelperText error={!!errorMap.city} classes={formHelperStyle}>
            {errorMap.city}
          </FormHelperText>
        </div>
        <div className={`${PopperFormStyle.state_container} ${PopperFormStyle.label_text}`}>
          State
          <input
            className={PopperFormStyle.popper_form_input}
            onChange={(event) => {
              setStateValue(event.target.value);
            }}
            style={{ border: errorMap.stateValue ? '1.5px solid #F62227' : '1.5px solid #E2E2EA' }}
            value={stateValue}
          />
          <FormHelperText error={!!errorMap.stateValue} classes={formHelperStyle}>
            {errorMap.stateValue}
          </FormHelperText>
        </div>
        <div className={`${PopperFormStyle.zip_code_container} ${PopperFormStyle.label_text}`}>
          Zip Code
          <input
            className={PopperFormStyle.popper_form_input}
            onChange={(event) => {
              setZipCode(event.target.value);
            }}
            style={{ border: errorMap.zipCode ? '1.5px solid #F62227' : '1.5px solid #E2E2EA' }}
            value={zipCode}
          />
          <FormHelperText error={!!errorMap.zipCode} classes={formHelperStyle}>
            {errorMap.zipCode}
          </FormHelperText>
        </div>
      </div>
      <div className={PopperFormStyle.address_button_container}>
        <div className={PopperFormStyle.close_button} onClick={closeFunc}>
          Close
        </div>
        <div className={PopperFormStyle.submit_button} onClick={submit}>
          Submit
        </div>
      </div>
    </div>
  );
};

AddressFormComponent.propTypes = {
  title: PropTypes.string,
  closeFunc: PropTypes.func,
  submitFunc: PropTypes.func
};
