/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useStore } from 'react-hookstore';
import Routes from './routes/Routes';
import MainAppFeature from './features/main-app-feature/MainAppFeature';
import { DisplayBannerContextProvider } from 'context/DisplayBannerContext/DisplayBannerContext';
import ConfigurationService from './services/ConfigurationService';
import { userProfileStore } from './state-managements/stores/user-profile-store';
import { LocalCacheService } from './services/local-cache-service';
import { XemelgoService } from './services/XemelgoService';
import { LibraryBooksOutlined } from '@material-ui/icons';
import configuration from './configuration.json';
import Amplify from 'aws-amplify';
import './App.css';

const getAppIcon = (appName, isFocused) => {
  switch (appName) {
    case 'reports':
      return (
        <LibraryBooksOutlined style={{ color: !isFocused && '#343434' }} className="app-icon" />
      );
    default:
      return (
        <img
          src={
            isFocused
              ? require(`./img/${appName}_icon_blue.png`)
              : require(`./img/${appName}_icon_dark.png`)
          }
          className="app-icon"
          alt=""
        />
      );
  }
};

const iconStyle = {
  display: 'flex',
  alignItems: 'center',
  height: 40,
  overflow: 'hidden'
};

const generateIcon = (iconName, displayName, isFocused) => {
  return (
    <div style={iconStyle}>
      {getAppIcon(iconName, isFocused)}
      <div style={{ marginLeft: 10 }}>{displayName}</div>
    </div>
  );
};

const buildAmplifyConfig = () => {
  const config = {
    Auth: configuration.AuthConfiguration,
    API: {
      endpoints: [
        ...configuration.ConfigurationService_endpoints.endpoints,
        ...configuration.ConfigurationService_endpoints_test.endpoints
      ]
    }
  };
  return config;
};

const MainApp = ({ history }) => {
  const [tabStructure, setTabStructure] = useState([]);
  const [redirectPath, setRedirectPath] = useState('/'); // RedirectPath is on temporary used before dashboard page is implemented
  const [isLoading, setLoading] = useState(true);
  /**
   * @type {./domains/user-profile.UserProfile}
   */
  // check if the scenario is prod (0) or dev (1)
  XemelgoService.getClient().setScenario(sessionStorage.getItem('scenario') || '0');

  const [userProfile] = useStore(userProfileStore);
  useEffect(() => {
    setLoading(true);
    const isAdmin = userProfile.isUserSuperAdmin();

    XemelgoService.getClient().setScenario(sessionStorage.getItem('scenario') || '0');
    let newTabStructure = tabStructure;

    ConfigurationService.getFullConfiguration().then((customerConfiguration) => {
      const { serviceMap = {} } = customerConfiguration.configData;
      const { xGraph = {} } = serviceMap;
      const amplifyConfig = buildAmplifyConfig();
      amplifyConfig.API.endpoints.push({ ...xGraph });
      Amplify.configure(amplifyConfig);

      const {
        solutionOptions = [],
        settingOptions = []
      } = customerConfiguration.configData.webClient;

      const defaultWorkOrderTab =
        customerConfiguration.solutionConfiguration.uiPreferences.trackPageConfiguration.defaultTab;

      const solutionTabStructure = solutionOptions
        .map(({ id, title, adminOnly }) => {
          if (!adminOnly || isAdmin) {
            switch (id) {
              case 'order':
                return {
                  id: 'work-order',
                  title: title || 'Work Orders',
                  display: (isFocused) => {
                    return generateIcon('work-order', title || 'Work Orders', isFocused);
                  },
                  action: () => {
                    if (defaultWorkOrderTab === 'all-parts') {
                      history.push('/work-order?group=all-parts');
                    } else if (defaultWorkOrderTab === 'location') {
                      history.push('/work-order?group=location');
                    } else {
                      history.push('/work-order');
                    }
                  }
                };

              case 'inventory':
                return {
                  id: 'inventory',
                  title: title || 'Inventory',
                  display: (isFocused) => {
                    return generateIcon('inventory', title || 'Inventory', isFocused);
                  },
                  action: () => {
                    history.push('/inventory');
                  }
                };

              case 'asset':
                return {
                  id: 'asset',
                  title: title || 'Asset',
                  display: (isFocused) => {
                    return generateIcon('asset', title || 'Asset', isFocused);
                  },
                  action: () => {
                    history.push('/asset');
                  }
                };

              case 'shipments':
                return {
                  id: 'shipments',
                  title: title || 'Shipments',
                  display: (isFocused) => {
                    return generateIcon('shipments', title || 'Shipments', isFocused);
                  },
                  action: () => {
                    history.push('/shipments');
                  }
                };

              case 'purchase-order':
                return {
                  id: 'purchase-order',
                  title: title || 'Purchase Orders',
                  display: (isFocused) => {
                    return generateIcon('purchase-order', title || 'Purchase Orders', isFocused);
                  },
                  action: () => {
                    history.push('/purchase-order');
                  }
                };
              case 'management-dashboard':
                return {
                  id: 'management-dashboard',
                  title: title || 'Management Dashboard',
                  display: (isFocused) => {
                    return generateIcon(
                      'management-dashboard',
                      title || 'Management Dashboard',
                      isFocused
                    );
                  },
                  action: () => {
                    history.push('/management-dashboard');
                  }
                };

              default:
                break;
            }
          }
        })
        .filter((each) => each)
        .sort((a, b) => {
          if (a.id === 'management-dashboard') {
            return -1;
          } else if (b.id === 'management-dashboard') {
            return 1;
          } else {
            return a.title.localeCompare(b.title, undefined, {
              numeric: true,
              sensitivity: 'base'
            });
          }
        });

      const settingTabStructure = settingOptions
        .map(({ id, title, adminOnly }) => {
          if (!adminOnly || isAdmin) {
            switch (id) {
              case 'reports':
                return {
                  id: 'reports',
                  title: title || 'Reports',
                  display: (isFocused) => {
                    return generateIcon('reports', title || 'Reports', isFocused);
                  },
                  action: () => {
                    history.push('/reports');
                  }
                };

              case 'item-types-management':
                return {
                  id: 'item-types-management',
                  title: title || 'Item Types Management',
                  display: (isFocused) => {
                    return generateIcon(
                      'item-types-management',
                      title || 'Item Types Management',
                      isFocused
                    );
                  },
                  action: () => {
                    history.push('/item-types-management');
                  }
                };

              case 'my-facility':
                return {
                  id: 'my-facility',
                  title: title || 'My Facility',
                  display: (isFocused) => {
                    return generateIcon('my-facility', title || 'My Facility', isFocused);
                  },
                  action: () => {
                    history.push('/my-facility');
                  }
                };

              case 'users':
                return {
                  id: 'users',
                  title: title || 'Users',
                  display: (isFocused) => {
                    return generateIcon('users', title || 'Users', isFocused);
                  },
                  action: () => {
                    history.push('/users');
                  }
                };

              case 'alerts':
                return {
                  id: 'alerts',
                  title: title || 'Alerts',
                  display: (isFocused) => {
                    return generateIcon('alerts', title || 'Alerts', isFocused);
                  },
                  action: () => {
                    history.push('/alerts');
                  }
                };

              default:
                break;
            }
          }
        })
        .filter((each) => each)
        .sort((a, b) => {
          return a.title.localeCompare(b.title, undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        });

      const landingPage = LocalCacheService.loadUserConfig().getLandingPage();
      const landingURL = landingPage !== '/' ? landingPage : `/${newTabStructure[0].id}`;
      setRedirectPath(landingURL); // RedirectPath is on temporary used before dashboard page is implemented
      setTabStructure([
        { tabStructure: solutionTabStructure },
        { tabStructure: settingTabStructure }
      ]);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return null;
  }
  return (
    <DisplayBannerContextProvider>
      <MainAppFeature render={<Routes redirectPath={redirectPath} />} tabStructure={tabStructure} />
    </DisplayBannerContextProvider>
  );
};

export default withRouter(MainApp);

MainApp.propTypes = {
  history: PropTypes.object.isRequired
};
