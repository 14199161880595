import React, { PureComponent, Fragment } from 'react';
import './css/ProgressBar.css';

/* ProgressBar
  The progess bar will take a low stock threshold (min),
  overstock threshold(max), current stock (value),
  status (healthColor), and the amount not in a location(undetected)
*/
export default class ProgressBar extends PureComponent {
  renderText() {
    const {
      healthDetermineBy,
      expired,
      soonToExpire,
      value,
      healthColor,
      showTotalWeight,
      totalWeight
    } = this.props;
    const { incoming = 0 } = this.props;

    if (healthDetermineBy === 'stock' || !healthDetermineBy) {
      return (
        <div className="text-box">
          <p className="stock-text" style={{ color: healthColor }}>
            {`${value - incoming} units`}
          </p>
          <p className="stock-text"> - </p>
          <p className="stock-text" style={{ color: '#166ED5' }}>
            {showTotalWeight ? `${totalWeight.toFixed(2)} Lbs` : `${incoming} incoming`}
          </p>
        </div>
      );
    }
    if (healthDetermineBy === 'expiration') {
      return (
        <div className="text-box">
          {expired > 0 && (
            <Fragment>
              <p className="stock-text" style={{ color: '#F62227' }}>
                {`${expired} expired`}
              </p>
              <p className="stock-text"> - </p>
            </Fragment>
          )}
          {soonToExpire > 0 && (
            <Fragment>
              <p className="stock-text" style={{ color: '#FFCF0F' }}>
                {`${soonToExpire} expiring soon`}
              </p>
              <p className="stock-text"> - </p>
            </Fragment>
          )}
          <p className="stock-text">{`${value} units`}</p>
        </div>
      );
    }
  }

  renderBar() {
    const { min, healthColor, healthDetermineBy, expired, soonToExpire } = this.props;
    let { incoming = 0, value, max } = this.props;

    let actualMax;
    if (healthDetermineBy === 'expiration') {
      max = value;
    }

    if (max) actualMax = max;
    // if there's no max, size the bar based on value and/or min
    else if (!max && value > min) actualMax = Math.ceil(value * 1.1);
    else if (!max && value <= min) actualMax = Math.ceil(min * 1.1);
    // calculate how much the bar should be filled
    let barPercentage = (value / actualMax) * 100;
    // set percentages to prevent overflow or to match low stock marking
    if (barPercentage > 100) barPercentage = 100;
    else if (barPercentage < 4 || !barPercentage) barPercentage = 4;

    let minPercentage = (min / actualMax) * 100;
    if (minPercentage > 75 && max) minPercentage = 75;
    else if (!minPercentage) minPercentage = 3;
    else if (minPercentage < 10) minPercentage = 10;

    const lowStockMark = {
      left: `${minPercentage - 1.3}%`
    };

    if (healthDetermineBy === 'stock') {
      // the styling for the detected items
      let barWidthAndColor = {
        width: `${barPercentage}%`,
        background: healthColor,
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px'
      };
      if (barPercentage === 100) {
        barWidthAndColor = {
          width: `${barPercentage}%`,
          background: healthColor,
          borderRadius: '10px'
        };
      }
      // calculate the percentage of undetected items
      const undetectedPercentage = (incoming / actualMax) * 100;
      // styling for undetected items
      const secondWidthAndColor = {
        width: `${undetectedPercentage}%`,
        left: `${barPercentage - undetectedPercentage}%`,
        background: '#166ED5',
        borderTopLeftRadius: barPercentage === undetectedPercentage && '10px',
        borderBottomLeftRadius: barPercentage === undetectedPercentage && '10px'
      };

      return (
        <Fragment>
          <div className="box">
            <div className="fill-start" style={barWidthAndColor} />
            <div className="fill-default" style={secondWidthAndColor} />
          </div>
          <div className="tick-mark">
            <p className="marker-zero bolder">0</p>
          </div>
          <div className="tick-mark show-overstock">
            <p className="marker-number bolder">{max || actualMax}</p>
          </div>
          {minPercentage > 3 && (
            <div className="tick-mark" style={lowStockMark}>
              <p className="marker-number bolder">{min}</p>
            </div>
          )}
        </Fragment>
      );
    }
    if (healthDetermineBy === 'expiration') {
      const expiredPercentage = (expired / actualMax) * 100;
      const soonToExpirePercentage = (soonToExpire / actualMax) * 100;
      const expiredWidthAndColor = {
        width: `${expiredPercentage}%`,
        background: '#F62227',
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px',
        borderTopRightRadius: value === expired && '10px',
        borderBottomRightRadius: value === expired && '10px'
      };

      const soonToExpireWidthAndColor = {
        width: `${soonToExpirePercentage}%`,
        left: `${expiredPercentage}%`,
        background: '#FFCF0F',
        borderTopLeftRadius: !expired && '10px',
        borderBottomLeftRadius: !expired && '10px',
        borderTopRightRadius: value === expired + soonToExpire && '10px',
        borderBottomRightRadius: value === expired + soonToExpire && '10px'
      };

      const unexpiredWidthAndColor = {
        width: `${barPercentage}%`,
        background: '#00B200',
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px',
        borderTopRightRadius: value > 0 && '10px',
        borderBottomRightRadius: value > 0 && '10px'
      };
      return (
        <Fragment>
          <div className="box">
            <div className="fill-default" style={unexpiredWidthAndColor} />
            {value && (
              <Fragment>
                <div className="fill-start" style={expiredWidthAndColor} />
                <div className="fill-default" style={soonToExpireWidthAndColor} />
              </Fragment>
            )}
          </div>
          <div className="tick-mark">
            <p className="marker-zero bolder">0</p>
          </div>
          <div className="tick-mark show-overstock">
            <p className="marker-number bolder">{max}</p>
          </div>
        </Fragment>
      );
    }
  }

  render() {
    return (
      <div className="bar-container">
        {this.renderText()}
        {this.renderBar()}
      </div>
    );
  }
}
