import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import UsersPageFeature from '../../features/users-page-feature/UsersPageFeature';

const UsersApp = () => {
  return (
    <Fragment>
      <Route exact path="/users" component={UsersPageFeature} />
    </Fragment>
  );
};

export default UsersApp;
