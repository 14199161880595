import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useXemelgoClient } from '../../services/xemelgo-service';
import { useAppConfigProvider } from '../../services/soft-cache-service';
import { formatNumber, getItemFromS3 } from '../../common/Utilities';
import { LocalCacheService } from '../../services/local-cache-service';
import Spinner from 'react-bootstrap/Spinner';
import { ModalForm } from '../../components/modal-form';
import { PurchaseOrderDetailBody } from '../../components/purchase-order-detail-components/PurchaseOrderDetailBody';
import { PurchaseOrderDetailHeader } from '../../components/purchase-order-detail-components/PurchaseOrderDetailHeader';
import { PurchaseOrderDetailFooter } from '../../components/purchase-order-detail-components/PurchaseOrderDetailFooter';
import './style.css';

const APP_ID = 'purchaseOrder';
const FULFILLMENT_STATUS_FLAG_CONFIG = 'fulfillmentStatusFlagMap';
const ORDER_STATUS_FLAG_CONFIG = 'orderStatusFlagMap';
const CURRENCY_PROPERTY = 'currencyType';
const LOCATION_CATEGORIES_PROPERTY = 'possibleDetectorLocations';
const NUMBER_PROPERTIES = ['quantity_ordered', 'direct_unit_cost', 'shipping_charge'];
const ITEM_ORDER_FORMAT_PROPERTIES = [
  'quantity_ordered',
  'quantity_fulfilled',
  'total_cost_by_quantity_ordered',
  'total_cost_by_quantity_fulfilled',
  'shipping_charge',
  'direct_unit_cost'
];
const NO_BUYER_ERROR = 'MISSING_BUYER';
const NO_PO_ERROR = 'MISSING_PO';

export const PurchaseOrderDetailModalFeature = ({ show, onClose, purchaseOrderId, history }) => {
  const configProvider = useAppConfigProvider(APP_ID);
  const orderStatusFlagMap = configProvider.getValue(ORDER_STATUS_FLAG_CONFIG, 'object');
  const fulfillmentStatusFlagMap = configProvider.getValue(
    FULFILLMENT_STATUS_FLAG_CONFIG,
    'object'
  );
  const currencyType = configProvider.getValue(CURRENCY_PROPERTY, 'string');
  const locationCategories = configProvider.getValue(LOCATION_CATEGORIES_PROPERTY, 'array');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [purchaseOrderClient] = useState(useXemelgoClient().getPurchaseOrderClient());
  const [hasEdits, setHasEdits] = useState(false);
  const [personClient] = useState(useXemelgoClient().getPersonClient());
  const [addressClient] = useState(useXemelgoClient().getAddressClient());
  const [mailingAddress, setMailingAddress] = useState({});
  const [shippingAddress, setShippingAddress] = useState({});
  const [locationId, setLocationId] = useState('');
  const [showShareForm, setShowShareForm] = useState(false);
  const [editedValues, setEditedValues] = useState({});
  const [editedItemOrders, setEditedItemOrders] = useState({});
  const [itemOrderErrorMap, setItemOrderErrorMap] = useState({});
  const [errorMessageMap, setErrorMessageMap] = useState({});
  const [submitTicket, setSubmitTicket] = useState('');
  const [popperFormComponent, setPopperFormComponent] = useState(undefined);
  const [itemOrderToCancel, setItemOrderToCancel] = useState(undefined);
  const [errorBannerMessage, setErrorBannerMessage] = useState(undefined);
  const [mainBodyError, setMainBodyError] = useState(undefined);
  const [vendorIdentifier, setVendorIdentifier] = useState(undefined);
  const [localPurchaseOrderId, setLocalPurchaseOrderId] = useState('');
  const [buyer, setBuyer] = useState({});
  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    setLocalPurchaseOrderId(purchaseOrderId);
  }, [purchaseOrderId]);

  const handleDownloadPDFCallback = useCallback(
    async (id) => {
      try {
        setLoading(true);
        const result = await purchaseOrderClient.generatePurchaseOrderPDF(
          id || localPurchaseOrderId
        );
        await getItemFromS3('xemelgo-generated-reports', result);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setErrorBannerMessage('Failed to download PDF.');
      }
    },
    [purchaseOrderClient, localPurchaseOrderId]
  );

  const onLoadCallback = useCallback(
    async (onLoadId) => {
      setLoading(true);
      setData({});
      setEditedValues({});
      setSubmitTicket('');
      setShowShareForm(false);
      setPopperFormComponent(undefined);
      setErrorMessageMap({});
      setEditedItemOrders({});
      setItemOrderErrorMap({});
      setShippingAddress({});
      setMailingAddress({});
      setLocationId('');
      setHasEdits(false);
      setErrorBannerMessage(undefined);
      setMainBodyError(undefined);
      setIsNew(onLoadId === 'NEW');
      setVendorIdentifier(undefined);
      try {
        const result = await purchaseOrderClient.getPurchaseOrderDetails(
          onLoadId,
          locationCategories
        );
        const {
          itemOrders = [],
          hasShippingAddress = [],
          purchasedFrom = [],
          issuedBy = [{}]
        } = result;

        if (hasShippingAddress.length) {
          setShippingAddress(hasShippingAddress[0]);
          if (hasShippingAddress[0].ofLocation.length) {
            setLocationId(hasShippingAddress[0].ofLocation[0].id);
          }
        }

        if (purchasedFrom.length && purchasedFrom[0].hasBusinessAddress.length) {
          setMailingAddress(purchasedFrom[0].hasBusinessAddress[0]);
        }

        itemOrders.forEach((order) => {
          const { fulfillment_status, order_status } = order;
          let decimalPlaces = 2;
          const splitUnitCost = order.direct_unit_cost?.toString().split('.');
          if (splitUnitCost?.length === 2 && splitUnitCost[1].length > 2) {
            decimalPlaces = splitUnitCost[1].length;
          }
          order.direct_unit_cost = formatNumber(order.direct_unit_cost, decimalPlaces);

          order.shipping_charge = formatNumber(order.shipping_charge, 2);
          order.quantity_ordered = formatNumber(order.quantity_ordered);
          order.quantity_fulfilled = formatNumber(order.quantity_fulfilled);
          order.orderStatusColor =
            orderStatusFlagMap[order_status] && orderStatusFlagMap[order_status].color;
          order.orderStatusLabel =
            orderStatusFlagMap[order_status] && orderStatusFlagMap[order_status].label;
          order.fulfillmentStatusColor =
            fulfillmentStatusFlagMap[fulfillment_status] &&
            fulfillmentStatusFlagMap[fulfillment_status].color;
          order.fulfillmentStatusLabel =
            fulfillmentStatusFlagMap[fulfillment_status] &&
            fulfillmentStatusFlagMap[fulfillment_status].label;
          order.total_cost_by_quantity_ordered = formatNumber(
            order.total_cost_by_quantity_ordered,
            2
          );
          order.total_cost_by_quantity_fulfilled = formatNumber(
            order.total_cost_by_quantity_fulfilled,
            2
          );
        });

        let tempBuyer = issuedBy[0];

        if (onLoadId === 'NEW') {
          const userProfile = LocalCacheService.loadUserProfile();
          const { persons } = await personClient.getPersonByCognitoId(userProfile.userId);
          if (!persons[0]) {
            throw NO_BUYER_ERROR;
          }
          tempBuyer = persons[0];
          const newEditedMap = {
            order_status: 'Open',
            class: 'Standard',
            total_cost_by_quantities_ordered: 0,
            total_cost_by_quantities_fulfilled: 0,
            fulfillment_status: 'Unfulfilled',
            creation_date: new Date(),
            shipping_method: 'Vendor-shipped'
          };
          setEditedValues(newEditedMap);
        } else if (!result.id) {
          throw NO_PO_ERROR;
        }

        setBuyer(tempBuyer);
        setData(result);
      } catch (error) {
        switch (error) {
          case NO_BUYER_ERROR:
            setMainBodyError(
              "There's an issue with your account. Please reach out to Xemelgo support."
            );
            break;
          case NO_PO_ERROR:
            setMainBodyError('The PO does not exist in Xemelgo.');
            break;
          default:
            setMainBodyError('Something went wrong.');
            break;
        }
        console.log(error);
      }
      setLoading(false);
    },
    [
      locationCategories,
      purchaseOrderClient,
      orderStatusFlagMap,
      fulfillmentStatusFlagMap,
      personClient
    ]
  );

  const handleClose = () => {
    onClose(vendorIdentifier);
  };

  const saveChangesCallback = useCallback(
    async (submitTicket) => {
      setSubmitTicket('');
      const tempEditedValues = { ...editedValues };
      const tempEditedItemOrders = { ...editedItemOrders };
      let purchaseOrderIdToUse = localPurchaseOrderId;
      for (const prop in tempEditedValues) {
        if (prop.includes('date') && tempEditedValues[prop]) {
          tempEditedValues[prop] = tempEditedValues[prop].getTime();
        }
      }
      try {
        let vendorId =
          (data.purchasedFrom?.length && data.purchasedFrom[0].id) || tempEditedValues.vendor;
        const tempVendorIdentifier = data.partners.find((partner) => partner.id === vendorId)
          ?.identifier;
        if (localPurchaseOrderId === 'NEW') {
          const { vendor } = tempEditedValues;
          const { id: buyerId } = buyer;
          delete tempEditedValues.vendor;

          for(const property of Object.keys(tempEditedValues)) {
            if (tempEditedValues[property] === null) {
              delete tempEditedValues[property];
            }
          }
          
          const payload = {
            fields: tempEditedValues,
            connections: {
              issuedBy: [buyerId]
            }
          };

          if (vendor) {
            payload.connections.purchasedFrom = [vendor];
          }

          const poResult = await purchaseOrderClient.createPurchaseOrder(payload);
          const { id: poId } = poResult;
          purchaseOrderIdToUse = poId;
        } else {
          await purchaseOrderClient.updatePurchaseOrder(localPurchaseOrderId, tempEditedValues);
        }

        let shippingAddressId = shippingAddress.id;
        if (shippingAddress.id === 'NEW') {
          delete shippingAddress.id;
          const shippingAddressResult = await addressClient.createAddress({
            fields: shippingAddress
          });
          shippingAddressId = shippingAddressResult.id;
          locationId &&
            (await addressClient.attachAddressToLocation(locationId, shippingAddressId));
        }

        if (
          data.hasShippingAddress?.length &&
          data.hasShippingAddress[0].id !== shippingAddressId
        ) {
          await addressClient.detachPurchaseOrderFromAddress(
            purchaseOrderIdToUse,
            data.hasShippingAddress[0].id
          );
        }

        if (
          shippingAddressId &&
          (!data.hasShippingAddress ||
            !data.hasShippingAddress.length ||
            data.hasShippingAddress[0].id !== shippingAddressId)
        ) {
          await addressClient.attachPurchaseOrderToAddress(purchaseOrderIdToUse, shippingAddressId);
        }

        if (mailingAddress.id === 'NEW' && vendorId) {
          delete mailingAddress.id;
          const mailingAddressResult = await addressClient.createAddress({
            fields: mailingAddress
          });
          await addressClient.attachAddressToPartner(vendorId, mailingAddressResult.id);
        }

        for (const id in tempEditedItemOrders) {
          const currentProperties = tempEditedItemOrders[id];
          const { itemTypeId } = currentProperties;
          delete currentProperties.identifier;
          delete currentProperties.itemTypeId;
          for (const property in currentProperties) {
            if (ITEM_ORDER_FORMAT_PROPERTIES.includes(property)) {
              currentProperties[property] = Number.parseFloat(
                currentProperties[property].replaceAll(',', '')
              );
            }
          }
          if (id.includes('NEW')) {
            const payload = {
              fields: currentProperties,
              connections: {
                partOfOrder: [purchaseOrderIdToUse]
              }
            };
            const result = await purchaseOrderClient.createItemOrder(payload);
            const { id: itemOrderId } = result;
            await purchaseOrderClient.attachItemOrderToType(itemOrderId, itemTypeId);
          } else {
            await purchaseOrderClient.updateItemOrder(id, currentProperties);
          }
        }

        setLocalPurchaseOrderId(purchaseOrderIdToUse);
        if (submitTicket === 'save_and_download') {
          await onLoadCallback(purchaseOrderIdToUse);
          await handleDownloadPDFCallback(purchaseOrderIdToUse);
          setVendorIdentifier(tempVendorIdentifier);
          setShowShareForm(true);
        } else {
          onClose(tempVendorIdentifier);
        }
      } catch (error) {
        console.log(`Error ${error}`);
        setLoading(false);
        setErrorBannerMessage(`Failed to ${isNew ? 'Create' : 'Save'}.`);
      }
    },
    [
      handleDownloadPDFCallback,
      addressClient,
      data.hasShippingAddress,
      data.purchasedFrom,
      data.partners,
      editedItemOrders,
      editedValues,
      locationId,
      mailingAddress,
      onClose,
      onLoadCallback,
      purchaseOrderClient,
      localPurchaseOrderId,
      shippingAddress,
      buyer,
      isNew
    ]
  );

  const handleCloseBanner = () => {
    setErrorBannerMessage(undefined);
  };

  const validateDataCallback = useCallback(() => {
    const tempItemOrderErrorMap = { ...itemOrderErrorMap };
    const tempErrorMessageMap = { ...errorMessageMap };

    let hasError = false;
    let bannerErrorMessage = 'Failed to create: ';
    if (!shippingAddress.id) {
      hasError = true;
      bannerErrorMessage += 'Shipping address required. ';
    }

    if (!mailingAddress.id) {
      hasError = true;
      bannerErrorMessage += 'Mailing address required. ';
    }

    if (data.itemOrders?.length) {
      data.itemOrders.forEach((order) => {
        const { id } = order;
        if (id.includes('NEW')) {
          const editedOrder = editedItemOrders[id];
          NUMBER_PROPERTIES.forEach((property) => {
            const parsedValue = Number.parseFloat(editedOrder[property].replaceAll(',', ''));
            if (isNaN(parsedValue)) {
              if (!tempItemOrderErrorMap[id]) {
                tempItemOrderErrorMap[id] = {};
              }
              tempItemOrderErrorMap[id][property] = 'Please enter a number';
              hasError = true;
            } else if (parsedValue < 0) {
              if (!tempItemOrderErrorMap[id]) {
                tempItemOrderErrorMap[id] = {};
              }
              tempItemOrderErrorMap[id][property] = 'Please enter a positive number';
              hasError = true;
            }
            if (property === 'quantity_ordered' && parsedValue === 0) {
              if (!tempItemOrderErrorMap[id]) {
                tempItemOrderErrorMap[id] = {};
              }
              tempItemOrderErrorMap[id][property] = 'Quantity cannot be 0';
              hasError = true;
            }
          });
          if (!editedOrder.identifier) {
            if (!tempItemOrderErrorMap[id]) {
              tempItemOrderErrorMap[id] = {};
            }
            tempItemOrderErrorMap[id].identifier = 'Please select an item type';
            hasError = true;
          }
        }
      });
    } else {
      bannerErrorMessage += 'At least 1 item order required. ';
      hasError = true;
    }

    if (bannerErrorMessage !== 'Failed to create: ') {
      setErrorBannerMessage(bannerErrorMessage);
    } else {
      setErrorBannerMessage(undefined);
    }

    if (isNew && !editedValues.identifier?.trim()) {
      tempErrorMessageMap.identifier = 'Purchase Order # cannot be empty';
      hasError = true;
      setErrorMessageMap(tempErrorMessageMap);
    }
    setItemOrderErrorMap(tempItemOrderErrorMap);
    return hasError;
  }, [
    data.itemOrders,
    editedItemOrders,
    itemOrderErrorMap,
    mailingAddress,
    shippingAddress,
    errorMessageMap,
    editedValues,
    isNew
  ]);

  const toggleShareForm = () => {
    if (!!Object.keys(editedValues).length) {
      setShowShareForm(true);
      setSubmitTicket('save_and_share');
    } else {
      setShowShareForm(!showShareForm);
    }
    if (!!popperFormComponent) {
      setPopperFormComponent(undefined);
    }
  };

  const handleSetPopperFormComponent = (component) => {
    setPopperFormComponent(component);
    if (showShareForm) {
      setShowShareForm(false);
    }
  };

  useEffect(() => {
    //only load in the data if the modal's showing
    if (show) {
      onLoadCallback(purchaseOrderId);
    }
  }, [show, onLoadCallback, purchaseOrderId]);

  useEffect(() => {
    if (submitTicket) {
      const hasError = validateDataCallback();
      if (
        !!Object.keys(errorMessageMap).length ||
        !!Object.keys(itemOrderErrorMap).length ||
        hasError
      ) {
        setSubmitTicket('');
      } else {
        setLoading(true);
        saveChangesCallback(submitTicket);
      }
    }
  }, [submitTicket, errorMessageMap, itemOrderErrorMap, saveChangesCallback, validateDataCallback]);

  const handleComplete = (id) => {
    const tempEditedItemOrders = { ...editedItemOrders };
    const tempEditedValues = { ...editedValues };
    const tempValueMap = { ...data };
    const itemOrder = tempValueMap.itemOrders.find((order) => order.id === id);
    itemOrder.orderStatusColor = orderStatusFlagMap.Closed && orderStatusFlagMap.Closed.color;
    itemOrder.orderStatusLabel = orderStatusFlagMap.Closed && orderStatusFlagMap.Closed.label;

    if (!tempEditedItemOrders[id]) {
      tempEditedItemOrders[id] = {};
    }
    tempEditedItemOrders[id]['order_status'] = 'Closed';

    let shouldComplete = true;

    tempValueMap.itemOrders.forEach((order) => {
      const orderStatus = tempEditedItemOrders[order.id]?.order_status || order.order_status;
      if (orderStatus !== 'Closed' && orderStatus !== 'Cancelled') {
        shouldComplete = false;
      }
    });

    if (shouldComplete) {
      tempEditedValues.order_status = 'Closed';
    }

    if (!hasEdits) {
      setHasEdits(true);
    }

    setEditedValues(tempEditedValues);
    setEditedItemOrders(tempEditedItemOrders);
    setData(tempValueMap);
  };

  const handleViewItemOrder = (id) => {
    const itemOrder = data.itemOrders.find((order) => order.id === id) || {};
    const { orderOf = [{}] } = itemOrder;
    history.push(`/inventory/itemType/detail?id=${orderOf[0]?.id}`);
  };

  const getOptions = (rowData) => {
    if (rowData.id?.includes('NEW')) {
      return [
        {
          label: 'View item order',
          action: () => {
            //what todo if user hasn't selected an item order?
          }
        },
        {
          label: 'Remove item',
          color: '#FF7278',
          action: (id) => {
            handleRemove(id);
          }
        }
      ];
    } else if (rowData.order_status === 'Cancelled') {
      return [
        {
          label: 'View item order',
          action: handleViewItemOrder
        }
      ];
    } else if (rowData.order_status === 'Open') {
      if (rowData.fulfillment_status === 'Unfulfilled') {
        return [
          {
            label: 'View item order',
            action: handleViewItemOrder
          },
          {
            label: 'Cancel',
            color: '#FF7278',
            action: (id) => {
              setItemOrderToCancel(id);
              setPopperFormComponent('cancel');
            }
          }
        ];
      } else if (rowData.fulfillment_status === 'PartiallyFulfilled') {
        return [
          {
            label: 'View item order',
            action: handleViewItemOrder
          },
          {
            label: `Mark as ${orderStatusFlagMap?.Closed?.label || 'complete'}`,
            action: handleComplete
          }
        ];
      }
    }
    return [
      {
        label: 'View item order',
        action: handleViewItemOrder
      }
    ];
  };

  const validateInput = (id) => {
    const tempErrorMessageMap = { ...errorMessageMap };
    const creationDateValue =
      editedValues.creation_date !== undefined ? editedValues.creation_date : data.creation_date;
    const creationDate = new Date(creationDateValue).setHours(0, 0, 0, 0);
    switch (id) {
      case 'identifier':
        if (editedValues[id] !== undefined && !editedValues[id]?.trim()) {
          tempErrorMessageMap[id] = 'Purchase Order # cannot be empty';
        } else {
          delete tempErrorMessageMap[id];
        }
        break;
      case 'scheduled_ship_date':
      case 'fulfillment_date':
      case 'cancel_date':
        if (editedValues[id]) {
          if (isNaN(editedValues[id].getTime())) {
            tempErrorMessageMap[id] = 'Please enter a valid date';
          } else if (creationDate > editedValues[id].getTime()) {
            tempErrorMessageMap[id] = 'Date cannot be before Purchase Order Date';
          } else {
            delete tempErrorMessageMap[id];
          }
        } else {
          delete tempErrorMessageMap[id];
        }
        break;
      case 'creation_date':
        if (editedValues.creation_date !== undefined && !editedValues.creation_date) {
          tempErrorMessageMap[id] = 'Purchase Order Date cannot be empty';
        } else if (editedValues.creation_date && isNaN(editedValues.creation_date.getTime())) {
          tempErrorMessageMap[id] = 'Please enter a valid date';
        } else {
          delete tempErrorMessageMap[id];
        }
        break;
      default:
        break;
    }
    setErrorMessageMap(tempErrorMessageMap);
  };

  const onDateAccept = (id, value) => {
    const tempErrorMessageMap = { ...errorMessageMap };
    const creationDateValue =
      editedValues.creation_date !== undefined ? editedValues.creation_date : data.creation_date;
    const creationDate = new Date(creationDateValue).setHours(0, 0, 0, 0);
    switch (id) {
      case 'scheduled_ship_date':
      case 'fulfillment_date':
      case 'cancel_date':
        if (creationDate > value.getTime()) {
          tempErrorMessageMap[id] = 'Date cannot be before Purchase Order Date';
        } else {
          delete tempErrorMessageMap[id];
        }
        break;
      default:
        delete tempErrorMessageMap[id];
        break;
    }

    setErrorMessageMap(tempErrorMessageMap);
  };

  const renderLoading = () => {
    return (
      <div className="loading_circle">
        <Spinner animation="border" />
      </div>
    );
  };

  const handleEdit = (id, value) => {
    const tempEditedValues = { ...editedValues };
    tempEditedValues[id] = value;
    setEditedValues(tempEditedValues);
    if (!hasEdits) {
      setHasEdits(true);
    }
  };

  const handleItemOrderEdit = (id, property, value) => {
    const tempEditedItemOrders = { ...editedItemOrders };
    if (!tempEditedItemOrders[id]) {
      tempEditedItemOrders[id] = {};
    }
    tempEditedItemOrders[id][property] = value;
    setEditedItemOrders(tempEditedItemOrders);
    if (!hasEdits) {
      setHasEdits(true);
    }
  };

  const onItemTypeSelect = (id, property, value) => {
    const tempEditedItemOrders = { ...editedItemOrders };
    const tempItemOrderErrorMap = { ...itemOrderErrorMap };
    const itemType = data.itemTypes.find((type) => type.identifier === value);
    tempEditedItemOrders[id]['identifier'] = value;
    tempEditedItemOrders[id]['itemTypeId'] = itemType?.id;

    if (tempItemOrderErrorMap[id]) {
      delete tempItemOrderErrorMap[id]['identifier'];
      if (Object.keys(tempItemOrderErrorMap[id]).length === 0) {
        delete tempItemOrderErrorMap[id];
      }
    }
    setItemOrderErrorMap(tempItemOrderErrorMap);
    setEditedItemOrders(tempEditedItemOrders);
  };

  const onItemOrderBlur = (id, property) => {
    const tempEditedItemOrders = { ...editedItemOrders };
    const tempItemOrderErrorMap = { ...itemOrderErrorMap };
    const tempEditedValues = { ...editedValues };
    const tempData = { ...data };
    const currentRow = tempEditedItemOrders[id];
    const defaultData = tempData.itemOrders.find((element) => element.id === id);
    //validate inputs for row
    if (currentRow && currentRow[property] !== undefined) {
      if (!currentRow[property]) {
        if (!tempItemOrderErrorMap[id]) {
          tempItemOrderErrorMap[id] = {};
        }
        tempItemOrderErrorMap[id][property] = 'Input cannot be empty';
      } else {
        const parsedValue = Number.parseFloat(currentRow[property].replaceAll(',', ''));
        if (isNaN(parsedValue)) {
          if (!tempItemOrderErrorMap[id]) {
            tempItemOrderErrorMap[id] = {};
          }
          tempItemOrderErrorMap[id][property] = 'Please enter a number';
        } else if (parsedValue < 0) {
          if (!tempItemOrderErrorMap[id]) {
            tempItemOrderErrorMap[id] = {};
          }
          tempItemOrderErrorMap[id][property] = 'Please enter a positive number';
        } else if (property === 'quantity_ordered' && parsedValue === 0) {
          if (!tempItemOrderErrorMap[id]) {
            tempItemOrderErrorMap[id] = {};
          }
          tempItemOrderErrorMap[id][property] = 'Quantity cannot be 0';
        } else {
          switch (property) {
            case 'quantity_ordered':
              tempEditedItemOrders[id][property] = formatNumber(parsedValue, 0);
              break;
            case 'direct_unit_cost':
              let decimalPlaces = 2;
              const splitUnitCost = parsedValue.toString().split('.');
              if (splitUnitCost?.length === 2 && splitUnitCost[1].length > 2) {
                decimalPlaces = splitUnitCost[1].length;
              }
              tempEditedItemOrders[id][property] = formatNumber(parsedValue, decimalPlaces);
              break;
            case 'shipping_charge':
              tempEditedItemOrders[id][property] = formatNumber(parsedValue, 2);
              break;
            default:
              break;
          }

          if (tempItemOrderErrorMap[id]) {
            delete tempItemOrderErrorMap[id][property];
            if (Object.keys(tempItemOrderErrorMap[id]).length === 0) {
              delete tempItemOrderErrorMap[id];
            }
          }
        }
      }
    }

    if (!tempItemOrderErrorMap[id]) {
      //recalculate for given row
      const quantity_ordered = currentRow?.quantity_ordered || defaultData.quantity_ordered;
      const parsedQuantity = Number.parseFloat(quantity_ordered.replaceAll(',', ''));

      const parsedQuantityFulfilled = Number.parseFloat(
        defaultData.quantity_fulfilled.replaceAll(',', '')
      );

      const unitCost = currentRow?.direct_unit_cost || defaultData.direct_unit_cost;
      const parsedUnitCost = Number.parseFloat(unitCost.replaceAll(',', ''));

      const shippingCharge = currentRow?.shipping_charge || defaultData.shipping_charge;
      const parsedShippingCharge = Number.parseFloat(shippingCharge.replaceAll(',', ''));

      const total = parsedQuantity * parsedUnitCost + parsedShippingCharge;
      const formattedTotal = formatNumber(total, 2);

      let totalFulfilled = parsedQuantityFulfilled * parsedUnitCost;
      if (parsedQuantityFulfilled > 0) {
        totalFulfilled += parsedShippingCharge;
      }
      const formattedTotalFulfilled = formatNumber(totalFulfilled, 2);

      if (!tempEditedItemOrders[id]) {
        tempEditedItemOrders[id] = {};
      }

      if (property === 'quantity_ordered') {
        if (parsedQuantityFulfilled > 0 && parsedQuantity <= parsedQuantityFulfilled) {
          tempEditedItemOrders[id]['order_status'] = 'Closed';
          tempEditedItemOrders[id]['fulfillment_status'] = 'Fulfilled';
          defaultData.orderStatusLabel =
            orderStatusFlagMap['Closed'] && orderStatusFlagMap['Closed'].label;
          defaultData.orderStatusColor =
            orderStatusFlagMap['Closed'] && orderStatusFlagMap['Closed'].color;
          defaultData.fulfillmentStatusLabel =
            fulfillmentStatusFlagMap['Fulfilled'] && fulfillmentStatusFlagMap['Fulfilled'].label;
          defaultData.fulfillmentStatusColor =
            fulfillmentStatusFlagMap['Fulfilled'] && fulfillmentStatusFlagMap['Fulfilled'].color;

          let shouldComplete = true;
          let shouldFulfill = true;

          tempData.itemOrders.forEach((order) => {
            const editedOrder = tempEditedItemOrders[order.id];
            const fulfillmentStatus = editedOrder?.fulfillment_status || order.fulfillment_status;
            const orderStatus = editedOrder?.order_status || order.order_status;
            if (orderStatus !== 'Closed' && orderStatus !== 'Cancelled') {
              shouldComplete = false;
            }
            if (orderStatus === 'Closed' && fulfillmentStatus !== 'Fulfilled') {
              shouldFulfill = false;
            }
          });

          if (shouldComplete) {
            tempEditedValues.order_status = 'Closed';
            if (shouldFulfill) {
              tempEditedValues.fulfillment_status = 'Fulfilled';
            }
          }
        } else if (parsedQuantityFulfilled > 0 && parsedQuantity > parsedQuantityFulfilled) {
          tempEditedItemOrders[id]['order_status'] = 'Open';
          tempEditedItemOrders[id]['fulfillment_status'] = 'PartiallyFulfilled';
          defaultData.orderStatusLabel =
            orderStatusFlagMap['Open'] && orderStatusFlagMap['Open'].label;
          defaultData.orderStatusColor =
            orderStatusFlagMap['Open'] && orderStatusFlagMap['Open'].color;
          defaultData.fulfillmentStatusLabel =
            fulfillmentStatusFlagMap['PartiallyFulfilled'] &&
            fulfillmentStatusFlagMap['PartiallyFulfilled'].label;
          defaultData.fulfillmentStatusColor =
            fulfillmentStatusFlagMap['PartiallyFulfilled'] &&
            fulfillmentStatusFlagMap['PartiallyFulfilled'].color;
          tempEditedValues.order_status = 'Open';
          tempEditedValues.fulfillment_status = 'PartiallyFulfilled';
        }
      }

      tempEditedItemOrders[id]['total_cost_by_quantity_ordered'] = formattedTotal;
      tempEditedItemOrders[id]['total_cost_by_quantity_fulfilled'] = formattedTotalFulfilled;
    }

    let poTotal = 0;
    let poFulfilledTotal = 0;

    data.itemOrders.forEach((order) => {
      const {
        id,
        total_cost_by_quantity_ordered,
        total_cost_by_quantity_fulfilled,
        order_status
      } = order;
      const editedOrder = tempEditedItemOrders[id];
      const orderStatus = editedOrder?.order_status || order_status;
      if (orderStatus !== 'Cancelled') {
        if (editedOrder?.total_cost_by_quantity_ordered) {
          poTotal += Number.parseFloat(
            editedOrder.total_cost_by_quantity_ordered.replaceAll(',', '')
          );
          poFulfilledTotal += Number.parseFloat(
            editedOrder.total_cost_by_quantity_fulfilled.replaceAll(',', '')
          );
        } else {
          poTotal += Number.parseFloat(total_cost_by_quantity_ordered.replaceAll(',', ''));
          poFulfilledTotal += Number.parseFloat(
            total_cost_by_quantity_fulfilled.replaceAll(',', '')
          );
        }
      }
    });

    tempEditedValues.total_cost_by_quantities_ordered = poTotal;
    tempEditedValues.total_cost_by_quantities_fulfilled = poFulfilledTotal;

    setData(tempData);
    setEditedValues(tempEditedValues);
    setEditedItemOrders(tempEditedItemOrders);
    setItemOrderErrorMap(tempItemOrderErrorMap);
  };

  const handleCancel = (comment) => {
    const tempValueMap = { ...data };
    const tempEditedItemOrders = { ...editedItemOrders };
    const tempEditedValues = { ...editedValues };
    const itemOrder = tempValueMap.itemOrders.find((order) => order.id === itemOrderToCancel);
    itemOrder.orderStatusColor = orderStatusFlagMap.Cancelled && orderStatusFlagMap.Cancelled.color;
    itemOrder.orderStatusLabel = orderStatusFlagMap.Cancelled && orderStatusFlagMap.Cancelled.label;

    if (!tempEditedItemOrders[itemOrderToCancel]) {
      tempEditedItemOrders[itemOrderToCancel] = {};
    }
    tempEditedItemOrders[itemOrderToCancel]['order_status'] = 'Cancelled';
    tempEditedItemOrders[itemOrderToCancel]['comments'] = comment;
    tempEditedItemOrders[itemOrderToCancel]['cancel_date'] = new Date().getTime();

    let poTotal = 0;
    let cancelledCost = 0;
    let shouldCancel = true;
    let shouldComplete = true;
    let shouldFulfill = true;

    tempValueMap.itemOrders.forEach((order) => {
      const { id, total_cost_by_quantity_ordered, order_status, fulfillment_status } = order;
      const editedOrder = tempEditedItemOrders[id];
      const orderStatus = editedOrder?.order_status || order_status;
      const fulfillmentStatus = editedOrder?.fulfillment_status || fulfillment_status;
      if (orderStatus !== 'Cancelled') {
        if (editedOrder?.total_cost_by_quantity_ordered) {
          poTotal += Number.parseFloat(
            editedOrder.total_cost_by_quantity_ordered.replaceAll(',', '')
          );
        } else {
          poTotal += Number.parseFloat(total_cost_by_quantity_ordered.replaceAll(',', ''));
        }
        shouldCancel = false;
        if (orderStatus !== 'Closed') {
          shouldComplete = false;
        }
        if (fulfillmentStatus !== 'Fulfilled') {
          shouldFulfill = false;
        }
      } else {
        if (editedOrder?.total_cost_by_quantity_ordered) {
          cancelledCost += Number.parseFloat(
            editedOrder.total_cost_by_quantity_ordered.replaceAll(',', '')
          );
        } else {
          cancelledCost += Number.parseFloat(total_cost_by_quantity_ordered.replaceAll(',', ''));
        }
      }
    });

    // shouldn't need to recalculate actual costs when cancelling because you shouldn't be able to
    // cancel partially fulfilled orders
    if (shouldCancel) {
      tempEditedValues.total_cost_by_quantities_ordered = cancelledCost;
      tempEditedValues.order_status = 'Cancelled';
    } else {
      if (shouldComplete) {
        tempEditedValues.order_status = 'Closed';
        if (shouldFulfill) {
          tempEditedValues.fulfillment_status = 'Fulfilled';
        }
      }
      tempEditedValues.total_cost_by_quantities_ordered = poTotal;
    }

    if (!hasEdits) {
      setHasEdits(true);
    }

    setData(tempValueMap);
    setEditedItemOrders(tempEditedItemOrders);
    setEditedValues(tempEditedValues);
    setItemOrderToCancel(undefined);
    setPopperFormComponent(undefined);
  };

  const handleAddItemOrder = () => {
    const tempValueMap = { ...data };
    const tempEditedItemOrders = { ...editedItemOrders };
    const tempEditedValues = { ...editedValues };
    const id = `NEW-${Math.random()}`;
    //the hard coded unit is temporary
    tempValueMap.itemOrders.push({
      id,
      quantity_ordered: '0',
      quantity_fulfilled: '0',
      direct_unit_cost: '0.00',
      unit: 'lbs',
      shipping_charge: '0.00',
      total_cost_by_quantity_ordered: '0.00',
      total_cost_by_quantity_fulfilled: '0.00',
      order_status: 'Open',
      fulfillment_status: 'Unfulfilled'
    });

    tempEditedItemOrders[id] = {
      quantity_ordered: '0',
      quantity_fulfilled: '0',
      direct_unit_cost: '0.00',
      unit: 'lbs',
      shipping_charge: '0.00',
      total_cost_by_quantity_ordered: '0.00',
      total_cost_by_quantity_fulfilled: '0.00',
      order_status: 'Open',
      fulfillment_status: 'Unfulfilled'
    };

    if (tempValueMap.order_status === 'Closed') {
      tempEditedValues.order_status = 'Open';
      tempEditedValues.fulfillment_status = 'PartiallyFulfilled';
    }

    if (!hasEdits) {
      setHasEdits(true);
    }
    setData(tempValueMap);
    setEditedValues(tempEditedValues);
    setEditedItemOrders(tempEditedItemOrders);
  };

  const handleRemove = (id) => {
    const tempValueMap = { ...data };
    const tempEditedItemOrders = { ...editedItemOrders };
    const tempEditedValues = { ...editedValues };
    const tempItemOrderErrorMap = { ...itemOrderErrorMap };

    const orderIndex = tempValueMap.itemOrders.findIndex((order) => order.id === id);

    if (orderIndex > -1) {
      tempValueMap.itemOrders.splice(orderIndex, 1);
    }

    delete tempEditedItemOrders[id];
    delete tempItemOrderErrorMap[id];

    let poTotal = 0;
    let cancelledCost = 0;
    let shouldCancel = true;
    let shouldComplete = true;
    let shouldFulfill = true;

    data.itemOrders.forEach((order) => {
      const { id, total_cost_by_quantity_ordered, order_status, fulfillment_status } = order;
      const editedOrder = tempEditedItemOrders[id];
      const fulfillmentStatus = editedOrder?.fulfillment_status || fulfillment_status;
      const orderStatus = editedOrder?.order_status || order_status;
      if (orderStatus !== 'Cancelled') {
        if (editedOrder?.total_cost_by_quantity_ordered) {
          poTotal += Number.parseFloat(
            editedOrder.total_cost_by_quantity_ordered.replaceAll(',', '')
          );
        } else {
          poTotal += Number.parseFloat(total_cost_by_quantity_ordered.replaceAll(',', ''));
        }
        shouldCancel = false;
        if (orderStatus !== 'Fulfilled') {
          shouldComplete = false;
        }
        if (fulfillmentStatus !== 'Fulfilled') {
          shouldFulfill = false;
        }
      } else {
        if (editedOrder?.total_cost_by_quantity_ordered) {
          cancelledCost += Number.parseFloat(
            editedOrder.total_cost_by_quantity_ordered.replaceAll(',', '')
          );
        } else {
          cancelledCost += Number.parseFloat(total_cost_by_quantity_ordered.replaceAll(',', ''));
        }
      }
    });

    if (!isNew && shouldCancel) {
      tempEditedValues.total_cost_by_quantities_ordered = cancelledCost;
      tempEditedValues.order_status = 'Cancelled';
    } else {
      if (!isNew && shouldComplete) {
        tempEditedValues.order_status = 'Closed';
        if (shouldFulfill) {
          tempEditedValues.fulfillment_status = 'Fulfilled';
        }
      }
      tempEditedValues.total_cost_by_quantities_ordered = poTotal;
    }

    setData(tempValueMap);
    setEditedItemOrders(tempEditedItemOrders);
    setItemOrderErrorMap(tempItemOrderErrorMap);
    setEditedValues(tempEditedValues);
  };

  const handleLocationChange = (event) => {
    const location = data.locations.find((location) => location.id === event.target.value);
    location && location.hasAddress.length
      ? setShippingAddress(location.hasAddress[0])
      : setShippingAddress({});
    setPopperFormComponent(undefined);
    setLocationId(event.target.value);
    if (!hasEdits) {
      setHasEdits(true);
    }
  };

  const handleVendorChange = (event) => {
    handleEdit('vendor', event.target.value);
    const partner = data.partners.find((partner) => partner.id === event.target.value);
    partner && partner.hasBusinessAddress.length
      ? setMailingAddress(partner.hasBusinessAddress[0])
      : setMailingAddress({});
    setPopperFormComponent(undefined);
    if (!hasEdits) {
      setHasEdits(true);
    }
  };

  const handleAddressFormSubmit = (payload) => {
    payload.id = 'NEW';
    payload.country_code = 'US';
    if (popperFormComponent === 'shipping_address') {
      setShippingAddress(payload);
    } else {
      setMailingAddress(payload);
    }
    setPopperFormComponent(undefined);
    if (!hasEdits) {
      setHasEdits(true);
    }
  };

  const renderBody = () => {
    if (loading) {
      return renderLoading();
    } else if (mainBodyError) {
      return <div className="loading_circle">{mainBodyError}</div>;
    } else {
      return (
        <PurchaseOrderDetailBody
          valueMap={data}
          buyer={buyer}
          currencyType={currencyType || 'USD'}
          handleEdit={handleEdit}
          editedValues={editedValues}
          shippingAddress={shippingAddress}
          mailingAddress={mailingAddress}
          locationId={locationId}
          handleLocationChange={handleLocationChange}
          handleVendorChange={handleVendorChange}
          handleAddressFormSubmit={handleAddressFormSubmit}
          errorMessageMap={errorMessageMap}
          onDateAccept={onDateAccept}
          validationFunc={validateInput}
          isCancelled={data && data.order_status === 'Cancelled'}
          isNew={isNew}
          handleItemOrderEdit={handleItemOrderEdit}
          editedItemOrders={editedItemOrders}
          itemOrderErrorMap={itemOrderErrorMap}
          onItemOrderBlur={onItemOrderBlur}
          popperFormComponent={popperFormComponent}
          setPopperFormComponent={handleSetPopperFormComponent}
          getOptions={getOptions}
          handleCancel={handleCancel}
          handleAddItemOrder={handleAddItemOrder}
          onItemTypeSelect={onItemTypeSelect}
          errorBannerMessage={errorBannerMessage}
          closeBannerFunc={handleCloseBanner}
        />
      );
    }
  };

  return (
    <ModalForm
      scrollable
      show={show}
      title={
        <PurchaseOrderDetailHeader
          onClose={handleClose}
          minimalHeader={loading || !!mainBodyError}
          title={data && data.identifier}
          orderStatusLabel={
            data &&
            orderStatusFlagMap[editedValues.order_status || data.order_status] &&
            orderStatusFlagMap[editedValues.order_status || data.order_status].label
          }
          orderStatusColor={
            data &&
            orderStatusFlagMap[editedValues.order_status || data.order_status] &&
            orderStatusFlagMap[editedValues.order_status || data.order_status].color
          }
          fulfillmentStatusColor={
            data &&
            fulfillmentStatusFlagMap[editedValues.fulfillment_status || data.fulfillment_status] &&
            fulfillmentStatusFlagMap[editedValues.fulfillment_status || data.fulfillment_status]
              .color
          }
          fulfillmentStatusLabel={
            data &&
            fulfillmentStatusFlagMap[editedValues.fulfillment_status || data.fulfillment_status] &&
            fulfillmentStatusFlagMap[editedValues.fulfillment_status || data.fulfillment_status]
              .label
          }
          isCancelled={data && data.order_status === 'Cancelled'}
          amount={
            editedValues.total_cost_by_quantities_ordered !== undefined
              ? editedValues.total_cost_by_quantities_ordered
              : data?.total_cost_by_quantities_ordered
          }
          currencyType={currencyType || 'USD'}
          isNew={isNew}
        />
      }
      body={renderBody()}
      footer={
        !loading && (
          <PurchaseOrderDetailFooter
            onClose={handleClose}
            showShareForm={showShareForm}
            toggleShareForm={toggleShareForm}
            hasEdits={hasEdits}
            setSubmitTicket={setSubmitTicket}
            handleDownloadPDF={handleDownloadPDFCallback}
            isNew={isNew}
          />
        )
      }
      prefix="po"
      className="purchaseOrderDetailsModal"
    />
  );
};

PurchaseOrderDetailModalFeature.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  purchaseOrderId: PropTypes.string
};
