import React, { useCallback, useState, useEffect } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';

export const TextInput = ({
  label,
  placeholder,
  inputClassName,
  initialValue,
  onChange,
  onBlur,
  readonly,
  autoPopulate,
  incomingValue
}) => {
  const [componentId] = useState(v4());
  const [value, setValue] = useState(initialValue || '');

  const onChangeCallback = useCallback(
    (event) => {
      const { value: inputValue } = event.target;
      setValue(inputValue);
      onChange(inputValue);
    },
    [onChange]
  );

  const onBlurCallback = useCallback(
    (event) => {
      const { value: inputValue } = event.target;
      setValue(inputValue.trim());
      onBlur(inputValue.trim());
    },
    [onBlur]
  );

  useEffect(() => {
    if(incomingValue && incomingValue !== value) {
      setValue(incomingValue)
    }
  }, [incomingValue])

  return (
    <FormGroup>
      <Label for={componentId} className="form-label">
        {label}
      </Label>
      <Input
        id={componentId}
        type="text"
        className={inputClassName}
        value={value}
        onChange={onChangeCallback}
        onBlur={onBlurCallback}
        placeholder={placeholder}
        readOnly={readonly}
        disabled={autoPopulate}
      />
    </FormGroup>
  );
};

TextInput.defaultProps = {
  placeholder: '',
  inputClassName: '',
  initialValue: '',
  onChange: () => {},
  onBlur: () => {},
  readonly: false
};

TextInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  placeholder: PropTypes.string,
  inputClassName: PropTypes.string,
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  readonly: PropTypes.bool
};
