import React from 'react';
import Style from './LoadingCircle.module.css';

const LoadingCircle = ({ message, messageStyle = null, shouldCenter, isSmall }) => {
  return (
    <div className={shouldCenter ? Style.container_scaled : Style.container}>
      <div className={isSmall ? Style.lds_roller_scaled : Style.lds_roller}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
      <div className={messageStyle}>{message}</div>
    </div>
  );
};

export default LoadingCircle;
