import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Card } from 'mdbreact';
import { Delete, Edit, AccountCircleOutlined } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import Style from './UsersPage.module.css';
import UserService from '../../services/UserService';
import { LocalCacheService } from '../../services/local-cache-service';
import AuthService from '../../services/AuthService';
import ConfigurationService from '../../services/ConfigurationService';
import { useXemelgoClient } from '../../services/xemelgo-service';
import { useAppConfigProvider } from '../../services/soft-cache-service';
import ListView from '../../components/TrackPageComponents/ListView';
import UserModal from '../../components/users-page/UserModal';
import LoadingCircle from '../../components/loading/LoadingCircle';
import SearchableTabList from '../../components/searchable-tab-list/SearchableTabList';
import DisplayBanner from '../../components/display-banner/DisplayBanner';
import { genericSort, naturalSort } from '../../common/Utilities';
const RESEND_PASSWORD_STATUS = 'FORCE_CHANGE_PASSWORD';
const defaultSortMethod = 'name';
const APP_ID = 'usersPage';

const personFields = ['id', 'identifier', 'given_name', 'family_name', 'cognito_id'];

const attributes = {
  givenName: {
    label: 'First Name',
    index: 1,
    editable: false,
    required: true,
    type: 'input'
  },
  familyName: {
    label: 'Last Name',
    index: 2,
    editable: false,
    required: true,
    type: 'input'
  },
  email: {
    label: 'E-mail',
    index: 3,
    editable: false,
    required: true,
    hiddenForVendorClientUser: true,
    type: 'email'
  },
  phoneNumber: {
    label: 'Phone',
    index: 4,
    editable: false,
    hiddenForVendorClientUser: true,
    type: 'phone'
  },
  alias: {
    autoGenerate: true,
    editable: true,
    index: 6,
    label: 'Alias',
    type: 'input'
  },
  roles: {
    hiddenForVendorClientUser: true,
    index: 7,
    label: 'Roles',
    maximumChecked: 1,
    minimumChecked: 0,
    options: [
      {
        id: 'admin',
        label: 'Admin'
      }
    ],
    type: 'checkboxGroup'
  }
};

const UsersPageFeature = ({ history }) => {
  const client = useXemelgoClient();
  const [spClient] = useState(client.getSensorProfileClient());
  const configProvider = useAppConfigProvider(APP_ID);
  const usersPageConfig = configProvider.config;
  const {
    customerName: tenantName = '',
    hasVendorClients = false,
    hasUserTracking = false,
    additionalAttributes = {}
  } = usersPageConfig;
  const combinedAttributes = { ...attributes, ...additionalAttributes };

  const genericListHeaders = [
    {
      id: 'displayName',
      label: 'NAME',
      default: defaultSortMethod === 'displayName'
    },
    {
      id: 'email',
      label: 'EMAIL ADDRESS',
      hiddenForVendorClients: true
    },
    {
      id: 'role',
      label: 'ROLE',
      hiddenForVendorClients: true
    },
    {
      id: 'alias',
      label: 'ALIAS'
    }
  ];

  if (hasUserTracking) {
    const tagHeader = {
      id: 'sensorProfile',
      label: 'TAG ID'
    };
    genericListHeaders.splice(3, 0, tagHeader);
  }

  genericListHeaders.forEach((header) => {
    header.renderComponent = (value, id) =>
      renderCell(header.id === 'sensorProfile' ? value.vid : value, id, header.id);
  });

  const isAdmin = useRef(false);
  const tenantId = useRef('');
  const aliasStore = useRef([]);

  const [userAttributes] = useState(combinedAttributes);
  const [loading, setLoading] = useState(true);
  const [currentAction, setCurrentAction] = useState({});
  const [currentPartner, setCurrentPartner] = useState({});
  const [partnerTabs, setPartnerTabs] = useState({});
  const [usersList, setUsersList] = useState({});
  const [formData, setFormData] = useState({});
  const [showBanner, setShowBanner] = useState(false);
  const [bannerError, setBannerError] = useState(false);
  const [bannerMessage, setBannerMessage] = useState('');
  const [listHeaders, setListHeaders] = useState(genericListHeaders);

  const usersRef = useRef(usersList);
  usersRef.current = usersList;

  const initializeUserService = async (userService) => {
    const authConfig = ConfigurationService.getAuthConfiguration();
    const credentials = await AuthService.retrieveEssentialCredentials();

    const config = {
      region: authConfig.Auth.region,
      userPoolId: authConfig.Auth.userPoolId,
      credentials
    };

    await userService.configure(config);
  };

  useEffect(() => {
    initializeUserService(UserService).then(async () => {
      const userProfile = LocalCacheService.loadUserProfile();
      const isUserAdmin = userProfile.isUserSuperAdmin();
      const tenantIdToUpdate = userProfile.getTenantId();

      isAdmin.current = isUserAdmin;
      tenantId.current = tenantIdToUpdate;
      if (!isUserAdmin) {
        const landingPage = LocalCacheService.loadUserConfig().getLandingPage();
        history.push(landingPage);
      } else {
        await onLoad();
      }
    });
  }, []);

  useEffect(() => {
    const { action = '', user = {}, isVendorClientUser } = currentAction;
    const formDataToUpdate = {};
    if (action.length) {
      for (const key of Object.keys(userAttributes)) {
        const { type, options, hiddenForVendorClientUser } = userAttributes[key];
        if (isVendorClientUser && hiddenForVendorClientUser) {
          continue;
        }
        let value = user[key] || '';
        if (type === 'checkboxGroup') {
          value = {};
          options.forEach((option) => {
            value[option.id] = user[option.id] || false;
            // To not allow removing of already assigned roles
            if (user[option.id]) {
              value.disabled = true;
            }
          });
        }
        if (key === 'sensorProfile') {
          value = user[key]?.vid;
        }
        formDataToUpdate[key] = {
          ...userAttributes[key],
          disabled: action === 'edit' && !userAttributes[key].editable,
          value
        };
      }
    }
    setFormData(formDataToUpdate);
  }, [currentAction, userAttributes]);

  useEffect(() => {
    let listHeadersToSet = [...genericListHeaders];

    if (hasVendorClients && currentPartner.identifier !== tenantName) {
      listHeadersToSet = listHeadersToSet.filter((header) => !header.hiddenForVendorClients);
    }

    setListHeaders(listHeadersToSet);
  }, [currentPartner, hasVendorClients, tenantName]);

  const generateAlias = (param1, param2) => {
    let generatedValue = `${param1.slice(0, 1) + param2}`.toLowerCase();
    const aliasExists = aliasStore.current.filter((alias) => alias.includes(generatedValue));
    // If given alias already exists, append a number at the end
    if (aliasExists.length) {
      aliasExists.sort();
      const latestExistingAlias = aliasExists.slice(-1).pop();
      // check if the latest existing alias already has a number appended
      if (/\d+$/.test(latestExistingAlias)) {
        /*
          Increment the suffix by 1
          This assumes that names won't have numbers in them
        */
        generatedValue = latestExistingAlias.replace(/\d+$/, (n) => ++n);
      } else {
        generatedValue = `${generatedValue}1`;
      }
    }
    return generatedValue;
  };

  const onLoad = async () => {
    // Clear states
    setLoading(true);

    await listUsers();
    if (hasVendorClients) {
      await listVendorClients();
    }
    if (!Object.keys(currentPartner).length) {
      setCurrentPartner({ id: 'tenant', identifier: tenantName });
    }

    setLoading(false);
  };

  const listUsers = async () => {
    const aliasStoreToUpdate = [];
    const PersonClient = client.getPersonClient();
    let personsList = await PersonClient.listPersonsWithDetails(
      personFields,
      hasUserTracking,
      hasVendorClients
    );
    const cognitoUsers = await UserService.getUsersInGroup(tenantId.current);

    personsList.forEach((person) => {
      aliasStoreToUpdate.push(person.identifier);
    });

    aliasStore.current = aliasStoreToUpdate;

    // ensuring all cognito users have a person node (the opposite is not true)
    let needToReload = false;
    for (const cognitoUser of cognitoUsers) {
      // cognito id is stored as Username in cognito
      const { Username } = cognitoUser;
      const personNodeExists = personsList.find((person) => person.cognito_id === Username);
      if (!personNodeExists) {
        needToReload = true;
        const givenName = UserService.getAttribute(cognitoUser, 'given_name');
        const familyName = UserService.getAttribute(cognitoUser, 'family_name');
        const alias = generateAlias(givenName, familyName);
        await PersonClient.createPerson(alias, givenName, familyName, Username);
        aliasStoreToUpdate.push(alias);
      }
      aliasStore.current = aliasStoreToUpdate;
    }
    if (needToReload) {
      await onLoad();
      return;
    }

    const usersObject = {};

    // Only show person nodes who have a cognito id if it is not a vendor client solution
    if (!hasVendorClients) {
      personsList = personsList.filter((person) => person.cognito_id);
    }

    personsList.forEach((person) => {
      const {
        id,
        worksFor,
        associatedWithSession,
        hasSensorProfile,
        identifier: alias,
        given_name: givenName,
        family_name: familyName
      } = person;
      const customer = worksFor && worksFor[0]?.identifier;
      const trackingSession = associatedWithSession && associatedWithSession[0];
      const sensorProfile = hasSensorProfile && hasSensorProfile[0];
      const displayName = `${`${givenName} ${familyName}`}`;

      const personObject = {
        alias,
        givenName,
        familyName,
        displayName,
        id,
        customer,
        trackingSession,
        sensorProfile
      };

      const cognitoUser = cognitoUsers.find((user) => user.Username === person.cognito_id);
      if (cognitoUser) {
        personObject.cognitoId = cognitoUser.Username;
        personObject.email = UserService.getAttribute(cognitoUser, 'email');
        personObject.admin = UserService.getAttribute(cognitoUser, 'custom:admin') === 'true';
        personObject.role =
          UserService.getAttribute(cognitoUser, 'custom:admin') === 'true' ? 'Admin' : '-';
        const status = cognitoUser.UserStatus;
        if (status === RESEND_PASSWORD_STATUS) {
          personObject.status = cognitoUser.UserStatus;
          personObject.countDownTimer = 0;
        }
      }

      const customerName = customer || tenantName;
      // group users by partners
      usersObject[customerName] = [personObject, ...(usersObject[customerName] || [])];
    });

    Object.keys(usersObject).forEach((group) => {
      usersObject[group] = genericSort(usersObject[group], 'displayName');
    });

    setUsersList(usersObject);
  };

  const listVendorClients = async () => {
    const PartnerClient = client.getPartnerClient();
    const LocationClient = client.getLocationClient();

    let partnersList = await PartnerClient.listPartners();

    const customersLocations = await LocationClient.getLocationsOfCategory('Customer');

    let needToReload = false;

    for (const customerLocation of customersLocations) {
      const partnerExists = partnersList.find(
        (partner) => partner.identifier === customerLocation.name
      );
      if (!partnerExists) {
        needToReload = true;
        await PartnerClient.createPartner(customerLocation.name);
      }
    }

    if (needToReload) {
      await onLoad();
      return;
    }

    // Add parent tenant to partners list
    partnersList.push({ id: 'tenant', identifier: tenantName });

    partnersList = naturalSort(partnersList, 'identifier');

    // Set the parent tenant at the top of the user groups list
    partnersList.sort((x, y) => {
      return x.identifier === tenantName ? -1 : y.identifier === tenantName ? 1 : 0;
    });

    const partnerTabsToUpdate = {
      title: 'User Groups',
      tabs: partnersList
    };
    setPartnerTabs(partnerTabsToUpdate);
  };

  const createUser = async () => {
    let needToReturn;

    setShowBanner(false);
    setBannerError(false);
    setBannerMessage('');

    const formDataToValidate = { ...formData };
    const { isVendorClientUser } = currentAction;

    if (hasUserTracking && formData.sensorProfile) {
      const spExists = await spClient.getSensorProfileByVid(formData.sensorProfile.value);

      if (spExists) {
        formDataToValidate.sensorProfile.error = true;
        formDataToValidate.sensorProfile.errorMessage =
          'The provided RFID Tag is already being tracked';
      }
    }

    if (formData.alias) {
      const aliasExists = aliasStore.current.find((alias) => alias === formData.alias.value);
      if (aliasExists) {
        formDataToValidate.alias.error = true;
        formDataToValidate.alias.errorMessage =
          'The provided alias is already associated with another user';
      }
    }

    Object.keys(formDataToValidate).forEach((key) => {
      const { autoGenerate } = formDataToValidate[key];
      const givenName = formDataToValidate.givenName.value;
      const familyName = formDataToValidate.familyName.value;
      if (autoGenerate) {
        if (!formDataToValidate[key].value && givenName && familyName) {
          formDataToValidate[key].value = generateAlias(givenName, familyName);
        }
      }
    });

    Object.keys(formDataToValidate).forEach((key) => {
      if (typeof formDataToValidate[key].value === 'string') {
        formDataToValidate[key].value = formDataToValidate[key].value.trim();
      }
      const { required, value, label } = formDataToValidate[key];
      if (required && !value.length) {
        formDataToValidate[key].error = true;
        formDataToValidate[key].errorMessage = `${label} is a required field`;
      }
    });

    Object.keys(formDataToValidate).forEach((key) => {
      const { error } = formDataToValidate[key];
      if (error) {
        needToReturn = true;
      }
    });

    if (needToReturn) {
      setFormData(formDataToValidate);
      return;
    }

    setLoading(true);
    const PersonClient = client.getPersonClient();

    let cognitoId;
    if (!isVendorClientUser && formData.email.value) {
      const payload = {
        username: formData.email.value,
        email: formData.email.value,
        phone: formData.phoneNumber.formattedValue,
        verifyMethod: 'email',
        givenName: formData.givenName.value,
        familyName: formData.familyName.value,
        /*
          isAdmin has to be string 'true' and not boolean since only string and
          number are supported by Cognito for custom attributes
        */
        isAdmin: formData.roles.value.admin ? 'true' : 'false',
        tenantId: tenantId.current
      };

      try {
        const user = await UserService.createUser(payload);
        cognitoId = user.User.Username;
        await UserService.addUserToTenantGroup(tenantId.current, formData.email.value);
      } catch (error) {
        const { message = `Could not invite ${formData.email.value}.` } = error || {};
        onFail(`${message} Please try again.`);
        return;
      }
    }

    let person;
    const errorBannerMessage = isVendorClientUser
      ? `Could not add ${formData.givenName.value} ${formData.familyName.value}`
      : `Could not invite ${formData.email.value}`;
    try {
      person = await PersonClient.createPerson(
        formData.alias.value,
        formData.givenName.value,
        formData.familyName.value,
        cognitoId
      );
    } catch (e) {
      // rollback
      if (cognitoId) {
        await UserService.deleteUser(cognitoId);
      }
      onFail(`${errorBannerMessage}, please try again`);
      return;
    }

    try {
      if (person && isVendorClientUser) {
        await PersonClient.attachPersonToPartner(person.id, currentPartner.id);
      }

      if (person && hasUserTracking && formData.sensorProfile.value) {
        const SensorProfileClient = client.getSensorProfileClient();
        const PersonTsClient = client.getPersonTrackingSessionClient();
        const sp = await SensorProfileClient.createSensorProfile(
          'RFID Tag',
          formData.sensorProfile.value,
          'N/A'
        );
        await PersonClient.attachPersonToSensorProfile(person.id, sp.id);
        await PersonTsClient.startPersonTrackingSession(person.id);
      }
    } catch (e) {
      onFail(`${errorBannerMessage}, please try again`);
      return;
    }

    setCurrentAction({});
    setFormData({});
    setLoading(false);
    await onLoad();
    setShowBanner(true);
    const message = isVendorClientUser
      ? `User ${formData.givenName.value} ${formData.familyName.value} has been created`
      : `Your invitation has been sent to ${formData.email?.value}`;
    setBannerMessage(message);
  };

  const editUser = async () => {
    const {
      id,
      sensorProfile,
      alias,
      admin,
      cognitoId,
      trackingSession,
      displayName
    } = currentAction.user;
    let nothingToUpdate = true;
    let needToReturn;

    setShowBanner(false);
    setBannerError(false);
    setBannerMessage('');

    const formDataToValidate = { ...formData };
    const spExists = await spClient.getSensorProfileByVid(formData.sensorProfile.value);

    if (spExists && spExists !== sensorProfile?.vid) {
      formDataToValidate.sensorProfile.error = true;
      formDataToValidate.sensorProfile.errorMessage =
        'The provided RFID Tag is already being tracked';
    }

    const aliasExists = aliasStore.current.find((alias) => alias === formData.alias.value);
    if (aliasExists && aliasExists !== alias) {
      formDataToValidate.alias.error = true;
      formDataToValidate.alias.errorMessage =
        'The provided alias is already associated with another user';
    }

    Object.keys(formDataToValidate).forEach((key) => {
      const { error } = formDataToValidate[key];
      if (error) {
        needToReturn = true;
      }
    });

    if (needToReturn) {
      setFormData(formDataToValidate);
      return;
    }

    setLoading(true);
    const PersonClient = client.getPersonClient();
    Object.keys(formData).forEach((key) => {
      if (typeof formData[key].value === 'string') {
        formData[key].value.trim();
      }
    });

    if (
      !currentAction.isVendorClientUser &&
      formData.roles.value.admin &&
      formData.roles.value.admin !== admin
    ) {
      nothingToUpdate = false;
      try {
        await UserService.addAdminPrivilege(cognitoId);
      } catch (e) {
        onFail(`Could not update ${displayName}, please try again`);
        return;
      }
    }

    if (formData.alias.value !== alias) {
      nothingToUpdate = false;
      try {
        await PersonClient.updatePerson(id, formData.alias.value);
      } catch (e) {
        onFail(`Could not update ${displayName}, please try again`);
        return;
      }
    }

    if (
      hasUserTracking &&
      formData.sensorProfile.value &&
      formData.sensorProfile.value !== sensorProfile?.vid
    ) {
      nothingToUpdate = false;
      const SensorProfileClient = client.getSensorProfileClient();
      const PersonTsClient = client.getPersonTrackingSessionClient();
      try {
        if (!sensorProfile) {
          const sp = await SensorProfileClient.createSensorProfile(
            'RFID Tag',
            formData.sensorProfile.value,
            'N/A'
          );
          await PersonClient.attachPersonToSensorProfile(id, sp.id);
          await PersonTsClient.startPersonTrackingSession(id);
        } else {
          if (trackingSession) {
            await PersonTsClient.endPersonTrackingSession(trackingSession.id);
          }
          await PersonClient.detachPersonFromSensorProfile(id, sensorProfile.id);
          const sp = await SensorProfileClient.createSensorProfile(
            'RFID Tag',
            formData.sensorProfile.value,
            'N/A'
          );
          await PersonClient.attachPersonToSensorProfile(id, sp.id);
          await PersonTsClient.startPersonTrackingSession(id);
        }
      } catch (e) {
        onFail(`Could not update ${displayName}, please try again`);
        return;
      }
    }

    setCurrentAction({});
    setFormData({});
    setLoading(false);
    if (!nothingToUpdate) {
      await onLoad();
      setShowBanner(true);
      setBannerMessage(`Successfully updated ${displayName}`);
    }
  };

  const deleteUser = async () => {
    const {
      id,
      displayName,
      cognitoId,
      trackingSession,
      alias,
      givenName,
      familyName,
      email
    } = currentAction.user;

    setShowBanner(false);
    setBannerError(false);
    setBannerMessage('');

    setLoading(true);
    const PersonClient = client.getPersonClient();
    const PersonTsClient = client.getPersonTrackingSessionClient();

    try {
      if (trackingSession) {
        await PersonTsClient.endPersonTrackingSession(trackingSession.id);
      }
      await PersonClient.removePerson(id);
    } catch (e) {
      onFail(`Could not delete ${displayName}, please try again`);
      return;
    }

    if (cognitoId) {
      try {
        await UserService.deleteUser(cognitoId);
      } catch (e) {
        // rollback
        await PersonClient.createPerson(alias, givenName, familyName, cognitoId);
        onFail(`Could not delete ${displayName}, please try again`);
        return;
      }
    }

    setCurrentAction({});
    setShowBanner(true);
    setLoading(false);
    setBannerMessage(`${displayName} (${email}) has been successfully deleted`);
    await onLoad();
  };

  const onFail = (errorMessage) => {
    setLoading(false);
    setCurrentAction({});
    setFormData({});
    setShowBanner(true);
    setBannerError(true);
    setBannerMessage(errorMessage);
  };

  const resendInvitation = async (user) => {
    const { email } = user;
    const payload = {
      username: email
    };
    await UserService.resendTemporaryPassword(payload);
    const usersCopy = { ...usersRef.current };

    Object.keys(usersCopy).forEach((key) => {
      const currentUserIdx = usersCopy[key].findIndex((el) => el.cognitoId === user.cognitoId);
      if (currentUserIdx > -1) {
        usersCopy[key][currentUserIdx].invitationResent = true;
      }
      setShowBanner(true);
      setBannerMessage(`Email invitation resent to ${email}`);
    });
    setUsersList(usersCopy);
  };

  const renderResend = (user) => {
    if (!user.invitationResent) {
      return (
        <div className={Style.user_resend_container}>
          <div className={Style.user_resend} onClick={() => resendInvitation(user)}>
            Resend invitation
          </div>
        </div>
      );
    }
    return <div className={Style.user_resend_countdown}>Invitation resent</div>;
  };

  const renderCell = (value, id, header) => {
    const user = usersRef.current?.[currentPartner.identifier]?.find((user) => user.id === id);
    const resetPwd = user?.status === RESEND_PASSWORD_STATUS;
    const textStyle = resetPwd ? Style.user_resend_text : Style.user_text;
    return (
      <Fragment>
        <div className={textStyle}>{value || '-'}</div>
        {header === 'displayName' && user?.status === RESEND_PASSWORD_STATUS && renderResend(user)}
      </Fragment>
    );
  };

  if (loading) {
    return <LoadingCircle />;
  }

  const HoverComponent = ({
    display = false,
    onEditClicked,
    onDeleteClicked,
    enableDelete = true
  }) => (
    <div className={Style.hover_component}>
      {display && (
        <div>
          <Edit className={Style.edit_button} onClick={onEditClicked} />
          {enableDelete && <Delete className={Style.delete_button} onClick={onDeleteClicked} />}
        </div>
      )}
    </div>
  );

  const confirmFunctions = {
    create: createUser,
    edit: editUser,
    delete: deleteUser
  };

  return (
    <Fragment>
      {currentAction?.action?.length && (
        <UserModal
          data={formData}
          updateDataFn={setFormData}
          currentAction={currentAction}
          currentPartner={currentPartner}
          updateCurrentActionFn={setCurrentAction}
          confirmFns={confirmFunctions}
        />
      )}
      {showBanner && (
        <DisplayBanner
          bannerError={bannerError}
          bannerMessage={bannerMessage}
          onCloseBanner={() => {
            setShowBanner(false);
            setBannerError(false);
            setBannerMessage('');
          }}
        />
      )}
      <Card className={Style.card}>
        <div className={Style.header}>
          <div className={Style.page_title}>
            <div>
              <AccountCircleOutlined fontSize="large" className={Style.account_icon} />
            </div>
            <p className={Style.header_text}>Users</p>
          </div>
        </div>
        <div className={Style.main_container}>
          {hasVendorClients && (
            <div className={Style.left_container}>
              <SearchableTabList
                tabStructure={partnerTabs}
                onSelect={(partner) => setCurrentPartner(partner)}
                searchPlaceholder="Search user groups"
                defaultLength={10}
                focusedTab={currentPartner.id}
                tabStyle={Style.tab_style}
                tabListStyle={Style.tab_list_style}
                focusedTabStyle={Style.focused_tab_style}
                tabListHeaderStyle={Style.groupby_label}
              />
            </div>
          )}
          <div className={Style.right_container}>
            <div className={Style.users_list_header}>
              <div className={Style.users_list_title}>
                {`Manage ${currentPartner.identifier} Users`}
              </div>
              <div className={Style.users_list_sub_title}>
                {`${usersList[currentPartner.identifier]?.length || 0} ${
                  (usersList[currentPartner.identifier]?.length || 0) === 1 ? 'user' : 'users'
                }`}
              </div>
            </div>
            {isAdmin.current && (
              <div
                className={Style.add_button}
                onClick={() => {
                  setCurrentAction({
                    action: 'create',
                    isVendorClientUser: currentPartner.identifier !== tenantName
                  });
                }}
              >
                + Add New User
              </div>
            )}
            <div className={Style.list_container}>
              <ListView
                defaultColumnSort={{ key: 'displayName', direction: 'desc' }}
                headerStructureList={listHeaders}
                headerStyle={{ color: '#979799', fontSize: 14 }}
                dataList={usersList[currentPartner.identifier] || []}
                emptyListText="No Users"
                enablePagination={false}
                onHoverRenderCondition={() => true}
                onHoverComponent={(hoverRow, user) => (
                  <HoverComponent
                    display={hoverRow === user.id}
                    onEditClicked={() => {
                      setCurrentAction({
                        action: 'edit',
                        user,
                        isVendorClientUser: currentPartner.identifier !== tenantName
                      });
                    }}
                    onDeleteClicked={() => {
                      setCurrentAction({
                        action: 'delete',
                        user,
                        isVendorClientUser: currentPartner.identifier !== tenantName
                      });
                    }}
                    enableDelete={!user.admin}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </Card>
    </Fragment>
  );
};

export default withRouter(UsersPageFeature);
