import React, { Component } from 'react';
import EditableTableComponent from '../../components/editable-table/EditableTableComponent';
import { XemelgoService } from '../../services/XemelgoService';
import { findDrillDownValue } from '../../common/Utilities';

export default class BacklogRuleContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropDownOptions: []
    };

    this.handleRuleConditionRequests = this.handleRuleConditionRequests.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
  }

  componentDidMount() {
    const { ruleConditionsList = [], locationDataList = [] } = this.props;
    this.onLoad(ruleConditionsList, locationDataList);
  }

  componentWillReceiveProps(newProps) {
    const ruleConditionsList = [...newProps.ruleConditionsList];
    const oldRuleConditionsList = [...this.props.ruleConditionsList];
    if (ruleConditionsList !== oldRuleConditionsList) {
      const { locationDataList = [] } = newProps;
      this.onLoad(ruleConditionsList, locationDataList);
    }
  }

  onLoad = (ruleConditionsList, locationDataList) => {
    const conditions = this.buildRuleConditions(ruleConditionsList);

    let dropDownOptions = locationDataList.map((eachLocation) => {
      return { objectTypeName: eachLocation.name, objectTypeId: eachLocation.id };
    });
    conditions.forEach((eachCondition) => {
      const locationInfo = locationDataList.find(
        (location) => location.id === eachCondition.locationId
      );
      if (locationInfo) {
        eachCondition.locationName = locationInfo.name;
      }
      dropDownOptions = dropDownOptions.filter(
        (eachOption) => eachOption.objectTypeId !== eachCondition.locationId
      );
    });
    this.setState({
      conditions,
      dropDownOptions
    });
  };

  buildUpdateRuleConditionPayloadFromRequest = (requestPayload) => {
    const ret = [];
    requestPayload.conditionId.forEach((eachId) => {
      const tags = {};
      const conditions = [];
      tags.locationId =
        requestPayload.locationId === 'All Locations' ? undefined : requestPayload.locationId;
      tags.locationName =
        requestPayload.locationId === 'All Locations' ? undefined : requestPayload.locationName;
      if (eachId.type === 'warningCondition') {
        tags.stage = 'warning';
        tags.warningThreshold = requestPayload.warningThreshold * 1;
        tags.criticalThreshold = requestPayload.criticalThreshold * 1;
        tags.threshold = requestPayload.warningThreshold * 1;
        conditions.push({ property: 'backlogCount', op: '>', value: tags.warningThreshold });
        conditions.push({ property: 'backlogCount', op: '<=', value: tags.criticalThreshold });
      } else {
        tags.stage = 'critical';
        tags.threshold = requestPayload.criticalThreshold * 1;
        tags.criticalThreshold = requestPayload.criticalThreshold * 1;
        conditions.push({ property: 'backlogCount', op: '>', value: tags.criticalThreshold });
      }
      if (tags.locationId) {
        conditions.push({ property: 'locationId', op: '=', value: tags.locationId });
      }
      ret.push({
        id: eachId.ruleConditionId,
        tags,
        conditions
      });
    });
    return ret;
  };

  buildCreateRuleConditionPayloadFromRequest = (requestPayload) => {
    const ret = [
      {
        name: `backlog_${requestPayload.locationName.replace(/[^\w\s]/gi, '_')}_${
          requestPayload.locationId
        }_warning`,
        tags: {
          warningThreshold: requestPayload.warningThreshold * 1,
          criticalThreshold: requestPayload.criticalThreshold * 1,
          threshold: requestPayload.warningThreshold * 1,
          locationId: requestPayload.locationId,
          locationName: requestPayload.locationName,
          stage: 'warning'
        },
        conditions: [
          {
            property: 'backlogCount',
            op: '>',
            value: requestPayload.warningThreshold * 1
          },
          {
            property: 'backlogCount',
            op: '<=',
            value: requestPayload.criticalThreshold * 1
          },
          {
            property: 'locationId',
            op: '=',
            value: requestPayload.locationId
          }
        ]
      },
      {
        name: `backlog_${requestPayload.locationName.replace(/[^\w\s]/gi, '_')}_${
          requestPayload.locationId
        }_critical`,
        tags: {
          criticalThreshold: requestPayload.criticalThreshold * 1,
          threshold: requestPayload.criticalThreshold * 1,
          locationId: requestPayload.locationId,
          locationName: requestPayload.locationName,
          stage: 'critical'
        },
        conditions: [
          {
            property: 'backlogCount',
            op: '>',
            value: requestPayload.criticalThreshold * 1
          },
          {
            property: 'locationId',
            op: '=',
            value: requestPayload.locationId
          }
        ]
      }
    ];
    return ret;
  };

  buildRuleConditions = (ruleConditions) => {
    const { locationDataList, trackingSessionList } = this.props;
    const reducedRuleConditions = ruleConditions.reduce((final, eachCondition) => {
      if (final[eachCondition.tags.locationId || 'All Locations']) {
        if (eachCondition.tags.warningThreshold !== undefined) {
          final[eachCondition.tags.locationId || 'All Locations'].warningCondition = {
            ruleConditionId: eachCondition.id,
            ruleConditionName: eachCondition.name,
            threshold: eachCondition.tags.warningThreshold
          };
        } else {
          final[eachCondition.tags.locationId || 'All Locations'].criticalCondition = {
            ruleConditionId: eachCondition.id,
            ruleConditionName: eachCondition.name,
            threshold: eachCondition.tags.criticalThreshold
          };
        }
      } else {
        final[eachCondition.tags.locationId || 'All Locations'] = {
          subscribed: eachCondition.hasSubscriptions,
          locationName: eachCondition.tags.locationName || 'All Locations',
          locationId: eachCondition.tags.locationId || 'All Locations'
        };
        if (eachCondition.tags.warningThreshold !== undefined) {
          final[eachCondition.tags.locationId || 'All Locations'].warningCondition = {
            ruleConditionId: eachCondition.id,
            ruleConditionName: eachCondition.name,
            threshold: eachCondition.tags.warningThreshold
          };
        } else {
          final[eachCondition.tags.locationId || 'All Locations'].criticalCondition = {
            ruleConditionId: eachCondition.id,
            ruleConditionName: eachCondition.name,
            threshold: eachCondition.tags.criticalThreshold
          };
        }
      }
      return final;
    }, {});

    let condition = [];
    Object.keys(reducedRuleConditions).forEach((locationId) => {
      const criticalConditionKeys = [locationId, 'criticalCondition'];
      const warningConditionKeys = [locationId, 'warningCondition'];

      // Backlog count should only be counting item with the class of Traveller

      let currentBacklogCount = '';
      trackingSessionList.forEach(eachTS => {
        if(
          eachTS.lastDetectedLocation[0] && 
          eachTS.lastDetectedLocation[0].id === locationId
          ){
            if(currentBacklogCount === '') currentBacklogCount = 0;
            currentBacklogCount++;
        }
      })

      condition.push({
        id: [
          findDrillDownValue(reducedRuleConditions, [...warningConditionKeys, 'ruleConditionId']),
          findDrillDownValue(reducedRuleConditions, [...criticalConditionKeys, 'ruleConditionId'])
        ],
        conditionId: [
          {
            ruleConditionId: findDrillDownValue(reducedRuleConditions, [
              ...warningConditionKeys,
              'ruleConditionId'
            ]),
            type: 'warningCondition'
          },
          {
            ruleConditionId: findDrillDownValue(reducedRuleConditions, [
              ...criticalConditionKeys,
              'ruleConditionId'
            ]),
            type: 'criticalCondition'
          }
        ],
        currentValue: currentBacklogCount,
        subscribed: findDrillDownValue(reducedRuleConditions, [locationId, 'subscribed']),
        criticalThreshold: findDrillDownValue(reducedRuleConditions, [
          ...criticalConditionKeys,
          'threshold'
        ]),
        warningThreshold: findDrillDownValue(reducedRuleConditions, [
          ...warningConditionKeys,
          'threshold'
        ]),
        locationName: findDrillDownValue(reducedRuleConditions, [locationId, 'locationName']),
        locationId: findDrillDownValue(reducedRuleConditions, [locationId, 'locationId']),
        disableDelete:
          findDrillDownValue(reducedRuleConditions, [locationId, 'locationName']) ===
          'All Locations'
      });
    });

    condition = condition.sort((a, b) => {
      return a.locationName.localeCompare(b.locationName);
    });
    return condition;
  };

  handleValidation = (payloads) => {
    let warningThreshold;
    let criticalThreshold;
    let currentRowId;
    const inputErrorMap = {};

    const validatedObject = {
      errorExists: false,
      errorMap: inputErrorMap
    };

    payloads.forEach((payloadItem) => {
      const inputError = {
        warningThreshold: false,
        criticalThreshold: false
      };
      if (payloadItem._event !== 'pending_delete') {
        warningThreshold = payloadItem.warningThreshold;
        criticalThreshold = payloadItem.criticalThreshold;
        currentRowId = payloadItem.id;

        /**
         * (+thresholdValue === +thresholdValue) is called a 'self check' that is used to check if it is a valid number
         * '+variable' converts the value into a number.
         */

        if (
          warningThreshold === '' ||
          !warningThreshold ||
          // eslint-disable-next-line no-self-compare
          !(+warningThreshold === +warningThreshold) ||
          +warningThreshold <= 0
        ) {
          inputError.warningThreshold = true;
        }
        if (
          criticalThreshold === '' ||
          !criticalThreshold ||
          // eslint-disable-next-line no-self-compare
          !(+criticalThreshold === +criticalThreshold) ||
          +criticalThreshold <= 0 ||
          +warningThreshold >= +criticalThreshold
        ) {
          inputError.criticalThreshold = true;
        }

        inputErrorMap[currentRowId] = inputError;
      }
    });

    const BreakForEachLoop = { exception: 'Error exists.' };

    try {
      Object.keys(inputErrorMap).forEach((key) => {
        if (inputErrorMap[key].warningThreshold || inputErrorMap[key].criticalThreshold) {
          validatedObject.errorExists = true;
          throw BreakForEachLoop;
        } else {
          validatedObject.errorExists = false;
        }
      });
    } catch (e) {
      alert('Please enter valid values in the highlighted field(s).');
      if (e !== BreakForEachLoop) throw e;
    }

    validatedObject.errorMap = inputErrorMap;

    return validatedObject;
  };

  handleAutoFill = (headerId, newValue, data) => {
    const { dropDownOptions } = this.state;

    const autoFillObject = {
      data,
      changed: true
    };

    if (headerId === 'locationName') {
      const itemIndex = dropDownOptions
        .map((i) => {
          return i.objectTypeName;
        })
        .indexOf(newValue);
      const itemId = dropDownOptions[itemIndex].objectTypeId;
      data.locationId = itemId;
    }
    autoFillObject.data = data;

    if (data.name === '' && data.threshold === '') {
      autoFillObject.changed = false;
    }

    return autoFillObject;
  };

  handleRuleConditionRequests = async (requests) => {
    const { ruleId } = this.props;
    if (!requests || requests.length === 0) {
      return;
    }
    const RulePageClient = XemelgoService.getClient().getRulePageClient();

    await Promise.all(
      requests.map(async (request) => {
        const event = request._event;
        let payload;
        switch (event) {
          case 'pending_new':
            payload = this.buildCreateRuleConditionPayloadFromRequest(request);
            await Promise.all(
              payload.map(async (eachPayload) => {
                await RulePageClient.createRuleCondition(
                  eachPayload.name,
                  eachPayload.tags,
                  ruleId,
                  eachPayload.conditions
                );
              })
            );
            break;
          case 'pending_update':
            payload = this.buildUpdateRuleConditionPayloadFromRequest(request);
            await Promise.all(
              payload.map(async (eachPayload) => {
                await RulePageClient.updateRuleCondition(
                  eachPayload.id,
                  undefined,
                  eachPayload.tags,
                  eachPayload.conditions
                );
              })
            );
            break;
          case 'pending_delete':
            await Promise.all(
              request.conditionId.map(async (eachId) => {
                await RulePageClient.removeRuleCondition(eachId.ruleConditionId, false);
              })
            );
            break;
          default:
            console.log(`Unsupported Event[Name:${event}]`);
            break;
        }
      })
    );

    await this.props.onLoad();
    const { ruleConditionsList = [] } = this.props;
    const conditions = await this.buildRuleConditions(ruleConditionsList);
    return conditions.map((condition) => condition.id);
  };

  handleSubscriptionChange = async (ruleConditionId, subscribed) => {
    const { ruleConditionsList } = this.props;
    const ruleConditions = ruleConditionsList.filter((condition) => {
      let match = false;
      ruleConditionId.forEach((each) => {
        if (each === condition.id) {
          match = true;
        }
      });
      return match;
    });
    const notLocationCondition = !ruleConditions[0].tags.locationId;
    const subscribedRuleConditionIdList = [];
    const unsubscribedRuleConditionIdList = [];
    if (subscribed) {
      ruleConditions.forEach((each) => {
        subscribedRuleConditionIdList.push(each.id);
      });
      if (!notLocationCondition) {
        const allLocationRuleCondition = ruleConditionsList.filter((each) => {
          return !each.tags.locationId;
        });
        allLocationRuleCondition.forEach((each) => {
          unsubscribedRuleConditionIdList.push(each.id);
        });
      } else {
        const listOfCondition = ruleConditionsList.filter((each) => {
          let match = true;
          ruleConditions.forEach((eachR) => {
            if (each.id === eachR.id) {
              match = false;
            }
          });
          return match;
        });
        listOfCondition.forEach((each) => {
          unsubscribedRuleConditionIdList.push(each.id);
        });
      }
    } else {
      ruleConditionId.forEach((each) => {
        unsubscribedRuleConditionIdList.push(each);
      });
    }
    const RulePageClient = XemelgoService.getClient().getRulePageClient();
    await RulePageClient.updateSubscriptionForRuleConditions(
      subscribedRuleConditionIdList,
      unsubscribedRuleConditionIdList
    );
    await this.props.onLoad();
  };

  render() {
    const { conditions, dropDownOptions } = this.state;

    const headers = [
      {
        displayName: '',
        id: 'subscribed',
        cell: {
          input: 'switch',
          display: 'switch',
          modifiable: true // whether it can be edited after being added
        }
      },
      {
        displayName: 'Location',
        id: 'locationName',
        cell: {
          input: 'dropdown',
          data: dropDownOptions,
          display: 'text',
          modifiable: false // whether it can be edited after being added
        }
      },
      {
        displayName: 'Current Backlog',
        id: 'currentValue',
        cell: {
          input: 'text',
          display: 'text',
          modifiable: false // whether it can be edited after being added
        }
      },
      {
        displayName: 'Warning Threshold',
        id: 'warningThreshold',
        cell: {
          input: 'text',
          display: 'text',
          modifiable: true // whether it can be edited after being added
        }
      },
      {
        displayName: 'Critical Threshold',
        id: 'criticalThreshold',
        cell: {
          input: 'text',
          display: 'text',
          modifiable: true // whether it can be edited after being added
        }
      }
    ];

    return (
      <div>
        <div>
          <p className="tabbedSectionComponent-contentTitle">Backlog</p>
          <p style={{ color: '#343434' }}>
            Sends a notification if the backlog of orders in location goes over the set threshold.
          </p>
        </div>
        <div className="note">
          <span>Receive notifications for the locations listed:</span>
        </div>
        <EditableTableComponent
          headers={headers}
          dataList={conditions}
          handleChangesFn={this.handleRuleConditionRequests}
          handleValidationFn={this.handleValidation}
          handleSubscriptionChange={this.handleSubscriptionChange}
          handleAutoFillFn={this.handleAutoFill}
        />
      </div>
    );
  }
}