import React, { useEffect, useState } from 'react';
import AddPageComponent from '../../components/add-page-component/AddPageComponent';
import { useAppConfigProvider } from '../../services/soft-cache-service';
import {
  processOnboardingPageAttributes,
  AddInventoryFeatureFunctionMap
} from '../../common/commonAPICalls';
import {
  AddPageInputTypeMap,
  validCSVHeaderCheck,
  validCSVDataCheck
} from '../../common/Utilities';
import { useXemelgoClient } from '../../services/xemelgo-service';

const FEATURE_ID = 'addItemType';
const APP_ID = 'itemTypesManagement';

const AddItemTypeFeature = () => {
  const configProvider = useAppConfigProvider(APP_ID);
  const [itemTypeClient] = useState(useXemelgoClient().getItemTypeClient());
  const addItemTypeConfiguration = configProvider.getValue(FEATURE_ID, 'object');
  const [itemTypeLabel, setItemTypeLabel] = useState('');
  const [attributeMap, setAttributeMap] = useState({});
  const [isUploadCsvEnabled, setIsUploadCsvEnabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [bannerMessage, setBannerMessage] = useState('');
  const [showBanner, setShowBanner] = useState(false);
  const [bannerError, setBannerError] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Item Type');

  const onLoad = async () => {
    const {
      attributeMap: newDefaultAttributeMap = {},
      uploadCsv: newUploadCsv,
      itemTypeLabel: newItemTypeLabel = 'Item Type'
    } = addItemTypeConfiguration || {};

    const { defaultAttributeMap } = await processOnboardingPageAttributes(
      newDefaultAttributeMap,
      {},
      AddInventoryFeatureFunctionMap
    );

    setItemTypeLabel(newItemTypeLabel);
    setAttributeMap(defaultAttributeMap);
    setIsUploadCsvEnabled(newUploadCsv);
    setLoading(false);
  };
  useEffect(() => {
    onLoad();
  }, []);

  const onSubmit = async (formData) => {
    setLoading(true);
    setLoadingMessage('Creating Item Type');

    const itemTypePayload = {};

    Object.keys(formData).forEach((id) => {
      const { value, type, skipForCreation } = formData[id];
      if (!skipForCreation && value) {
        let finalValue;

        switch (type) {
          case AddPageInputTypeMap.DATE_PICKER:
            finalValue = value ? value.getTime() : undefined;
            break;
          case AddPageInputTypeMap.SEARCH_DROP_DOWN_FROM_API:
          case AddPageInputTypeMap.SEARCH_DROP_DOWN:
            finalValue = value && typeof value === 'object' ? value.value : value;
            break;
          case AddPageInputTypeMap.CHECK_BOX_GROUP:
          case AddPageInputTypeMap.INPUT:
          default:
            finalValue = value;
            break;
        }

        itemTypePayload[id] = finalValue;
      }
    });

    if (!itemTypePayload.name) {
      itemTypePayload.name = itemTypePayload.identifier;
    }
    try {
      const response = await itemTypeClient.createItemTypeV2(itemTypePayload);

      if (response.name === 'Error') {
        setBannerError(true);
        setBannerMessage(response.message);
      } else {
        setBannerError(false);
        setBannerMessage('Item Type(s) created');
      }
    } catch (err) {
      setBannerMessage(err.message);
      setBannerError(true);
    }
    setLoading(false);
    setLoadingMessage('');
    setShowBanner(true);
  };

  const onUploadCSVSubmit = async (dataList) => {
    setLoading(true);

    const dataListWithStatus = [];
    let hasError = false;
    for (let i = 0; i < dataList.length; i++) {
      setLoadingMessage(`Creating Item Type(s) ${i + 1} of ${dataList.length}`);
      const itemTypePayload = {};
      Object.keys(dataList[i]).forEach((id) => {
        const attribute = attributeMap[id];
        const value = dataList[i][id];
        if (value) {
          const { type, transformInput, numberOnly } = attribute;
          let finalValue;
          switch (type) {
            case AddPageInputTypeMap.DATE_PICKER:
              finalValue = value ? Date.parse(value) : undefined;
              break;
            case AddPageInputTypeMap.INPUT:
              if (numberOnly) {
                finalValue = parseInt(value);
                break;
              } else if (transformInput) {
                if (transformInput === 'toUpperCase') {
                  finalValue = value.toUpperCase();
                  break;
                }
              }
            // eslint-disable-next-line
            case AddPageInputTypeMap.SEARCH_DROP_DOWN_FROM_API:
            case AddPageInputTypeMap.SEARCH_DROP_DOWN:
            case AddPageInputTypeMap.CHECK_BOX_GROUP:
            default:
              finalValue = value;
              break;
          }

          itemTypePayload[id] = finalValue;
        }
      });
      if (!itemTypePayload.name) {
        itemTypePayload.name = itemTypePayload.identifier;
      }
      const processedData = { ...dataList[i] };
      try {
        await itemTypeClient.createItemTypeV2(itemTypePayload);
      } catch (err) {
        hasError = true;
        processedData.errorMessage = err.message ? err.message.toString() : err.toString();
      }
      dataListWithStatus.push(processedData);
    }

    await onLoad();
    setBannerError(hasError);
    setBannerMessage(
      hasError
        ? bannerMessage ||
            'One or more item types could not be created: Please check the following item type(s) and retry.'
        : 'Item Type(s) created successfully.'
    );
    setShowBanner(true);
    setLoading(false);
    setLoadingMessage('');

    return dataListWithStatus;
  };

  return (
    <AddPageComponent
      title={itemTypeLabel}
      loading={loading}
      loadingMessage={loadingMessage}
      validCSVHeaderCheck={(data) => {
        const { valid, errorMessage } = validCSVHeaderCheck(data, attributeMap, {});
        if (!valid) {
          setBannerError(!valid);
          setBannerMessage(errorMessage);
          setShowBanner(true);
        }
        return valid;
      }}
      validCSVDataCheck={(data) => {
        const { valid, errorMessage } = validCSVDataCheck(data, attributeMap, {});
        if (!valid) {
          setBannerError(!valid);
          setBannerMessage(errorMessage);
          setShowBanner(true);
        }
        return valid;
      }}
      bannerError={bannerError}
      showBanner={showBanner}
      setShowBanner={setShowBanner}
      bannerMessage={bannerMessage}
      onCloseBanner={() => {
        setBannerError(false);
        setBannerMessage('');
        setShowBanner(false);
      }}
      defaultAttributeMap={attributeMap}
      uploadCsv={isUploadCsvEnabled}
      onSubmit={onSubmit}
      onUploadCSVSubmit={onUploadCSVSubmit}
    />
  );
};

export default AddItemTypeFeature;
