import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useAppConfigProvider } from '../../services/soft-cache-service';
import './style.css';
import { ListLocations } from '../../features/list-locations';
import { RoutableView } from '../../components/routable-view';
import { TwoColumnsPaneView } from '../../components/two-columns-pane-view';
import { ListDetectors } from '../../features/list-detectors';
import SideNavigationBar from '../../features/side-navigation-bar/SideNavigationBar';
import { useStore } from 'react-hookstore';
import { userProfileStore } from '../../state-managements/stores/user-profile-store';
import { LocalCacheService } from '../../services/local-cache-service';

const AppId = 'myFacility';
const TabMap = {
  locations: {
    displayName: 'Locations',
    href: '/my-facility/locations',
    component: <ListLocations appId={AppId} />
  },
  detectors: {
    displayName: 'Detectors',
    href: '/my-facility/detectors',
    component: <ListDetectors appId={AppId} />
  }
};

export const MyFacility = withRouter(({ history }) => {
  const [tabs, setTabs] = useState([]);
  const [defaultTabId, setDefaultTabId] = useState(history.location.pathname.split('/')[2]);
  const configProvider = useAppConfigProvider(AppId);

  const [userProfile] = useStore(userProfileStore);

  useEffect(() => {
    if (!history.location.pathname.split('/')[2] && tabs.length) {
      history.push(tabs[0].href);
    }
  });

  useEffect(() => {
    let isCancelled = false;

    const tabsFromConfig = configProvider.getValue('tabs', 'array', ['locations', 'detectors']);
    const tabInfoList = tabsFromConfig
      .filter((tabId) => {
        return TabMap[tabId];
      })
      .map((tabId) => {
        const tabInfo = TabMap[tabId];
        const { href, displayName, component } = tabInfo;
        return {
          id: tabId,
          href,
          action: () => {
            history.push(`${href}`);
          },
          display: displayName,
          component
        };
      });

    if (!isCancelled) {
      setTabs(tabInfoList);
      if (!defaultTabId) {
        setDefaultTabId(tabInfoList[0].id);
      }
      // set first tab as active tab
      // history.push(tabInfoList[0].href);
    }

    return () => {
      isCancelled = true;
    };
  }, [history, defaultTabId, configProvider]);

  if (!userProfile.isUserSuperAdmin()) {
    const landingPage = LocalCacheService.loadUserConfig().getLandingPage();
    history.push(landingPage);
  }

  return (
    <TwoColumnsPaneView
      className="my-facility-app"
      title="My Facility"
      leftPane={<SideNavigationBar tabStructure={tabs} defaultTab={defaultTabId} />}
      rightPane={<RoutableView partialViews={tabs} />}
    />
  );
});
