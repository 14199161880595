import { ClientProvider } from '@xemelgo/x-client';
import { useState } from 'react';
import { useStore } from 'react-hookstore';
import CONFIGURATION from '../configuration.json';
import { configStore } from '../state-managements/stores/config-store';
import Amplify from 'aws-amplify';
/**
 * the name of the method must start with use*, refers to custom react hooks doc for more information
 * @returns {undefined}
 */
export const useXemelgoClient = () => {
  const [client, setClient] = useState(null);
  const [userConfig] = useStore(configStore);
  // TODO: apiGateway is the name of service from old customer configuration. Keeping it here for backward compatibility support
  const services = ['xGraph', 'xMain', 'apiGateway'];

  // returns cached client if exists
  if (client) {
    return client;
  }

  let apiVersion = null;
  const endpoints = services
    .filter((serviceName) => {
      return userConfig.getServiceConfiguration(serviceName);
    })
    .map((serviceName) => {
      const config = userConfig.getServiceConfiguration(serviceName);
      const { name, endpoint, region, apiVersion: version } = config;

      if (version) {
        apiVersion = version;
      }

      return {
        name,
        endpoint,
        region
      };
    });

  const clientConfig = {
    endpoints,
    apiVersion,
    auth: CONFIGURATION.AuthConfiguration
  };

  const xemelgoClient = ClientProvider.getClient(Amplify.API, clientConfig);
  setClient(xemelgoClient);
  return xemelgoClient;
};
