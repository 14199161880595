import React, { useState, useEffect } from 'react';
import { useDisplayBannerContext } from 'context/DisplayBannerContext/DisplayBannerContext';
import { useXemelgoClient } from '../../../../services/xemelgo-service';
import { useSubFeatureConfigProvider } from '../../../../services/soft-cache-service';
import { ModalForm } from '../../../../components/modal-form';
import DisplayBanner from '../../../../components/display-banner/DisplayBanner';
import Spinner from 'react-bootstrap/Spinner';
import InputGroup from '../../../../components/my-facility-v2-components/InputGroup';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import EditLocationFormV2Style from './EditLocationFormV2.module.css';
import './EditLocationFormV2.css';

const SubFeatureId = 'editLocationForm';
export const EditLocationFormV2 = ({
  appId,
  featureId,
  location,
  show,
  onFormClose,
  onSave,
  modelId
}) => {
  const { setShowBanner, setBannerTitle, setBannerHasError } = useDisplayBannerContext();
  const [loading, setLoading] = useState(true);
  const [innerLocations, setInnerLocations] = useState([]);
  const [showModalBanner, setShowModalBanner] = useState(false);
  const [modalBannerMessage, setModalBannerMessage] = useState('');
  const [properties, setProperties] = useState([]);
  const [defaultValues, setDefaultValues] = useState({});
  const [editedProperties, setEditedProperties] = useState({});
  const [xemelgoClient] = useState(useXemelgoClient());
  const [configProvider] = useState(useSubFeatureConfigProvider(appId, featureId, SubFeatureId));

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    const tempDefaultValues = { ...location };
    const model = configProvider.getModel(modelId);
    const displayName = model.getValue('displayName', 'string', tempDefaultValues.category);
    const modelConfig = model.getDefinitionObject();
    const { roles } = modelConfig;
    const tempProperties = model.getProperties().map((property) => {
      return {
        ...property,
        name: property.id,
        displayName: `${displayName} ${property.displayName}`,
        optional: true,
        disabled: !property.__updatable
      };
    });

    if (roles && roles.options) {
      let rolesOptions;
      rolesOptions = roles.options.map((option) => {
        return {
          id: option.name,
          label: option.name,
          value: option.name
        };
      });

      tempProperties.push({
        name: 'role',
        displayName: `${displayName} Role`,
        options: rolesOptions,
        optional: true
      });

      tempDefaultValues.role = tempDefaultValues?.roles?.map((role) => role.name).join(', ') || '';
    }

    if (tempDefaultValues.parent) {
      tempProperties.unshift({
        name: 'parent',
        displayName: `${tempDefaultValues.parent.category} Name`,
        optional: true,
        disabled: true
      });
      tempDefaultValues.parent = tempDefaultValues.parent.name;
    }

    const locationClient = xemelgoClient.getLocationClient();
    const locations = await locationClient.getLocationChildrenByParentId(tempDefaultValues.id);
    const sorted = locations.sort((loc1, loc2) => loc1.name.localeCompare(loc2.name));

    setInnerLocations(sorted);
    setLoading(false);
    setDefaultValues(tempDefaultValues);
    setProperties(tempProperties);
  };

  //getLocationChildrenByParentId to query and show children

  const handleEdit = (id, value) => {
    const tempEditedProperties = { ...editedProperties };
    tempEditedProperties[id] = value;
    setEditedProperties(tempEditedProperties);
  };

  const handleUpdateLocation = async () => {
    const locationClient = xemelgoClient.getLocationClient();
    const { role = {} } = editedProperties;
    const { id: roleName } = role;
    let roleId;

    delete editedProperties.role;

    if (roleName) {
      const roleResult = await locationClient.getRolesByNames([roleName]);
      const { locationRoles = [{}] } = roleResult;
      roleId = locationRoles[0]?.id;
    }

    await locationClient.updateLocationV2(
      defaultValues.id,
      editedProperties,
      roleId ? location.roles.map((role) => role.id) : [],
      roleId ? [roleId] : []
    );
  };

  const verifyPayload = async () => {
    const tempProperties = [...properties];
    let canSubmit = true;
    if (editedProperties.name !== undefined && editedProperties.name !== defaultValues.name) {
      if (!editedProperties.name) {
        canSubmit = false;
        setModalBannerMessage('Location name cannot be empty!');
      } else {
        const locationClient = xemelgoClient.getLocationClient();
        const locationResult = await locationClient.getLocationsByNames([editedProperties.name]);
        if (locationResult.length > 0) {
          canSubmit = false;
          setModalBannerMessage(`A location with name ${editedProperties.name} already exists!`);
        }
        if (!canSubmit) {
          const name = tempProperties.find((property) => property.name === 'name');
          name.error = true;
        }
      }
    }
    setProperties(tempProperties);
    return canSubmit;
  };

  const renderLoading = () => {
    return (
      <div className={EditLocationFormV2Style.loading_circle}>
        <Spinner animation="border" />
      </div>
    );
  };

  return (
    <ModalForm
      scrollable
      show={show}
      prefix="location"
      className={EditLocationFormV2Style.location_modal}
      title={
        <div
          className={`${EditLocationFormV2Style.flex_row} ${EditLocationFormV2Style.title_container}`}
        >
          {`Edit ${location.name}`}
          <div onClick={onFormClose} className={EditLocationFormV2Style.close_button}>
            <CloseRoundedIcon />
          </div>
        </div>
      }
      body={
        loading ? (
          renderLoading()
        ) : (
          <div>
            {showModalBanner && (
              <DisplayBanner
                onCloseBanner={() => {
                  setShowModalBanner(false);
                }}
                bannerMessage={modalBannerMessage}
                bannerError
              />
            )}
            <InputGroup
              title="Location Information"
              properties={properties}
              onChange={handleEdit}
              values={editedProperties}
              placeholders={defaultValues}
            />
            {innerLocations.length > 0 && (
              <div className={EditLocationFormV2Style.inner_location_container}>
                <div className={EditLocationFormV2Style.inner_location_title_text}>
                  {innerLocations[0].category}
                </div>
                <div className={EditLocationFormV2Style.inner_locations_list_container}>
                  {innerLocations.map((innerLocation, index) => (
                    <div
                      key={innerLocation.name}
                      className={EditLocationFormV2Style.inner_location_item}
                      style={{ borderBottom: index < innerLocations.length - 1 && '0.1em solid #E2E2EA'}}
                    >
                      {innerLocation.name}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )
      }
      footer={
        <div className={EditLocationFormV2Style.flex_row}>
          <div
            className={`${EditLocationFormV2Style.button} ${EditLocationFormV2Style.discard_button}`}
            onClick={onFormClose}
          >
            Discard
          </div>
          <div
            className={`${EditLocationFormV2Style.button} ${EditLocationFormV2Style.create_button}`}
            onClick={async () => {
              setLoading(true);
              const canSubmit = await verifyPayload();
              if (canSubmit) {
                await handleUpdateLocation();
                setBannerHasError(false);
                setBannerTitle(`Successfully updated ${location.name}!`);
                setShowBanner(true);
                onSave();
              } else {
                setShowModalBanner(true);
              }
              setLoading(false);
            }}
          >
            Save
          </div>
        </div>
      }
    />
  );
};
