import { XemelgoService } from '../services/XemelgoService';
import { genericSort } from './Utilities';

const ONE_DAY_IN_MILLISECOND = 1000 * 60 * 60 * 24;

export const BulkUpdateFeatureFunctionMap = {
  locations: (args) => getLocationIdentifierData(args),
  'item-identifier': (args) => getItemIdentifierData(args),
  'task-identifier': (args) => getTaskIdentifierData(args),
  'item-type-id': (args) => getItemTypeIdData(args)
};

/* TODO: abstract out this file to be more genric so the features send args for
 * API call as well as to specify how the result from API needs to be
 * processed and returned
 */
export const AddAssetFeatureFunctionMap = {
  onboardingLocation: (argsForAPI) => getOnboardingLocationOptions(argsForAPI),
  itemType: (argsForAPI) => getAssetTypeOptions(argsForAPI)
};

export const AddOrderFeatureFunctionMap = {
  onboardingLocation: (argsForAPI) => getOnboardingLocationOptions(argsForAPI)
};

export const AddInventoryFeatureFunctionMap = {
  onboardingLocation: (argsForAPI) => getOnboardingLocationOptions(argsForAPI),
  cabinet: (argsForAPI) => getTargetLocationOptions(argsForAPI),
  item_number: (argsForAPI) => getInventoryItemTypeOptions(argsForAPI)
};

export async function processOnboardingPageAttributes(
  defaultAttributeMap,
  customAttributeMap,
  featureFunctionMap
) {
  if (defaultAttributeMap) {
    for (const attributeId of Object.keys(defaultAttributeMap)) {
      if (defaultAttributeMap[attributeId].type === 'dropdownWithOptionsFromAPI') {
        const getOptionsFn = featureFunctionMap[attributeId];
        defaultAttributeMap[attributeId].options = getOptionsFn
          ? await getOptionsFn(defaultAttributeMap[attributeId].argsForAPI)
          : [];
      }
    }
  }

  if (customAttributeMap) {
    for (const attributeId of Object.keys(customAttributeMap)) {
      if (customAttributeMap[attributeId].type === 'dropdownWithOptionsFromAPI') {
        const getOptionsFn = featureFunctionMap[attributeId];
        customAttributeMap[attributeId].options = getOptionsFn
          ? await getOptionsFn(customAttributeMap[attributeId].argsForAPI)
          : [];
      }
    }
  }
  return { defaultAttributeMap, customAttributeMap };
}

async function processAttributeMap(attributeMap, featureFunctionMap, additionalConfig, filterOnly) {
  if (filterOnly) {
    const filteredAttributeMap = {};
    Object.keys(attributeMap)
      .filter((attribute) => {
        return attributeMap[attribute].editable && attributeMap[attribute].editable.bulk;
      })
      .forEach((id) => {
        filteredAttributeMap[id] = attributeMap[id];
      });

    return { ...filteredAttributeMap };
  }

  for (const attributeId of Object.keys(attributeMap)) {
    if (attributeMap[attributeId].validateCSVInputWithOptions) {
      const getOptionsFn =
        featureFunctionMap[attributeMap[attributeId].validateCSVInputWithOptions];
      const result = getOptionsFn ? await getOptionsFn(additionalConfig) : {};
      attributeMap[attributeId].options = result || {};
    }
  }
  return { ...attributeMap };
}

export async function processBulkUpdateAttributes(
  defaultAttributeMap,
  customAttributeMap,
  featureFunctionMap,
  additionalConfig,
  filterOnly
) {
  let filteredDefaultAttributeMap;
  let filteredCustomAttributeMap = {};
  if (defaultAttributeMap) {
    filteredDefaultAttributeMap = await processAttributeMap(
      defaultAttributeMap,
      featureFunctionMap,
      additionalConfig,
      filterOnly
    );
  }

  if (customAttributeMap) {
    filteredCustomAttributeMap = await processAttributeMap(
      customAttributeMap,
      featureFunctionMap,
      additionalConfig,
      filterOnly
    );
  }

  return {
    defaultAttributeMap: filteredDefaultAttributeMap,
    customAttributeMap: filteredCustomAttributeMap
  };
}

const getInventoryItemTypeOptions = async (argsForAPI) => {
  const itemTypeClient = XemelgoService.getClient().getItemTypeClient();
  const data = await itemTypeClient.listItemTypes(['id', 'identifier'], 'Inventory');
  let sortedItemTypeList = [];
  const itemTypes = data.map((item) => {
    return {
      id: item.id,
      value: item.identifier,
      label: item.identifier
    };
  });

  if (itemTypes && itemTypes.length) {
    sortedItemTypeList = genericSort(itemTypes, 'label', 'ascending');
  }

  return sortedItemTypeList;
};

const getAssetTypeOptions = async (argsForAPI) => {
  const itemTypeClient = XemelgoService.getClient().getItemTypeClient();
  const data = await itemTypeClient.listAssetTypes();
  let sortedAssetTypeList = [];
  const assetTypes = data.map((item) => {
    return {
      id: item.id,
      value: item.identifier,
      label: item.identifier,
      itemTypeIdentifier: item.identifier,
      expected_lifespan: item.expected_lifespan
        ? item.expected_lifespan / ONE_DAY_IN_MILLISECOND
        : null,
      schedule_refresh_interval: item.schedule_refresh_interval
        ? item.schedule_refresh_interval / ONE_DAY_IN_MILLISECOND
        : null,
      usage_limit: item.usage_limit || null
    };
  });

  if (assetTypes && assetTypes.length) {
    sortedAssetTypeList = genericSort(assetTypes, 'label', 'ascending');
  }

  return sortedAssetTypeList;
};

const getOnboardingLocationOptions = async (locationCategory) => {
  const locationClient = XemelgoService.getClient().getLocationClient();
  const locationList = await locationClient.getLocationsOfCategory(locationCategory);
  let sortedLocationOptions = [];
  if (locationList.length) {
    const locationOptions = [];
    locationList.forEach((location) => {
      if (location.identifier) {
        locationOptions.push({
          id: location.id,
          label: `Name: ${location.name} (Identifier: ${location.identifier})`,
          value: location.id,
          valueToValidateWith: location.identifier
        });
      }
    });
    if (locationOptions.length) {
      sortedLocationOptions = genericSort(locationOptions, 'label', 'ascending');
    }
  }

  return sortedLocationOptions;
};

const getTargetLocationOptions = async (locationCategory) => {
  const locationClient = XemelgoService.getClient().getLocationClient();
  const locationList = await locationClient.getLocationsOfCategory(locationCategory);
  let sortedLocationOptions = [];
  if (locationList.length) {
    const locationOptions = [];
    locationList.forEach((location) => {
      if (location.identifier) {
        locationOptions.push({
          id: location.id,
          label: `Name: ${location.name} (Identifier: ${location.identifier})`,
          value: location.identifier,
          valueToValidateWith: location.identifier
        });
      }
    });
    if (locationOptions.length) {
      sortedLocationOptions = genericSort(locationOptions, 'label', 'ascending');
    }
  }

  return sortedLocationOptions;
};

const getLocationIdentifierData = async (additionalConfig) => {
  const { possibleDetectorLocationCategories: locationCategories = [] } = additionalConfig;
  const locationIdentifierToLocationMap = {};

  try {
    // Get locations to find out id from identifier in CSV
    const locationClient = XemelgoService.getClient().getLocationClient();
    const allLocations = await locationClient.getLocationsOfCategory(locationCategories);
    allLocations &&
      allLocations.length &&
      allLocations.forEach((location) => {
        if (location.identifier) {
          const locationIdentifier = location.identifier.trim().toUpperCase();
          locationIdentifierToLocationMap[locationIdentifier] = location;
        }
      });
    return locationIdentifierToLocationMap;
  } catch (e) {
    return `Error fetching data to validate - ${e}`;
  }
};

const getItemIdentifierData = async (additionalConfig) => {
  const { itemClass: itemClassFilter = ['Asset', 'Inventory'] } = additionalConfig;
  const itemIdentifierToItemMap = {};
  try {
    const itemClient = XemelgoService.getClient().getItemClient();
    const items = await itemClient.getItemDataForBulkUpdate(itemClassFilter);
    items &&
      items.length > 0 &&
      items.forEach((item, i) => {
        if (item && item.identifier) {
          const identifier = item.identifier.trim().toUpperCase();
          itemIdentifierToItemMap[identifier] = item;
        }
      });
    return itemIdentifierToItemMap;
  } catch (e) {
    return `Error fetching data to validate - ${e}`;
  }
};

const getTaskIdentifierData = async () => {
  try {
    const itemClient = XemelgoService.getClient().getItemClient();
    const taskIdentifierToObjectMap = await itemClient.getTaskDataForBulkUpdate();
    return taskIdentifierToObjectMap;
  } catch (e) {
    return `Error fetching data to validate - ${e}`;
  }
};

const getItemTypeIdData = async () => {
  try {
    const itemTypeClient = XemelgoService.getClient().getItemTypeClient();
    const itemTypes = await itemTypeClient.getItemTypes(['identifier', 'id']);
    const itemTypeIdentifierToIdMap = {};
    itemTypes.forEach((eachItemType) => {
      const { id, identifier } = eachItemType;
      itemTypeIdentifierToIdMap[identifier.trim().toUpperCase()] = id;
    });
    return itemTypeIdentifierToIdMap;
  } catch (e) {
    return `Error fetching data to validate - ${e}`;
  }
};
