import React, { useState, useEffect, useMemo, useRef } from 'react';
import AutoSizeTextArea from '../AutoSizeTextArea/AutoSizeTextArea';
import Style from './SearchDropdown.module.css';
import PropTypes from 'prop-types';

const useOutsideAlerter = (ref, func) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        func();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
};

const SearchDropdown = ({
  options,
  selectedItem,
  onItemSelected,
  inputValue,
  onChangeText,
  error,
  errorMessage,
  withoutOptionFilter,
  placeholder
}) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    setIsFocused(false);
  });
  const [inputText, setInputText] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setInputText(inputValue);
  }, [inputValue]);

  useEffect(() => {
    if (!isFocused && inputText !== selectedItem.label) {
      setInputText(selectedItem?.label || '');
    }
  }, [selectedItem?.label, isFocused]);

  const filteredOptions = useMemo(() => {
    if (withoutOptionFilter) {
      return options;
    }
    return options.filter(({ label }) => {
      return label.toLowerCase().includes(inputText.toLowerCase());
    });
  }, [inputText, options]);

  return (
    <div className={Style.dropdown_container} ref={wrapperRef}>
      <AutoSizeTextArea
        error={error}
        errorMessage={errorMessage}
        value={withoutOptionFilter ? selectedItem?.label : inputText}
        onChangeText={(text) => {
          if (!withoutOptionFilter) {
            setInputText(text);
            onChangeText(text);
          }
        }}
        onFocus={() => {
          setIsFocused(true);
        }}
        placeholder={placeholder}
      />
      {isFocused && (
        <div className={Style.dropdown_menu_container}>
          <ul className={`${Style.dropdown_menu} ${Style.flex_column2}`}>
            {filteredOptions.length ? (
              filteredOptions.map((eachOption) => {
                const { id, label } = eachOption;
                return (
                  <li
                    key={id}
                    className={Style.dropdown_item}
                    onClick={() => {
                      setInputText(label);
                      onItemSelected(eachOption);
                      setIsFocused(false);
                    }}
                  >
                    {label}
                  </li>
                );
              })
            ) : (
              <li className={Style.dropdown_no_result_message}>No matching items</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

SearchDropdown.defaultProps = {
  options: [],
  onItemSelected: () => {},
  onChangeText: () => {},
  inputValue: '',
  selectedItem: {},
  error: false,
  errorMessage: '',
  withoutOptionFilter: false
};

SearchDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, label: PropTypes.string, value: PropTypes.any })
  ),
  onItemSelected: PropTypes.func,
  onChangeText: PropTypes.func,
  inputValue: PropTypes.string,
  selectedItem: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.any
  }),
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  withoutOptionFilter: PropTypes.bool
};

export default SearchDropdown;
