import React from 'react';
import Style from './TextFilterInputComponent.module.css';

const TextFilterInputComponent = ({
  onChange = () => {},
  placeholder = 'Type to filter results',
  containerStyle = null
}) => {
  return (
    <div className={containerStyle || Style.container}>
      <input
        className={Style.input_field_style}
        placeholder={placeholder}
        onChange={(event) => {
          onChange(event.target.value);
        }}
      />
    </div>
  );
};

export default TextFilterInputComponent;
