import React from 'react';
import Overview from '../../Overview';
import AssetOverviewComponentStyle from './AssetOverviewComponent.module.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const AssetOverviewComponent = ({
  leftComponent,
  logoImage,
  numOfAsset,
  numOfMissing,
  numOfWeeklyMissing
}) => {
  const LeftComponent = () => {
    if (leftComponent) {
      return leftComponent;
    }
    return logoImage ? (
      <img className={AssetOverviewComponentStyle.image} src={logoImage} alt="failed to load img" />
    ) : (
      <Skeleton className={AssetOverviewComponentStyle.image} width={300} height={300} />
    );
  };

  const RightComponent = () => (
    <div className={AssetOverviewComponentStyle.right_container}>
      <p className={AssetOverviewComponentStyle.header_style}>Overview</p>
      <div className={AssetOverviewComponentStyle.group}>
        <p className={AssetOverviewComponentStyle.label_group}>
          Total Count
          <br />
          <b className={AssetOverviewComponentStyle.value}>
            {numOfAsset !== undefined ? numOfAsset : <Skeleton />}
          </b>
        </p>
        <p className={AssetOverviewComponentStyle.label_group}>
          Total Missing Count
          <br />
          <b className={AssetOverviewComponentStyle.value}>
            {numOfMissing !== undefined ? numOfMissing : <Skeleton />}
          </b>
        </p>
      </div>

      <p className={AssetOverviewComponentStyle.header_style}>Metrics</p>
      <div className={AssetOverviewComponentStyle.group}>
        <p className={AssetOverviewComponentStyle.label_group}>
          Assets Missing Weekly
          <br />
          <b className={AssetOverviewComponentStyle.value}>
            {numOfWeeklyMissing !== undefined ? numOfWeeklyMissing : <Skeleton />}
          </b>
        </p>
      </div>
    </div>
  );

  return <Overview leftComponent={<LeftComponent />} rightComponent={<RightComponent />} />;
};

export default AssetOverviewComponent;
