import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SortIcon from '@material-ui/icons/Sort';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSortAlphaDown,
  faSortAlphaUp,
  faSortNumericDown,
  faSortNumericUp,
  faSortAmountUpAlt,
  faSortAmountDownAlt
} from '@fortawesome/free-solid-svg-icons';
import { MDBDropdown, MDBDropdownToggle, MDBDropdownItem, MDBDropdownMenu } from 'mdbreact';
import SortIconDropDownStyle from './SortIconDropDown.module.css';

const iconSelection = (type, reverse) => {
  switch (type) {
    case 'number':
      if (reverse) {
        return faSortNumericUp;
      }
      return faSortNumericDown;
    case 'character':
      if (reverse) {
        return faSortAlphaUp;
      }
      return faSortAlphaDown;
    default:
      if (reverse) {
        return faSortAmountUpAlt;
      }
      return faSortAmountDownAlt;
  }
};

const SortIconDropDown = ({ sortSchema = [], onClick, defaultSortReversed = false }) => {
  const [focusedId, setFocusedId] = useState('');
  const [reverse, setReverse] = useState(false);
  const [oldSchema, setOldSchema] = useState([]);
  useEffect(() => {
    if (JSON.stringify(oldSchema) !== JSON.stringify(sortSchema)) {
      sortSchema.forEach((each) => {
        if (each.default) {
          setFocusedId(each.id);
          setReverse(defaultSortReversed);
          onClick(each.compareFunc, each.id, reverse);
        }
      });
    } else {
      sortSchema.forEach((each) => {
        if (each.id === focusedId) {
          onClick(
            (a, b) => {
              const schema = reverse
                ? -each.compareFunc(a, b, reverse)
                : each.compareFunc(a, b, reverse);
              return schema;
            },
            each.id,
            reverse
          );
        }
      });
    }
    setOldSchema(sortSchema);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortSchema]);
  return (
    <MDBDropdown>
      <MDBDropdownToggle className={SortIconDropDownStyle.dropdown_toggle} color="">
        <SortIcon className={SortIconDropDownStyle.icon_button} />
      </MDBDropdownToggle>
      <MDBDropdownMenu className={SortIconDropDownStyle.dropdown_menu}>
        {sortSchema &&
          sortSchema.map((each) => {
            return (
              <MDBDropdownItem
                id={each.id}
                key={each.id}
                className={`${SortIconDropDownStyle.dropdown_item} ${focusedId === each.id &&
                  SortIconDropDownStyle.active}`}
                onClick={() => {
                  if (focusedId === each.id) {
                    const newReverseValue = !reverse;
                    onClick(
                      (a, b) => {
                        const compareFunction = newReverseValue
                          ? -each.compareFunc(a, b, newReverseValue)
                          : each.compareFunc(a, b, newReverseValue);
                        return compareFunction;
                      },
                      each.id,
                      reverse
                    );
                    setReverse(newReverseValue);
                  } else {
                    setReverse(false);
                    setFocusedId(each.id);
                    onClick(each.compareFunc, each.id, reverse);
                  }
                }}
                toggle={false}
              >
                <div className={SortIconDropDownStyle.title}>{each.value}</div>
                <FontAwesomeIcon
                  icon={iconSelection(each.type, reverse && focusedId === each.id)}
                />
              </MDBDropdownItem>
            );
          })}
      </MDBDropdownMenu>
    </MDBDropdown>
  );
};

export default SortIconDropDown;

SortIconDropDown.defaultProps = {
  sortSchema: [],
  onClick: () => {}
};

SortIconDropDown.propTypes = {
  sortSchema: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func
};
