import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import ManagementDashboardScreen from '../../screens/ManagementDashboardScreen/ManagementDashboardScreen';

const ManagementDashboardApp = () => {
  return (
    <Fragment>
      <Route exact path="/management-dashboard" component={ManagementDashboardScreen} />
    </Fragment>
  );
};

export default ManagementDashboardApp;
