/**
 * @param assets
 * @return {{total, expired, missing}}
 */
const getAssetSummaryStats = (assets) => {
  const now = Date.now();
  const total = assets.length;

  const filterMissings = assets.filter((asset) => asset.isMissing);
  const missingCount = filterMissings.length;

  const filteredExpireds = assets.filter((asset) => asset.expiry_date && asset.expiry_date <= now);
  const expiredCount = filteredExpireds.length;
  return {
    total,
    missing: missingCount,
    overdue: expiredCount
  };
};

const getDisplayableStats = (stats, configuration) => {
  return configuration.map((statName) => {
    const value = stats[statName];
    return {
      name: statName,
      value
    };
  });
};

/**
 *
 * @param assetsByLocations
 * @param configuration
 * @return { { [string]: { type: string, total: number, stats: { name: string, value: any }[] } }}
 */
export const getLocationStats = (
  assetsByLocations,
  configuration = ['total', 'missing', 'overdue']
) => {
  const statMap = assetsByLocations.reduce((map, locationWithAssets) => {
    const clonedMap = { ...map };
    const { id, tracksItem: assets } = locationWithAssets;
    const stats = getAssetSummaryStats(assets);
    clonedMap[id] = stats;
    return clonedMap;
  }, {});

  const xformedStatMap = Object.entries(statMap).reduce((map, [locationId, stats]) => {
    const clonedMap = { ...map };
    clonedMap[locationId] = {
      type: 'Asset',
      total: stats.total,
      stats: getDisplayableStats(stats, configuration)
    };

    return clonedMap;
  }, {});

  return xformedStatMap;
};
