import React, { Fragment, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import { useXemelgoClient } from '../../services/xemelgo-service';
import { useFeatureConfigProvider, useAppConfigProvider } from '../../services/soft-cache-service';
import './style.css';
import { TwoColumnsPaneView } from '../../components/two-columns-pane-view';
import { ListResourceGroupPanel } from './features/list-resource-group-panel';
import { AddResourceForm } from './features/add-resource-form';
import { AddDetectorFormV2 } from './features/add-detector-form-v2/AddDetectorFormV2';
import { ResourceDetailPane } from './features/resource-detail-pane';
import { DeleteResourceForm } from './features/delete-resource-form';
import { EditResourceForm } from './features/edit-resource-form';
import { EditDetectorFormV2 } from './features/edit-detector-form-v2/EditDetectorFormV2';
import { getDetectorResourceManager } from '../domains/resource-managers/detector-resource-manager';
import { DefaultConfiguration } from './configuration';
import { FeatureConfigurationProvider } from '../../domains/feature-configuration-provider';

const prepareLocationArguments = (locations) => {
  const sorted = locations.sort((loc1, loc2) => loc1.getName().localeCompare(loc2.getName()));
  const locationArguments = sorted.map((loc) => {
    const nameSegments = [loc.getName()];
    const parentLoc = loc.getParent();
    if (parentLoc) {
      nameSegments.push(parentLoc.getName());
    }

    return {
      key: loc.getId(),
      value: nameSegments.join(' - ')
    };
  });
  return locationArguments;
};

/**
 * ModelMap has to be merged separately due to the fact that empty provided config will always return { modelMap: {}},
 *  which will result in the provided empty model map to wipe out the model map from default configuration.
 *
 * This helper function will merge model map onto a separate variable, and assign it back after the full configuration merged.
 * @param providedConfig
 * @param defaultConfig
 * @returns {{modelMap: {}}}
 */
const mergeConfigs = (providedConfig, defaultConfig) => {
  const { modelMap: providerModelMap = {} } = providedConfig;
  const { modelMap: defaultModelMap = {} } = defaultConfig;
  const mergeModelMap = { ...defaultModelMap, ...providerModelMap };
  const mergedConfig = { ...defaultConfig, ...providedConfig, modelMap: mergeModelMap };
  return mergedConfig;
};

const FeatureId = 'listDetectors';
const APP_ID = 'myFacility';
export const ListDetectors = ({ appId }) => {
  const appConfigProvider = useAppConfigProvider(APP_ID);
  const useV2 = appConfigProvider.config.useV2;
  const [xemelgoClient] = useState(useXemelgoClient());
  const [providedConfigProvider] = useState(useFeatureConfigProvider(appId, FeatureId));
  const [configProvider, setConfigProvider] = useState(null);
  const [resourceMap, setResourceMap] = useState({});
  const [needRefreshData, setNeedRefreshData] = useState(true);
  const [selectedResource, setSelectedResource] = useState(null);
  const [openAddForm, setOpenAddForm] = useState(false);
  const [addFormArgument, setAddFormArgument] = useState(null);
  const [openDeleteForm, setOpenDeleteForm] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [editFormArgument, setEditFormArgument] = useState(null);
  const [resourceForEdit, setResourceForEdit] = useState(null);
  const [creationError, setCreationError] = useState(false);
  const [loading, setLoading] = useState(false);

  /**
   * Name: mergedProvideWithDefaultConfig.
   */
  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (!providedConfigProvider) {
      return cancelCallback;
    }

    const providedConfig = providedConfigProvider.getConfiguration();
    const mergedConfig = mergeConfigs(providedConfig, DefaultConfiguration);
    const mergedConfigProvider = FeatureConfigurationProvider.parse(FeatureId, mergedConfig);

    if (!cancelled) {
      setConfigProvider(mergedConfigProvider);
    }

    return cancelCallback;
  }, [providedConfigProvider]);

  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (!configProvider || !xemelgoClient || !needRefreshData) {
      return cancelCallback;
    }
    setLoading(true);
    const detectorClient = xemelgoClient.getDetectorClient();
    const { modelMap = {} } = configProvider.getFeatureConfiguration('resourceDetailView');
    const modeMap = {};
    const classOptions = modelMap.detector?.properties?.class?.options || [];
    classOptions.forEach((option) => {
      const modeOptions = option?.properties?.mode?.options || [];
      modeOptions.forEach((modeOption) => {
        modeMap[modeOption.key] = modeOption.value;
      });
    });
    
    detectorClient.listDetectors().then((results) => {
      const sorted = results.sort((detector1, detector2) =>
        detector1.getSerial().localeCompare(detector2.getSerial())
      );
      const transformedMap = sorted
        .map((detector) => {
          const location = detector.getLocation();
          const serial = detector.getSerial();
          const nameSegments = [serial];
          let locName = null;
          let locId = null;
          if (location) {
            locName = location.getName();
            locId = location.getId();
            nameSegments.push(locName);
          }
          let action = 'Default';
          const detectorActions = detector.getActions();

          if (detectorActions) {
            switch (detectorActions[0]) {
              case 'endTrackingSession':
                action = 'Mark as Complete';
                break;
              case 'sameDetectorEndTrackingSession':
                action = 'Entry / Exit doorway';
                break;
              case 'In Transit':
                action = 'Mark as Shipping';
                break;
              case 'Received':
                action = 'Mark as Received';
                break;
              case 'increaseItemUsage':
                action = 'Increase Item Usage';
                break;
              case 'flipHasExitState':
                action = 'Entry / Exit (VMI)';
                break;
              default:
                break;
            }
          }

          return {
            id: detector.getId(),
            name: nameSegments.join(' - '),
            vid: serial,
            class: `${detector.getClass()} Reader`,
            vendor: detector.getVendor(),
            mode: modeMap[detector.getMode()],
            location: locName,
            locationId: locId,
            action
          };
        })
        .reduce((map, resource) => {
          const clonedMap = { ...map };
          const { id } = resource;
          clonedMap[id] = resource;
          return clonedMap;
        }, {});

      if (!cancelled) {
        setResourceMap(transformedMap);
        setNeedRefreshData(false);
      }
      setLoading(false);
    });
    return cancelCallback;
  }, [configProvider, xemelgoClient, needRefreshData]);

  const refreshData = useCallback(() => {
    setSelectedResource(null);
    setNeedRefreshData(true);
    setCreationError(false);
    setLoading(false);
  }, []);

  const onRecordSelected = useCallback(
    (id) => {
      const resource = resourceMap[id];

      // override the name used for displaying on the detail pane.
      const cloned = { ...resource };
      cloned.name = cloned.vid;
      setSelectedResource(cloned);
    },
    [resourceMap]
  );

  const onDeleteButtonClicked = useCallback(() => {
    setOpenDeleteForm(true);
  }, []);

  const onCancelDeleteFormCallback = useCallback(() => {
    setOpenDeleteForm(false);
  }, []);

  const onSubmitDeleteForm = useCallback(
    (id) => {
      const detectorClient = xemelgoClient.getDetectorClient();
      // TODO: need to handle when delete fails
      detectorClient.removeDetector(id).then(() => {
        setOpenDeleteForm(false);
        refreshData();
      });
    },
    [xemelgoClient, refreshData]
  );

  const onAddButtonClicked = useCallback(() => {
    // find out from configuration for which location arguments we need to fetch
    const featureArgumentsMap = configProvider.getValue('featureArgumentsMap', 'object', {
      addResource: { location: 'department' }
    });
    const { addResource } = featureArgumentsMap;
    const { location: locationModelId } = addResource;

    // find out from the model the category name
    const locationModelProvider = configProvider.getModel(locationModelId);
    const locationCategory = locationModelProvider.getValue('category', 'object', {
      name: locationModelId
    });
    const { name: categoryName } = locationCategory;

    // fetch the data
    const locationClient = xemelgoClient.getLocationClient();
    locationClient.getLocationsOfCategory(categoryName).then((results) => {
      const locationArguments = prepareLocationArguments(results);

      const providedArgument = { location: locationArguments };
      if (useV2) {
        window.fcWidget.hide();
      }
      setAddFormArgument(providedArgument);
      setOpenAddForm(true);
    });
  }, [configProvider, xemelgoClient, useV2]);

  const onCancelAddFormCallback = useCallback(() => {
    if (useV2) {
      window.fcWidget.show();
    }
    setOpenAddForm(false);
  }, []);

  const onAddFormSubmit = useCallback(
    async (payloads) => {
      setLoading(true);
      if (useV2) {
        window.fcWidget.show();
        setOpenAddForm(false);
        refreshData();
        setLoading(false);
      } else {
        const detectorRM = getDetectorResourceManager(xemelgoClient);
        await Promise.all(
          payloads.map(async (payload) => {
            await detectorRM.createDetectorAndAttachToLocation(payload);
          })
        ).then(
          () => {
            setOpenAddForm(false);
            refreshData();
            setLoading(false);
          },
          (error) => {
            setCreationError(error);
            setLoading(false);
          }
        );
      }
    },
    // eslint-disable-next-line
    [xemelgoClient, refreshData, creationError, useV2]
  );

  const onEditButtonClicked = useCallback(() => {
    const featureArgumentsMap = configProvider.getValue('featureArgumentsMap', 'object', {
      editResource: { location: 'department' }
    });
    const { location: locationModelId } = featureArgumentsMap.editResource;
    const locationModelConfigProvider = configProvider.getModel(locationModelId);
    const locationCategory = locationModelConfigProvider.getValue('category', 'object', {
      name: locationModelId
    });

    const { name: categoryName } = locationCategory;
    const locationClient = xemelgoClient.getLocationClient();
    const editResource = { ...selectedResource };

    locationClient.getLocationsOfCategory(categoryName).then((results) => {
      const locationArguments = prepareLocationArguments(results);
      if (useV2) {
        window.fcWidget.hide();
      }
      setEditFormArgument({ location: locationArguments });
      setResourceForEdit(editResource);
      setOpenEditForm(true);
    });
  }, [xemelgoClient, selectedResource, configProvider]);

  const onEditCancel = useCallback(() => {
    if (useV2) {
      window.fcWidget.show();
    }
    setOpenEditForm(false);
  }, []);

  const onEditSave = useCallback(
    (payload) => {
      if (useV2) {
        setOpenEditForm(false);
        refreshData();
      } else {
        // not much we are allowing user to change on detector beside location
        const { id, locationId } = payload;

        if (!locationId) {
          return;
        }

        const detectorClient = xemelgoClient.getDetectorClient();
        detectorClient.changeDetectorLocation(id, locationId).then(() => {
          setOpenEditForm(false);
          refreshData();
        });
      }
    },
    [xemelgoClient, refreshData]
  );

  const renderAddForm = () => {
    if (useV2) {
      return (
        <AddDetectorFormV2
          modelId="detector"
          configuration={configProvider.getFeatureConfiguration('addResource')}
          show={openAddForm}
          providedArgument={addFormArgument}
          onSubmit={onAddFormSubmit}
          onCancel={onCancelAddFormCallback}
        />
      );
    } else {
      return (
        <AddResourceForm
          modelId="detector"
          configuration={configProvider.getFeatureConfiguration('addResource')}
          show={openAddForm}
          providedArgument={addFormArgument}
          onSubmit={onAddFormSubmit}
          onCancel={onCancelAddFormCallback}
          creationError={creationError}
          resources={Object.values(resourceMap)}
        />
      );
    }
  };

  const renderEditForm = () => {
    if (useV2) {
      return (
        <EditDetectorFormV2
          resource={resourceForEdit}
          modelId="detector"
          configuration={configProvider.getFeatureConfiguration('editResource')}
          show={openEditForm}
          providedArgument={editFormArgument}
          onCancel={onEditCancel}
          onSave={onEditSave}
        />
      );
    } else {
      return (
        <EditResourceForm
          resource={resourceForEdit}
          modelId="detector"
          configuration={configProvider.getFeatureConfiguration('editResource')}
          show={openEditForm}
          providedArgument={editFormArgument}
          onCancel={onEditCancel}
          onSave={onEditSave}
        />
      );
    }
  };

  return (
    <Fragment>
      {!configProvider || loading ? (
        <div className="loading-circle">
          <Spinner animation="border" />
        </div>
      ) : (
        <Fragment>
          {openEditForm && renderEditForm()}
          {openAddForm && renderAddForm()}
          {openDeleteForm && (
            <DeleteResourceForm
              resource={selectedResource}
              show={openDeleteForm}
              onSubmit={onSubmitDeleteForm}
              onCancel={onCancelDeleteFormCallback}
            />
          )}
          <TwoColumnsPaneView
            className="list-detectors"
            leftPane={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <ListResourceGroupPanel
                modelId="detector"
                resources={Object.values(resourceMap)}
                configuration={configProvider.getConfiguration()}
                focus={!needRefreshData}
                onRecordSelected={onRecordSelected}
                onAddClicked={onAddButtonClicked}
              />
            }
            rightPane={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <ResourceDetailPane
                modelId="detector"
                configuration={configProvider.getFeatureConfiguration('resourceDetailView')}
                resource={selectedResource}
                onDeleteClicked={onDeleteButtonClicked}
                onEditClicked={onEditButtonClicked}
              />
            }
          />
        </Fragment>
      )}
    </Fragment>
  );
};

ListDetectors.propTypes = {
  appId: PropTypes.string.isRequired
};
