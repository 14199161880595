import React from 'react';
import PropTypes from 'prop-types';
import Style from './InputGroup.module.css';

const InputGroup = ({ label, children }) => {
  return (
    <div className={Style.group_container}>
      <span className={Style.group_label}>{label}</span>
      {children}
    </div>
  );
};

InputGroup.defaultProps = {
  label: '',
  children: {}
};

InputGroup.propTypes = {
  label: PropTypes.string,
  children: PropTypes.any
};

export default InputGroup;
