import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'mdbreact';
import misroute_icon from 'img/misroute_icon.png';
import missingItem_icon from 'img/missingItem_icon.png';
import blue_circle from 'img/blue_circle.png';
import Style from './ItemTile.module.css';
import { sortByPriorityMap } from '../../common/Utilities';

const alertIconMap = {
  missingItem: missingItem_icon,
  misroutedItem: misroute_icon
};

const getAlertIcon = (alertType) => {
  return alertIconMap[alertType];
};

const getAlertIconContainer = (alertStatus) => {
  return (
    <div className={Style.alert_icon_container}>
      {alertStatus && <img className={Style.alert_icon} src={getAlertIcon(alertStatus)} alt="!" />}
    </div>
  );
};

const getRecentItemIndicationContainer = (isRecentlyScannedItem) => {
  return (
    isRecentlyScannedItem && (
      <div className={Style.alert_icon_container}>
        <img className={Style.recent_item_indicator} src={blue_circle} alt="!" />
      </div>
    )
  );
};

const getStatusFlagsContainer = (statusFlags) => (
  <div className={Style.statusFlags_container}>
    {statusFlags &&
      statusFlags.length > 0 &&
      statusFlags.map((status, index) => (
        <p
          key={index}
          className={Style.status_text}
          style={{
            color: status.color
          }}
        >
          {status.displayText}
          {index < statusFlags.length - 1 ? ` ⁃ ` : ''}
        </p>
      ))}
  </div>
);

const getDataRow = (data) => {
  return (
    <Row md="4" key={data.itemIdentifier} className={Style.row}>
      {data.itemDataDisplayConfigMap.itemDataDisplayList.map((item, index) => {
        switch (item.type) {
          case 'status':
            let sortedStatuses = [];
            if (data[item.key] && data[item.key].length) {
              sortedStatuses = data[item.key].sort((a, b) => (a.priority < b.priority ? 1 : -1));
              return (
                <div key={index} className={Style.data_item_div}>
                  <p className={Style.data_item_value} style={item.style}>
                    {sortedStatuses.map((status) => {
                      return (
                        <p
                          style={{
                            color: status.color,
                            'margin-bottom': '3px'
                          }}
                        >
                          {status.displayText}
                        </p>
                      );
                    })}
                  </p>
                </div>
              );
            }
            return null;
          default:
            return (
              <div key={index} className={Style.data_item_div}>
                <p className={Style.data_item_title}>{item.header || ''}</p>
                <p className={Style.data_item_value} style={item.style}>
                  {data[item.key] || '-'}
                </p>
              </div>
            );
        }
      })}
    </Row>
  );
};

const ItemTile = ({ data }) =>
  data && (
    <a
      key={data.id}
      title={`Click to view details of ${data.itemIdentifier}`}
      className={`
        ${Style.display_block}
        ${Style.cell}
      `}
      style={{
        backgroundColor: data.color || 'white',
        borderLeft: `1.5em solid ${data.statusFlagMapList.length &&
          data.statusFlagMapList[0].color}`,
        borderRight: `2px solid ${data.statusFlagMapList.length &&
          data.statusFlagMapList[0].color}`,
        borderTop: `2px solid ${data.statusFlagMapList.length && data.statusFlagMapList[0].color}`,
        borderBottom: `2px solid ${data.statusFlagMapList.length &&
          data.statusFlagMapList[0].color}`
      }}
      href={data.detailsPageLink}
    >
      {getStatusFlagsContainer(data.statusFlagMapList)}
      {getRecentItemIndicationContainer(data.isRecentlyScannedItem)}
      {getAlertIconContainer(data.alertStatus)}
      {getDataRow(data)}
    </a>
  );

export default ItemTile;

ItemTile.defaultProps = {
  data: undefined
};

ItemTile.propTypes = {
  data: PropTypes.object
};
