import React, { useState } from 'react';
import { MDBInput } from 'mdbreact';
import PropTypes from 'prop-types';
import { useStore } from 'react-hookstore';
import { loggingUserStore } from '../../../state-managements/stores/login-store';

const ForgotPasswordVerificationForm = ({ logo, onSubmit }) => {
  const [loggingUser] = useStore(loggingUserStore);
  const [errorMessage, setErrorMessage] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfimPassword] = useState('');

  const validateInputFields = () => {
    return (
      verificationCode.length > 0 &&
      newPassword.length > 0 &&
      confirmPassword.length > 0 &&
      newPassword === confirmPassword
    );
  };

  const onVerificationCodeChange = (event) => {
    const veriCode = event.target.value;
    setVerificationCode(veriCode);
  };

  const onNewPasswordChange = (event) => {
    const newPwd = event.target.value;
    setNewPassword(newPwd);
  };

  const onConfirmPasswordChange = (event) => {
    const confirmPwd = event.target.value;
    setConfimPassword(confirmPwd);
  };

  return (
    <div className="content-body login-page-body">
      <div className="container login-form" style={{ textAlign: 'initial' }}>
        <form className="login-form-background">
          <img src={logo} className="brand-logo" alt="brand-logo" />
          <div className="form-group">
            <p
              style={{
                textAlign: 'center',
                color: 'white',
                marginBottom: 20
              }}
            >
              Check your email and Please enter the verification code you received along with a new
              password.
            </p>
            <div className="error_message">{errorMessage}</div>
            <MDBInput
              className="input-default"
              label="Verification Code"
              value={verificationCode}
              id="verificationCode"
              onInput={onVerificationCodeChange}
            />
            <MDBInput
              className="input-default"
              label="New Password"
              value={newPassword}
              id="newPassword"
              onInput={onNewPasswordChange}
              type="password"
            />
            <MDBInput
              className={
                newPassword === confirmPassword ? 'input-default' : 'input-default is-invalid'
              }
              label="Confirm New Password"
              id="confirmPassword"
              value={confirmPassword}
              onInput={onConfirmPasswordChange}
              type="password"
            />
          </div>
          <div className="profile-form-buttons">
            <button
              disabled={!validateInputFields()}
              type="submit"
              onClick={async (e) => {
                e.preventDefault();
                try {
                  await onSubmit(loggingUser, verificationCode, newPassword);
                } catch (error) {
                  setErrorMessage(error.message);
                }
              }}
              className="default-button"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

ForgotPasswordVerificationForm.propTypes = {
  logo: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export { ForgotPasswordVerificationForm };
