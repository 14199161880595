/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useStore } from 'react-hookstore';
import SwitchWithLabel from '../../components/switch-with-label/SwitchWithLabel';
import HorizontalPaginateControl from '../../components/horizontal-paginate-control/HorizontalPaginateControl';
import ShipmentsTrackPageComponent from '../../components/TrackPageComponents/shipments-track-page-component/ShipmentsTrackPageComponent';
import ShipmentsTrackPageFeatureStyle from './ShipmentsTrackPageFeature.module.css';
import Pagination from '../../components/pagination/Pagination';
import RecentItemsDisplay from '../../components/recent-items-display/RecentItemsDisplay';
import ShipmentsListView from '../../components/shipments-list-view-components/ShipmentsListView';
import SummaryTilesHolder from '../../components/summary-tiles-holder/SummaryTilesHolder';
import TextFilterInputComponent from '../../components/text-filter-input-component/TextFilterInputComponent';
import SortIconDropDown from '../../components/sort-icon-dropdown/SortIconDropDown';

import { getCurrentTimestamp, getFormattedDate, splitArray } from '../../common/Utilities';
import { useXemelgoClient } from '../../services/xemelgo-service';
import { useAppConfigProvider } from '../../services/soft-cache-service';

import { LocalCacheService } from '../../services/local-cache-service';
import { SessionStorageService } from '../../services/session-storage-service';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const APP_ID = 'shipments';
const TRACK_PAGE_CONFIG = 'trackPage';

const ItemStringMaxLength = 12;
const LocationNameStringMaxLength = 20;
const viewHeightPercentage = 0.6;
const itemHeight = 100;
const tileWidthpercentage = 0.52;
const summaryTileWidth = 180;

const ShipmentsTrackPageFeature = ({ history }) => {
  const oneHourInMilliSeconds = 3600000;
  const currentPageRef = useRef(1);
  const autoScrollIntervalId = useRef(null);
  const currentTilePage = useRef(1);
  const locationSummaryTileState = useRef({
    locationSummaryTileMap: {},
    filterViewEnabled: false
  });

  const configProvider = useAppConfigProvider(APP_ID);
  const TrackPageConfig = configProvider.getValue(TRACK_PAGE_CONFIG, 'object');

  const savedDashboardState = SessionStorageService.getSavedShipmentsDashboardState();

  const [contentLoaded, setContentLoaded] = useState(false);
  const [client] = useState(useXemelgoClient());
  const [pageTitle, setPageTitle] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [maxNumberOfColumns] = useState(3);
  const [tilesPerPage, setTilesPerPage] = useState(4);
  const [recentlyScannedItemCount, setRecentlyScannedItemCount] = useState(0);
  const [itemList, setItemList] = useState([]);
  const [displayedItemList, setDisplayedItemList] = useState(null);
  const [autoScroll, setAutoScroll] = useState(false);
  const [textFilterString, setTextFilterString] = useState(null);
  const [autoScrollInt, setAutoScrollInt] = useState(null);
  const [tilePageCount, setTilePageCount] = useState(1);
  const [locationSummaryTileDataList, setLocationSummaryTileDataList] = useState([]);
  const [displayedLocationSummaryTileDataList, setDisplayedLocationSummaryTileDataList] = useState(
    []
  );
  const prevItemListRef = useRef();
  const [processedItemList, setProcessedItemList] = useState([]);
  const sortParamsRef = useRef({});
  const defaultSortId = useRef('');

  useEffect(() => {
    onLoad();
    // cleanup (equivalent of componentWillUnmount)
    return () => {
      updateSavedDashboardState();
      if (autoScrollIntervalId.current) {
        clearInterval(autoScrollIntervalId.current);
        autoScrollIntervalId.current = null;
      }
      window.removeEventListener('resize', updateWindowDimensions);
      window.removeEventListener('beforeunload', updateSavedDashboardState);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    currentPageRef.current = currentPage <= pageCount ? currentPage : 1;
    if (pageCount <= 1 && autoScroll) {
      setAutoScroll(false);
    }
    updateItemListSort(sortParamsRef.current.sortId, sortParamsRef.current.sortDirectionReversed);
    displayFilteredItems(itemList);
    // eslint-disable-next-line
  }, [currentPage, itemsPerPage, pageCount, textFilterString]);

  useEffect(() => {
    handleAutoScrollSwitch();
    // eslint-disable-next-line
  }, [autoScroll]);

  useEffect(() => {
    const newTilePageCount = Math.ceil(locationSummaryTileDataList.length / tilesPerPage);
    setTilePageCount(newTilePageCount);
    showCurrentPageSummaryTiles(
      locationSummaryTileDataList,
      currentTilePage.current <= newTilePageCount ? currentTilePage.current : 1
    );
    // eslint-disable-next-line
  }, [tilesPerPage]);

  const updateWindowDimensions = () => {
    const height = window.innerHeight;
    const width = window.innerWidth;

    const newItemsPerPage =
      Math.floor(Math.floor(height * viewHeightPercentage) / itemHeight) * maxNumberOfColumns;
    const newTilesPerPage = Math.floor(Math.floor(width * tileWidthpercentage) / summaryTileWidth);

    setItemsPerPage(newItemsPerPage);
    setTilesPerPage(newTilesPerPage);
  };

  const updateSavedDashboardState = () => {
    const newState = {
      currentTilePage: currentTilePage.current,
      locationSummaryTileState: { ...locationSummaryTileState.current },
      sortParams: sortParamsRef.current,
      currentPage: currentPageRef.current
    };
    const userProfile = LocalCacheService.loadUserProfile();
    // if user is logged out, don't update dashboard state
    if (userProfile) {
      SessionStorageService.saveShipmentsDashboardState(newState);
    }
  };

  // filter view is true if there's at least one disabled tile, false if all are enabled
  const isFilterViewEnabled = (summaryTileMap) => {
    for (const v of Object.values(summaryTileMap)) {
      if (!v) return true;
    }
    return false;
  };

  /* Shorten display values to stringLength. Add ... at end if shortened */
  const shortenName = (name, stringLength) => {
    return name.length > stringLength ? `${name.substring(0, stringLength - 3)}...` : name;
  };

  const getItemCountOfType = (data, type) => {
    let count = 0;
    data.forEach((item) => {
      if (item[type]) {
        count++;
      }
    });
    return count;
  };

  const getCurrentPageItemsFromList = (dataList, currentPageNumber, itemCountPerPage) => {
    const indexOfLastItem = currentPageNumber * itemCountPerPage;
    const indexOfFirstItem = indexOfLastItem - itemCountPerPage;
    const currentPageItems = dataList.slice(indexOfFirstItem, indexOfLastItem);
    return currentPageItems;
  };

  const groupItemsByLocationStatus = (itemList) => {
    const currentLocationGroupedItemsMap = {};
    const nextLocationGroupedItemsMap = {};

    for (const item of itemList) {
      if (item.currentLocationStatus) {
        if (!currentLocationGroupedItemsMap[item.currentLocationStatus]) {
          currentLocationGroupedItemsMap[item.currentLocationStatus] = [item];
        } else {
          currentLocationGroupedItemsMap[item.currentLocationStatus].push(item);
        }
      }
      if (item.nextLocationStatus) {
        if (!nextLocationGroupedItemsMap[item.nextLocationStatus]) {
          nextLocationGroupedItemsMap[item.nextLocationStatus] = [item];
        } else {
          nextLocationGroupedItemsMap[item.nextLocationStatus].push(item);
        }
      }
    }

    return { currentLocationGroupedItemsMap, nextLocationGroupedItemsMap };
  };

  /* for future use
  const getStatusFlagCount = (data) => {
    const someMap = {};
    data.forEach((item) => {
      if (item.statusFlagMapList.length) {
        item.statusFlagMapList.forEach((statusFlag) => {
          if (someMap[statusFlag.id]) {
            someMap[statusFlag.id].count++;
          } else {
            someMap[statusFlag.id] = {
              displayText: statusFlag.displayText,
              color: statusFlag.color,
              count: 1
            };
          }
        });
      }
    });
    return Object.values(someMap);
  };
  */

  const getDetailsPageLink = (itemClass, itemId) => {
    switch (itemClass) {
      case 'Part':
        return `/work-order/part/detail?itemId=${itemId}`;
      case 'Traveller':
        return `/work-order/detail?itemId=${itemId}`;
      case 'Asset':
        return `/asset/detail?itemId=${itemId}`;
      case 'Inventory':
        return `inventory/item/detail?itemId=${itemId}`;
      default:
        return `/${itemClass}/detail?itemId=${itemId}`;
    }
  };

  const updateItemListSort = (sortId, sortDirectionReversed) => {
    let sortFunc = getSortCompareFunc(sortId);
    itemList.sort((a, b) => {
      return sortDirectionReversed
        ? -sortFunc(a, b, sortDirectionReversed)
        : sortFunc(a, b, sortDirectionReversed);
    });
  };

  const handleFilterView = (tileId, tileType) => {
    // stop auto scrolling
    if (autoScrollIntervalId.current) {
      clearInterval(autoScrollIntervalId.current);
      autoScrollIntervalId.current = null;
      setAutoScroll(false);
    }

    const { locationFilterSelectMode } = TrackPageConfig;

    setCurrentPage(1);

    if (tileType === 'locationSummary') {
      const { locationSummaryTileMap } = locationSummaryTileState.current;

      const locationSummaryTileStateCopy = { ...locationSummaryTileState.current };
      let { filterViewEnabled } = locationSummaryTileStateCopy;
      const locationSummaryTileMapCopy = locationSummaryTileMap;
      const tileNames = Object.keys(locationSummaryTileMapCopy);

      if (locationFilterSelectMode === 'single-select') {
        tileNames.forEach((tile) => {
          // enable only the tile clicked and disable all others
          locationSummaryTileMapCopy[tile] = tile === tileId;
        });
        filterViewEnabled = true;
      } else if (locationFilterSelectMode === 'multi-select') {
        if (!filterViewEnabled) {
          tileNames.forEach((tile) => {
            // enable only the tile clicked and disable all others
            locationSummaryTileMapCopy[tile] = tile === tileId;
          });
        } else {
          // toggle on/off the summaryTile
          locationSummaryTileMapCopy[tileId] = !locationSummaryTileMapCopy[tileId];

          // get all active tiles
          const enabledTiles = tileNames.filter((tile) => {
            return locationSummaryTileMapCopy[tile] === true;
          });

          // if only one tile is enabled and that tile is clicked, enable all tiles (get out of filter view)
          if (enabledTiles.length < 1) {
            tileNames.forEach((key) => {
              locationSummaryTileMapCopy[key] = true;
            });
          }
        }

        filterViewEnabled = isFilterViewEnabled(locationSummaryTileMapCopy);
      }

      locationSummaryTileState.current = {
        locationSummaryTileMap: { ...locationSummaryTileMapCopy },
        filterViewEnabled
      };
    }

    updateItemListSort(sortParamsRef.current.sortId, sortParamsRef.current.sortDirectionReversed);
    displayFilteredItems(itemList);
  };

  const onLoad = async () => {
    const { title, autoScrollInterval } = TrackPageConfig;

    setAutoScrollInt(autoScrollInterval);
    setPageTitle(title);

    // event listenenr for window resizing
    window.addEventListener('resize', updateWindowDimensions);
    // event listener for page refresh (F5) so dashboard state is saved on refresh
    window.addEventListener('beforeunload', updateSavedDashboardState);

    loadContent();
  };

  const loadContent = async () => {
    const {
      locationConfigurationMap,
      shipmentsTrackingLocationCategory,
      shipmentsTrackingItemClasses,
      statusFlagMap,
      itemDataDisplayConfigMap
    } = TrackPageConfig;

    const TrackPageClient = client.getTrackPageClient();
    const LocationClient = client.getLocationClient();

    const locationList = await LocationClient.getLocationsOfCategory(
      shipmentsTrackingLocationCategory
    );
    const activeItems =
      (await TrackPageClient.getShipmentsPageData(
        shipmentsTrackingLocationCategory,
        shipmentsTrackingItemClasses
      )) || [];

    const currentTimestamp = getCurrentTimestamp();
    const unsortedItemList = [];
    activeItems.forEach((item) => {
      if (item) {
        const {
          expiry_date,
          is_shipping,
          lastKnownLocation,
          lastUpdatedTime,
          lastNewDetectionTime,
          currentLocationStatus,
          nextLocationStatus,
          statusFlags,
          due_date
        } = item;
        const itemClass = item.class;
        const itemIdentifier = item.identifier;
        const shortenedItemIdentifier = shortenName(itemIdentifier, ItemStringMaxLength);
        const trackedObjectId = item.id;

        // if an item's current or next location isn't part of the valid shipments route, don't show it
        if (
          !locationConfigurationMap.validShipmentRouteMap[currentLocationStatus] &&
          !locationConfigurationMap.validShipmentRouteMap[nextLocationStatus]
        ) {
          return;
        }

        const statusFlagMapList = [];
        statusFlags.forEach((item) => {
          const statusFlag = item.toLowerCase();
          if (statusFlagMap[statusFlag]) {
            statusFlagMapList.push({
              id: statusFlag,
              displayText: statusFlagMap[statusFlag].displayText,
              color: statusFlagMap[statusFlag].color,
              priority: statusFlagMap[statusFlag].priority
            });
          }
        });

        const color = is_shipping
          ? locationConfigurationMap.locationColorMap[lastKnownLocation.id].shipping
          : locationConfigurationMap.locationColorMap[lastKnownLocation.id].receiving;
        const lastKnownLocationId = lastKnownLocation.id;
        const isRecentlyScannedItem =
          currentTimestamp - lastNewDetectionTime <= oneHourInMilliSeconds;
        const detailsPageLink = getDetailsPageLink(itemClass, trackedObjectId);

        const row = {
          ...item,
          itemIdentifier,
          shortenedItemIdentifier,
          itemClass,
          lastKnownLocation,
          lastKnownLocationId,
          is_shipping,
          currentLocationStatus,
          nextLocationStatus,
          color,
          lastUpdatedTime,
          lastNewDetectionTime,
          trackedObjectId,
          statusFlags,
          statusFlagMapList,
          itemDataDisplayConfigMap,
          detailsPageLink,
          isRecentlyScannedItem,
          due_date: due_date && getFormattedDate(new Date(parseInt(due_date, 10)), 'MMM D, YYYY'),
          expiry_date:
            expiry_date && getFormattedDate(new Date(parseInt(expiry_date, 10)), 'MMM D, YYYY'),
          timeDisplay: getFormattedDate(
            new Date(parseInt(lastNewDetectionTime, 10)),
            'hh:mm A MMM D'
          )
        };

        unsortedItemList.push(row);
      }
    });

    unsortedItemList.forEach((item) => {
      item.statusFlagMapList = item.statusFlagMapList.sort((a, b) => {
        return a.priority - b.priority;
      });
    });

    const configSortSchema = TrackPageConfig['itemDataDisplayConfigMap']['sortSchema'];
    let defaultSortIndex = configSortSchema.findIndex((attribute) => attribute.default === true);
    if (defaultSortIndex < 0) {
      defaultSortIndex = 0;
      configSortSchema[0]['default'] = true;
    }
    defaultSortId.current = configSortSchema[defaultSortIndex].id;

    setItemList(unsortedItemList);
    formulateSummaryData(unsortedItemList, locationList);
    const defaultSortCompareFunc = getSortCompareFunc();

    if (savedDashboardState) {
      const {
        sortParams: { sortId, sortDirectionReversed }
      } = savedDashboardState;
      locationSummaryTileState.current = savedDashboardState.locationSummaryTileState;
      sortParamsRef.current = { sortId, sortDirectionReversed };
      let sortFunc = getSortCompareFunc(sortId);
      unsortedItemList.sort((a, b) => {
        return sortDirectionReversed
          ? -sortFunc(a, b, sortDirectionReversed)
          : sortFunc(a, b, sortDirectionReversed);
      });
      displayFilteredItems(unsortedItemList);
      setCurrentPage(savedDashboardState.currentPage);
    } else {
      sortParamsRef.current = {
        sortId: defaultSortId.current,
        sortDirectionReversed: !configSortSchema[defaultSortIndex]['ascending']
      };
      unsortedItemList.sort(
        (a, b) =>
          defaultSortCompareFunc(a, b, !configSortSchema[defaultSortIndex]['ascending']) ||
          undefined
      );
      displayFilteredItems(unsortedItemList);
    }
    updateWindowDimensions();
    setContentLoaded(true);
  };

  const getSortCompareFunc = (sortType = defaultSortId.current) => {
    const sortSchema = getSortSchema();
    let defaultSortCompareFunc;
    sortSchema.forEach((each) => {
      if (each.id === sortType) {
        defaultSortCompareFunc = each.compareFunc;
      }
    });
    return defaultSortCompareFunc;
  };

  const getSortSchema = () => {
    if (TrackPageConfig) {
      const currentSortSchema = TrackPageConfig['itemDataDisplayConfigMap']['sortSchema'] || [];
      let processedSortSchema;
      const statusFlagMap = TrackPageConfig['statusFlagMap'];

      if (currentSortSchema && currentSortSchema.length > 0) {
        //determining whether or not to be able to sort on status and then removing status from sort schema if applicable
        if (!TrackPageConfig['statusFlagMap']) {
          let spliceIndex = -1;
          for (const i in currentSortSchema) {
            if (currentSortSchema[i].type === 'status') {
              spliceIndex = i;
              break;
            }
          }
          currentSortSchema.splice(spliceIndex, 1);
        }
        // SortIconDropdown determines which dropdown field to highlight based on the default value so this logic changes
        // the default value to the saved sort
        if (
          savedDashboardState &&
          sortParamsRef.current.sortId &&
          savedDashboardState.sortParams.sortId &&
          sortParamsRef.current.sortId === savedDashboardState.sortParams.sortId
        ) {
          const currentSortIndex = currentSortSchema.findIndex(
            (attribute) => attribute.default === true
          );
          const newCurrentSortIndex = currentSortSchema.findIndex(
            (attribute) => attribute.id === savedDashboardState.sortParams.sortId
          );
          // switching the default to a different sortId so that the highlighted option in the dropdown updates when the
          //savedDashboardState changes
          if (
            currentSortIndex > -1 &&
            newCurrentSortIndex > -1 &&
            currentSortSchema[currentSortIndex].id !== savedDashboardState.sortParams.sortId
          ) {
            currentSortSchema[newCurrentSortIndex]['default'] =
              currentSortSchema[currentSortIndex]['default'];
            delete currentSortSchema[currentSortIndex]['default'];
          }
        }
        processedSortSchema = [...currentSortSchema];

        currentSortSchema.forEach((item, i) => {
          const { type, id } = item;
          switch (type) {
            case 'number':
              processedSortSchema[i].compareFunc = (a, b, reverse) => {
                if (a[id] === b[id] || (!a[id] && !b[id])) {
                  return 0;
                }
                if (!a[id]) {
                  return reverse ? -1 : 1;
                }
                if (!b[id]) {
                  return reverse ? 1 : -1;
                }
                return a[id] < b[id] ? -1 : 1;
              };
              break;
            case 'character':
              processedSortSchema[i].compareFunc = (a, b, reverse) => {
                if (!a[id] && !b[id]) {
                  return 0;
                }
                if (!a[id]) {
                  return reverse ? -1 : 1;
                }
                if (!b[id]) {
                  return reverse ? 1 : -1;
                }
                if (a[id].toLowerCase() === b[id].toLowerCase()) {
                  return 0;
                }
                return a[id].toLowerCase() < b[id].toLowerCase() ? -1 : 1;
              };
              break;
            case 'status':
              processedSortSchema[i].compareFunc = (a, b) => {
                const statusLevelCalculator = (statusId) => {
                  return statusId in statusFlagMap ? statusFlagMap[statusId].weight : 0;
                };
                let aLevel = 0;
                let bLevel = 0;
                a &&
                  a.statusFlags.forEach((each) => {
                    aLevel += statusLevelCalculator(each);
                  });
                b &&
                  b.statusFlags.forEach((each) => {
                    bLevel += statusLevelCalculator(each);
                  });
                if (aLevel === bLevel) {
                  return 0;
                }
                return aLevel < bLevel ? 1 : -1;
              };
              break;
            default:
              break;
          }
        });
      }
      return processedSortSchema || [];
    }
    return [];
  };

  const sortItems = (sortCompareFunc, sortId, reverse) => {
    sortParamsRef.current = { sortId, sortDirectionReversed: reverse };
    if (processedItemList !== prevItemListRef.current) {
      prevItemListRef.current = processedItemList;
    } else {
      processedItemList.sort(sortCompareFunc || undefined);
      displayFilteredItems(processedItemList);
    }
  };

  const displayFilteredItems = (unprocessedItemList) => {
    const dataList = unprocessedItemList || itemList;
    let sortedItemList = [];
    let filteredItemList = [];
    const currentLocationItems = []; // list of items whose currentLocationStatus is one of the enabled summary tiles
    const nextLocationItems = []; // list of items whose nextLocationStatus is one of the enabled summary tiles

    // Object with key - summary tile, value - true/false (enabled or disabled)
    const { locationSummaryTileMap } = locationSummaryTileState.current;
    dataList.forEach((item) => {
      if (locationSummaryTileMap[item.currentLocationStatus]) {
        currentLocationItems.push(item);
      } else if (locationSummaryTileMap[item.nextLocationStatus]) {
        nextLocationItems.push(item);
      }
    });

    if (TrackPageConfig && TrackPageConfig.hasKnownDestinations) {
      sortedItemList = [...nextLocationItems, ...currentLocationItems];
    } else {
      sortedItemList = [...dataList];
    }
    filteredItemList = [...sortedItemList];

    if (TrackPageConfig && textFilterString) {
      const { itemDataDisplayConfigMap = {} } = TrackPageConfig;
      const { itemDataDisplayList = [] } = itemDataDisplayConfigMap;
      const dataDisplayKeys = itemDataDisplayList.map((data) => data.key);

      filteredItemList = sortedItemList.filter((item) => {
        const { statusFlagMapList = [] } = item;
        // check if text filter input string matches any data displayed on the item tiles
        for (const key of dataDisplayKeys) {
          if (
            item[key] &&
            item[key]
              .toString()
              .toLowerCase()
              .includes(textFilterString.trim().toLowerCase())
          ) {
            return true;
          }
        }
        // check if text filter input string matches any status flag displayed on any of the item tiles
        if (statusFlagMapList && statusFlagMapList.length > 0) {
          const statusFlagDisplayTextList = Object.values(statusFlagMapList).map((flag) =>
            flag.displayText.trim().toLowerCase()
          );
          for (const flag of statusFlagDisplayTextList) {
            if (flag.includes(textFilterString.trim().toLowerCase())) {
              return true;
            }
          }
        }
        return false;
      });
    }
    const recentlyScannedItems = getItemCountOfType(filteredItemList, 'isRecentlyScannedItem');

    // Logic for displaying current items
    const currentPageItems = getCurrentPageItemsFromList(
      filteredItemList,
      currentPageRef.current,
      itemsPerPage
    );
    const lastItemTemp = Math.ceil(filteredItemList.length / itemsPerPage);

    setPageCount(lastItemTemp);
    setRecentlyScannedItemCount(recentlyScannedItems);
    setProcessedItemList(filteredItemList);
    setDisplayedItemList(currentPageItems);
  };

  const formulateSummaryData = async (itemList, locationList) => {
    const locationSummaryTileStateCopy = { ...locationSummaryTileState.current };
    const summaryTileDataListTemp = [];
    let locationData = {};

    const {
      locationConfigurationMap,
      hasKnownDestinations,
      locationFilterSelectMode
    } = TrackPageConfig;
    const { validShipmentRouteMap, locationColorMap } = locationConfigurationMap;

    const {
      currentLocationGroupedItemsMap,
      nextLocationGroupedItemsMap
    } = groupItemsByLocationStatus(itemList);

    for (let i = 0; i < locationList.length; i++) {
      const locationId = locationList[i].getId();
      const locationName = locationList[i].getName();
      const locationShippingId = `${locationId}-shipping`;
      const locationReceivingId = `${locationId}-receiving`;

      if (validShipmentRouteMap[locationReceivingId]) {
        // count of items that have been/ are being received at this location
        const receivingItemCount =
          (currentLocationGroupedItemsMap[locationReceivingId] &&
            currentLocationGroupedItemsMap[locationReceivingId].length) ||
          0;
        // count of items that are in transit to this location
        const inTransitItemCount =
          (nextLocationGroupedItemsMap[locationReceivingId] &&
            nextLocationGroupedItemsMap[locationReceivingId].length) ||
          0;

        locationData = {
          id: locationReceivingId,
          name: locationName,
          title: `Receiving at ${shortenName(locationName, LocationNameStringMaxLength)}`,
          color: locationColorMap[locationId].receiving,
          totalItems: hasKnownDestinations
            ? receivingItemCount + inTransitItemCount
            : receivingItemCount,
          data: {
            badgeList: [
              {
                id: 'inTransit',
                label: 'In Transit',
                value: inTransitItemCount
              }
            ]
          }
        };

        if (!(locationData.id in locationSummaryTileStateCopy.locationSummaryTileMap))
          locationSummaryTileStateCopy.locationSummaryTileMap[locationData.id] = true;

        summaryTileDataListTemp.push(locationData);
      }

      if (validShipmentRouteMap[locationShippingId]) {
        // count of items that have been/ are being shipped from this location
        const inTransitItemCount =
          (currentLocationGroupedItemsMap[locationShippingId] &&
            currentLocationGroupedItemsMap[locationShippingId].length) ||
          0;
        locationData = {
          id: locationShippingId,
          name: locationName,
          title: `Shipped from ${locationName}`,
          color: locationColorMap[locationId].shipping,
          totalItems: inTransitItemCount,
          data: {}
        };

        if (!(locationData.id in locationSummaryTileStateCopy.locationSummaryTileMap))
          locationSummaryTileStateCopy.locationSummaryTileMap[locationData.id] = true;

        summaryTileDataListTemp.push(locationData);
      }
    }

    /* When filter select mode is single-select, we need one of the filters
     * (first one) to be enabled by default instead of all in the case of multi-select
     */
    if (locationFilterSelectMode === 'single-select') {
      Object.keys(locationSummaryTileStateCopy.locationSummaryTileMap).forEach(
        (filterId, index) => {
          if (index === 0) locationSummaryTileStateCopy.locationSummaryTileMap[filterId] = true;
          else locationSummaryTileStateCopy.locationSummaryTileMap[filterId] = false;
        }
      );
    }

    const newTilePageCount = Math.ceil(summaryTileDataListTemp.length / tilesPerPage);
    setTilePageCount(newTilePageCount);
    const tilePageNumber = savedDashboardState ? savedDashboardState.currentTilePage : 1;
    showCurrentPageSummaryTiles(
      summaryTileDataListTemp,
      tilePageNumber <= newTilePageCount ? tilePageNumber : 1
    );
    locationSummaryTileState.current = { ...locationSummaryTileStateCopy };
  };

  const handleSummaryTilePagination = (action) => {
    switch (action) {
      case 'next':
        showCurrentPageSummaryTiles(
          locationSummaryTileDataList,
          currentTilePage.current + 1 <= tilePageCount
            ? currentTilePage.current + 1
            : currentTilePage.current
        );
        break;

      case 'previous':
        showCurrentPageSummaryTiles(
          locationSummaryTileDataList,
          currentTilePage.current - 1 > 0 ? currentTilePage.current - 1 : 1
        );
        break;
      default:
        break;
    }
  };

  const showCurrentPageSummaryTiles = (dataList, tilePageNumber) => {
    currentTilePage.current = tilePageNumber;
    const currentPageSummaryTiles = getCurrentPageItemsFromList(
      dataList,
      tilePageNumber,
      tilesPerPage
    );
    setDisplayedLocationSummaryTileDataList(currentPageSummaryTiles);
    setLocationSummaryTileDataList(dataList);
  };

  const handleAutoScrollSwitch = () => {
    if (autoScroll && pageCount > 1) {
      autoScrollIntervalId.current = setInterval(() => {
        const nextPage = (currentPageRef.current % pageCount) + 1;
        setCurrentPage(nextPage);
        currentPageRef.current = nextPage;
      }, autoScrollInt);
    } else {
      clearInterval(autoScrollIntervalId.current);
      autoScrollIntervalId.current = null;
    }
  };

  const sortDropdownComponent = () => {
    return (
      <SortIconDropDown
        sortSchema={getSortSchema()}
        onClick={(compareFunc, sortId, reverse) => {
          sortItems(compareFunc, sortId, reverse);
        }}
        defaultSortReversed={sortParamsRef.current.sortDirectionReversed}
      />
    );
  };

  const PaginatedLocationTileContent = () => {
    return (
      <SummaryTilesHolder
        locationSummaryTileState={locationSummaryTileState.current}
        summaryTileMetadataList={displayedLocationSummaryTileDataList}
        showBadgeContainer={TrackPageConfig.hasKnownDestinations}
        onClick={handleFilterView}
      />
    );
  };

  const PaginatedLocationTiles = () => {
    if (!contentLoaded) {
      return (
        <Skeleton
          className={ShipmentsTrackPageFeatureStyle.location_tiles}
          width={240}
          height={160}
          count={4}
          inline
        />
      );
    }

    return (
      <HorizontalPaginateControl
        disablePreviousButton={currentTilePage.current === 1}
        onClickPreviousButton={handleSummaryTilePagination}
        paginatedContent={PaginatedLocationTileContent}
        disableNextButton={currentTilePage.current >= tilePageCount}
        onClickNextButton={handleSummaryTilePagination}
      />
    );
  };

  const AutoScrollSwitch = () => (
    <SwitchWithLabel
      label="Auto Scroll"
      onChange={setAutoScroll}
      checked={autoScroll}
      disabled={pageCount <= 1}
    />
  );

  const PaginationComponent = () => (
    <Pagination
      currentPage={currentPageRef.current}
      pageCount={pageCount}
      onClick={setCurrentPage}
    />
  );

  const TextFilterBar = () => (
    <TextFilterInputComponent placeholder="Type to filter items" onChange={setTextFilterString} />
  );

  const RecentItemsComponent = () => (
    <RecentItemsDisplay label="Recent Items" value={recentlyScannedItemCount} />
  );

  const ShipmentListViewComponent = () => {
    if (!contentLoaded) {
      return (
        <div>
          <Skeleton
            containerClassName={ShipmentsTrackPageFeatureStyle.itemContainer}
            className={ShipmentsTrackPageFeatureStyle.item}
            count={3}
            height={75}
            inline
          />
          <Skeleton
            containerClassName={ShipmentsTrackPageFeatureStyle.itemContainer}
            className={ShipmentsTrackPageFeatureStyle.item}
            count={3}
            height={75}
            inline
          />
        </div>
      );
    }

    let columns = [];
    if (displayedItemList && displayedItemList.length > 0) {
      columns = splitArray(displayedItemList, itemsPerPage / maxNumberOfColumns);
    }
    return <ShipmentsListView columnsList={columns} />;
  };

  return (
    <ShipmentsTrackPageComponent
      title={pageTitle}
      sortDropdownComponent={sortDropdownComponent}
      summaryTilesContainerComponent={PaginatedLocationTiles}
      autoScrollComponent={AutoScrollSwitch}
      paginationComponent={PaginationComponent}
      textFilterComponent={TextFilterBar}
      shipmentListViewTopContainerContent={RecentItemsComponent}
      shipmentListViewComponent={ShipmentListViewComponent}
    />
  );
};
export default withRouter(ShipmentsTrackPageFeature);
