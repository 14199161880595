import React, { useEffect, useState, useRef } from 'react';
import AddPageComponent from '../../components/add-page-component/AddPageComponent';
import { useAppConfigProvider } from '../../services/soft-cache-service';
import { XemelgoService } from '../../services/XemelgoService';
import {
  AddPageInputTypeMap,
  validCSVHeaderCheck,
  validCSVDataCheck
} from '../../common/Utilities';
import {
  AddAssetFeatureFunctionMap,
  processOnboardingPageAttributes
} from '../../common/commonAPICalls';

const FEATURE_ID = 'addAsset';
const PART_CONSTRAINT = 'partConstraint';
const REUSE_SENSOR_PROFILE = 'reuseSensorProfile';
const APP_ID = 'asset';

const ONE_DAY_IN_MILLISECOND = 1000 * 60 * 60 * 24;

const AddAssetPageFeature = () => {
  const configProvider = useAppConfigProvider(APP_ID);
  const [defaultAttributeMap, setDefaultAttributeMap] = useState({});
  const [customAttributeMap, setCustomAttributeMap] = useState({});
  const [attributeGroupMap, setAttributeGroupMap] = useState({});
  const [partConstraint, setPartConstraint] = useState({});
  const [reuseSensorProfile, setReuseSensorProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const [showBanner, setShowBanner] = useState(false);
  const bannerMessage = useRef('');
  const [bannerError, setBannerError] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [uploadCsv, setUploadCsv] = useState(false);
  const [enableOnboardingToLocation, setEnableOnboardingToLocation] = useState(false);
  const [assetClient, setAssetClient] = useState(null);
  const [locationClient, setLocationClient] = useState(null);
  const [publishClient, setPublishClient] = useState(null);
  const [possibleOnboardingLocationCategories, setPossibleOnboardingLocationCategories] = useState(
    []
  );

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line
  }, []);

  const onLoad = async () => {
    setLoading(true);

    const xemelgoClient = XemelgoService.getClient();
    const addAssetConfiguration = configProvider.getValue(FEATURE_ID, 'object');
    const newPartConstraint = configProvider.getValue(PART_CONSTRAINT, 'object') || {};
    const newReuseSensorProfile = configProvider.getValue(REUSE_SENSOR_PROFILE, 'object') || {};

    const {
      defaultAttributeMap: newDefaultAttributeMap = {},
      customAttributeMap: newCustomAttributeMap = {},
      attributeGroupMap: newAttributeGroupMap = {},
      uploadCsv: newUploadCsv,
      enableOnboardingToLocation: newEnableOnboardingToLocation,
      possibleOnboardingLocationCategories: newPossibleOnboardingLocationCategories = ['Department']
    } = addAssetConfiguration;

    const processedAttributes = await processOnboardingPageAttributes(
      newDefaultAttributeMap,
      newCustomAttributeMap,
      AddAssetFeatureFunctionMap
    );

    setAttributeGroupMap(newAttributeGroupMap);
    setDefaultAttributeMap({ ...processedAttributes.defaultAttributeMap });
    setCustomAttributeMap({ ...processedAttributes.customAttributeMap });
    setPartConstraint(newPartConstraint);
    setReuseSensorProfile(newReuseSensorProfile);
    setUploadCsv(newUploadCsv);
    setEnableOnboardingToLocation(newEnableOnboardingToLocation);
    setPossibleOnboardingLocationCategories(newPossibleOnboardingLocationCategories);
    setLoading(false);
    setAssetClient(xemelgoClient.getAssetClient());
    setLocationClient(xemelgoClient.getLocationClient());
    setPublishClient(xemelgoClient.getPublishClient());
  };

  const onSubmit = async (formData) => {
    setLoading(true);
    setLoadingMessage('Creating Asset');

    const assetPayload = { startTrackingSession: true };
    Object.keys(formData).forEach((id) => {
      const { value, type } = formData[id];
      let finalValue;
      switch (type) {
        case AddPageInputTypeMap.DATE_PICKER:
          finalValue = value ? value.getTime() : undefined;
          break;
        case AddPageInputTypeMap.SEARCH_DROP_DOWN_FROM_API:
        case AddPageInputTypeMap.SEARCH_DROP_DOWN:
          finalValue = value && typeof value === 'object' ? value.value : value;
          break;
        case AddPageInputTypeMap.CHECK_BOX_GROUP:
        case AddPageInputTypeMap.INPUT:
        default:
          finalValue = value;
      }
      if (Object.keys(customAttributeMap).find((key) => key === id)) {
        if (!assetPayload.customFields) {
          assetPayload.customFields = {};
        }
        assetPayload.customFields[id] = finalValue;
      } else {
        assetPayload[id] = finalValue;
      }
    });

    try {
      const { allow, autoDisassociationOnReuse } = reuseSensorProfile;
      const {
        identifier,
        sensorProfileVid,
        startTrackingSession,
        itemType,
        itemTypeIdentifier,
        expected_lifespan,
        schedule_refresh_interval,
        refresh_date,
        usage_limit,
        expirationDate,
        customFields
      } = assetPayload;
      const onboardingLocationId = enableOnboardingToLocation
        ? assetPayload.onboardingLocation
        : null;
      await assetClient.createAsset(
        allow,
        autoDisassociationOnReuse,
        identifier,
        sensorProfileVid,
        startTrackingSession,
        {
          identifier: itemTypeIdentifier || itemType,
          expected_lifespan: expected_lifespan
            ? expected_lifespan * ONE_DAY_IN_MILLISECOND
            : undefined,
          schedule_refresh_interval: schedule_refresh_interval
            ? schedule_refresh_interval * ONE_DAY_IN_MILLISECOND
            : undefined,
          usage_limit: usage_limit || undefined
        },
        expirationDate,
        onboardingLocationId,
        refresh_date,
        customFields
      );
      setBannerError(false);
      bannerMessage.current = 'Asset(s) created';
      await onLoad();
    } catch (err) {
      let errorMsg = '';
      if (typeof err === 'string') {
        errorMsg = err;
      } else {
        errorMsg = err.message;
      }
      bannerMessage.current = errorMsg;
      setBannerError(true);
    }
    setLoading(false);
    setLoadingMessage('');
    setShowBanner(true);
  };

  const onUploadCSVSubmit = async (dataList) => {
    const locationIdentifierToIdMap = {};
    let allLocations = [];
    setLoading(true);
    if (enableOnboardingToLocation) {
      try {
        allLocations = await locationClient.getLocationsOfCategory(
          possibleOnboardingLocationCategories
        );
        allLocations?.length &&
          allLocations.forEach((location) => {
            if (location.identifier) {
              locationIdentifierToIdMap[location.identifier.toUpperCase()] = location.id;
            }
          });
      } catch (err) {
        let errorMsg = '';
        if (typeof err === 'string') {
          errorMsg = err;
        } else {
          errorMsg = err.message;
        }
        setBannerError(true);
        bannerMessage.current = `Loading Error - ${errorMsg}`;
        setLoading(false);
        return [];
      }
    }
    const dataListWithStatus = [];
    const locationIdToItemIdMap = {}; // for bulk user event payload
    let hasError = false;
    for (let i = 0; i < dataList.length; i++) {
      setLoadingMessage(`Creating Asset(s) ${i + 1} of ${dataList.length}`);
      const assetPayload = { startTrackingSession: true };
      Object.keys(dataList[i]).forEach((id) => {
        const attributeMap = defaultAttributeMap[id] || customAttributeMap[id];
        const value = dataList[i][id];
        const { type, transformInput, numberOnly } = attributeMap;
        let finalValue;
        switch (type) {
          case AddPageInputTypeMap.DATE_PICKER:
            finalValue = value ? Date.parse(value) : undefined;
            break;
          case AddPageInputTypeMap.INPUT:
            if (numberOnly) {
              finalValue = parseInt(value);
              break;
            } else if (transformInput) {
              if (transformInput === 'toUpperCase') {
                finalValue = value.toUpperCase();
                break;
              }
            }
          // eslint-disable-next-line
          case AddPageInputTypeMap.SEARCH_DROP_DOWN_FROM_API:
          case AddPageInputTypeMap.SEARCH_DROP_DOWN:
          case AddPageInputTypeMap.CHECK_BOX_GROUP:
          default:
            finalValue = value;
            break;
        }
        if (Object.keys(customAttributeMap).find((key) => key === id)) {
          if (!assetPayload.customFields) {
            assetPayload.customFields = {};
          }
          assetPayload.customFields[id] = finalValue;
        } else {
          assetPayload[id] = finalValue;
        }
      });
      const processedData = { ...dataList[i] };
      try {
        const { allow, autoDisassociationOnReuse } = reuseSensorProfile;

        const {
          identifier,
          sensorProfileVid,
          startTrackingSession,
          expected_lifespan,
          schedule_refresh_interval,
          refresh_date,
          usage_limit,
          itemType,
          itemTypeIdentifier,
          expirationDate,
          customFields
        } = assetPayload;
        const onboardingLocationId =
          enableOnboardingToLocation &&
          assetPayload.onboardingLocation &&
          Object.keys(locationIdentifierToIdMap).length > 0
            ? locationIdentifierToIdMap[assetPayload.onboardingLocation.trim().toUpperCase()]
            : null;
        const { itemId } = await assetClient.createAsset(
          allow,
          autoDisassociationOnReuse,
          identifier,
          sensorProfileVid,
          startTrackingSession,
          {
            identifier: itemTypeIdentifier || itemType,
            expected_lifespan: expected_lifespan
              ? expected_lifespan * ONE_DAY_IN_MILLISECOND
              : undefined,
            schedule_refresh_interval: schedule_refresh_interval
              ? schedule_refresh_interval * ONE_DAY_IN_MILLISECOND
              : undefined,
            usage_limit: usage_limit || undefined
          },
          expirationDate,
          undefined, // onboardingLocationId
          refresh_date,
          customFields
        );
        if (onboardingLocationId && itemId) {
          if (locationIdToItemIdMap[onboardingLocationId]) {
            locationIdToItemIdMap[onboardingLocationId].push(itemId);
          } else {
            locationIdToItemIdMap[onboardingLocationId] = [itemId];
          }
        }
      } catch (err) {
        hasError = true;
        processedData.errorMessage = err.message ? err.message.toString() : err.toString();
      }
      dataListWithStatus.push(processedData);
    }

    try {
      if (Object.keys(locationIdToItemIdMap).length > 0) {
        for (const locationId of Object.keys(locationIdToItemIdMap)) {
          const itemIds = locationIdToItemIdMap[locationId];
          if (itemIds && itemIds.length > 0) {
            while (itemIds.length > 0) {
              const currentSlice = itemIds.splice(0, 50);
              await publishClient.publishUserEvent([...currentSlice], locationId);
            }
          }
        }
      }
    } catch (err) {
      hasError = true;
      const errMessage = err.errorMessage ? err.errorMessage.toString() : err.toString();
      bannerMessage.current = `Error auto adding assets - ${errMessage}`;
    } finally {
      await onLoad();
      setBannerError(hasError);
      bannerMessage.current = hasError
        ? bannerMessage.current ||
          'One or more assets could not be created: Please check the following asset(s) and retry.'
        : 'Asset(s) created successfully.';
      setShowBanner(true);
      setLoading(false);
      setLoadingMessage('');
    }
    return dataListWithStatus;
  };

  return (
    <AddPageComponent
      attributeGroupMap={attributeGroupMap}
      loading={loading}
      loadingMessage={loadingMessage}
      title="Asset"
      validCSVHeaderCheck={(data) => {
        const { valid, errorMessage } = validCSVHeaderCheck(
          data,
          defaultAttributeMap,
          customAttributeMap
        );
        if (!valid) {
          setBannerError(!valid);
          bannerMessage.current = errorMessage;
          setShowBanner(true);
        }
        return valid;
      }}
      validCSVDataCheck={(data) => {
        const { valid, errorMessage } = validCSVDataCheck(
          data,
          defaultAttributeMap,
          customAttributeMap
        );
        if (!valid) {
          setBannerError(!valid);
          bannerMessage.current = errorMessage;
          setShowBanner(true);
        }
        return valid;
      }}
      bannerError={bannerError}
      showBanner={showBanner}
      setShowBanner={setShowBanner}
      bannerMessage={bannerMessage.current}
      onCloseBanner={() => {
        setBannerError(false);
        bannerMessage.current = '';
        setShowBanner(false);
      }}
      defaultAttributeMap={defaultAttributeMap}
      customAttributeMap={customAttributeMap}
      uploadCsv={uploadCsv}
      partConstraint={partConstraint}
      onSubmit={onSubmit}
      onUploadCSVSubmit={onUploadCSVSubmit}
    />
  );
};

export default AddAssetPageFeature;
