import React, { useState, useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import LoadingCircle from '../../components/loading/LoadingCircle';
import { listItemsFromS3, getItemFromS3, getFormattedDate } from '../../common/Utilities';
import CONFIGURATION from '../../configuration.json';
import ReportsPageComponent from '../../components/reports-page-component/ReportsPageComponent';
import { CreateReportModalFeature } from './CreateReportModalFeature';
import { useAppConfigProvider } from '../../services/soft-cache-service';
import { LocalCacheService } from '../../services/local-cache-service';
import { SessionStorageService } from '../../services/session-storage-service';

const APP_ID = 'reports';
const ENABLE_CREATE_REPORT = 'createReportEnabled';

const ReportsPageFeature = () => {
  const configProvider = useAppConfigProvider(APP_ID);
  const CreateReportEnabled = configProvider.getValue(ENABLE_CREATE_REPORT, 'boolean');
  const [reports, setReports] = useState({});
  const [reportsList, setReportsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    if (Object.keys(reports).length) {
      const { IsTruncated, NextContinuationToken, Contents } = reports;
      const tempReportsList = [...reportsList];
      tempReportsList.push(...Contents);
      setReportsList(tempReportsList);
      setLoading(IsTruncated);
      if (IsTruncated) {
        onLoad(NextContinuationToken);
      }
    }
  }, [reports]);

  const onLoad = async (continuationToken) => {
    const userProfile = LocalCacheService.loadUserProfile();
    let tenantId = userProfile.getTenantId();

    const isTestingMode = SessionStorageService.getTestMode();
    isTestingMode === 'Test' && (tenantId += `-test`);

    await listItemsFromS3(
      CONFIGURATION.s3_report_configuration.bucket_name,
      setReports,
      `${CONFIGURATION.production ? 'prod' : 'dev'}/${tenantId}/`,
      continuationToken
    );
  };

  const getReport = async (key) => {
    const userProfile = LocalCacheService.loadUserProfile();
    let tenantId = userProfile.getTenantId();

    const isTestingMode = SessionStorageService.getTestMode();
    isTestingMode === 'Test' && (tenantId += `-test`);

    await getItemFromS3(CONFIGURATION.s3_report_configuration.bucket_name, key);
  };

  const getListViewHeaderStructure = () => {
    return [
      {
        id: 'Key',
        default: true,
        title: true,
        index: 0,
        label: 'Report',
        renderComponent: (data) => {
          return data;
        }
      },
      {
        id: 'type',
        index: 1,
        label: 'Type',
        renderComponent: (data) => {
          return data;
        }
      },
      {
        id: 'lastModified',
        index: 2,
        label: 'Date',
        renderComponent: (data) => {
          return getFormattedDate(data, 'hh:mm A MMM D');
        }
      }
    ];
  };

  const toggleModal = async (action) => {
    setShowModal(!showModal);
    if (action === 'submit') {
      setLoading(true);
      setReportsList([]);
      setReports({});
      await onLoad();
    }
  };

  const handleListViewClick = ({ id }) => {
    getReport(id);
  };

  const renderReports = () => {
    const filesToRender = [];

    reportsList.forEach((file) => {
      const { Key, LastModified } = file;

      // Based on the document the file path should follow the format of
      // File Path: `${deployment - stage}/${tenant}/${Report Type}/${UTC Date}/${ UTC DateTime }_${ additional attribute }.csv`
      // Example: dev/multi-solutions-dev/On-Hand Report/2021_04_09/on_hand_report_2021_04_09.txt
      const filePathList = Key.split('/');
      const date = new Date(Date.parse(LastModified));

      //File name should follow format of tenantID_fileType_otherData.csv
      const formattedKey = filePathList[filePathList.length - 1];
      const formattedType = filePathList[2];
      filesToRender.push({
        id: Key,
        Key: formattedKey,
        lastModified: date.getTime(),
        type: formattedType
      });
    });

    return (
      <Fragment>
        <ReportsPageComponent
          listViewHeaders={getListViewHeaderStructure()}
          listViewData={filesToRender}
          listViewClick={handleListViewClick}
          toggleModal={toggleModal}
          enableCreateReport={CreateReportEnabled}
        />
        <CreateReportModalFeature toggleModal={toggleModal} show={showModal} />
      </Fragment>
    );
  };

  return <Fragment>{loading ? <LoadingCircle /> : renderReports()}</Fragment>;
};

export default withRouter(ReportsPageFeature);
