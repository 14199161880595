import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useAppConfigProvider } from '../../services/soft-cache-service';
import { useXemelgoClient } from '../../services/xemelgo-service';

import LoadingCircle from '../../components/loading/LoadingCircle';
import { ModalForm } from '../../components/modal-form';
import InventoryModalHeader from '../../components/add-inventory-v2-components/InventoryModalHeader';
import InventoryModalFooter from '../../components/add-inventory-v2-components/InventoryModalFooter';
import ItemTypeInformation from '../../components/add-inventory-v2-components/ItemTypeInformation';
import AdditionalInformation from '../../components/add-inventory-v2-components/AdditionalInformation';
import './AddInventoryV2Style.css';

import {
  useImportTagPrinterScripts,
  getDefaultPrinter,
  getAvailablePrinters,
  getPrinterStatus,
  printTag
} from '../../services/tag-printer-service';
import { queryForLatestDetectionsByReader } from '../../services/get-recent-tags-service';
import { getFormattedDate, getCurrentTimestamp } from '../../common/utils';
import { naturalSort } from '../../common/Utilities';

const FEATURE_ID = 'addInventory';
const APP_ID = 'inventory';

const oneMinute = 60 * 1000;

const selectedItemTypeInfo = {
  identifier: { hiddenOnInfoCard: true },
  name: { hiddenOnInfoCard: true },
  image_path: { hiddenOnInfoCard: true },
  size_ts: { label: 'Size' },
  color_ts: { label: 'Color' },
  sku_ts: { label: 'UPC' }
};

const printerInfo = {
  name: { hiddenOnInfoCard: true },
  message: { label: 'Status' },
  id: { label: 'IP Address' }
};

const AddInventoryPageFeatureV2 = ({ onClose }) => {
  const configProvider = useAppConfigProvider(APP_ID);
  const [itemTypeClient] = useState(useXemelgoClient().getItemTypeClient());
  const [locationClient] = useState(useXemelgoClient().getLocationClient());
  const [detectorClient] = useState(useXemelgoClient().getDetectorClient());
  const [inventoryClient] = useState(useXemelgoClient().getInventoryClient());
  const [restClient] = useState(useXemelgoClient().getRestClient());

  const [itemTypes, setItemTypes] = useState([]);
  const [locations, setLocations] = useState([]);
  const [printers, setPrinters] = useState([]);
  const [encodingStations, setEncodingStations] = useState([]);
  const [detectors, setDetectors] = useState([]);

  const [selectedOnboardingMode, setSelectedOnboardingMode] = useState('');
  const [selectedItemType, setSelectedItemType] = useState({});
  const [selectedLocation, setSelectedLocation] = useState({});
  const [selectedPrinterObject, setSelectedPrinterObject] = useState({});
  const [selectedPrinterInfo, setSelectedPrinterInfo] = useState({});
  const [selectedEncodingStation, setSelectedEncodingStation] = useState({});
  const [selectedItemQty, setSelectedItemQty] = useState(0);
  const [selectedItemTag, setSelectedItemTag] = useState('');

  const [printerScriptReady, setPrinterScriptReady] = useState(false);
  const [loading, setLoading] = useState(true);
  const [additionalInfoLoading, setAdditionalInfoLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [headerMessage, setHeaderMessage] = useState('');
  const [error, setError] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  const intervalId = useRef(null);

  useImportTagPrinterScripts(() => setPrinterScriptReady(true));

  const addInventoryConfiguration = configProvider.getValue(FEATURE_ID, 'object');

  const tabs = [
    {
      id: 'onboardMode',
      tabStructure: [
        {
          id: 'add',
          display: 'Add Existing Tag',
          action: () => {
            setSelectedOnboardingMode('add');
          }
        },
        {
          id: 'print',
          display: 'Print New Tag',
          action: () => {
            setSelectedOnboardingMode('print');
          }
        }
      ]
    }
  ];

  const {
    onboardingOptions = ['add', 'print'],
    enableOnboardingToLocation,
    possibleOnboardingLocationCategories = ['Department'],
    defaultOnboardingTab = 'add',
    isTagInputEditable = false,
    getTagsApiUrl = 'https://dvzoza0ah1.execute-api.us-west-2.amazonaws.com/v1/devices/'
  } = addInventoryConfiguration;

  const onboardingModeTabs = [...tabs];

  onboardingModeTabs[0].tabStructure = tabs[0].tabStructure.map(
    (tab) => onboardingOptions.includes(tab.id) && tab
  );

  useEffect(() => {
    onLoad();
    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
        intervalId.current = null;
      }

      clearFormInputs();
    };
  }, []);

  useEffect(() => {
    if (loading) return;
    setError(false);
    setHeaderMessage('');
    if (printerScriptReady && selectedOnboardingMode === 'print') {
      queryPrinters();
    } else if (selectedOnboardingMode === 'add') {
      checkEncodingStations();
    }
  }, [loading, printerScriptReady, selectedOnboardingMode]);

  useEffect(() => {
    if (Object.keys(selectedPrinterObject).length) {
      const interval = setInterval(async () => {
        const { message, readyToPrint } = await getPrinterStatus(selectedPrinterObject);

        const printerInfoToUpdate = { ...selectedPrinterInfo };
        printerInfoToUpdate.message.value = readyToPrint ? 'Online' : message;
        printerInfoToUpdate.message.statusColor = readyToPrint ? 'green' : 'red';
        setSelectedPrinterInfo({ ...printerInfoToUpdate });
      }, oneMinute);

      intervalId.current = interval;
    } else if (intervalId.current) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
  }, [selectedPrinterObject]);

  useEffect(() => {
    if (selectedOnboardingMode === 'print') {
      setSubmitButtonDisabled(
        !selectedItemQty ||
          !Object.keys(selectedItemType).length ||
          !Object.keys(selectedPrinterObject).length
      );
    } else if (selectedOnboardingMode === 'add') {
      setSubmitButtonDisabled(
        !selectedItemTag.length ||
          !Object.keys(selectedItemType).length ||
          !Object.keys(selectedEncodingStation).length
      );
    }
  }, [
    selectedOnboardingMode,
    selectedItemQty,
    selectedItemTag,
    selectedItemType,
    selectedPrinterObject
  ]);

  const onLoad = async () => {
    setError(false);
    setHeaderMessage('');

    const newItemTypesPromise = itemTypeClient.getItemTypes([
      'id',
      'identifier',
      'name',
      'size_ts',
      'color_ts',
      'sku_ts',
      'image_path'
    ]);

    let newLocationsPromise;
    const locationsToQuery = [...possibleOnboardingLocationCategories, 'Encoding Station'];

    if (enableOnboardingToLocation) {
      newLocationsPromise = locationClient.getLocationsOfCategory(locationsToQuery);
    }

    let newDetectorsPromise;
    if (onboardingOptions.includes('add')) {
      newDetectorsPromise = detectorClient.listDetectors();
    }

    try {
      const [newItemTypes = [], newLocations = [], newDetectors = []] = await Promise.all([
        newItemTypesPromise,
        newLocationsPromise,
        newDetectorsPromise
      ]);

      let itemTypesToSet = newItemTypes.map((itemType) => {
        const { name: label } = itemType;
        return { label, ...itemType };
      });
      itemTypesToSet = naturalSort(itemTypesToSet, 'label');

      let locationsToSet = [];
      const newEncodingStations = [];

      newLocations.forEach((location) => {
        const { id, name: label, category } = location;
        if (category === 'Encoding Station') {
          newEncodingStations.push({ id, label });
        } else {
          locationsToSet.push({ id, label });
        }
      });
      locationsToSet = naturalSort(locationsToSet, 'label');

      newEncodingStations.forEach((station) => {
        newDetectors.forEach((detector) => {
          if (station.id === detector?.location?.id) {
            station.detectorSerial = detector.name;
          }
        });
      });

      let encodingStationsToSet = newEncodingStations.filter((station) => station.detectorSerial);

      encodingStationsToSet = naturalSort(encodingStationsToSet, 'label');

      setItemTypes(itemTypesToSet);
      setLocations(locationsToSet);
      setEncodingStations(encodingStationsToSet);
      setDetectors(newDetectors);
      setSelectedOnboardingMode(defaultOnboardingTab);
    } catch (e) {
      setError(true);
      setHeaderMessage(e);
    } finally {
      setLoading(false);
    }
  };

  const clearFormInputs = () => {
    setHeaderMessage('');
    setError(false);
    setSelectedItemType({});
    setSelectedLocation({});
    setSelectedItemQty(0);
    setSelectedItemTag('');
  };

  const capitaliseFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const onSelectItemType = (itemType) => {
    if (!itemType) {
      setSelectedItemType({});
      return;
    }

    Object.keys(selectedItemTypeInfo).forEach((key) => {
      selectedItemTypeInfo[key].value = itemType[key];

      if (key === 'name') {
        selectedItemTypeInfo[key].label = itemType[key];
      }
    });

    setSelectedItemType({ ...selectedItemTypeInfo });
  };

  const queryPrinters = async () => {
    setAdditionalInfoLoading(true);
    const defaultPrinterPromise = getDefaultPrinter();
    const availablePrintersPromise = getAvailablePrinters();

    try {
      const [defaultPrinter = {}, availablePrinters = []] = await Promise.all([
        defaultPrinterPromise,
        availablePrintersPromise
      ]);

      let printersToSet = availablePrinters.map((printer) => {
        const { uid: id, name } = printer;
        return { id, name, label: name, printerObject: printer };
      });
      printersToSet = naturalSort(printersToSet, 'label');

      const defaultPrinterToSet = printersToSet.find(
        (printer) => printer.id === defaultPrinter.uid
      );

      setPrinters(printersToSet);
      onSelectPrinter(defaultPrinterToSet);
    } catch (e) {
      setAdditionalInfoLoading(false);
      setError(true);
      setHeaderMessage(e);
    }
  };

  const checkEncodingStations = async () => {
    if (!encodingStations.length) {
      setError(true);
      setHeaderMessage(`No Encoding Stations have been setup.`);
      setAdditionalInfoLoading(false);
      return;
    }

    if (encodingStations.length === 1) {
      setSelectedEncodingStation(encodingStations[0]);
    }
  };

  const queryForTags = async () => {
    setError(false);
    setHeaderMessage('');
    setSelectedItemTag('');
    setAdditionalInfoLoading(true);

    try {
      const response = await queryForLatestDetectionsByReader(
        getTagsApiUrl,
        selectedEncodingStation.detectorSerial
      );
      const message = await response.json();

      if (message.tags?.length > 1) {
        setError(true);
        setHeaderMessage(
          `Multiple tags detected.
          Ensure that there are no stray tags nearby.`
        );
        return;
      }
      setSelectedItemTag(message.tags[0].epc);
    } catch (e) {
      setError(true);
      setHeaderMessage(`Could not scan tags, ${e}`);
    } finally {
      setAdditionalInfoLoading(false);
    }
  };

  const onSelectPrinter = async (printer) => {
    if (!printer) {
      setSelectedPrinterObject({});
      setSelectedPrinterInfo({});
      return;
    }
    setAdditionalInfoLoading(true);
    const { printerObject } = printer;
    const status = await getPrinterStatus(printerObject);

    const consolidatedPrinterData = { ...status, ...printer };
    const printerInfoToUpdate = { ...printerInfo };

    Object.keys(printerInfoToUpdate).forEach((key) => {
      printerInfoToUpdate[key].value = consolidatedPrinterData[key];

      if (key === 'message') {
        if (consolidatedPrinterData.readyToPrint) {
          printerInfoToUpdate[key].value = 'Online';
          printerInfoToUpdate[key].statusColor = 'green';
        } else {
          printerInfoToUpdate[key].statusColor = 'red';
        }
      }

      if (key === 'name') {
        printerInfoToUpdate[key].label = consolidatedPrinterData[key];
      }
    });

    setSelectedPrinterInfo(printerInfoToUpdate);
    setSelectedPrinterObject(printerObject);
    setAdditionalInfoLoading(false);
  };

  const generateTags = (quantity = 1) => {
    const tags = [];

    const timeStamp = getCurrentTimestamp();
    const date = getFormattedDate(timeStamp, 'YYMMDD');

    const startingSerial = 1;
    for (let i = startingSerial; i <= quantity; i++) {
      const paddedString = i.toString().padStart(5, '0');
      tags.push(`${date}${timeStamp}${paddedString}`);
    }

    return tags;
  };

  const onSubmit = async () => {
    setHeaderMessage('');
    setError(false);
    setLoading(true);

    const itemNumber = selectedItemType.identifier.value;
    const onboardingLocation = Object.keys(selectedLocation).length ? selectedLocation.id : null;

    try {
      let responseMessage;
      if (selectedOnboardingMode === 'print') {
        responseMessage = await submitPrintTags(itemNumber, onboardingLocation);
      } else if (selectedOnboardingMode === 'add') {
        responseMessage = await submitAddTags(itemNumber, onboardingLocation);
      }

      clearFormInputs();
      setHeaderMessage(responseMessage);
    } catch (e) {
      setError(true);
      setHeaderMessage(e);
    }

    setLoading(false);
  };

  const submitPrintTags = async (itemNumber, onboardingLocation) => {
    try {
      const payload = [];
      const generatedTags = generateTags(selectedItemQty);

      generatedTags.forEach((tag) => {
        const itemPayload = {
          item_number: itemNumber,
          tracker_serial: tag,
          name: tag
        };

        payload.push(itemPayload);
      });

      setLoadingMessage('Generating tags...');
      await inventoryClient.createItemSet(payload, onboardingLocation);
      await printTags(generatedTags);
      return 'Printed tags successfully!';
    } catch (e) {
      throw 'Could not print tags';
    }
  };

  const submitAddTags = async (itemNumber, onboardingLocation) => {
    try {
      const payload = [
        {
          item_number: itemNumber,
          tracker_serial: selectedItemTag,
          name: selectedItemTag
        }
      ];
      setLoadingMessage('Adding tag...');
      await inventoryClient.createItemSet(payload, onboardingLocation);
      return 'Added tag successfully!';
    } catch (e) {
      throw 'Could not add tag';
    }
  };

  const printTags = async (tagsToPrint) => {
    let errorMessage;

    const { message, readyToPrint } = await getPrinterStatus(selectedPrinterObject);

    if (readyToPrint) {
      for (const [idx, tag] of tagsToPrint.entries()) {
        setLoadingMessage(`Printing tag ${idx + 1} of ${tagsToPrint.length}...`);

        try {
          await printTag(selectedPrinterObject, tag, selectedItemType);
        } catch (e) {
          errorMessage = e;
          break;
        }
      }
    } else {
      errorMessage = message;
    }

    if (errorMessage) {
      throw errorMessage;
    }
  };

  return (
    <ModalForm
      scrollable
      show
      title={<InventoryModalHeader onClose={onClose} error={error} headerMessage={headerMessage} />}
      body={
        loading ? (
          <LoadingCircle message={loadingMessage} messageStyle="loading_message" />
        ) : (
          <Fragment>
            <ItemTypeInformation
              itemTypes={itemTypes}
              onSelect={onSelectItemType}
              selectedItemType={selectedItemType}
            />
            <AdditionalInformation
              enableOnboardingToLocation={enableOnboardingToLocation}
              locations={locations}
              selectedLocation={selectedLocation}
              onSelectLocation={setSelectedLocation}
              onboardingModeTabs={onboardingModeTabs}
              selectedOnboardingMode={selectedOnboardingMode}
              quantity={selectedItemQty}
              onChangeQuantity={setSelectedItemQty}
              stations={encodingStations}
              selectedStation={selectedEncodingStation}
              onSelectStation={setSelectedEncodingStation}
              tag={selectedItemTag}
              onChangeTag={setSelectedItemTag}
              onScanTag={queryForTags}
              isTagInputEditable={isTagInputEditable}
              printers={printers}
              selectedPrinter={selectedPrinterInfo || {}}
              onSelectPrinter={onSelectPrinter}
              contentLoading={additionalInfoLoading}
            />
          </Fragment>
        )
      }
      footer={
        <InventoryModalFooter
          onClose={onClose}
          onSubmit={onSubmit}
          disabled={submitButtonDisabled}
          submitLabel={capitaliseFirstLetter(selectedOnboardingMode)}
        />
      }
      prefix="inv"
      className="inv_modal_container"
    />
  );
};

export default AddInventoryPageFeatureV2;
