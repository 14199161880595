import { ClientProvider } from '@xemelgo/x-client';
import CONFIGURATION from '../configuration.json';
import { LocalCacheService } from './local-cache-service';
import Amplify from 'aws-amplify';

export const XemelgoService = {
  getClient() {
    const userConfig = LocalCacheService.loadUserConfig();

    // TODO: apiGateway is the name of service from old customer configuration. Keeping it here for backward compatibility support
    const services = ['xGraph', 'xMain', 'apiGateway'];
    let apiVersion = null;
    const endpoints = services
      .filter((serviceName) => {
        return userConfig.getServiceConfiguration(serviceName);
      })
      .map((serviceName) => {
        const config = userConfig.getServiceConfiguration(serviceName);
        const { name, endpoint, region, apiVersion: version } = config;

        if (version) {
          apiVersion = version;
        }

        return {
          name,
          endpoint,
          region
        };
      });

    const clientConfig = {
      endpoints,
      apiVersion,
      auth: CONFIGURATION.AuthConfiguration
    };

    return ClientProvider.getClient(Amplify.API, clientConfig);
  }
};
