import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import Style from './PopperFormComponents.module.css';

const useFormHelperStyle = makeStyles({
  root: {
    marginTop: '-2px'
  },
  error: {
    color: '#F62227'
  }
});

export const CancelFormComponent = ({ onSubmit, closeFunc }) => {
  const formHelperStyle = useFormHelperStyle();

  const [comment, setComment] = useState('');
  const [error, setError] = useState(false);

  const handleChange = (event) => {
    setComment(event.target.value);
  };

  return (
    <div className={Style.cancel_container}>
      <div className={Style.cancel_title}>Cancelling item</div>
      <div className={Style.label_text}>Reason for cancellation</div>
      <textarea
        placeholder="e.g. Item is no longer needed"
        className={Style.cancel_input}
        onChange={handleChange}
        style={{
          border: error ? '1.5px solid #F62227' : '1.5px solid #E2E2EA'
        }}
      />
      <FormHelperText error={error} classes={formHelperStyle}>
        {error && 'Reason for cancellation is required'}
      </FormHelperText>
      <div className={Style.cancel_button_container}>
        <div className={Style.close_button} onClick={closeFunc}>
          Close
        </div>
        <div
          className={Style.submit_button}
          onClick={() => {
            if (comment.trim()) {
              onSubmit(comment);
            } else {
              setError(true);
            }
          }}
        >
          Submit
        </div>
      </div>
    </div>
  );
};

CancelFormComponent.propTypes = {
  onSubmit: PropTypes.func,
  closeFunc: PropTypes.func
};
