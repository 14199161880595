import React, { Fragment, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import { useXemelgoClient } from '../../services/xemelgo-service';
import {
  useFeatureConfigProvider,
  useConfigurationProvider,
  useAppConfigProvider
} from '../../services/soft-cache-service';
import './style.css';
import { TwoColumnsPaneView } from '../../components/two-columns-pane-view';
import { ListLocationGroupsPanel } from './features/list-location-groups-panel';
import { LocationDetailPane } from './features/location-detail-pane';
import { DefaultConfiguration } from './configuration';
import { getLocations } from '../../services/location-service';

const FeatureId = 'listLocations';
const APP_ID = 'myFacility';

export const ListLocations = ({ appId }) => {
  const appConfigProvider = useAppConfigProvider(APP_ID);
  const useV2 = appConfigProvider.config.useV2;
  const [locationsByModelMap, setLocationsByModelMap] = useState({});
  const [selectedLocationInfo, setSelectedLocationInfo] = useState(null);
  const [refreshingModelIds, setRefreshingModelIds] = useState([]);
  const [xemelgoClient] = useState(useXemelgoClient());
  const [configProvider] = useState(
    useFeatureConfigProvider(appId, FeatureId, DefaultConfiguration)
  );
  const LocationRoleConfig = useConfigurationProvider().config.features.locationRole || {};
  const [modelConfigurationMap, setModelConfigurationMap] = useState({});
  const [locationModelOrders, setLocationModelOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  /**
   * Name: parseConfiguration
   * Parse configuration and determine which location category should the page display.
   */
  useEffect(() => {
    let cancelled = false;

    const modelIds = configProvider.getValue('modelOrders', 'array', []);
    const modelMap = modelIds
      .map((modelId) => {
        return configProvider.getModel(modelId);
      })
      .reduce((map, model) => {
        const clonedMap = { ...map };
        const modelId = model.getModelId();
        clonedMap[modelId] = model.getDefinitionObject();
        return clonedMap;
      }, {});
    if (!cancelled) {
      setModelConfigurationMap(modelMap);
      setRefreshingModelIds(Object.keys(modelMap));
      setLocationModelOrders(modelIds);
    }

    return () => {
      cancelled = true;
    };
  }, [configProvider]);

  const loadRoles = async () => {
    const locationClient = xemelgoClient.getLocationClient();
    const rolesResult = await locationClient.listRoles();
    const { locationRoles = [] } = rolesResult;
    const { roles = [] } = LocationRoleConfig;

    const missingRoles = roles.filter((role) =>
      !locationRoles.find((locationRole) => locationRole.name === role.name)
    );
    for (const role of missingRoles) {
      await locationClient.createRole(role);
    }
  }

  useEffect(() => {
    loadRoles();
  }, []);

  /**
   * Name: fetchLocationsByCategory
   */
  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };
    setLoading(true);
    const locationClient = xemelgoClient.getLocationClient();
    const fetchData = async () => {
      const map = {};
      for (let index = 0; index < refreshingModelIds.length; index++) {
        const modelId = refreshingModelIds[index];
        const modelConfiguration = modelConfigurationMap[modelId];
        const { category } = modelConfiguration;
        const { name: categoryName } = category;

        // const locations = await locationClient.getLocationsOfCategory(categoryName);
        // eslint-disable-next-line no-await-in-loop
        const locations = await locationClient.getLocationsOfCategory(categoryName, useV2);
        const sorted = locations.sort((loc1, loc2) => loc1.name.localeCompare(loc2.name));
        map[modelId] = sorted;
      }
      return map;
    };

    fetchData().then((responseMap) => {
      if (!cancelled) {
        setLocationsByModelMap((existingMap) => {
          const merged = { ...existingMap, ...responseMap };
          return merged;
        });
        if (Object.keys(responseMap).length !== 0) {
          setLoading(false);
        }
      }
    });

    return cancelCallback;
  }, [refreshingModelIds, modelConfigurationMap, xemelgoClient]);

  /**
   * Name: refreshSelectedLocationRecord
   * This effect will trigger when the locations data is updated.
   *  The main purpose of this effect is to refresh data on active (selected) location.
   */
  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    let locationMap = {};
    Object.values(locationsByModelMap).forEach((locations) => {
      const map = locations.reduce((m, location) => {
        const clonedMap = { ...m };
        const id = location.getId();
        clonedMap[id] = location;
        return clonedMap;
      }, {});
      locationMap = { ...locationMap, ...map };
    });

    if (!cancelled) {
      setSelectedLocationInfo((previous) => {
        if (previous) {
          const { location: selectedLocation, modelId: selectedModelId } = previous;
          const id = selectedLocation.getId();
          const refreshedLocation = locationMap[id];
          return { location: refreshedLocation, modelId: selectedModelId };
        }
        return null;
      });
    }
    return cancelCallback;
  }, [locationsByModelMap]);

  const onLocationRecordChanged = useCallback((modelId) => {
    setRefreshingModelIds([modelId]);
  }, []);

  const onDeleteRecord = useCallback((modelId) => {
    setSelectedLocationInfo(null);
    setRefreshingModelIds((modelIds) => {
      const clonedList = [...modelIds];
      clonedList.push(modelId);
      return clonedList;
    });
  }, []);

  return (
    <Fragment>
      {loading ? (
        <div className="loading-circle">
          <Spinner animation="border" />
        </div>
      ) : (
        <TwoColumnsPaneView
          className="list-locations-feature"
          leftPane={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <ListLocationGroupsPanel
              locationModelIds={locationModelOrders}
              locationModelConfigurationMap={modelConfigurationMap}
              locationsMap={locationsByModelMap}
              appId={appId}
              featureId={FeatureId}
              onSelectLocation={(location, modelId) =>
                setSelectedLocationInfo({ location, modelId })
              }
              onLocationRecordChanged={onLocationRecordChanged}
              configuration={configProvider.getConfiguration()}
            />
          }
          rightPane={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <LocationDetailPane
              locationInfo={selectedLocationInfo}
              appId={appId}
              featureId={FeatureId}
              onChanged={onLocationRecordChanged}
              onDelete={onDeleteRecord}
              configuration={configProvider
                .getSubFeatureConfigurationProvider('locationDetail')
                .getConfiguration()}
            />
          }
        />
      )}
    </Fragment>
  );
};

ListLocations.propTypes = {
  appId: PropTypes.string.isRequired
};
