import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AddressFormComponent } from '../popper-forms/AddressFormComponent';
import { CancelFormComponent } from '../popper-forms/CancelFormComponent';
import { formatCurrency, formatNumber, getValueOrDefault } from '../../common/Utilities';
import PurchaseOrderDetailStyle from './PurchaseOrderDetailsComponent.module.css';
import DatePickerInput from '../add-page-component/add-page-inputs/DatePickerInput';
import TextInput from '../add-page-component/add-page-inputs/TextInput';
import { makeStyles } from '@material-ui/core/styles';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import { EditableTableComponentV2 } from '../editable-table/EditableTableComponentV2';
import DisplayBanner from '../display-banner/DisplayBanner';
import Popper from '@material-ui/core/Popper';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'mdbreact';

const useInputStyle = makeStyles({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    border: '1.5px solid #E2E2EA',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF'
  },
  focused: {
    border: '1.5px solid #166ED5',
    borderRadius: '3px'
  },
  error: {
    border: '1.5px solid #F62227'
  }
});

export const PurchaseOrderDetailBody = ({
  valueMap,
  buyer,
  currencyType,
  editedValues,
  shippingAddress,
  mailingAddress,
  locationId,
  handleLocationChange,
  handleVendorChange,
  handleAddressFormSubmit,
  handleEdit,
  validationFunc,
  errorMessageMap,
  onDateAccept,
  isCancelled,
  isNew,
  handleItemOrderEdit,
  editedItemOrders,
  itemOrderErrorMap,
  onItemOrderBlur,
  popperFormComponent,
  setPopperFormComponent,
  getOptions,
  handleCancel,
  handleAddItemOrder,
  onItemTypeSelect,
  errorBannerMessage,
  closeBannerFunc
}) => {
  const [targetElRef, setTargetElRef] = useState(null);

  const headerConfig = [
    {
      displayName: 'Item',
      id: 'identifier',
      editable: (rowData) => {
        return rowData.id?.includes('NEW');
      },
      input: 'dropdown',
      display: 'text',
      width: '13%',
      getSubText: (rowData, editedValues) => {
        return (
          <>
            <div className={PurchaseOrderDetailStyle.item_order_description}>
              {rowData.description || '--'}
            </div>
            {(editedValues?.comments || rowData.comments) && (
              <div className={PurchaseOrderDetailStyle.item_order_comments}>
                <span className={PurchaseOrderDetailStyle.bold_text}>Cancellation Reason: </span>
                {editedValues?.comments || rowData.comments}
              </div>
            )}
          </>
        );
      },
      onChange: onItemTypeSelect,
      onBlur: onItemOrderBlur,
      getAdditionalStyles: (rowData) => {
        if (rowData.id?.includes('NEW')) {
          return {
            marginTop: '13.5px'
          };
        }
        return {};
      },
      getDataList: () => {
        return valueMap.itemTypes?.map((type) => type.identifier);
      },
      renderOptions: (option) => {
        const type = valueMap.itemTypes?.find((type) => type.identifier === option);
        return (
          <div className={PurchaseOrderDetailStyle.dropdown_option_container}>
            <div className={PurchaseOrderDetailStyle.dropdown_option_text}>{option}</div>
            <div className={PurchaseOrderDetailStyle.dropdown_sub_text}>{type.description}</div>
          </div>
        );
      }
    },
    {
      displayName: 'Order Status',
      id: 'status',
      display: 'status',
      labelId: 'orderStatusLabel',
      colorId: 'orderStatusColor',
      getAdditionalStyles: () => {
        return {
          marginTop: '23.5px'
        };
      }
    },
    {
      displayName: 'Fulfilment Status',
      id: 'fulfillmentStatus',
      display: 'status',
      labelId: 'fulfillmentStatusLabel',
      colorId: 'fulfillmentStatusColor',
      width: '13%',
      getAdditionalStyles: () => {
        return {
          marginTop: '23.5px'
        };
      }
    },
    {
      displayName: 'Qty',
      id: 'quantity_ordered',
      rightAlign: true,
      editable: (rowData) => {
        const orderStatus = editedItemOrders[rowData.id]?.order_status || rowData.order_status;
        return orderStatus !== 'Cancelled';
      },
      input: 'text',
      display: 'text',
      getAdditionalStyles: () => {
        return {
          marginTop: '13.5px'
        };
      },
      onChange: handleItemOrderEdit,
      onBlur: onItemOrderBlur
    },
    {
      displayName: 'Received Qty',
      id: 'quantity_fulfilled',
      rightAlign: true,
      display: 'text',
      getAdditionalStyles: () => {
        return {
          marginTop: '13.5px'
        };
      }
    },
    {
      displayName: 'Cost per unit',
      id: 'direct_unit_cost',
      rightAlign: true,
      editable: (rowData) => {
        const orderStatus = editedItemOrders[rowData.id]?.order_status || rowData.order_status;
        return orderStatus !== 'Cancelled';
      },
      input: 'text',
      display: 'text',
      suffix: (rowData) => {
        return <div style={{ marginLeft: '0.5em' }}>/ {rowData.unit}</div>;
      },
      getAdditionalStyles: () => {
        return {
          marginTop: '13.5px'
        };
      },
      onChange: handleItemOrderEdit,
      onBlur: onItemOrderBlur
    },
    {
      displayName: 'Freight charge',
      id: 'shipping_charge',
      rightAlign: true,
      editable: (rowData) => {
        const orderStatus = editedItemOrders[rowData.id]?.order_status || rowData.order_status;
        return orderStatus !== 'Cancelled';
      },
      input: 'text',
      display: 'text',
      getAdditionalStyles: () => {
        return {
          marginTop: '13.5px'
        };
      },
      onChange: handleItemOrderEdit,
      onBlur: onItemOrderBlur
    },
    {
      displayName: 'Actual Cost',
      id: 'total_cost_by_quantity_fulfilled',
      rightAlign: true,
      display: 'text',
      getAdditionalStyles: (rowData) => {
        return {
          marginTop: '13.5px'
        };
      }
    },
    {
      displayName: 'Expected Cost',
      id: 'total_cost_by_quantity_ordered',
      rightAlign: true,
      display: 'text',
      getAdditionalStyles: (rowData) => {
        const orderStatus = editedItemOrders[rowData.id]?.order_status || rowData.order_status;
        if (orderStatus === 'Cancelled') {
          return {
            textDecoration: 'line-through',
            marginTop: '13.5px'
          };
        }
        return {
          marginTop: '13.5px'
        };
      }
    },
    {
      displayName: '',
      display: (rowData) => {
        const options = getOptions(rowData);
        return (
          <Dropdown>
            <DropdownToggle
              nav
              className={PurchaseOrderDetailStyle.item_order_more_button}
              onClick={(event) => {
                setPopperFormComponent(undefined);
                setTargetElRef(event.currentTarget);
              }}
            >
              <MoreIcon fontSize="small" />
            </DropdownToggle>
            <DropdownMenu className={PurchaseOrderDetailStyle.item_order_options_container} right>
              {options.map((option) => {
                const { action, label, color } = option;
                return (
                  <DropdownItem
                    className={PurchaseOrderDetailStyle.item_order_options}
                    key={`${label}${rowData.id}`}
                  >
                    <div
                      className={PurchaseOrderDetailStyle.item_order_options_text}
                      style={{ color: color || '#166ED5' }}
                      onClick={() => {
                        action(rowData.id);
                      }}
                    >
                      {label}
                    </div>
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>
        );
      },
      getAdditionalStyles: () => {
        return {
          marginTop: '13.5px'
        };
      }
    }
  ];

  const inputStyle = useInputStyle();

  const renderDateField = (id) => {
    const value = editedValues[id] !== undefined ? editedValues[id] : valueMap[id];
    const minDate =
      editedValues.creation_date !== undefined
        ? editedValues.creation_date
        : valueMap.creation_date;
    return (
      <DatePickerInput
        id={id}
        value={value}
        onChange={handleEdit}
        error={!!errorMessageMap[id]}
        onBlur={() => {
          validationFunc(id);
        }}
        inputVariant="standard"
        KeyboardButtonProps={{
          disableRipple: true,
          size: 'small',
          style: { color: '#166ED5' }
        }}
        onAccept={(date) => {
          onDateAccept(id, date);
        }}
        inputProps={{
          style: {
            width: '100%',
            padding: '0 0 0 2%',
            height: '28px'
          }
        }}
        InputProps={{ classes: inputStyle, disableUnderline: true }}
        size="small"
        errorMessage={errorMessageMap[id]}
        minDate={id !== 'creation_date' && minDate}
      />
    );
  };

  const getShippingMethodOptions = () => {
    const shippingMethodOptionList = [
      {
        id: 'Vendor-shipped',
        value: 'Vendor-shipped'
      },
      {
        id: 'Self-organized freight',
        value: 'Self-organized freight'
      },
      {
        id: 'Ship direct to customer',
        value: 'Ship direct to customer'
      }
    ];

    return shippingMethodOptionList;
  };

  const renderAddButton = (type) => {
    switch (type) {
      case 'mailing_address':
        if (
          (editedValues.vendor || valueMap.purchasedFrom?.length) &&
          (!mailingAddress.id || mailingAddress.id === 'NEW')
        ) {
          return (
            <div
              className={PurchaseOrderDetailStyle.po_info_edit_button}
              onClick={(event) => {
                setTargetElRef(event.currentTarget);
                setPopperFormComponent('mailing_address');
              }}
            >
              Add
            </div>
          );
        }
        break;
      case 'shipping_address':
        if (shippingAddress.id === undefined || shippingAddress.id === 'NEW') {
          return (
            <div
              className={PurchaseOrderDetailStyle.po_info_edit_button}
              onClick={(event) => {
                setTargetElRef(event.currentTarget);
                setPopperFormComponent('shipping_address');
              }}
            >
              Add
            </div>
          );
        }
        break;
      default:
        break;
    }
  };

  const renderPurchaseOrderInfoSection = () => {
    return (
      <div className={PurchaseOrderDetailStyle.po_info_section}>
        {errorBannerMessage && (
          <div className={PurchaseOrderDetailStyle.error_container}>
            <DisplayBanner
              bannerError
              bannerMessage={errorBannerMessage}
              onCloseBanner={closeBannerFunc}
            />
          </div>
        )}
        <div className={PurchaseOrderDetailStyle.po_info_title_section}>
          <p className={PurchaseOrderDetailStyle.bold_text}>Purchase Order Information</p>
          <div className={PurchaseOrderDetailStyle.buyer_section}>
            <p>
              <span
                className={`${PurchaseOrderDetailStyle.bold_text} ${PurchaseOrderDetailStyle.buyer_text}`}
              >
                Buyer
              </span>
              {buyer ? `${buyer.given_name || ''} ${buyer.family_name || ''}` : '-'}
            </p>
          </div>
        </div>
        <div className={PurchaseOrderDetailStyle.po_info_input_section}>
          <div className={PurchaseOrderDetailStyle.po_info_input_container}>
            <p className={PurchaseOrderDetailStyle.bold_text}>Purchase Order #</p>
            <TextInput
              id="identifier"
              value={
                editedValues.identifier !== undefined
                  ? editedValues.identifier
                  : valueMap.identifier
              }
              errorMessage={errorMessageMap.identifier}
              onBlur={() => {
                validationFunc('identifier');
              }}
              size="small"
              variant="standard"
              InputProps={{ classes: inputStyle, disableUnderline: true }}
              inputProps={{
                style: {
                  width: '100%',
                  height: '28px',
                  padding: '0 0 0 2%'
                }
              }}
              error={!!errorMessageMap.identifier}
              onChange={handleEdit}
            />
          </div>
          <div className={PurchaseOrderDetailStyle.po_info_input_container}>
            <p className={PurchaseOrderDetailStyle.bold_text}>Purchase Order Date</p>
            {renderDateField('creation_date')}
          </div>
          <div className={PurchaseOrderDetailStyle.po_info_input_container}>
            <p className={PurchaseOrderDetailStyle.bold_text}>
              {isCancelled ? 'Cancelled Date' : 'Received Date'}
            </p>
            {renderDateField(isCancelled ? 'cancel_date' : 'fulfillment_date')}
          </div>
        </div>
        <div className={PurchaseOrderDetailStyle.po_info_input_section}>
          <div className={PurchaseOrderDetailStyle.po_info_input_container}>
            <p className={PurchaseOrderDetailStyle.bold_text}>Vendor</p>
            {isNew ? (
              <select
                className={PurchaseOrderDetailStyle.po_info_input_dropdown}
                onChange={handleVendorChange}
              >
                <option value=""></option>
                {valueMap.partners
                  .sort((partnerA, partnerB) => {
                    const partnerAName = partnerA.name || partnerA.identifier;
                    const partnerBName = partnerB.name || partnerB.identifier;

                    return partnerAName.localeCompare(partnerBName);
                  })
                  .map((partner) => (
                    <option key={partner.id} value={partner.id}>
                      {partner.name || partner.identifier}
                    </option>
                  ))}
              </select>
            ) : (
              getValueOrDefault(() => {
                return valueMap.purchasedFrom[0]?.name || valueMap.purchasedFrom[0]?.identifier;
              }, '-')
            )}
          </div>
          <div className={PurchaseOrderDetailStyle.po_info_input_container}>
            <p className={PurchaseOrderDetailStyle.bold_text}>Ship To</p>
            <select
              className={PurchaseOrderDetailStyle.po_info_input_dropdown}
              value={locationId}
              onChange={handleLocationChange}
            >
              <option value=""></option>
              {valueMap.locations
                .sort((locationA, locationB) => {
                  const locationAName = locationA.name || locationA.identifier;
                  const locationBName = locationB.name || locationB.identifier;

                  return locationAName.localeCompare(locationBName);
                })
                .map((location) => (
                  <option key={location.id} value={location.id}>
                    {location.name}
                  </option>
                ))}
            </select>
          </div>
          <div className={PurchaseOrderDetailStyle.po_info_input_container}>
            <p className={PurchaseOrderDetailStyle.bold_text}>Shipping Method</p>
            <select
              className={PurchaseOrderDetailStyle.po_info_input_dropdown}
              value={editedValues.shipping_method || valueMap.shipping_method || 'Vendor-shipped'}
              onChange={(event) => {
                handleEdit('shipping_method', event.target.value);
              }}
            >
              {getShippingMethodOptions().map((method) => (
                <option value={method.id} key={method.id}>
                  {method.value}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className={PurchaseOrderDetailStyle.po_info_input_section}>
          <div className={PurchaseOrderDetailStyle.po_info_input_container}>
            <div className={PurchaseOrderDetailStyle.po_info_address_section}>
              <p className={PurchaseOrderDetailStyle.bold_text}> Mailing Address</p>
              {renderAddButton('mailing_address')}
            </div>
            {Object.keys(mailingAddress).length > 0 ? (
              <>
                <div>{mailingAddress.address_line_0}</div>
                <div>{`${mailingAddress.locality}, ${mailingAddress.admin_area} ${mailingAddress.postal_code}`}</div>
              </>
            ) : (
              '-'
            )}
          </div>
          <div className={PurchaseOrderDetailStyle.po_info_input_container}>
            <div className={PurchaseOrderDetailStyle.po_info_address_section}>
              <p className={PurchaseOrderDetailStyle.bold_text}>Shipping Address</p>
              {renderAddButton('shipping_address')}
            </div>
            {Object.keys(shippingAddress).length > 0 ? (
              <>
                <div>{shippingAddress.address_line_0}</div>
                <div>{`${shippingAddress.locality}, ${shippingAddress.admin_area} ${shippingAddress.postal_code}`}</div>
              </>
            ) : (
              '-'
            )}
          </div>
          <div className={PurchaseOrderDetailStyle.po_info_input_container}>
            <p className={PurchaseOrderDetailStyle.bold_text}>Scheduled Ship Date</p>
            {renderDateField('scheduled_ship_date')}
          </div>
        </div>
      </div>
    );
  };

  const renderTotalsSection = () => {
    return (
      <div className={PurchaseOrderDetailStyle.bottom_section}>
        <div className={PurchaseOrderDetailStyle.remarks_container}>
          Remarks
          <textarea
            className={PurchaseOrderDetailStyle.remarks_input}
            onChange={(event) => {
              handleEdit('comments', event.target.value);
            }}
            value={
              (editedValues.comments !== undefined ? editedValues.comments : valueMap.comments) ||
              ''
            }
          />
        </div>
        <div className={PurchaseOrderDetailStyle.totals_container}>
          <div className={PurchaseOrderDetailStyle.totals_row}>
            <div className={PurchaseOrderDetailStyle.totals_label}>Subtotal</div>
            <div className={PurchaseOrderDetailStyle.totals_amount}>
              {formatNumber(
                editedValues.total_cost_by_quantities_ordered ||
                  valueMap.total_cost_by_quantities_ordered ||
                  0,
                2
              )}
            </div>
            <div className={PurchaseOrderDetailStyle.currency_label}>{currencyType}</div>
          </div>
          <div className={PurchaseOrderDetailStyle.totals_row}>
            <div className={PurchaseOrderDetailStyle.totals_label}>Freight</div>
            <div className={PurchaseOrderDetailStyle.freight_text}>Applied per item</div>
            <div className={PurchaseOrderDetailStyle.currency_label}>{currencyType}</div>
          </div>
          {/* <div className={PurchaseOrderDetailStyle.totals_row}>
            <div className={PurchaseOrderDetailStyle.totals_label}>Other</div>
            <input className={PurchaseOrderDetailStyle.totals_input} />
            <div className={PurchaseOrderDetailStyle.currency_label}>{currencyType}</div>
          </div>
          <div className={PurchaseOrderDetailStyle.totals_row}>
            <div className={PurchaseOrderDetailStyle.totals_label}>Discount</div>
            <input className={PurchaseOrderDetailStyle.totals_input} />
            <select className={PurchaseOrderDetailStyle.discount_dropdown}>
              <option value="%">%</option>
              <option value="Flat">Flat</option>
            </select>
          </div> */}
          <div className={PurchaseOrderDetailStyle.totals_row}>
            <div className={PurchaseOrderDetailStyle.totals_title}>Total</div>
            <div className={PurchaseOrderDetailStyle.total_amount}>
              {formatCurrency(
                editedValues.total_cost_by_quantities_ordered ||
                  valueMap.total_cost_by_quantities_ordered ||
                  0,
                currencyType
              )}
            </div>
            <div className={PurchaseOrderDetailStyle.currency_label}>{currencyType}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderItemOrderSection = () => {
    return (
      <div className={PurchaseOrderDetailStyle.item_order_container}>
        <div className={PurchaseOrderDetailStyle.item_order_title}>Items</div>
        <EditableTableComponentV2
          headerConfig={headerConfig}
          dataList={valueMap.itemOrders}
          editedValueMap={editedItemOrders}
          errorMap={itemOrderErrorMap}
          addItem={handleAddItemOrder}
          disableAdd={!isNew && isCancelled}
        />
      </div>
    );
  };

  const getPopperComponent = () => {
    switch (popperFormComponent) {
      case 'cancel':
        return (
          <CancelFormComponent
            closeFunc={() => {
              setPopperFormComponent(undefined);
            }}
            onSubmit={handleCancel}
          />
        );
      case 'shipping_address':
      case 'mailing_address':
        return (
          <AddressFormComponent
            title="Edit Shipping Address"
            closeFunc={() => {
              setPopperFormComponent(undefined);
            }}
            submitFunc={handleAddressFormSubmit}
          />
        );
      default:
        return <></>;
    }
  };

  const renderPopperComponent = () => {
    return (
      <Popper
        id="body-popper"
        open={!!popperFormComponent}
        anchorEl={targetElRef}
        disablePortal
        placement="bottom-end"
      >
        {getPopperComponent()}
      </Popper>
    );
  };

  return (
    <div>
      {renderPurchaseOrderInfoSection()}
      {renderItemOrderSection()}
      {renderTotalsSection()}
      {renderPopperComponent()}
    </div>
  );
};

PurchaseOrderDetailBody.defaultProps = {
  valueMap: {},
  currencyType: 'USD'
};

PurchaseOrderDetailBody.propTypes = {
  valueMap: PropTypes.object,
  currencyType: PropTypes.string,
  buyer: PropTypes.object,
  handleEdit: PropTypes.func,
  editedValues: PropTypes.object,
  shippingAddress: PropTypes.object,
  mailingAddress: PropTypes.object,
  locationId: PropTypes.string,
  handleLocationChange: PropTypes.func,
  handleVendorChange: PropTypes.func,
  handleAddressFormSubmit: PropTypes.func,
  errorMessageMap: PropTypes.object,
  onDateAccept: PropTypes.func,
  validationFunc: PropTypes.func,
  isCancelled: PropTypes.bool,
  isNew: PropTypes.bool,
  handleItemOrderEdit: PropTypes.func,
  editedItemOrders: PropTypes.object,
  itemOrderErrorMap: PropTypes.object,
  onItemOrderBlur: PropTypes.func,
  popperFormComponent: PropTypes.string,
  setPopperFormcomponent: PropTypes.func,
  getOptions: PropTypes.func,
  handleCancel: PropTypes.func,
  handleAddItemOrder: PropTypes.func,
  onItemTypeSelect: PropTypes.func,
  errorBannerMessage: PropTypes.string,
  closeBannerFunc: PropTypes.func
};
