import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import TextareaAutosize from 'react-textarea-autosize';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import ItemTypeDetailStyle from './ItemTypeDetailComponent.module.css';
import { Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'mdbreact';
import { Modal, Alert } from 'react-bootstrap';

const ItemDetailComponent = ({
  itemTypeInfo,
  itemTypeLabel,
  editMode,
  setEditMode,
  handleCancel,
  handleChange,
  image,
  alert,
  onCloseAlert,
  showAlert,
  setShowDeleteModal,
  showDeleteModal,
  handleUpdateItemType,
  handleDelete,
  defaultValue
}) => {
  const renderMoreButton = () => {
    return (
      <Dropdown>
        <DropdownToggle nav className={ItemTypeDetailStyle.dropdown_toggle}>
          <MoreIcon />
        </DropdownToggle>

        <DropdownMenu>
          <DropdownItem
            className="dropdown-item"
            onClick={() => {
              setEditMode(true);
            }}
          >
            <div>{`Edit ${itemTypeLabel}`}</div>
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              setShowDeleteModal(true);
            }}
          >
            <div className={ItemTypeDetailStyle.red_text}>{`Delete ${itemTypeLabel}`}</div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  const renderFormInput = () => {
    return (
      <div>
        <TextareaAutosize
          onChange={handleChange}
          className={ItemTypeDetailStyle.form_input}
          defaultValue={defaultValue}
        />
      </div>
    );
  };

  const renderFormButtons = () => {
    return (
      <div className={ItemTypeDetailStyle.button_container}>
        <button
          className={`${ItemTypeDetailStyle.btn} ${ItemTypeDetailStyle.gray_btn}`}
          onClick={() => {
            handleCancel();
          }}
        >
          Cancel
        </button>
        <button
          className={`${ItemTypeDetailStyle.btn} ${ItemTypeDetailStyle.blue_btn}`}
          onClick={() => {
            handleUpdateItemType();
          }}
        >
          Save
        </button>
      </div>
    );
  };

  const DisplayBanner = () => {
    if (showAlert) {
      return (
        <Alert variant={'danger'} onClose={onCloseAlert} dismissible>
          <h4>{'Error'}</h4>
          <p>{alert}</p>
        </Alert>
      );
    }
  };

  return (
    <Fragment>
      {DisplayBanner()}
      <Modal show={showDeleteModal}>
        <Modal.Header>
          <Modal.Title className={ItemTypeDetailStyle.delete_modal_title}>
            {`Delete ${itemTypeLabel}?`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            Do you want to permanently delete {itemTypeInfo.identifier}? Please ensure that all
            items associated with this {itemTypeLabel.toLowerCase()} have already been deleted
            before proceeding.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="cancel-button"
            onClick={() => {
              setShowDeleteModal(false);
            }}
          >
            Cancel
          </button>
          <button
            className="confirm-delete-button"
            onClick={() => {
              setShowDeleteModal(false);
              handleDelete();
            }}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>
      <div className={ItemTypeDetailStyle.grid_card_container_style}>
        <img className={ItemTypeDetailStyle.image} src={image} alt="logo" />
        <div className={ItemTypeDetailStyle.title_container}>
          <div className={ItemTypeDetailStyle.title}>
            {`${itemTypeLabel} Name:`}
            {renderMoreButton()}
          </div>
          <div className={ItemTypeDetailStyle.identifier}>
            {editMode ? renderFormInput() : itemTypeInfo.identifier}
          </div>
        </div>
        <div>{editMode && renderFormButtons()}</div>
      </div>
    </Fragment>
  );
};

export default withRouter(ItemDetailComponent);
