import React from 'react';
import Style from './GridCardContentGroupby.module.css';

const GridCardContentGroupby = ({ title = {}, contentList = [] }) => {
  return (
    <div className={Style.container}>
      <div className={Style.name}>
        {`${title.label ? `${title.label} ` : ''}${title.value || ''}`}
      </div>
      <div className={Style.content_container}>
        {contentList?.map((eachContent) => {
          return (
            <p key={eachContent.label} className={Style.item}>
              {`${eachContent.label ? `${eachContent.label} ` : ''}${
                eachContent.value || eachContent.value === 0
                  ? eachContent.value
                  : eachContent.defaultValue || '-'
              }`}
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default GridCardContentGroupby;
