import React, { Component } from 'react';
import { Card, CardBody } from 'mdbreact';
import Style from './summaryTile.module.css';

export default class SummaryTile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tileInfo: this.props.data, // { title: 'Total Requests', totalItems: totalCount, status: 'Total' }
      filterEnabled: this.props.filterEnabled
    };

    this.handleClick = this.handleClick.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      tileInfo: newProps.data,
      filterEnabled: newProps.filterEnabled
    });
  }

  renderLeftColumnContent = (tileInfo) => {
    return (
      <div className={Style.summary_tile_left_column}>
        <p className={Style.left_column_number}>{tileInfo.totalItems}</p>
        <p className={Style.left_column_text}> Total Items </p>
      </div>
    );
  };

  renderRightColumnContent = (tileInfo) => {
    if (tileInfo.length < 1) return null;

    const rightColumnContent = tileInfo.map((data) => {
      if (data.count > 0) {
        return (
          <div className={Style.right_column_content}>
            <p className={Style.status_count}>{data.count}</p>
            <p className={`${Style.status_name}`} style={{ color: data.color }}>
              {data.displayText}
            </p>
          </div>
        );
      }
      return null;
    });

    return rightColumnContent;
  };

  handleClick() {
    // to handle filter view
    const tileClicked = this.state.tileInfo.id;
    this.props.callBackFn(tileClicked, 'summary');
  }

  render() {
    const { tileInfo, filterEnabled } = this.state;

    return (
      <Card
        title="Click to view items in this group."
        className={`${Style.summary_tile} ${!filterEnabled && Style.filter_disabled}`}
        onClick={() => this.handleClick()}
      >
        <CardBody className={Style.summary_tile_body}>
          <div className={Style.summary_tile_title} style={{ backgroundColor: tileInfo.color }}>
            {tileInfo.title}
          </div>
          <div className={Style.summary_tile_content}>
            {this.renderLeftColumnContent(tileInfo)}
            <div className={Style.summary_tile_right_column}>
              {this.renderRightColumnContent(tileInfo.data)}
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }
}
