import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import ItemTypesManagementTrackPageFeature from '../../features/item-types-management-track-page-feature/ItemTypesManagementTrackPageFeature';
import AddItemTypeFeature from '../../features/item-types-management-track-page-feature/AddItemTypeFeature';
import BulkUpdateItemTypesFeature from '../../features/item-types-management-track-page-feature/BulkUpdateItemTypesFeature';
import ItemTypeDetailStackNavigation from 'navigations/ItemTypeDetailStackNavigation';
const ItemTypesManagementApp = () => {
  return (
    <Fragment>
      <Route exact path="/item-types-management" component={ItemTypesManagementTrackPageFeature} />
      <Route path="/item-types-management/detail" component={ItemTypeDetailStackNavigation} />
      <Route path="/item-types-management/create" component={AddItemTypeFeature} />
      <Route path="/item-types-management/bulk-update" component={BulkUpdateItemTypesFeature} />
    </Fragment>
  );
};

export default ItemTypesManagementApp;
