import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'mdbreact';
import ItemTile from './ItemTile';
import Style from './ShipmentsListView.module.css';

const ShipmentsListView = ({ columnsList }) => {
  return (
    <Container className={Style.itemContainer}>
      {columnsList && columnsList.length > 0 ? (
        columnsList.map((columnData, index) => {
          return (
            <div key={index} className={Style.rowWrapToColumn}>
              {columnData &&
                columnData.length > 0 &&
                columnData.map((row, index) => <ItemTile key={index} data={row} />)}
            </div>
          );
        })
      ) : (
        <p className={Style.noRowsToShow}> No active items to track.</p>
      )}
    </Container>
  );
};

export default ShipmentsListView;

ShipmentsListView.defaultProps = {
  columnsList: undefined
};

ShipmentsListView.propTypes = {
  columnsList: PropTypes.array
};
