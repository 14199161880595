import React, { useState, useEffect } from 'react';
import TrackPageComponent from '../../components/TrackPageComponents/track-page-component/TrackPageComponentV2';
import LoadingCircle from '../../components/loading/LoadingCircle';
import { useXemelgoClient } from '../../services/xemelgo-service';
import { useAppConfigProvider } from '../../services/soft-cache-service';
import { pluralizeWord } from '../../common/Utilities';

const APP_ID = 'itemTypesManagement';
const TRACK_PAGE_CONFIG = 'trackPage';

const ItemTypesManagementTrackPageFeature = ({ history }) => {
  const configProvider = useAppConfigProvider(APP_ID);
  const TrackPageConfig = configProvider.getValue(TRACK_PAGE_CONFIG, 'object');

  const [itemTypeClient] = useState(useXemelgoClient().getItemTypeClient());
  const [listViewStructureList, setListViewStructureList] = useState([]);
  const [itemTypeLabel, setItemTypeLabel] = useState('Item Type');
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(true);

  const onLoad = async () => {
    const {
      listViewConfig = {},
      itemTypeAttributes = [],
      itemTypeLabel: newItemTypeLabel = 'Item Type'
    } = TrackPageConfig || {};
    const newListViewStructure = Object.keys(listViewConfig)
      .sort((a, b) => {
        return listViewConfig[a].index - listViewConfig[b].index;
      })
      .map((eachKey) => {
        const { label, title, index, default: defaultSort } = listViewConfig[eachKey];
        return { id: eachKey, title, default: defaultSort, index, label };
      });

    try {
      const itemTypesResult = await itemTypeClient.getItemTypes(itemTypeAttributes);

      // set undefined attributes to be null for rendering '-' on ListView
      const processItemTypesResult = itemTypesResult.map((eachItemType) => {
        // searchString is used for filter, it contains all values showing in each row separate by space
        const ret = { ...eachItemType, searchString: '' };
        Object.keys(listViewConfig).forEach((eachKey) => {
          ret[eachKey] = eachItemType[eachKey] || null;

          // adding value to searchString
          if (eachItemType[eachKey]) {
            ret.searchString += `${eachItemType[eachKey]}`;
          }
        });
        return ret;
      });

      setDataList([
        {
          id: 'item-type-management',
          metricsList: [],
          subHeaderList: [],
          hide: false,
          dataList: processItemTypesResult
        }
      ]);
    } catch (error) {
      setDataList([]);
    }
    setItemTypeLabel(newItemTypeLabel);
    setListViewStructureList(newListViewStructure);
    setLoading(false);
  };

  useEffect(() => {
    onLoad();
  }, []);

  const listViewFilterFunc = (inputValue, eachData) => {
    if (!inputValue) {
      return true;
    }
    const keywords = inputValue
      .toLowerCase()
      .trim()
      .split(' ');
    let matched = true;

    keywords.forEach((eachKeyword) => {
      if (!eachData.searchString.toLowerCase().includes(eachKeyword)) {
        matched = false;
      }
    });
    return matched;
  };

  if (loading) {
    return <LoadingCircle />;
  }
  return (
    <TrackPageComponent
      numOfItemsPerPage={25}
      shouldUseDashForNullListViewContent
      title={`Manage ${pluralizeWord(itemTypeLabel)}`}
      titleIcon={
        <img
          src={require('../../img/item-types-management_icon_light_gray.png')}
          width="35x"
          height="35px"
          style={{ marginBottom: '2px' }}
        />
      }
      subTitle={`${dataList[0]?.dataList?.length || 0} ${pluralizeWord(itemTypeLabel)}`}
      mainColor="#c8cbd3"
      buttonColor="#166ED5"
      renderDataList={dataList}
      listViewMode
      listViewStructure={listViewStructureList}
      listViewFilterFunc={listViewFilterFunc}
      handleListViewClick={({ id }) => {
        history.push(`/item-types-management/detail?id=${id}`);
      }}
      additionalButtonsStructureList={[
        {
          id: 'add-item-type',
          title: `Add ${itemTypeLabel}`,
          onClick: () => {
            history.push(`${history.location.pathname}/create`);
          }
        }
      ]}
      moreButtonsList={[
        {
          id: 'bulk-update',
          title: 'Bulk Update',
          onClick: () => {
            history.push(`${history.location.pathname}/bulk-update`);
          }
        }
      ]}
    />
  );
};

export default ItemTypesManagementTrackPageFeature;
