import React, { Component } from 'react';

export default class ExpeditedRuleContent extends Component {
  render() {
    console.log('Expedited Rule content!!');
    return (
      <div>
        <div>
          <div>
            <p className="tabbedSectionComponent-contentTitle">Expedited</p>
            <p style={{ color: '#343434' }}>
              Sends a notification when an order is marked as expedited.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
