import React from 'react';
import Loading from 'components/loading/LoadingWord';
import StockLevelRuleContent from '../pages/alert/StockLevelRuleContent';
import BacklogRuleContent from '../pages/alert/BacklogRuleContent';
import IdleRuleContent from '../pages/alert/IdleRuleContent';
import MissingRuleContent from '../pages/alert/MissingRuleContent';
import ExpiredItemRuleContent from '../pages/alert/ExpiredItemRuleContent';
import ShipmentIssueRuleContent from '../pages/alert/ShipmentIssueRuleContent';
import DetectorOfflineContent from '../pages/alert/DetectorOfflineContent';
import { RefreshRuleContent } from '../pages/alert/RefreshRuleContent';
import { TriggerBasedIdleRuleContent } from '../pages/alert/TriggerBasedIdleRuleContent';
import UsageLimitContent from '../pages/alert/UsageLimitContent';
import { ShipmentVerifiedRuleContent } from '../pages/alert/ShipmentVerifiedRuleContent';
import ExpeditedRuleContent from '../pages/alert/ExpeditedRuleContent';

// import OverstockRuleContent from '../pages/alert/OverstockRuleContent';
// import ArrivedAtRuleContent from '../pages/alert/ArrivedAtRuleContent';
import { XemelgoService } from './XemelgoService';
import ConfigurationService from './ConfigurationService';
import AlertDefaultTemplate from './AlertDefaultTemplate';
import AuthService from './AuthService';
import { prepareRuleAndConditions } from '../pages/alert/alert-builders';

export const temporaryComponent = () => (
  <div>
    Under Construction
    <Loading />
  </div>
);

export const ruleInfoMap = {
  Backlog: {
    eventId: 'detection_event',
    enableRuleSubscription: false, // to show/hide rule subscription switch on alerts page
    // eslint-disable-next-line
    component: (
      ruleId,
      ruleConditionsList,
      locationDataList,
      itemTypeData,
      onLoad,
      ruleConfig,
      subscriptions,
      locationCategory,
      shipmentsTrackingLocationCategory,
      trackingSessionList
    ) => {
      locationDataList = locationDataList.filter((location) => {
        return locationCategory.includes(location.category);
      });
      return () => {
        return (
          <BacklogRuleContent
            ruleId={ruleId}
            ruleConditionsList={ruleConditionsList}
            locationDataList={locationDataList}
            trackingSessionList={trackingSessionList}
            onLoad={onLoad}
          />
        );
      };
    }
  },
  'Low Stock': {
    eventId: 'detection_event',
    enableRuleSubscription: true, // to show/hide rule subscription switch on alerts page
    component: (
      ruleId,
      ruleConditionsList,
      locationDataList,
      itemTypeData,
      onLoad,
      ruleConfig,
      subscriptions,
      locationCategory,
      shipmentsTrackingLocationCategory
    ) => {
      locationDataList = locationDataList.filter((location) => {
        return locationCategory.includes(location.category);
      });
      return () => {
        return (
          <StockLevelRuleContent
            ruleId={ruleId}
            ruleConditionsList={ruleConditionsList}
            locationDataList={locationDataList}
            itemTypeData={itemTypeData}
            onLoad={onLoad}
            ruleConfig={ruleConfig}
            subscriptions={subscriptions}
          />
        );
      };
    }
  },
  'Stock Alerts': {
    eventId: 'detection_event',
    enableRuleSubscription: false, // to show/hide rule subscription switch on alerts page
    component: (
      ruleId,
      ruleConditionsList,
      locationDataList,
      itemTypeData,
      onLoad,
      ruleConfig,
      subscriptions,
      locationCategory,
      shipmentsTrackingLocationCategory
    ) => {
      locationDataList = locationDataList.filter((location) => {
        return locationCategory.includes(location.category);
      });
      return () => {
        return (
          <StockLevelRuleContent
            ruleId={ruleId}
            ruleConditionsList={ruleConditionsList}
            locationDataList={locationDataList}
            itemTypeData={itemTypeData}
            onLoad={onLoad}
            ruleConfig={ruleConfig}
            subscriptions={subscriptions}
          />
        );
      };
    }
  },
  'Missing Item': {
    eventId: 'detection_event',
    enableRuleSubscription: true,
    component: (ruleId) => {
      return () => {
        return <MissingRuleContent ruleId={ruleId} />;
      };
    }
  },
  Idle: {
    eventId: 'idle_event',
    enableRuleSubscription: false, // to show/hide rule subscription switch on alerts page
    // eslint-disable-next-line
    component: (
      ruleId,
      ruleConditionsList,
      locationDataList,
      itemTypeData,
      onLoad,
      ruleConfig,
      subscriptions,
      locationCategory,
      shipmentsTrackingLocationCategory
    ) => {
      locationDataList = locationDataList.filter((location) => {
        return locationCategory.includes(location.category);
      });

      if (ruleConfig.triggerBased) {
        return () => (
          <TriggerBasedIdleRuleContent
            ruleId={ruleId}
            ruleConditionsList={ruleConditionsList}
            locationDataList={locationDataList}
            onLoad={onLoad}
            ruleConfig={ruleConfig}
          />
        );
      } else {
        return () => (
          <IdleRuleContent
            ruleId={ruleId}
            ruleConditionsList={ruleConditionsList}
            locationDataList={locationDataList}
            onLoad={onLoad}
            ruleConfig={ruleConfig}
          />
        );
      }
    }
  },
  Expiration: {
    eventId: 'expiration_event',
    enableRuleSubscription: true,
    // eslint-disable-next-line
    component: (ruleId, ruleConditionsMap, locationDataList, itemTypeData, onLoad, ruleConfig) => {
      return () => (
        <ExpiredItemRuleContent
          ruleId={ruleId}
          ruleConditionsMap={ruleConditionsMap}
          onLoad={onLoad}
          ruleConfig={ruleConfig}
        />
      );
    }
  },
  'Shipment Issue': {
    eventId: 'shipment_issue_event',
    // eslint-disable-next-line
    component: (
      ruleId,
      ruleConditionsList,
      locationDataList,
      itemTypeData,
      onLoad,
      ruleConfig,
      subscriptions,
      locationCategory,
      shipmentsTrackingLocationCategory
    ) => {
      locationDataList = locationDataList.filter((location) => {
        return shipmentsTrackingLocationCategory.includes(location.category);
      });
      return () => (
        <ShipmentIssueRuleContent
          ruleId={ruleId}
          ruleConditionsList={ruleConditionsList}
          locationDataList={locationDataList}
          onLoad={onLoad}
        />
      );
    }
  },
  'Detector Offline': {
    eventId: 'detector-issue',
    enableRuleSubscription: true,
    component: () => {
      return () => <DetectorOfflineContent />;
    }
  },
  // This is to account for different naming for refresh. This will be reworked when sudhana refactors the alerts
  Recharge: {
    eventId: 'expiration_event',
    enableRuleSubscription: true,
    // eslint-disable-next-line
    component: (ruleId, ruleConditionsMap, locationDataList, itemTypeData, onLoad, ruleConfig) => {
      return () => (
        <RefreshRuleContent
          ruleId={ruleId}
          ruleConditionsMap={ruleConditionsMap}
          onLoad={onLoad}
          ruleConfig={ruleConfig}
        />
      );
    }
  },
  Calibration: {
    eventId: 'expiration_event',
    enableRuleSubscription: true,
    // eslint-disable-next-line
    component: (ruleId, ruleConditionsMap, locationDataList, itemTypeData, onLoad, ruleConfig) => {
      return () => (
        <RefreshRuleContent
          ruleId={ruleId}
          ruleConditionsMap={ruleConditionsMap}
          onLoad={onLoad}
          ruleConfig={ruleConfig}
        />
      );
    }
  },
  'Geofence': {
    event: 'usage_limit_event',
    enableRuleSubscription: true,
    component: () => {
      return () => <UsageLimitContent />;
    }
  },
  'Shipment Verified': {
    eventId: 'shipment_verified',
    component: (
      ruleId,
      ruleConditionsList,
      locationDataList,
      itemTypeData,
      onLoad,
      ruleConfig,
      subscriptions,
      locationCategory,
      shipmentsTrackingLocationCategory
    ) => {
      locationDataList = locationDataList.filter((location) => {
        return shipmentsTrackingLocationCategory.includes(location.category);
      });
      return () => (
        <ShipmentVerifiedRuleContent
          ruleId={ruleId}
          ruleConditionsList={ruleConditionsList}
          locationDataList={locationDataList}
          onLoad={onLoad}
        />
      );
    }
  },
  // Overstock: {
  //   eventId: 'track-object-type-count-updated',
  //   component: ruleId => {
  //     return () => <OverstockRuleContent ruleId={ruleId} />;
  //   }
  // },
  // 'Arrived At': {
  //   eventId: 'analyzed-tracker-event-historical-count-by-department',
  //   component: ruleId => {
  //     return () => <ArrivedAtRuleContent ruleId={ruleId} />;
  //   }
  // },
  Expedited: {
    eventId: 'expedited_event',
    enableRuleSubscription: true,
    component: () => {
      return () => <ExpeditedRuleContent />;
    }
  }
};

class AlertService {
  getNotificationRecipients = async () => {
    const RulePageClient = XemelgoService.getClient().getRulePageClient();
    const response = await RulePageClient.getSubscriptionProfile();
    return response;
  };

  updateNotificationSubscription = async (payload) => {
    const RulePageClient = XemelgoService.getClient().getRulePageClient();
    const result = await RulePageClient.updateSubscriptionProfile(
      payload.email,
      payload.phoneNumber
    );
    return result;
  };

  fetchRules = async () => {
    const ruleNotificationMap = {};
    const RulePageClient = XemelgoService.getClient().getRulePageClient();

    const trackingConfig = await ConfigurationService.getTrackingConfiguration();
    const solutionConfig = await ConfigurationService.getSolutionConfiguration();
    const { uiPreferences = {} } = solutionConfig;
    const { shipments = {} } = uiPreferences;
    const { shipmentsTrackingLocationCategory = [] } = shipments;

    const locationCategory =
      trackingConfig.possibleDetectorLocations &&
      trackingConfig.possibleDetectorLocations.length > 0
        ? trackingConfig.possibleDetectorLocations
        : ['Department'];

    let result = await RulePageClient.fetchRules();
    // get list of rules that apply to the customer from customer configuration
    let customerRules = await ConfigurationService.getCustomerRules();

    // Filter for rules that users are subscribed to
    customerRules = customerRules.filter((rule) => rule.subscribable);
    const uniqueItemTypes = [];
    const uniqueItemTypeMap = {};

    // if customer has Low Stock in their rules, get all item types
    if (customerRules.find((rule) => rule.id === 'lowStock')) {
      const itemTypeClient = XemelgoService.getClient().getItemTypeClient();
      const itemTypes = await itemTypeClient.listItemTypes(['identifier', 'id'], 'Inventory');

      itemTypes.forEach((itemType) => {
        const itemTypeIdentifier = itemType.getIdentifier();
        // get only unique item types since duplicates are allowed in the backend
        if (!uniqueItemTypeMap[itemTypeIdentifier]) {
          uniqueItemTypeMap[itemTypeIdentifier] = true;
          uniqueItemTypes.push({
            objectTypeId: itemType.getId(),
            objectTypeName: itemTypeIdentifier
          });
        }
      });
    }

    // get user's subscription profile, create if doesn't already exist
    const profile = await RulePageClient.getSubscriptionProfile();
    if (!profile) {
      const sessionInfo = await AuthService.getSessionInfo();
      await RulePageClient.createSubscriptionProfile(
        [sessionInfo.attributes.email],
        [sessionInfo.attributes.phone_number]
      );
    }
    let fetchAgain = false;
    const date = new Date();
    for (const customerRule of customerRules) {
      const ruleName = customerRule.id;
      let defaultTemplate;
      if (!customerRule.emailTemplate || !customerRule.ruleTemplate) {
        switch (customerRule.displayName) {
          case 'Calibration':
            defaultTemplate = AlertDefaultTemplate.getTemplates('calibrate');
            break;
          case 'Recharge':
            defaultTemplate = AlertDefaultTemplate.getTemplates('recharge');
            break;
          default:
            defaultTemplate = AlertDefaultTemplate.getTemplates(customerRule.id);
            break;
        }
      }

      if (customerRule.optimalStock) {
        const optimalRule = result.rules.find((rule) => rule.name === 'Optimal Stock');
        const outOfStockRule = result.rules.find((rule) => rule.name === 'Out of Stock');
        let stockTemplate;
        if (!optimalRule) {
          if (!customerRule.optimalEmailTemplate || !customerRule.optimalRuleTemplate) {
            stockTemplate = AlertDefaultTemplate.getTemplates('optimalStock');
          }
          await RulePageClient.createRule(
            'Optimal Stock',
            'detection_event',
            customerRule.optimalEmailTemplate || stockTemplate.ruleEmailTemplate,
            customerRule.optimalRuleTemplate || stockTemplate.ruleTemplate
          );
        }
        if (!outOfStockRule) {
          if (!customerRule.outOfStockEmailTemplate || !customerRule.outOfStockRuleTemplate) {
            stockTemplate = AlertDefaultTemplate.getTemplates('outOfStock');
          }
          await RulePageClient.createRule(
            'Out of Stock',
            'detection_event',
            customerRule.outOfStockEmailTemplate || stockTemplate.ruleEmailTemplate,
            customerRule.outOfStockRuleTemplate || stockTemplate.ruleTemplate
          );
        }
        customerRule.displayName = 'Min Stock';
      } else if (customerRule.earlyExpiration && customerRule.displayName === 'Expiration') {
        const expiringSoonRule = result.rules.find((rule) => rule.name === 'Expiring Soon');
        if (!expiringSoonRule) {
          let expiringSoonTemplate;
          if (!customerRule.expiringSoonEmailTemplate || !customerRule.expiringSoonRuleTemplate) {
            expiringSoonTemplate = AlertDefaultTemplate.getTemplates('expiringSoon');
          }
          const ruleId = await RulePageClient.createRule(
            'Expiring Soon',
            'expiration_event',
            customerRule.ExpiringSoonEmailTemplate || expiringSoonTemplate.ruleEmailTemplate,
            customerRule.outOfStockRuleTemplate || expiringSoonTemplate.ruleTemplate
          );
          date.setHours(0);
          date.setMinutes(0);
          const tags = { timeToTrigger: date.getTime(), daysBeforeExpired: 5 };
          const soonToExpireConditionList = [
            { property: 'soonToExpireTypeCount', op: '>', value: '0' }
          ];
          const soonToExpireName = 'expiring_soon';
          await RulePageClient.createRuleCondition(
            soonToExpireName,
            tags,
            ruleId,
            soonToExpireConditionList
          );
        }
      } else if (customerRule.refreshSoon) {
        const refreshSoonRule = result.rules.find(
          (rule) => rule.name === `${customerRule.displayName} Soon`
        );
        if (!refreshSoonRule) {
          let refreshSoonTemplate;
          if (!customerRule.refreshSoonEmailTemplate || !customerRule.refreshSoonRuleTemplate) {
            switch (customerRule.displayName) {
              case 'Calibration':
                refreshSoonTemplate = AlertDefaultTemplate.getTemplates('calibrateSoon');
                break;
              case 'Recharge':
                refreshSoonTemplate = AlertDefaultTemplate.getTemplates('rechargeSoon');
                break;
              default:
                break;
            }
          }
          const ruleId = await RulePageClient.createRule(
            `${customerRule.displayName} Soon`,
            'expiration_event',
            customerRule.refreshSoonEmailTemplate || refreshSoonTemplate.ruleEmailTemplate,
            customerRule.refreshSoonRuleTemplate || refreshSoonTemplate.ruleTemplate
          );
          date.setHours(0);
          date.setMinutes(0);
          const refreshSoonTags = { timeToTrigger: date.getTime(), daysBeforeDue: 5 };
          const refreshSoonConditionList = [{ property: 'refreshSoonCount', op: '>', value: '0' }];
          const refreshSoonName = `${customerRule.displayName.toLowerCase()}_soon`;
          await RulePageClient.createRuleCondition(
            refreshSoonName,
            refreshSoonTags,
            ruleId,
            refreshSoonConditionList
          );
        }
      }

      const rule = result.rules.find((a) => a.name === customerRule.displayName);

      const ruleType = ruleName; // reassign without changing the name for readability between existing code and refactor code
      // eslint-disable-next-line no-await-in-loop
      await prepareRuleAndConditions(
        RulePageClient,
        result.rules,
        ruleType,
        customerRule,
        defaultTemplate
      );

      if (!rule) {
        fetchAgain = true;
        let ruleId;
        let ruleConditionId;
        const date = new Date();
        switch (ruleName) {
          case 'backlog':
            ruleId = await RulePageClient.createRule(
              customerRule.displayName,
              'detection_event',
              customerRule.emailTemplate || defaultTemplate.ruleEmailTemplate,
              customerRule.ruleTemplate || defaultTemplate.ruleTemplate
            );
            ruleConditionId = [];
            ruleConditionId.push(
              await RulePageClient.createRuleCondition(
                'backlog_allLocations_warning',
                { warningThreshold: 50, criticalThreshold: 80, stage: 'warning', threshold: 50 },
                ruleId,
                [
                  { property: 'backlogCount', op: '<=', value: 80 },
                  { property: 'backlogCount', op: '>', value: 50 }
                ]
              )
            );
            ruleConditionId.push(
              await RulePageClient.createRuleCondition(
                'backlog_allLocations_critical',
                { criticalThreshold: 80, stage: 'critical', threshold: 80 },
                ruleId,
                [{ property: 'backlogCount', op: '>', value: 80 }]
              )
            );
            await ruleConditionId.forEach(async (each) => {
              await RulePageClient.subscribeForRuleCondition(each);
            });
            break;
          case 'idle':
            const {
              interval = DEFAULT_IDLE_INTERVAL,
              triggerBased,
              classes,
              inventoryIdentifierTemplate
            } = customerRule;
            const tags = { duration: 4, classes };
            if (triggerBased) {
              const date = new Date();
              date.setHours(0);
              date.setMinutes(0);
              const formattedTime = date.getTime();
              tags.timeToTrigger = formattedTime;
              tags.inventoryIdentifierTemplate = inventoryIdentifierTemplate;
            } else {
              tags.interval = interval;
            }
            ruleId = await RulePageClient.createRule(
              customerRule.displayName,
              'idle_event',
              customerRule.emailTemplate || defaultTemplate.ruleEmailTemplate,
              customerRule.ruleTemplate || defaultTemplate.ruleTemplate
            );
            ruleConditionId = await RulePageClient.createRuleCondition(
              'idle_allLocations',
              tags,
              ruleId,
              [
                { property: 'duration', op: '>=', value: 4 },
                { property: 'locationId', op: '=', value: 'AllLocations' }
              ],
              'no_repeat'
            );
            await RulePageClient.subscribeForRuleCondition(ruleConditionId);
            break;
          case 'lowStock':
            await RulePageClient.createRule(
              customerRule.displayName,
              'detection_event',
              customerRule.emailTemplate || defaultTemplate.ruleEmailTemplate,
              customerRule.ruleTemplate || defaultTemplate.ruleTemplate
            );
            break;
          case 'missingItem':
            ruleId = await RulePageClient.createRule(
              customerRule.displayName,
              'detection_event',
              customerRule.emailTemplate || defaultTemplate.ruleEmailTemplate,
              customerRule.ruleTemplate || defaultTemplate.ruleTemplate
            );

            ruleConditionId = [];
            ruleConditionId.push(
              await RulePageClient.createRuleCondition('item_missing', {}, ruleId, [
                { property: 'state', op: '=', value: 'missing' }
              ])
            );
            ruleConditionId.push(
              await RulePageClient.createRuleCondition('item_found', {}, ruleId, [
                { property: 'state', op: '=', value: 'found' }
              ])
            );
            await ruleConditionId.forEach(async (each) => {
              await RulePageClient.subscribeForRuleCondition(each);
            });
            break;
          case 'expiration':
            ruleId = await RulePageClient.createRule(
              customerRule.displayName,
              'expiration_event',
              customerRule.emailTemplate || defaultTemplate.ruleEmailTemplate,
              customerRule.ruleTemplate || defaultTemplate.ruleTemplate
            );
            date.setHours(0);
            date.setMinutes(0);
            const expirationTags = { timeToTrigger: date.getTime() };
            const expirationConditionList = [{ property: 'expiredCount', op: '>', value: '0' }];
            const expirationName = 'expiration';
            await RulePageClient.createRuleCondition(
              expirationName,
              expirationTags,
              ruleId,
              expirationConditionList
            );
            break;
          case 'refresh':
            ruleId = await RulePageClient.createRule(
              customerRule.displayName,
              'expiration_event',
              customerRule.emailTemplate || defaultTemplate.ruleEmailTemplate,
              customerRule.ruleTemplate || defaultTemplate.ruleTemplate
            );
            date.setHours(0);
            date.setMinutes(0);
            const refreshTags = { timeToTrigger: date.getTime() };
            const refreshConditionList = [{ property: 'refreshCount', op: '>', value: '0' }];
            const refreshName = customerRule.displayName.toLowerCase();
            await RulePageClient.createRuleCondition(
              refreshName,
              refreshTags,
              ruleId,
              refreshConditionList
            );
            break;
          case 'shipmentIssue':
            ruleId = await RulePageClient.createRule(
              customerRule.displayName,
              'shipment_issue_event',
              customerRule.emailtemplate || defaultTemplate.ruleEmailTemplate,
              customerRule.ruleTemplate || defaultTemplate.ruleTemplate
            );
            ruleConditionId = await RulePageClient.createRuleCondition(
              'shipment_issue_allLocations',
              {},
              ruleId
            );
            await RulePageClient.subscribeForRuleCondition(ruleConditionId);
            break;
          case 'usageLimit':
            ruleId = await RulePageClient.createRule(
              customerRule.displayName,
              'usage_limit_event',
              customerRule.emailTemplate || defaultTemplate.ruleEmailTemplate,
              customerRule.ruleTemplate || defaultTemplate.ruleTemplate
            );
            await RulePageClient.createRuleCondition(
              'usage_over_limit',
              {},
              ruleId,
              [{ property: 'usageLimit', op: '>', value: 0 }],
              'no_repeat'
            );
            break;
          case 'shipmentVerified':
            ruleId = await RulePageClient.createRule(
              customerRule.displayName,
              'shipment_verified_event',
              customerRule.emailtemplate || defaultTemplate.ruleEmailTemplate,
              customerRule.ruleTemplate || defaultTemplate.ruleTemplate
            );
            ruleConditionId = await RulePageClient.createRuleCondition(
              'shipment_verified_allLocations',
              {},
              ruleId,
              undefined,
              'no_repeat'
            );
            break;
          case 'expedited':
            ruleId = await RulePageClient.createRule(
              customerRule.displayName,
              'expedited_event',
              customerRule.emailtemplate || defaultTemplate.ruleEmailTemplate,
              customerRule.ruleTemplate || defaultTemplate.ruleTemplate
            );
            ruleConditionId = await RulePageClient.createRuleCondition(
              'expedited',
              {},
              ruleId,
              [{ property: 'state', op: '=', value: 'expedited' }],
              'no_repeat'
            );
            break;
          default:
            break;
        }
      }
    }

    if (fetchAgain) {
      result = await RulePageClient.fetchRules();
    }
    customerRules.forEach((config) => {
      const currentRule = result.rules.find((rule) => rule.name === config.displayName);
      if (currentRule) {
        if (config.optimalStock) {
          const optimalRule = result.rules.find((rule) => rule.name === 'Optimal Stock');
          const outOfStockRule = result.rules.find((rule) => rule.name === 'Out of Stock');
          ruleNotificationMap['Stock Alerts'] = {
            ruleId: {
              minStock: currentRule.id,
              optimalStock: optimalRule.id,
              outOfStock: outOfStockRule.id
            },
            enabled: currentRule.hasSubscriptions,
            ruleConditions: {
              minStockConditions: currentRule.ruleConditions,
              optimalConditions: optimalRule.ruleConditions,
              outOfStockConditions: outOfStockRule.ruleConditions
            },
            eventId: currentRule.eventId,
            ruleConfig: config,
            subscriptions: {
              minStock: currentRule.hasSubscriptions,
              outOfStock: outOfStockRule.hasSubscriptions,
              optimalStock: optimalRule.hasSubscriptions
            }
          };
        } else if (config.displayName === 'Expiration') {
          const soonToExpireRule = result.rules.find((rule) => rule.name === 'Expiring Soon');
          ruleNotificationMap.Expiration = {
            ruleId: {
              expiration: currentRule.id,
              soonToExpire: soonToExpireRule && soonToExpireRule.id
            },
            enabled: currentRule.hasSubscriptions,
            ruleConditions: {
              expirationConditions: currentRule.ruleConditions,
              soonToExpireConditions: soonToExpireRule && soonToExpireRule.ruleConditions
            },
            eventId: currentRule.eventId,
            ruleConfig: config,
            subscriptions: {
              expiration: currentRule.hasSubscriptions,
              soonToExpire: soonToExpireRule && soonToExpireRule.hasSubscriptions
            }
          };
        } else if (config.displayName === 'Recharge' || config.displayName === 'Calibration') {
          const refreshSoonRule = result.rules.find(
            (rule) => rule.name === `${config.displayName} Soon`
          );
          ruleNotificationMap[config.displayName] = {
            ruleId: {
              refresh: currentRule.id,
              refreshSoon: refreshSoonRule && refreshSoonRule.id
            },
            enabled: currentRule.hasSubscriptions,
            ruleConditions: {
              refreshConditions: currentRule.ruleConditions,
              refreshSoonConditions: refreshSoonRule && refreshSoonRule.ruleConditions
            },
            eventId: currentRule.eventId,
            ruleConfig: config,
            subscriptions: {
              refresh: currentRule.hasSubscriptions,
              refreshSoon: refreshSoonRule && refreshSoonRule.hasSubscriptions
            }
          };
        } else {
          ruleNotificationMap[currentRule.name] = {
            ruleId: currentRule.id,
            enabled: currentRule.hasSubscriptions,
            ruleConditions: currentRule.ruleConditions,
            eventId: currentRule.eventId,
            ruleConfig: config
          };
        }
      }
    });

    const notificationSubscriptionFeatureEnabled = true;
    return {
      locationDataList: result.locations,
      trackingSessionList: result.trackingSessions,
      itemTypeData: uniqueItemTypes,
      ruleNotificationMap,
      notificationFeatureEnabled: notificationSubscriptionFeatureEnabled,
      shipmentsTrackingLocationCategory,
      locationCategory
    };
  };
}

const DEFAULT_IDLE_INTERVAL = 5;

export default new AlertService();