import React from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import {
  DateTimePicker as DTPicker,
  DatePicker as DPicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { EventRounded, Clear } from '@material-ui/icons';
import Style from './DateTimePicker.module.css';
import PropTypes from 'prop-types';
import moment from 'moment';

const theme = createTheme({
  palette: {
    primary: {
      main: '#166ED5'
    },
    secondary: {
      main: '#FFFFFF'
    }
  }
});

const TimePicker = ({
  error,
  errorMessage,
  onTimeChange,
  value,
  readOnly,
  pickerMode,
  format,
  inputClassName,
  inputContainerClassName,
  showIcon
}) => {
  const Picker = (props) => {
    switch (pickerMode) {
      case 'datetime':
        return <DTPicker {...props} />;

      case 'date':
      default:
        return <DPicker {...props} />;
    }
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Picker
            value={value && moment(value)}
            onChange={(newDate) => {
              onTimeChange(newDate.valueOf());
            }}
            inputVariant="outlined"
            format={format}
            TextFieldComponent={({
              onClick,
              onKeyDown,
              value: inputValue,
              onChange = () => {}
            }) => {
              if (readOnly) {
                return (
                  <p className={`${Style.read_only_text} ${inputClassName}`}>
                    {!value ? '--' : inputValue}
                  </p>
                );
              }
              return (
                <div className={`${Style.input_container} ${inputContainerClassName}`}>
                  <input
                    onChange={onChange}
                    className={`${Style.input} ${!showIcon && Style.input_with_no_icon} ${error &&
                      Style.input_error} ${inputClassName}`}
                    onClick={onClick}
                    onKeyDown={onKeyDown}
                    value={!value ? '' : inputValue}
                  />
                  {showIcon && (
                    <div className={Style.input_icon_container}>
                      {!!value && (
                        <Clear
                          onClick={() => {
                            onTimeChange(undefined);
                          }}
                        />
                      )}
                      <EventRounded onClick={onClick} />
                    </div>
                  )}
                </div>
              );
            }}
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
      {error && <p className={Style.error_message}>{errorMessage}</p>}
    </div>
  );
};

TimePicker.defaultProps = {
  error: false,
  errorMessage: '',
  onTimeChange: () => {},
  value: 0,
  readOnly: false,
  pickerMode: 'date',
  format: 'MM/dd/yyyy',
  inputClassName: '',
  inputContainerClassName: '',
  showIcon: true
};

TimePicker.propTypes = {
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  onTimeChange: PropTypes.func,
  value: PropTypes.number,
  readOnly: PropTypes.bool,
  pickerMode: PropTypes.string,
  format: PropTypes.string,
  inputClassName: PropTypes.string,
  inputContainerClassName: PropTypes.string,
  showIcon: PropTypes.bool
};

export const DateTimePicker = (props) => {
  return <TimePicker pickerMode="datetime" format="MM/dd/yyyy hh:mm a" {...props} />;
};

export const DatePicker = (props) => {
  return <TimePicker pickerMode="date" format="MM/dd/yyyy" {...props} />;
};

export default DateTimePicker;
