import React from 'react';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import PropTypes from 'prop-types';
import Style from './AddInventoryV2Components.module.css';

const InventoryModalHeader = ({ onClose, error, headerMessage }) => (
  <div>
    <div className={Style.title_container}>
      <div className={Style.inv_title_container}>
        <div className={Style.icon}>
          <img
            src={require('../../img/inventory_icon.svg')}
            alt="inventory_icon"
            width="40px"
            height="40px"
          />
        </div>
        <p className={Style.header_title}>Onboard Item</p>
        <p className={`${Style.header_additional_message} ${error ? Style.error : Style.success}`}>
          {headerMessage}
        </p>
      </div>
      <div style={{ float: 'right', paddingRight: '1%' }}>
        <div onClick={onClose} className={Style.close_icon}>
          <CloseRoundedIcon />
        </div>
      </div>
    </div>
  </div>
);

InventoryModalHeader.defaultProps = {
  error: false,
  headerMessage: ''
};

InventoryModalHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
  error: PropTypes.bool,
  headerMessage: PropTypes.string
};

export default InventoryModalHeader;
