import { useEffect, useState } from 'react';

export const useImportTagPrinterScripts = (onScriptLoad) => {
  useEffect(() => {
    let browserScript;
    let zebraBrowserScript;

    let browserScriptLoaded = false;
    let zebraBrowserScriptLoaded = false;

    const existingBrowserScript = document.getElementById('browserPrint');
    if (!existingBrowserScript) {
      browserScript = document.createElement('script');
      browserScript.type = 'text/javascript';
      browserScript.src = 'zebra-printer/BrowserPrint.js';
      browserScript.id = 'browserPrint';
      document.body.appendChild(browserScript);

      // if both the required script are loaded, run the callback
      browserScript.onload = () => {
        browserScriptLoaded = true;
        if (zebraBrowserScriptLoaded) {
          onScriptLoad();
        }
      };
    }

    const existingZebraBrowserScript = document.getElementById('browserPrintZebra');
    if (!existingZebraBrowserScript) {
      zebraBrowserScript = document.createElement('script');
      zebraBrowserScript.type = 'text/javascript';
      zebraBrowserScript.src = 'zebra-printer/BrowserPrint-Zebra.js';
      zebraBrowserScript.id = 'browserPrintZebra';
      document.body.appendChild(zebraBrowserScript);

      // if both the required script are loaded, run the callback
      zebraBrowserScript.onload = () => {
        zebraBrowserScriptLoaded = true;
        if (browserScriptLoaded) {
          onScriptLoad();
        }
      };
    }

    if (existingBrowserScript && existingZebraBrowserScript) {
      onScriptLoad();
    }

    return () => {
      if (existingBrowserScript && existingZebraBrowserScript) {
        existingBrowserScript.remove();
        existingZebraBrowserScript.remove();
      }
      document.body.removeChild(browserScript);
      document.body.removeChild(zebraBrowserScript);
    };
  }, []);
};

export const getDefaultPrinter = () => {
  return new Promise((resolve, reject) => {
    window.BrowserPrint.getDefaultDevice(
      'printer',
      (device) => {
        resolve(device);
      },
      (error) => {
        reject(
          `Cannot query for printers.
          Ensure that Zebra Browser Print is running.`
        );
      }
    );
  });
};

export const getAvailablePrinters = () => {
  return new Promise((resolve, reject) => {
    window.BrowserPrint.getLocalDevices(
      (deviceList) => {
        resolve(deviceList.printer);
      },
      (error) => {
        reject(`Cannot query for printers`);
      }
    );
  });
};

export const getPrinterStatus = (printer) => {
  const zebraPrinter = new window.Zebra.Printer(printer);
  return new Promise((resolve, reject) => {
    zebraPrinter.getStatus(
      (status) => {
        resolve({ message: status.getMessage(), readyToPrint: status.isPrinterReady() });
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export const getPrinterInfo = (printer) => {
  const zebraPrinter = new window.Zebra.Printer(printer);
  return new Promise((resolve, reject) => {
    zebraPrinter.getInfo(
      (info) => {
        resolve(info);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export const printTag = (printer, tagToPrint, item) => {
  const zebraPrinter = new window.Zebra.Printer(printer);
  return new Promise((resolve, reject) => {
    const { name, color_ts, sku_ts } = item;

    const printZPLCommand = `^XA^RS8^FO200,70^A0N,50^FD${name.value ||
      ''}^FS^FO200,140^A0N,50^FD${color_ts.value || ''}^FS^FO200,210^A0N,50^FD${sku_ts.value ||
      ''}^FS^FO200,400^BY4^BCI,100,Y,N,N^FD${tagToPrint ||
      ''}^FS^FO1000,70^BQN,2,6^FDMM,A${tagToPrint || ''}^FS^RFW,H^FD${tagToPrint || ''}^FS^XZ`;

    zebraPrinter.send(
      printZPLCommand,
      (success) => {
        resolve(success);
      },
      (error) => {
        reject(error);
      }
    );
  });
};
