import React, { Fragment, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ModalForm } from '../../../../components/modal-form';
import { AddLocationFooter } from './add-location-form-footer';
import { AddLocationFormBody } from './add-location-form-body';
import { AddLocationFormHeader } from './add-location-form-header';
import './style.css';
import { useXemelgoClient } from '../../../../services/xemelgo-service';
import LoadingCircle from '../../../../components/loading/LoadingCircle';
import { FeatureConfigurationProvider } from '../../../../domains/feature-configuration-provider';
import { getDetectorResourceManager } from '../../../domains/resource-managers/detector-resource-manager';

const fetchLocationOptions = (locationClient, categoryName) => {
  return locationClient.getLocationsOfCategory(categoryName).then((results) => {
    const sorted = results.sort((loc1, loc2) => loc1.getName().localeCompare(loc2.getName()));
    const options = sorted.map((loc) => {
      const nameSegments = [loc.getName()];
      const parentLoc = loc.getParent();
      if (parentLoc) {
        nameSegments.push(parentLoc.getName());
      }
      return {
        key: loc.getId(),
        value: nameSegments.join(' - ')
      };
    });
    return options;
  });
};

const constructParentProperty = (name, displayName) => {
  return {
    name,
    displayName,
    options: [],
    optionsProvided: true,
    __addable: true
  };
};

const retrieveProperties = (
  modelConfigProvider,
  defaultPropertyOrders = [],
  additionalFilterFn = (prop) => prop
) => {
  const propertyMap = modelConfigProvider.getPropertyMap();
  const propertyOrders = modelConfigProvider.getValue(
    'propertyOrders',
    'array',
    defaultPropertyOrders
  );

  const filterProperties = propertyOrders
    .map((propertyId) => {
      const property = propertyMap[propertyId];
      return { name: propertyId, ...property };
    })
    .filter(additionalFilterFn);

  return filterProperties;
};

const FeatureId = 'addResource';
export const AddLocationForm = ({ configuration, modelId, show, onFormClosed, onSubmit }) => {
  const [submitTicket, setSubmitTicket] = useState(null);
  const [numberOfSection, setNumberOfSection] = useState(1);
  const [showRemoveLastOperation, setShowRemoveLastOperation] = useState(false);
  const [xemelgoClient] = useState(useXemelgoClient());
  const [loading, setLoading] = useState(false);
  const [creationError, setCreationError] = useState(false);

  const [categoryName, setCategoryName] = useState(modelId);
  const [providedArgument, setProvidedArgument] = useState({});
  const [properties, setProperties] = useState(null);

  /**
   * Name: preparePropertiesAndArguments
   * Parse configuration for required information. Query information from API if needed.
   */
  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (!configuration || !modelId || !xemelgoClient) {
      return cancelCallback;
    }

    // configuration provided information.
    const configurationProvider = FeatureConfigurationProvider.parse(FeatureId, configuration);
    const modelConfigProvider = configurationProvider.getModel(modelId);
    const parentRef = modelConfigProvider.getValue('locatedInModel', 'string', null);

    let parentProperty = null;
    // prepare api-query information.
    if (parentRef) {
      const parentLocationModelProvider = configurationProvider.getModel(parentRef);
      const parentCategory = parentLocationModelProvider.getValue('category', 'object', {
        name: parentRef
      });
      const { name: parentCategoryName, displayName: parentCategoryDisplayName } = parentCategory;
      const parentProvidedArgumentName = 'parentId';
      parentProperty = constructParentProperty(
        parentProvidedArgumentName,
        parentCategoryDisplayName
      );
      const locationClient = xemelgoClient.getLocationClient();
      fetchLocationOptions(locationClient, parentCategoryName).then((options) => {
        if (!cancelled) {
          setProvidedArgument({ [parentProvidedArgumentName]: options });
        }
      });
    }

    // prepare the properties
    const modelConfig = modelConfigProvider.getDefinitionObject();
    const { category = { displayName: modelId }, canAttachDetector } = modelConfig;
    const { displayName: locationCategoryName } = category;

    const filterAddablePropertiesFn = (prop) => {
      const { __addable: canAdd } = prop;
      return canAdd;
    };

    const filteredProperties = retrieveProperties(
      modelConfigProvider,
      ['name', 'description'],
      filterAddablePropertiesFn
    );

    if (parentProperty) {
      filteredProperties.unshift(parentProperty);
    }

    if (canAttachDetector) {
      const detectorModelProvider = configurationProvider.getModel('detector');
      const detectorProperties = retrieveProperties(
        detectorModelProvider,
        ['vid', 'class'],
        filterAddablePropertiesFn
      );
      detectorProperties.forEach((property) => {
        property.detectorProperty = true;
      });
      filteredProperties.push(...detectorProperties);
    }

    if (!cancelled) {
      setProperties(filteredProperties);
      setCategoryName(locationCategoryName);
    }

    return cancelCallback;
  }, [configuration, modelId, xemelgoClient]);

  /**
   * Handle the submit button click event.
   * This will signal the submit workflow for the children component.
   */
  const onSubmitButtonClickedCallback = useCallback(() => {
    setSubmitTicket(Date.now().toString());
  }, []);

  /**
   * Handles the submit workflow after forms have been validated.
   */
  const onPayloadValidated = useCallback(
    (payloads) => {
      setLoading(true);
      const locationClient = xemelgoClient.getLocationClient();

      const submitSection = async (payload) => {
        const { name, description, category: locCategory, parentId, vid, identifier } = payload;
        let createdLocation;
        try {
          createdLocation = await locationClient.createLocation(
            name,
            locCategory,
            description,
            parentId,
            '',
            '',
            identifier
          );
        } catch (error) {
          setCreationError(error);
        }
        const { id: createdLocationId } = createdLocation;
        if (vid) {
          const detectorPayload = { ...payload, location: createdLocationId };
          const detectorRM = getDetectorResourceManager(xemelgoClient);
          await detectorRM.createDetectorAndAttachToLocation(detectorPayload);
        }
      };

      const promises = payloads.map((payload) => {
        return submitSection(payload);
      });

      Promise.all(promises).then(
        () => {
          setLoading(false);
          onSubmit();
        },
        () => {
          setLoading(false);
        }
      );
    },
    [xemelgoClient, setLoading, onSubmit]
  );

  /**
   * handle the request to increase number of section in the form.
   */
  const increaseNumberOfSectionCallback = useCallback(() => {
    setNumberOfSection(numberOfSection + 1);
    setShowRemoveLastOperation(true);
  }, [numberOfSection]);

  /**
   * Handle the request to decrease the number of section in the form.
   */
  const decreaseNumberOfSectionCallback = useCallback(() => {
    const countIfDecrease = numberOfSection - 1;
    const effectiveSessionCount = countIfDecrease > 1 ? countIfDecrease : 1;

    setNumberOfSection(effectiveSessionCount);
    setShowRemoveLastOperation(effectiveSessionCount > 1);
  }, [numberOfSection, setShowRemoveLastOperation]);

  return (
    <Fragment>
      {loading && <LoadingCircle />}
      <ModalForm
        show={show}
        title={<AddLocationFormHeader label={`Add ${categoryName}`} onCancel={onFormClosed} />}
        footer={
          /* eslint-disable react/jsx-wrap-multilines */
          <AddLocationFooter
            onCancel={onFormClosed}
            onSubmit={onSubmitButtonClickedCallback}
            addAnotherOnClick={increaseNumberOfSectionCallback}
            removeLastOnClick={decreaseNumberOfSectionCallback}
            showRemoveLastOperation={showRemoveLastOperation}
          />
        }
        body={
          /* eslint-disable react/jsx-wrap-multilines */
          !properties ? (
            <LoadingCircle />
          ) : (
            <AddLocationFormBody
              submitRequestTicket={submitTicket}
              numberOfSection={numberOfSection}
              onSubmit={onPayloadValidated}
              properties={properties}
              providedArgument={providedArgument}
              categoryName={categoryName}
              creationError={creationError}
            />
          )
        }
        className="add-form-modal"
      />
    </Fragment>
  );
};

AddLocationForm.defaultProps = {
  show: true,
  onFormClosed: () => {},
  onSubmit: () => {},
  configuration: {}
};

AddLocationForm.propTypes = {
  configuration: PropTypes.shape({
    modelMap: PropTypes.objectOf(
      PropTypes.shape({
        category: PropTypes.shape({
          name: PropTypes.string.isRequired,
          displayName: PropTypes.string.isRequired
        }),
        parentLocationCategory: PropTypes.shape({
          name: PropTypes.string.isRequired,
          displayName: PropTypes.string.isRequired
        }),
        propertyOrders: PropTypes.arrayOf(PropTypes.string),
        properties: PropTypes.objectOf(
          PropTypes.shape({
            __addable: PropTypes.bool,
            __optional: PropTypes.bool,
            __optionallyDependsOn: PropTypes.arrayOf(PropTypes.string),
            displayName: PropTypes.string,
            input: PropTypes.oneOfType([
              PropTypes.bool,
              PropTypes.string,
              PropTypes.arrayOf(
                PropTypes.shape({
                  key: PropTypes.string.isRequired,
                  value: PropTypes.string.isRequired
                })
              )
            ])
          })
        )
      })
    )
  }),
  modelId: PropTypes.string.isRequired,
  show: PropTypes.bool,
  onFormClosed: PropTypes.func,
  onSubmit: PropTypes.func
};
