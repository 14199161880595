import React from 'react';
import Style from './ScreenFrame.module.css';
import PropTypes from 'prop-types';

const ScreenFrame = ({
  title,
  titleIconComponent,
  color,
  children,
  titleRightComponent,
  verticalExpandDisabled
}) => {
  return (
    <div
      className={`${Style.grid} ${Style.page_frame} ${verticalExpandDisabled &&
        Style.fixed_height}`}
      style={{ borderTopColor: color }}
    >
      <div className={Style.title_container}>
        {titleIconComponent && (
          <div className={Style.title_icon} style={{ color }}>
            {typeof titleIconComponent === 'function' ? titleIconComponent() : titleIconComponent}
          </div>
        )}
        <p className={Style.title}>{title}</p>
        {titleRightComponent && (
          <div className={Style.title_right_container}>
            {typeof titleRightComponent === 'function'
              ? titleRightComponent()
              : titleRightComponent}
          </div>
        )}
      </div>
      <div className={`${Style.flex_column} ${verticalExpandDisabled && Style.overflow_hidden}`}>
        {children}
      </div>
    </div>
  );
};

ScreenFrame.defaultProps = {
  title: '',
  titleIconComponent: null,
  titleRightComponent: null,
  color: '#C8CBD3',
  verticalExpandDisabled: false
};

ScreenFrame.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  titleIconComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  titleRightComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  color: PropTypes.string,
  verticalExpandDisabled: PropTypes.bool
};

export default ScreenFrame;
