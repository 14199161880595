import React, { Component, Fragment } from 'react';
import { XemelgoService } from '../../services/XemelgoService';
import { getFormattedDate, getTimezone } from 'common/Utilities';
import { CollapsibleListView } from '../../components/collapsible-list-view';
import Spinner from 'react-bootstrap/Spinner';

export default class ExpiredItemRuleContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      timeToTrigger: '00:00',
      daysEarly: 5,
      editedTime: undefined,
      editedDays: undefined
    };
  }

  componentDidMount() {
    const { ruleConditionsMap } = this.props;
    this.parseConditionData(ruleConditionsMap);
  }

  parseConditionData = (ruleConditionsMap) => {
    const { expirationConditions = [], soonToExpireConditions = [] } = ruleConditionsMap;
    if (expirationConditions.length) {
      const expirationCondition = expirationConditions[0];
      const { tags = {} } = expirationCondition;
      const { timeToTrigger } = tags;
      const time = getFormattedDate(timeToTrigger, 'HH:mm');
      this.setState({
        timeToTrigger: time
      });
    }
    if (soonToExpireConditions.length) {
      const soonToExpireCondition = soonToExpireConditions[0];
      const soonToExpireTags = soonToExpireCondition.tags || {};
      const { daysBeforeExpired } = soonToExpireTags;
      this.setState({
        daysEarly: daysBeforeExpired
      });
    }
    this.setState({
      loading: false
    });
  };

  handleSubmit = async () => {
    const { editedTime, timeToTrigger, editedDays, daysEarly } = this.state;
    
    const { ruleConditionsMap, ruleId, ruleConfig } = this.props;
    const { expirationConditions = [], soonToExpireConditions = [] } = ruleConditionsMap;
    const { expiration, soonToExpire } = ruleId;

    const time = editedTime ? editedTime.split(':') : timeToTrigger.split(':');
    const date = new Date();
    date.setHours(+time[0])
    date.setMinutes(+time[1]);
    
    // Getting offset from datetime
    const minutesOffSet = (+time[0])*60 + (+time[1])
    const formattedTime = date.getTime();
    const RulePageClient = XemelgoService.getClient().getRulePageClient();
    const tags = { 
      timeToTrigger: formattedTime,
      minutesOffSet
    };
    const conditionList = [
      { property: 'expiredCount', op: '>', value: '0' }
    ];
    const name = `expiration`;
    if (expirationConditions.length) {
      const currentExpirationCondition = expirationConditions[0];
      const { id } = currentExpirationCondition;
      await RulePageClient.updateRuleCondition(id, name, tags);
    } else {
      await RulePageClient.createRuleCondition(
        name, 
        tags, 
        expiration, 
        conditionList
      );
    }

    if (ruleConfig.earlyExpiration) {
      const soonToExpireTags = { 
        timeToTrigger: formattedTime, 
        daysBeforeExpired: editedDays || daysEarly,
        minutesOffSet
      };
      const soonToExpireConditionList = [
        { property: 'soonToExpireTypeCount', op: '>', value: '0' }
      ];
      const soonToExpireName = 'expiring_soon';
      if (soonToExpireConditions.length) {
        const currentSoonToExpireCondition = soonToExpireConditions[0];
        const { id } = currentSoonToExpireCondition;
        await RulePageClient.updateRuleCondition(id, soonToExpireName, soonToExpireTags);
      } else {
        await RulePageClient.createRuleCondition(
          soonToExpireName,
          soonToExpireTags,
          soonToExpire,
          soonToExpireConditionList
        );
      }
    }

    this.setState({
      editedDays: undefined,
      editedTime: undefined
    });

    await this.props.onLoad();
  };

  handleCancel = () => {
    this.setState({
      editedTime: undefined,
      editedDays: undefined
    })
  }

  renderSwitches() {
    const { timeToTrigger, daysEarly, editedTime, editedDays } = this.state;
    const { ruleConfig } = this.props;
    return (
      <Fragment>
        <div className="alerts-settings-container">
          <div className="alerts-settings-text">
            Notification Time
            <div className="switch-helper-text">When should the notification be sent?</div>
          </div>
          <div className="early-expiration-container">
            <div>
              <input
                className={editedTime === '' ? 'time_input input-error' : 'time_input'}
                onChange={(event) => {
                  this.setState({ editedTime: event.target.value });
                }}
                id="notfication-time"
                type="time"
                value={editedTime !== undefined ? editedTime : timeToTrigger}
              />
              {`(${getTimezone()})`}
            </div>
            {editedTime === '' && <p className="error-text">Enter a time ex. 12:00 AM</p>}
          </div>
        </div>
        {ruleConfig.earlyExpiration && (
          <div className="alerts-settings-container">
            <div className="alerts-settings-text">
              Early Notification
              <div className="switch-helper-text">
                Receive notifications when items will expire in the selected number of days
              </div>
            </div>
            <div className="early-expiration-container">
              <div>
                <input
                  className={
                    editedDays === '' || editedDays < 1
                      ? 'early-expiration input-error'
                      : 'early-expiration'
                  }
                  type="number"
                  onChange={(event) => {
                    this.setState({ editedDays: event.target.value });
                  }}
                  min="1"
                  value={editedDays !== undefined ? editedDays : daysEarly}
                />
                days
              </div>
              {(editedDays === '' || editedDays < 1) && (
                <p className="error-text">Enter a number greater than or equal to 1</p>
              )}
            </div>
          </div>
        )}
        {(editedTime !== undefined || editedDays !== undefined) && (
          <div className="button-container">
            <button
              className="cancel-button"
              onClick={() => {
                this.handleCancel();
              }}
            >
              Cancel
            </button>
            <button
              className={'default-button'}
              disabled={editedTime === '' || editedDays === '' || editedDays < 1}
              onClick={() => {
                this.setState({
                  daysEarly: editedDays,
                  timeToTrigger: editedTime,
                  loading: true
                });
                this.handleSubmit();
              }}
            >
              Save
            </button>
          </div>
        )}
      </Fragment>
    );
  }

  render() {
    const { loading } = this.state;
    return (
      <div>
        <div>
          <p className="tabbedSectionComponent-contentTitle">Expiration</p>
          <p style={{ color: '#343434' }}>
            Sends a notification if any item expires or will expire soon. Select a time to check for
            expired items.
          </p>
        </div>
        <div>
          {loading ? (
            <div className="loading-circle">
              <Spinner animation="border" />
            </div>
          ) : (
            <CollapsibleListView
              className="alert-switches"
              key="settings"
              title="Alert Settings"
              content={this.renderSwitches()}
              openAtStart
            />
          )}
        </div>
      </div>
    );
  }
}
